import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TextTemplateComponent } from "../../test/text-template/text-template.component";
import { DataCoding, DetectedDataCodingResponse } from '../models/data-coding.model';
import { Destination } from '../models/destination.model';
import { PduDetails } from '../models/pdu-details.model';
import { SupplierSessionAssignment } from '../models/supplier-session-assignment.model';
import { TestGroupInfo } from '../models/test-group-info.model';
import { NPI_ITEMS, TON_ITEMS, TestGroup, getItemLabel } from '../models/test-group.model';
import { TestComment, TestDetails, TestResult, TestResultsCollection } from '../models/test-result.model';
import { UdhTlv } from '../models/udh-tlv.model';
import { BackendChannelService } from "./backend-channel.service";
import { RestUtils } from './rest-utils';
import { TemplatesService } from "./templates.service";
declare var moment: any;

@Injectable()
export class LiveNumberTestingService {

    //PDU decoding JS library
    // @ts-ignore
    pdu = require('pdu');
    http: HttpClient;
    utils = new RestUtils();
    headers = new HttpHeaders();
    private actionSubject: Subject<LntActionData> = new Subject<LntActionData>();
    action$ = this.actionSubject.asObservable();

    static DEFAULT_PRIORITY = 1;
    static PRIORITY_OPTIONS = [0, 1, 2, 3];
    ELASTIC_FILTERS: string[] = ['searchByComment', 'searchByText', 'commentDate'];

    constructor(http: HttpClient, private backendChannel: BackendChannelService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: LntActionData) {
        this.actionSubject.next(action);
    }

    one(id: number): Observable<TestResult> {
        let url = this.utils.buildUrl('ROLE/ntc/test-results/' + id, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get(url, options).pipe(map(_ => {
            return this.prepareTestResult(_);
        }));
    }

    testGroupInfo(testGroupId: number): Observable<TestGroupInfo> {
        let url = this.utils.buildUrl(`ROLE/ntc/test-groups/${testGroupId}/unified-info`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestGroupInfo>(url, options).pipe(
            map(info => {
                if (info.contentTexts && info.contentTexts.length) {
                    info.contentTexts.forEach(ct => ct.fromServer = true);
                }
                return info;
            })
        );
    }

    encodings(): Observable<DataCoding[]> {
        let url = this.utils.buildUrl('ROLE/ntc/encodings', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        const toHex = (d) => {
            return ("0" + (Number(d).toString(16))).slice(-2).toUpperCase()
        };

        return this.http.get<DataCoding[]>(url, options).pipe(map(encodings => {
            return encodings.map(_ => {
                _.dataCodingHex = '0x' + toHex(_.dataCoding);
                return _;
            });
        }));
    }

    detectEncoding(text: string): Observable<DataCoding> {
        let url = this.utils.buildUrl('ROLE/ntc/encodings/detect', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<DetectedDataCodingResponse>(url, { text: text }, options).pipe(
            map(_ => _.encoding)
        );
    }

    results(params: AllRequestParams): Observable<TestResultsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
            onlyMyTests: params.onlyMyTests
        };

        let url = this.utils.buildUrl('ROLE/ntc/test-results', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestResultsCollection>(url, options).pipe(map(response => {
            response.content = response.content.map(res => this.prepareTestResult(res));
            return response;
        }));
    }

    prepareTestResult(result) {
        if (result.textSent) {
            result.textSentBackend = result.textSent;
            result.textSentBackend = result.textSentBackend.replaceAll(TextTemplateComponent.PLACEHOLDER_MARKER, result.telqId);
            result.textSentBackend = result.textSentBackend.replaceAll('@sender', result.senderSent);
            if (result.textSentBackend.indexOf(result.telqId) === -1) {
                result.textSentBackend = result.telqId + ' ' + result.textSentBackend;
            }
        }
        return result;
    }

    shouldRunOnElasticSearch(searchParams: SearchParams): Boolean {
        return (searchParams.commentText !== null && searchParams.commentText.length > 0) ||
            (searchParams.commentDateFrom !== null && searchParams.commentDateFrom.length > 0) ||
            (searchParams.commentDateTo !== null && searchParams.commentDateTo.length > 0) ||
            (searchParams.text !== null && searchParams.text.length > 0)
    }

    searchResults(params: AllRequestParams, searchParams: SearchParams): Observable<TestResultsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
            searchType: this.shouldRunOnElasticSearch(searchParams) ? "ES" : "BASIC"
        };
        queryParams.sort = ['id,desc'];
        let url = this.utils.buildUrl('ROLE/stats/test-results/search', queryParams);

        for (let i in queryParams) {
            searchParams[i] = queryParams[i];
        }

        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<TestResultsCollection>(url, searchParams, options).pipe(map(_ => {
            let response = _;
            response.content = response.content.map(res => this.prepareTestResult(res));
            return response;
        }));
    }

    run(group: CreateTestGroup): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/test-groups`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, group, options);
    }

    recentDestinations(): Observable<Destination[]> {
        let url = this.utils.buildUrl('ROLE/ntc/recent-destinations', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Destination[]>(url, options);
    }

    swapNibbles(senderHex: string): string {
        return this.pdu.deSwapNibbles(senderHex);
    }

    parsePdu(pdu: string) {
        return this.pdu.parse(pdu, true);
    }

    gsm7bitDecode(hexString: string): string {
        if (hexString?.length) {

            let binaryString = '';
            for (let i = 0; i < hexString.length; i += 2) {
                const byte = hexString.substring(i, i + 2);
                const binary = parseInt(byte, 16).toString(2).padStart(8, '0');
                binaryString = binary + binaryString;
            }
            let decodedString = '';
            for (let i = 0; i < binaryString.length; i += 7) {
                const septet = binaryString.substring(i, i + 7).padStart(7, '0');
                const charCode = parseInt(septet, 2);
                decodedString += String.fromCharCode(charCode);
            }
            return decodedString.split('').reverse().join('');
        }
        return null;
    }


    getResultDetailsText(result: TestResult): Observable<TestDetails[]> {
        return forkJoin([
            this.pduDetails(result.id),
            this.oneTestGroup(result.testGroupId),
            this.encodings()
        ]).pipe(map(data => {
            let text = [];
            const pdu: PduDetails = data[0];
            const group: TestGroup = data[1];
            const encodings = data[2].map(_ => {
                return { id: _.id, label: _.name };
            });
            const testCaseSummary = [];

            // General ...
            text.push('ID: ' + result.id);
            text.push('Test group ID: ' + group.id);
            if (result.taskId) {
                text.push('Scheduled task ID: ' + result.taskId);
            }
            text.push('Date: ' + moment(result.createdAt).format('DD MMM YYYY HH:mm:ss'));
            text.push('DLR status: ' + result.messageState);
            text.push('DLR delay: ' + result.smppDelay + ' s');
            text.push('Rec. status: ' + result.testStatus);
            text.push('Rec. delay: ' + result.receiptDelay + ' s');
            text.push('Sender sent: ' + result.senderSent);
            text.push('Sender delivered: ' + result.senderDelivered);
            text.push('Text sent: ' + result.textSent);
            text.push('Text delivered: ' + result.textDeliveredFull);
            text.push('Phone: ' + result.destinationDto.phone);
            if (result.smscInfoDto) {
                text.push('SMSC: ' + result.smscInfoDto.phone);
            }
            if (group.callerIp) {
                text.push('IP (Browser/Client): ' + group.callerIp);
            }
            testCaseSummary.push({
                id: 'testoverview',
                title: 'Test Overview',
                data: text
            });
            text = [];

            // Test settings ...
            text.push('Country: ' + this.formatDestination(result.destinationDto));
            text.push('Supplier: ' + result.supplierDto.title + ' ' + result.supplierDto.routeType);
            let testIdTextType = TemplatesService.getLabelType(result.telqIdType);
            if (TemplatesService.showCaseForType(result.telqIdType)) {
                testIdTextType += `, ${TemplatesService.getLabelCase(result.telqIdCase)}`;
            }
            if (TemplatesService.showLengthForType(result.telqIdType)) {
                testIdTextType += `, ${result.telqIdLength}`;
            }
            text.push('Test ID text: ' + testIdTextType);
            if (group.ttl && (group.ttl % 60) === 0) {
                text.push('TTL: ' + parseInt(String(group.ttl / 60)) + ' min');
            } else {
                text.push('TTL: ' + group.ttl + ' sec');
            }
            testCaseSummary.push({
                id: 'testsettings',
                title: 'Test settings',
                data: text
            });
            text = [];

            if (group.tlvDtos?.length) {
                group.tlvDtos.forEach((td, index) => {
                    text.push(`TLV Tag ${index + 1}: ${td.tagHex}`)
                    text.push(`TLV Value ${index + 1}: ${td.valueHex}`)
                });
            }
            if (group.udhDtos?.length) {
                group.udhDtos.forEach((td, index) => {
                    text.push(`UDH Tag ${index + 1}: ${td.tagHex}`)
                    text.push(`UDH Value ${index + 1}: ${td.valueHex}`)
                });
            }
            text.push('Protocol ID: ' + (group.protocolId !== null ? group.protocolId : 'Automatic'));
            text.push('Source TON: ' + getItemLabel(TON_ITEMS, group.srcTon));
            text.push('Source NPI: ' + getItemLabel(NPI_ITEMS, group.srcNpi));
            text.push('Data Coding: ' + getItemLabel(encodings, group.encodingId));
            const validityPeriod = group.validityPeriod ? group.validityPeriod : 'Automatic';
            text.push('Validity Period: ' + validityPeriod);
            const scheduleDeliveryTime = group.scheduleDeliveryTime ? group.scheduleDeliveryTime : 'Automatic'
            text.push('Scheduled Delivery Time: ' + scheduleDeliveryTime);
            const replaceIfPresent = group.replaceIfPresent ? true : false;
            text.push('Replace If Present: ' + replaceIfPresent);
            const priority = group.priority ?? LiveNumberTestingService.DEFAULT_PRIORITY;
            text.push('Priority Flag: ' + priority);

            testCaseSummary.push({
                id: 'smppsettings',
                title: 'SMPP settings',
                data: text
            })
            text = [];

            if (pdu) {
                const smppMap = {};
                const pduMap = {};
                const decodedPduMap = {};
                text.push('');
                (pdu.submitSmPduData ? pdu.submitSmPduData : []).map((_, i) => {
                    text.push('SMPP PACKET #' + (i + 1));
                    text.push('Message ID: ' + _.messageId);
                    text.push('Sent at: ' + moment(_.submittedAt).format('DD MMM YYYY HH:mm:ss'));
                    if (pdu.deliveredSmsPduData && pdu.deliveredSmsPduData.pdusDelivered[i] && pdu.deliveredSmsPduData.pdusDelivered[i].length) {
                        text.push('Delivered at: ' + moment(pdu.deliveredSmsPduData.deliveredAt).format('DD MMM YYYY HH:mm:ss'));
                        smppMap[i] = text;
                        text = [];
                        // PDU ...
                        text.push('PDU: ' + pdu.deliveredSmsPduData.pdusDelivered[i]);
                        pduMap[i] = text;
                        text = [];
                        try {
                            let decodedPdu = this.pdu.parse(pdu.deliveredSmsPduData.pdusDelivered[i], true);
                            console.log(decodedPdu);
                            text.push('SMSC: ' + decodedPdu['smsc']);
                            text.push('SMSC Type: ' + decodedPdu['smsc_type']);
                            text.push('Sender ID: ' + decodedPdu['sender']);
                            text.push('Sender ID Type: ' + decodedPdu['sender_type']);
                            text.push('Encoding: ' + decodedPdu['encoding']);
                            text.push('Timestamp: ' + decodedPdu['time']);
                            text.push('Text: ' + decodedPdu['text']);

                            if (decodedPdu['udh'] == null) {
                                text.push('  * No UDH is present in the PDU * ');
                            }
                            else {
                                text.push('UDH in hex: ' + decodedPdu['udh_hex']);
                                text.push('UDH length: ' + decodedPdu['udh']['length']);
                                text.push('UDH IEI: ' + decodedPdu['udh']['iei']);
                                text.push('UDH Header Length: ' + decodedPdu['udh']['header_length']);
                                text.push('UDH Reference Number: ' + decodedPdu['udh']['reference_number']);
                                text.push('UDH Parts: ' + decodedPdu['udh']['parts']);
                                text.push('UDH Current part: ' + decodedPdu['udh']['current_part']);
                            }
                            decodedPduMap[i] = text;
                            text = [];
                        } catch (e) {
                            console.error(e);
                        }
                    } else {
                        smppMap[i] = text;
                        text = [];
                    }
                });
                if (Object.keys(smppMap).length > 0) {
                    testCaseSummary.push({
                        id: 'smpp',
                        title: 'SMPP',
                        data: [].concat.apply([], Object.values(smppMap))
                    });
                }

                if (Object.keys(pduMap).length > 0) {
                    testCaseSummary.push({
                        id: 'pdu',
                        title: 'PDU',
                        data: [].concat.apply([], Object.values(pduMap))
                    });
                }
                if (Object.keys(decodedPduMap).length > 0) {
                    testCaseSummary.push({
                        id: 'decodedPdu',
                        title: 'Decoded delivered PDU',
                        data: [].concat.apply([], Object.values(decodedPduMap))
                    });
                }
            }
            return testCaseSummary;
        }));
    }

    formatDestination(dest: Destination, icon = false): string {
        if (dest.mnc === null && dest.mcc === null) {
            return (dest.countryName ? dest.countryName : 'Unknown') + '/' + dest.phone;
        }

        let str = (dest.countryName ? dest.countryName : 'Unknown') + '(' + dest.mcc + ')/' + (dest.providerName ? dest.providerName : 'Unknown') + '(' + dest.mnc + ')';

        if (dest.originalMnc) {
            let iconStr = icon ? '<i class="icon-ported"></i>' : '<=';
            let provider = dest.originalProviderName ? dest.originalProviderName : 'Unknown';
            let originalStr = ' ' + iconStr + ' ' + provider + '(' + dest.originalMnc + ')';
            if (icon) {
                originalStr = `<span title="Ported from ${provider}">${originalStr}</span>`;
            }
            str += originalStr;
        }

        return str;
    }

    oneTestGroup(testGroupId): Observable<TestGroup> {
        let url = this.utils.buildUrl(`ROLE/ntc/test-groups/${testGroupId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestGroup>(url, options);
    }

    pduDetails(testId: number): Observable<PduDetails> {
        let url = this.utils.buildUrl(`ROLE/ntc/tests/${testId}/pdu-details`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PduDetails>(url, options);
    }

    getSmppDelayByTestId(testId: number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/test-delay/${testId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<any>(url, options);
    }

    getFileFromExportResult(result) {
        // split by "/" and return last item
        return result.filePath.split('/').slice(-1);
    }

    createComment(testId: number, model: TestComment): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/tests/${testId}/comment`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, model, options);
    }

    updateComment(commentId: number, model: TestComment): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/tests/comment/${commentId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, model, options);
    }

    getCommentById(testId: number, commentId: number): Observable<TestComment> {
        let url = this.utils.buildUrl(`ROLE/ntc/tests/${testId}/comment/${commentId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestComment>(url, options);
    }

    exportDestinations(requestBody: Object = {}): Promise<ExportResult> {
        return new Promise((resolve, reject) => {
            let options = this.utils.getHttpHeaderOptions(this.headers);
            this.http.post<ExportResult>(this.utils.buildUrl('ROLE/exp/export-async/active-networks'), requestBody, options)
                .subscribe(d => {
                    let statusCallBackUrl = d.statusCallback;
                    let repeat = 0;
                    let maxRepeat = 12;
                    let interval = setInterval(() => {
                        let options = this.utils.getHttpHeaderOptions(this.headers);
                        this.http.get<ExportStatus>(this.utils.buildUrl(`ROLE/exp/${statusCallBackUrl}`), options)
                            .subscribe(response => {
                                if (response.status === 'EXPORTED') {
                                    clearInterval(interval);
                                    d.downloadCallback = this.backendChannel.prepareUrl(
                                        this.utils.buildUrl(`ROLE/exp${d.downloadCallback}`)
                                    );
                                    resolve(d);
                                }
                                repeat++;
                                if (['FAILED', 'EXPIRED'].includes(response.status)) {
                                    clearInterval(interval);
                                    reject({ response, exportDetails: null });
                                }
                                if (maxRepeat === repeat) {
                                    clearInterval(interval);
                                    reject({ response, exportDetails: null });
                                }
                            })
                    }, 3000);
                })
        });
    }

    create() {
        return {
            id: null,
            enabled: true,
            countries: [],
            connections: [],
            senders: [],
            templates: [],
            dcs: -1,
            ton: -1,
            pid: null,
            npi: -1,
            ttl_min: 60,
            ttl: 3600,
            testRepeatsPerIteration: 1,
            amountX: 1,
            commentText: '',
            validityPeriod: null,
            scheduleDeliveryTime: null,
            replaceIfPresent: false,
            priority: LiveNumberTestingService.DEFAULT_PRIORITY,
            textAsTlv: false,
        };
    }
}

export interface CreateTestGroup {
    senders?: string[];
    dynamicTexts?: TelqText[];
    contentTexts: any[];
    sessionsSuppliers: SupplierSessionAssignment[];
    destinations: Destination[];
    tlv: UdhTlv[];
    udh: UdhTlv[];
    encodingId?: number;
    protocolId?: number;
    srcNpi?: number;
    srcTon?: number;
    userId: number;
    testRepeatsPerIteration: number;
    amountX: number;
    commentText?: string;
    ttl?: number;
    validityPeriod?: number;
    scheduleDeliveryTime?: string;
    replaceIfPresent?: boolean;
    priority?: number;
}

export interface TelqText {
    id?: number,
    fromServer?: boolean,
    text: string;
    telqIdType: string;
    telqIdText: string;
    telqIdCase: string;
    telqIdLength: number
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;
    sort: string[] = [];
    onlyMyTests: boolean = false;

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface SearchParams {
    createdAtFrom?: string;
    createdAtTo?: string;
    destinations?: Destination[];
    ids?: string[];
    telqIds?: string[];
    messageStates?: string[];
    senders?: string[];
    sessionSupplierIds?: SupplierSessionAssignment[];
    testStatuses?: string[];
    userIds?: number[];
    taskId?: number;
    taskIds?: number[];
    commentText?: string;
    commentDateFrom?: string;
    commentDateTo?: string;
    usersCollection?: any;
    text?: any;
    tlv?: { tagHex: string, valueHex: string },
    testGroupIds?: string[];
    showOnlyScheduledTests?: boolean;
}

interface ExportStatus {
    status: string
}
interface ExportResult {
    statusCallback: string
    filePath: string;
    downloadCallback: string;
}


interface LntActionData {
    name: string;
    row: TestResult;
    column: string;
}
