import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { Destination, OldDestination } from "../models/destination.model";
import { map } from "rxjs/operators";

@Injectable()
export class DestinationsService {

    utils = new RestUtils();

    headers = new HttpHeaders();


    constructor(
        @Inject('NETWORKS_ONLINE_URL') private networksOnlineUrl: string,
        @Inject('NETWORK_UPDATES_URL') private networkUpdatesUrl: string,
        @Inject('MO_NETWORKS_ONLINE_URL') private moNetworksOnlineUrl: string,
        @Inject('MO_NETWORK_UPDATES_URL') private moNetworkUpdatesUrl: string,
        @Inject('VOICE_NETWORKS_ONLINE_URL') private voiceNetworksOnlineUrl: string,
        @Inject('VOICE_NETWORK_UPDATES_URL') private voiceNetworkUpdatesUrl: string,
        private http: HttpClient
    ) {
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    online(): Observable<Destination[]> {
        return this.http.get<OldDestination[]>(this.networksOnlineUrl).pipe(
            map(d => d.map(_ => this.mapToDestination(_)))
        );
    }

    moOnline(): Observable<Destination[]> {
        return this.http.get<OldDestination[]>(this.moNetworksOnlineUrl).pipe(
            map(d => d.map(_ => this.mapToDestination(_)))
        );
    }

    voiceOnline(): Observable<Destination[]> {
        return this.http.get<OldDestination[]>(this.voiceNetworksOnlineUrl).pipe(
            map(d => d.map(_ => this.mapToDestination(_)))
        );
    }

    getNetworkUpdatesUrl(): string {
        return this.networkUpdatesUrl;
    }

    getMoNetworkUpdatesUrl(): string {
        return this.moNetworkUpdatesUrl;
    }

    getVoiceNetworkUpdatesUrl(): string {
        return this.voiceNetworkUpdatesUrl;
    }

    mapToDestination(destination: OldDestination): Destination {
        return {
            mcc: destination.mcc,
            countryName: destination.country,
            mnc: destination.mnc,
            providerName: destination.provider,
            originalMnc: destination.original_mnc,
            originalProviderName: destination.original_provider
        }
    }

    getFavourites(): Observable<Destination[]> {
        let url = this.utils.buildUrl('ROLE/usr/destination-favourites');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Destination[]>(url, options);
    }

    createFavorite(destination: Destination): Observable<any> {
        let url = this.utils.buildUrl('ROLE/usr/destination-favourites');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, destination, options);
    }

    deleteFavorite(mcc: string, mnc: string, originalMnc?: string): Observable<any> {
        let queryParams = {mcc: mcc, mnc: mnc, originalMnc: originalMnc};
        let url = this.utils.buildUrl('ROLE/usr/destination-favourites', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }
}
