import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, filter, map, Observable, Subscription } from 'rxjs';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { InvoiceService } from '../../shared/services/invoice.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { InvoicesTableComponent } from '../invoices-table/invoices-table.component';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { InvoicesFormComponent } from "../invoices-form/invoices-form.component";
import { Invoice } from "../../shared/models/invoice.model";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { InputSearchDatePeriod } from "../../shared/components/input/input.search.datePeriod";

@Component({
    selector: 'app-invoices-index',
    templateUrl: './invoices-index.component.html',
    styleUrls: ['invoices-index.component.scss'],
})

export class InvoicesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(InvoicesTableComponent, {static: false}) table: InvoicesTableComponent;

    @ViewChild(InvoicesFormComponent, {static: false}) createForm: InvoicesFormComponent;

    @ViewChild(InputSearchDatePeriod) datePeriod: InputSearchDatePeriod;

    @ViewChild('createModalTpl', { read: TemplateRef, static: false }) createModalTpl: any;
    createModal: DialogRef;

    isAdmin = false;
    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;
    isAutoGeneratedFilterChecked: boolean = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    invoice: Invoice;
    invoiceSecret: string;

    @ViewChild('paypalForm', { static: false }) paypalForm: ElementRef;
    @ViewChild('paypalModalTpl', { read: TemplateRef, static: false }) paypalModalTpl: any;
    paypalModal: DialogRef;

    paypalLoading = false;
    paypalConfirmUrl: string;
    paypalCancelUrl: string;
    paypalReturnUrl: string;

    detailsLoading = false;
    detailsInterval;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;
    detailsModal: DialogRef;

    partialPaymentModel = {amount: 0}
    partialPaymentForm: FormGroup;
    partialPaymentShow = false;

    private muteFilterTrigger = false;
    private autoRefreshInterval;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public userService: UsersService,
        public invoiceService: InvoiceService,
        public modal: ModalService,
        public validationService: ValidationService,
        public route: ActivatedRoute,
        titleService: Title,
        formBuilder: FormBuilder
    ) {
        titleService.setTitle('Invoices');
        userService.can('admin').then(_ => this.isAdmin = _).catch(() => { });
        this.partialPaymentForm = formBuilder.group({
            amount: ['', V.required]
        });
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            filter(() => !this.muteFilterTrigger),
            debounceTime(300),
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
        this.autoRefreshInterval = setInterval(() => {
            if (this.invoice || this.table.loading) {
                return;
            }
            this.table.update(false);
        }, 20 * 1000);
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.details) {
                this.invoiceView(params.details);
            }
            if (params.payment) {
                this.invoicePayment(params.payment);
            }
        });
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    invoiceAction(event) {
        if (event.name === 'view') {
            this.invoiceView(event.row.data.id)
        }

        if (event.name === 'paypalPayment') {
            this.invoicePayment(event.row.data.id);
        }
    }

    private invoicePayment(id: string) {
        this.invoiceSecret = null;
        this.invoice = null;
        this.paypalLoading = true;
        this.invoiceService.one(id).subscribe({
            next: invoice => this.invoice = invoice,
            complete: () => this.detailsLoading = false,
        });
        this.invoiceService.getSecret(id).subscribe({
            next: secret => {
                this.paypalConfirmUrl = this.invoiceService.getNotifyUrl(secret);
                this.paypalCancelUrl = window.location.href + '?return=cancel';
                this.paypalReturnUrl = this.invoiceService.getReturnUrl(secret);
                this.invoiceSecret = secret;
            },
            complete: () => this.paypalLoading = false
        });
        this.paypalModal = this.modal.alert().component(this.paypalModalTpl).open();
    }

    private invoiceView(id: string) {
        this.invoice = null;
        this.detailsLoading = true;
        this.invoiceService.one(id).subscribe({
            next: invoice => this.invoice = invoice,
            complete: () => this.detailsLoading = false,
        });
        this.detailsModal = this.modal.alert().component(this.detailsModalTpl).open();
        this.detailsModal.onDestroy.subscribe(() => {
            clearInterval(this.detailsInterval);
            this.invoice = null;
        });
        this.detailsInterval = setInterval(() => {
            if (this.detailsLoading) {return;}
            this.invoiceService.one(this.invoice.id).subscribe({
                next: invoice => this.invoice = invoice,
            });
        }, 1000 * 10);
    }

    invoiceCreate() {
        this.createModal = this.modal.alert().component(this.createModalTpl).open();
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {return;}
        if (this.muteFilterTrigger) {return;}
        this.table.requestParams.search = phrase;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    batchGenerateZohoReport() {
        const tasks = this.selectedRows.map(row => {
            return this.invoiceService.createZohoInvoice(row.data.id).pipe(map(() => {
                this.notificationService.success(`Generated zoho invoice ${row.data.userEmail}`, 'Invoices');
                row.batch = false;
                this.completeTask();
            }), catchError((error, caught) => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: `An error occurred while creating Zoho invoice ${row.data.userEmail}`,
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchSendZohoInvoices() {
        const tasks = this.selectedRows.map(row => {
            return this.invoiceService.sendZohoInvoice(row.data.id).pipe(map(() => {
                this.notificationService.success(`Zoho Invoice send for ${row.data.userEmail}`, 'Invoices');
                row.batch = false;
                this.completeTask();
            }), catchError((error, caught) => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: `An error occurred while sending Zoho invoice for ${row.data.userEmail}`,
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onChangeFilterDate(event: Event): void {
        if (this.muteFilterTrigger) {return;}
        const startDate = event['startIso'];
        const endDate = event['endIso'];
        this.table.requestParams['startDate'] = startDate ? startDate : '';
        this.table.requestParams['endDate'] = endDate ? endDate : '';
        this.table.requestParams.page = 1;
        this.table.update();
    }

    autoGenerateFilterChange(): void {
        if (this.muteFilterTrigger) {return;}
        this.table.requestParams['autoGenerated'] = this.isAutoGeneratedFilterChecked;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    onAfterSave() {
        this.createModal.close();
        this.resetFilters();
    }

    onClickVoid(): void {
        this.detailsLoading = true;
        this.invoiceService.markAsVoid(this.invoice.id).subscribe({
            next: _ => {
                this.notificationService.success(
                    'Invoice marked as void.',
                    'Invoices'
                );
                this.resetFilters();
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
            },
            error: error => {
                this.detailsLoading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while updating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    onClickMarkAsPaid() {
        this.detailsLoading = true;
        this.invoiceService.markAsPaid(this.invoice.id).subscribe({
            next: _ => {
                this.notificationService.success(
                    'Invoice updated',
                    'Invoices'
                );
                this.resetFilters();
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
            },
            error: error => {
                this.detailsLoading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while updating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    isPartialPaymentShow() {
        return this.isAdmin && this.invoice && this.invoice.status === 'WAITING' && !this.invoice.void;
    }

    partialPaymentOpen() {
        this.partialPaymentModel.amount = 0;
        this.partialPaymentForm.controls.amount.setValidators(this.createValidatorAmount());
        this.partialPaymentForm.reset();
        this.partialPaymentShow = true;
    }

    onSubmitPartialPayment() {
        this.detailsLoading = true;
        this.invoiceService.partialPayment(this.invoice.id, this.partialPaymentModel.amount).subscribe({
            next: () => {
                this.notificationService.success('Partial payment is complete.', 'Invoices');
                this.partialPaymentShow = false;
                this.resetFilters();
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
            },
            error: error => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while partial payment',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            },
        })
    }

    private createValidatorAmount() {
        return V.compose([
            V.required,
            V.digitsAndDot(true),
            V.min(0.001, false),
            V.price(3, true),
            V.max(this.invoice.commitment, true)
        ]);
    }

    resetFilters() {
        this.muteFilterTrigger = true;
        try {
            this.table.requestParams.search = '';
            this.searchControl.reset();
            this.table.requestParams['startDate'] = '';
            this.table.requestParams['endDate'] = '';
            this.datePeriod.reset();

            this.table.requestParams['autoGenerated'] = false;
            this.isAutoGeneratedFilterChecked = false;

            this.table.requestParams.page = 1;
            this.table.update();
        } catch (e) {
        }
        this.muteFilterTrigger = false;
    }

    ngOnDestroy() {
        clearInterval(this.autoRefreshInterval);
        clearInterval(this.detailsInterval);
    }
}
