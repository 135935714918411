
import { HttpClient } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthUser } from "../../shared/models/user.model";
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { UsersService } from '../../shared/services/users.service';
import { BrowserUtils } from "../../shared/services/browser-utils";

@Component({
    selector: 'app-update-detect',
    templateUrl: 'update-detect.component.html',
    styleUrls: ['update-detect.component.scss']
})
export class UpdateDetectComponent implements OnDestroy, OnInit {

    @Input() period: number = 60 * 1000;
    @Input() sidebarOpen = false
    @ViewChild('notifyModalTpl', { read: TemplateRef, static: false }) notifyModalTpl: any;
    user: AuthUser = null;
    newVersion: string = '';
    loading: boolean = false;
    notifyModalDialog: DialogRef = null;
    private readonly scheduler: any;
    forcedUpdate: boolean = false;
    postponeUpdates: boolean = false;
    ignoreUpdateUserIds: Set<number> = new Set();

    constructor(
        public http: HttpClient,
        public storage: LocalStorageService,
        public modal: ModalService,
        public userService: UsersService,
        public notificationService: NotificationService,
        public router: Router
    ) {
        this.forcedUpdate = environment['forcedUpdate'];
        this.ignoreUpdateUserIds = environment['ignoreUpdateUserIds'];
        if (this.forcedUpdate) {
            this.scheduler = setInterval(() => this.update(), this.period);
        }
        this.postponeUpdates = environment['postponeUpdates'].includes(this.router.url);
        notificationService.updates.subscribe(() => this.conditionalUpdateModalPopup());
        userService.authUpdates.subscribe(user => {
            this.user = user;
        });
    }

    ngOnInit() {
        this.update();
    }

    update() {
        this.getVersionFromServer().subscribe(() => {
            if (this.newVersion && (!this.notifyModalDialog) && !this.isIgnore()) {
                this.notifyModalDialog = this.modal.alert().dialogClass('modal-dialog small-modal')
                    .isBlocking(true).component(this.notifyModalTpl).open();
            }
        });
    }

    private isIgnore() {
        if (!this.user) {
            return false;
        }
        return this.ignoreUpdateUserIds.has(this.user.id);
    }

    conditionalUpdateModalPopup(): void {
        this.postponeUpdates = environment['postponeUpdates'].includes(this.router.url);
        this.getVersionFromServer().subscribe(() => {
            if (this.newVersion && !this.notifyModalDialog && !this.ignoreUpdateUserIds.has(this.user.id)) {
                this.notifyModalDialog = this.modal.alert().dialogClass('modal-dialog small-modal')
                    .isBlocking(!this.postponeUpdates).component(this.notifyModalTpl).open();
            }
        })
    }

    getVersionFromServer() {
        let time = Date.now();
        return this.http.get<{ current: string }>('/version.json?' + time).pipe(
            map((data) => {
                if (data.current !== BrowserUtils.getCurrentVersion()) {
                    this.newVersion = data.current;
                } else {
                    this.newVersion = '';
                }
                return data;
            })
        );
    }

    onClickUpdateButton() {
        window.location.href = window.location.href + '?v=' + this.newVersion;
        window.location.reload();
    }

    onUpdateCancel(dialogRef: any): void {
        dialogRef.close(true);
        this.notifyModalDialog = null;
        clearInterval(this.scheduler);
    }

    shouldCancelBeShown(): boolean {
        if (this.forcedUpdate) return false;
        if (this.postponeUpdates) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        if (this.scheduler) {
            clearInterval(this.scheduler);
        }
        this.notifyModalDialog = null
    }
}
