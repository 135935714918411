
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from "../../../shared/services/notification.service";
import { TableBase } from '../../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from '../../../shared/components/table/table.component';
import { AllRequestParams, CallbackHistoryService } from '../../../shared/services/callback-history.service';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { ModalService } from "../../../shared/services/modal.service";
import { CallbackHistory, CallbacksHistoryFilter } from "../../../shared/models/callback-history.model";

@Component({
    selector: 'app-callback-history-table',
    templateUrl: './callback-history-table.component.html',
    styleUrls: ['callback-history-table.component.scss']
})

export class CallbackHistoryTableComponent extends TableBase implements OnInit {

    requestParams = new AllRequestParams();

    @ViewChild('historyEventModalTpl', { read: TemplateRef, static: false }) historyEventModalTpl: any;
    historyEvent: {
        model: CallbackHistory,
        request: any,
        response: any
    };

    filter: CallbacksHistoryFilter;

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();

    constructor(
        private notificationService: NotificationService,
        private service: CallbackHistoryService,
        private datePipe: DatePipe,
        private modal: ModalService
    ) {
        super();
    }

    ngOnInit() {
        let columns = this.createColumns([
            { title: 'Service', prop: 'service', cellClassName: 'w-md', format: r => {
                const services = {
                    'LNT': 'Live number testing',
                    'MT': 'Manual testing',
                    'scheduler': 'Scheduler'
                };
                return services[r.service] ? services[r.service] : r.service;
            } },
            { title: 'Test ID', prop: 'testId', cellClassName: 'w-md', sort: true },
            { title: 'Sent', prop: 'sentAt', format: _ => this.formatDate(_.sentAt), cellClassName: 'w-lg', sort: true },
            { title: 'Version', prop: 'apiVersion', cellClassName: 'w-sm', format: r => {
                if (r.service === 'scheduler') {return '';}
                return r.apiVersion.replace('_', '.').toLowerCase();
            } },
            { title: 'Url', prop: 'url', cellClassName: 'w-lg' },
            {
                title: 'Response', prop: 'responseStatusCode', format: (_) => {
                    let className = 'bg-error-r2';
                    if (_.responseStatusCode >= 200 && _.responseStatusCode <= 299) {
                        className = 'bg-sucess-s2';
                    }
                    return `<span title="Details" class="label ${className} text-neutral-n1">${_.responseStatusCode}</span>`;
                }, type: ColumnTypes.HTML, cellClassName: 'w-sm pointer'
            },
            { title: 'Status', prop: 'status', cellClassName: 'w-md' },
        ]);

        this.setColumns(columns);
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
    }

    onCellClick(e: CellClickEvent) {
        if (e.column.prop === 'responseStatusCode') {
            const data = e.row.data as CallbackHistory;
            this.historyEvent = {
                model: data,
                request: this.httpMessage(data.callbackBody),
                response: this.httpMessage(data.responseBody),
            };
            this.modal.alert().component(this.historyEventModalTpl).open();
        }
    }

    private httpMessage(data: any): any {
        if (typeof data === 'string') {
            data = data.trim();
            if (data.startsWith('{')) {
                try {
                    return JSON.parse(data)
                } catch (e) {
                    return data;
                }
            }
        }
        return data
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.service = (this.filter && this.filter.service) ? this.filter.service : null
        this.requestParams.resetSort();
        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service.all(this.requestParams).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Api',
                    message: 'An error occurred while loading callback history',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                return throwError(() => e);
            })
        ).subscribe(collection => {
            let count = (this.page * this.currentSize);
            if (collection.content.length === this.currentSize) {
                count++;
            }
            this.setData(this.createRows(collection.content), count);
            this.loading = false;
        });
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    formatDate(date: Date) {
        return this.datePipe.transform(date, 'medium');
    }

    protected readonly event = event;
}
