
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from "rxjs";
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { TableBase } from '../../shared/components/table/table-base';
import { ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import { TestCaseTemplate, TestCaseTemplatesCollection } from '../../shared/models/test-case-template.model';
import { AllRequestParams, TestCaseTemplatesService } from '../../shared/services/test-case-template.service';
import { UsersService } from '../../shared/services/users.service';
import { ActionEvent, CellClickEvent } from "../../shared/components/table/table.component";
import { ModalService } from "../../shared/services/modal.service";
import { catchError } from "rxjs/operators";
import { BrowserUtils } from "../../shared/services/browser-utils";

@Component({
    selector: 'app-test-case-template-table',
    templateUrl: './test-case-template-table.component.html',
    styleUrls: ['./test-case-template-table.component.scss'],
})

export class TestCaseTemplateTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('titleModalTpl', { read: TemplateRef, static: false }) titleModalTpl: any;

    loading = false;
    isAdmin = false;
    requestParams = new AllRequestParams();
    testCaseTemplateCollection: TestCaseTemplatesCollection;
    currentTitle: string = '';
    storageContextName = 'test-case-template-table';

    constructor(
        public notificationService: NotificationService,
        public service: TestCaseTemplatesService,
        public storage: LocalStorageService,
        public userService: UsersService,
        public modal: ModalService,
        public activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.page = this.activatedRoute.snapshot.queryParams['page'] || 1;
        this.currentSize = this.activatedRoute.snapshot.queryParams['size'] || 20;
        let columns = [
            { title: 'ID', prop: 'id', sort: true, cellClassName: 'w-md' },
            { title: 'Title', prop: 'title', cellClassName: 'editable w-lg', format: _ => `<span>${BrowserUtils.escapeHtml(_.title)}</span>`, sort: true, type: ColumnTypes.HTML },
            { title: 'Use by default in Live number testing', prop: 'liveNumberTestingDefault', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center w-lg', sort: true },
        ];
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit', title: 'Edit test template' },
            { icon: 'far fa-trash-alt', name: 'delete', title: 'Delete test template' },
        ]

        this.userService.can('admin').then(isAdmin => {
            this.isAdmin = isAdmin;
            if (!isAdmin) {
                const hideColumns = ['ID'];
                columns = columns.filter(c => hideColumns.indexOf(c.title) === -1)
            }
            this.setColumns(this.createColumns(columns));
            this.update();
        }).catch(e => { });
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        forkJoin([
            this.userService.getAuthUser(),
            this.service.all(this.requestParams)
        ]).subscribe({
            next: responses => {
                const defaultLntTemplate = responses[0].defaultLntTemplate || 0;
                const collection: TestCaseTemplatesCollection = responses[1];
                let defaultContent;
                for (let i = 0; i < collection.content.length; i++) {
                    if (collection.content[i].id === defaultLntTemplate) {
                        defaultContent = collection.content[i];
                        defaultContent.liveNumberTestingDefault = true;

                        if (i !== 0) {
                            collection.content.splice(i, 1);
                            collection.content.unshift(defaultContent);
                        }
                        break;
                    }
                }

                this.testCaseTemplateCollection = collection;
                this.setData(this.createRows(collection.content), collection.totalElements);
                this.loading = false;
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Test templates',
                    message: 'An error occurred while loading test templates',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'title') {
            this.editTitle(event.row.data);
        }
    }

    editTitle(row: TestCaseTemplate) {
        this.currentTitle = row.title;
        let dialogRef = this.modal.alert().component(this.titleModalTpl).open();
        dialogRef.result.then(result => {
            if (result) {
                const data = { id: row.id, title: result };
                this.service.updateTestCaseTemplateTitle(data).pipe(catchError(e => {
                    this.notificationService.error({
                        title: 'Test templates',
                        message: 'An error occurred while test template updating',
                        serviceName: 'LNT',
                        requestMessage: this.service.prepareTextError(e),
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                    this.flashRow(row.id);
                    row.title = this.currentTitle;
                    throw e;
                })).subscribe((res) => {
                    this.notificationService.success('Test template updated', 'Test templates');
                    this.update();
                });
            }
        });

    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'switch' && event.column.prop === 'liveNumberTestingDefault') {
            this.loading = true;
            const templateId = event.row.data.liveNumberTestingDefault ? event.row.data.id : null;
            this.userService.saveDefaultTemplate(templateId).pipe(catchError(e => {
                this.notificationService.error({
                    title: 'Test templates',
                    message: 'An error occurred while test template updating',
                    serviceName: 'LNT',
                    requestMessage: this.service.prepareTextError(e),
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
                throw e;
            })).subscribe(() => {
                this.notificationService.success('Test template updated', 'Test templates');
                this.userService.authUser = null;
                this.update();
            });
        }

        this.actions.emit(event);
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    delete(template: TestCaseTemplate) {
        this.loading = true;
        this.service
            .delete(template.id)
            .pipe(catchError(e => {
                this.notificationService.error({
                    title: 'Test templates',
                    message: 'An error occurred while test template deleting',
                    serviceName: 'LNT',
                    requestMessage: this.service.prepareTextError(e),
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
                throw e;
            }))
            .subscribe(() => {
                this.notificationService.success('Test template deleted', 'Test templates');
                this.onAfterDelete.emit();
                this.update();
            });
    }
}
