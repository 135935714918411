
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ValidatorFn } from "@angular/forms";
import * as XLSX from 'xlsx';
import { InputFile } from "../../shared/components/input/input.file";
import { ConnectionImportRow, ConnectionsImportResult } from "../../shared/models/connection-import.model";
import { Session } from "../../shared/models/session.model";
import { Supplier } from "../../shared/models/supplier.model";
import { ConnectionsImportService } from "../../shared/services/connections-import.service";
import { NotificationService } from "../../shared/services/notification.service";
import { SessionsService } from "../../shared/services/sessions.service";
import { SuppliersService } from "../../shared/services/suppliers.service";
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { UdhTlvInputComponent } from "../../test/udh-tlv-input/udh-tlv-input.component";

@Component({
    selector: 'app-connections-import',
    templateUrl: './connections-import.component.html',
    styleUrls: ['./connections-import.component.scss'],
})

export class ConnectionsImportComponent implements OnInit {

    MAX_ROWS = 500;

    @Output() finished: EventEmitter<ConnectionsImportResult> = new EventEmitter();

    @Input() fileList: FileList = null;

    accept = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    acceptExtensions = ['xlsx']

    @ViewChild(InputFile, { static: false }) input: InputFile;

    items: ConnectionImportRow[] = [];
    errors: ValidationError[] = [];
    rowsCount = 0;
    importResult: ConnectionsImportResult;

    sessionsCount = 0;
    supplierCount = 0;
    assignmentsCount = 0;

    private headers = {
        'Login*': 0,
        'Password (max 9 characters)': 1,
        'Host*': 2,
        'Port*': 3,
        'Throughput (SMS/second)': 4,
        'System type': 5,
        'Dest TON': 6,
        'Dest NPI': 7,
        'Supplier_Title': 8,
        'Route_Type': 9,
        'Service_Type': 10,
        'Comment': 11,
        'Attributes (Comma separated)': 12,
        'TLV Tag [HEX]': 13,
        'TLV value [HEX]': 14,
        'UDH Tag [HEX]': 15,
        'UDH value [HEX]': 16
    };

    private propertyToHeader = {
        session: {
            hostIp: 'Host*',
            hostPort: 'Port*',
            systemId: 'Login*',
            password: 'Password (max 9 characters)',
            throughput: 'Throughput (SMS/second)',
            dstTon: 'Dest TON',
            dstNpi: 'Dest NPI',
        },
        supplier: {
            title: 'Supplier_Title',
            routeType: 'Route_Type',
            serviceType: 'Service_Type',
            comment: 'Comment',
            attributeList: 'Attributes (Comma separated)',
            udhTag: 'UDH Tag [HEX]',
            udhValue: 'UDH value [HEX]',
            tlvTag: 'TLV Tag [HEX]',
            tlvValue: 'TLV value [HEX]',
        },
    };

    private validators = {
        session: {
            hostIp: Vld.compose([Vld.required, Vld.domainOrIp(true), Vld.maxLength(255), Vld.noSpace()]),
            hostPort: Vld.compose([Vld.required, Vld.digits(true), Vld.noSpace(), Vld.min(0), Vld.max(65535)]),
            systemId: Vld.compose([Vld.required, Vld.maxLength(16), Vld.gsm7(true), Vld.noSpace()]),
            password: Vld.compose([Vld.maxLength(9), Vld.gsm7(true), Vld.noSpace()]),
            throughput: Vld.compose([Vld.required, Vld.min(1), Vld.max(50)]),
            dstTon: Vld.compose([Vld.required]),
            dstNpi: Vld.compose([Vld.required]),
        },
        supplier: {
            title: Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(255)]),
            routeType: Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(255)]),
            serviceType: Vld.compose([Vld.maxLength(5)]),
            comment: Vld.compose([Vld.maxLength(255)]),
            udhTag: Vld.compose([Vld.required, Vld.hex(true), UdhTlvInputComponent.createTagValidator(1, false)]),
            udhValue: Vld.compose([Vld.required, Vld.hex(true), UdhTlvInputComponent.createValueValidator(false)]),
            tlvTag: Vld.compose([Vld.required, Vld.hex(true), UdhTlvInputComponent.createTagValidator(2, false)]),
            tlvValue: Vld.compose([Vld.required, Vld.hex(true), UdhTlvInputComponent.createValueValidator(false)]),
        }
    };

    loading = false;

    constructor(
        public service: ConnectionsImportService,
        public validationService: ValidationService,
        public sessionService: SessionsService,
        public suppliersService: SuppliersService,
        public notificationService: NotificationService
    ) {

    }

    ngOnInit() {
        if (this.fileList) {
            this.input.onChangeInput(this.fileList);
        }
    }

    onChangeImportFile(files: File[]) {
        this.importResult = null;
        this.items = [];
        this.errors = [];
        this.rowsCount = 0;
        this.importResult = null;
        if (files.length) { this.importFromExcelFile(files[0]); }
    }

    importFromExcelFile(file: File) {
        /* wire up file reader */
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            const rows = XLSX.utils.sheet_to_json(ws, { header: 1 }) as string[][];
            this.rowsCount = rows.length > 1 ? rows.length - 1 : 0;
            if (!this.rowsCount || this.rowsCount > this.MAX_ROWS) {
                return;
            }
            this.import(rows);
        };
        reader.readAsBinaryString(file);
    }

    private import(rows: string[][]) {
        let errors: ValidationError[] = []
        let skippedHeaders = this.getSkippedHeaders(rows[0]);
        if (skippedHeaders.length) {
            this.errors.push({
                index: 0,
                messages: ['File does not contain headers: ' + skippedHeaders.join(', ')]
            });
            return;
        }
        let importItems = [];
        rows.forEach((row, indexRow) => {
            if (indexRow === 0) { return; }
            const importRow = this.prepareRow(row);
            if (importRow.errors.length) {
                errors.push({ index: indexRow, messages: importRow.errors });
            } else {
                importItems.push(importRow.row);
            }
        })
        this.errors = errors;
        if (errors.length) {
            return;
        }
        this.sessionsCount = 0;
        this.supplierCount = 0;
        this.assignmentsCount = 0;
        importItems.forEach(r => {
            if (r.supplier) { this.supplierCount++; }
            if (r.session) { this.sessionsCount++; }
            if (r.supplier && r.session) { this.assignmentsCount++; }
        });
        this.items = importItems.filter(_ => _.session || _.supplier);
        console.log(this.items)
    }

    private getSkippedHeaders(columns: string[]): string[] {
        let skipped: string[] = [];
        for (let headersKey in this.headers) {
            const index = this.headers[headersKey];
            if (!columns[index] || columns[index] !== headersKey) {
                skipped.push(headersKey);
            }
        }
        return skipped;
    }

    private prepareRow(columns: string[]): { row?: ConnectionImportRow, errors: string[] } {
        let row: ConnectionImportRow = {
            session: this.extractSession(columns),
            supplier: this.extractSupplier(columns)
        };
        let errors = [];

        let errorsSession = [];
        for (let prop in this.validators.session) {
            let validator = this.validators.session[prop];
            let value = row.session[prop];
            let headerName = this.propertyToHeader.session[prop];
            this.validate(validator, value).forEach(error => {
                errorsSession.push(headerName + ' ' + this.lwfirst(error))
            });
        }
        if (errorsSession.length) {
            row.session = null;
            errorsSession.forEach(_ => errors.push(_));
        }
        if (row.session) {
            row.session.windowWaitTimeout = null;
        }

        if (!row.supplier.title && !row.supplier.routeType) {
            row.supplier = null;
        } else {
            let errorsSupplier = [];
            for (let prop in this.validators.supplier) {
                let validator = this.validators.supplier[prop];
                let headerName = this.propertyToHeader.supplier[prop];
                let value = '';
                if (headerName === this.propertyToHeader.supplier.tlvTag) {
                    if (!row.supplier.tlvDtos.length) { continue; }
                    value = row.supplier.tlvDtos[0].tagHex;
                } else if (headerName === this.propertyToHeader.supplier.tlvValue) {
                    if (!row.supplier.tlvDtos.length) { continue; }
                    value = row.supplier.tlvDtos[0].valueHex;
                } else if (headerName === this.propertyToHeader.supplier.udhTag) {
                    if (!row.supplier.udhDtos.length) { continue; }
                    value = row.supplier.udhDtos[0].tagHex;
                } else if (headerName === this.propertyToHeader.supplier.udhValue) {
                    if (!row.supplier.udhDtos.length) { continue; }
                    value = row.supplier.udhDtos[0].valueHex;
                } else {
                    value = row.supplier[prop] ? row.supplier[prop] : '';
                }
                this.validate(validator, value).forEach(_ => errorsSupplier.push(headerName + ' ' + this.lwfirst(_)));
            }
            if (row.supplier.attributeList.length) {
                let headerName = this.propertyToHeader.supplier.attributeList;
                this.validateAttributes(row.supplier.attributeList).forEach(_ => errorsSupplier.push(headerName + ' ' + this.lwfirst(_)));
            }
            if (errorsSupplier.length) {
                row.supplier = null;
                errorsSupplier.forEach(_ => errors.push(_));
            }
        }

        return { row: row, errors: errors };
    }

    private lwfirst(str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    private validateAttributes(attributes: string[]): string[] {
        let errors: string[] = [];
        let disabledErrorAttrs: string[] = [];
        attributes.forEach(attr => {
            if (!this.suppliersService.attributes.includes(attr)) {
                errors.push(`wrong value "${attr}". Allowed attributes: ` + this.suppliersService.attributes.join(', '));
            } else if (SuppliersService.attrDisableRules[attr]) {
                const disableAttr = SuppliersService.attrDisableRules[attr];
                if (attributes.includes(disableAttr) && !disabledErrorAttrs.includes(attr)) {
                    disabledErrorAttrs.push(attr);
                    disabledErrorAttrs.push(disableAttr);
                    errors.push(`can't use ${attr} and ${disableAttr} at the same time`);
                }
            }
        });
        return errors;
    }

    private validate(validator: ValidatorFn, value: string | number): string[] {
        let errors = validator.call(null, { value: value });
        if (errors === null) {
            return [];
        }
        let errorMessages = [];
        for (let type in errors) {
            this.validationService.getTextMessage(type, errors[type]).forEach(_ => errorMessages.push(_));
        }
        return errorMessages;
    }

    private extractSession(row: string[]): Session {
        let session = this.sessionService.create();
        if (row[this.headers['Login*']]) {
            session.systemId = String(row[this.headers['Login*']]).trim();
        }
        if (row[this.headers['Password (max 9 characters)']]) {
            session.password = String(row[this.headers['Password (max 9 characters)']]).trim();
        }
        if (row[this.headers['Host*']]) {
            session.hostIp = String(row[this.headers['Host*']]).trim();
        }
        if (row[this.headers['Port*']]) {
            session.hostPort = parseInt(String(row[this.headers['Port*']]).trim());
        }
        if (row[this.headers['Throughput (SMS/second)']]) {
            session.throughput = parseInt(String(row[this.headers['Throughput (SMS/second)']]).trim());
        }
        if (row[this.headers['System type']]) {
            session.systemType = String(row[this.headers['System type']]).trim();
        }
        if (row[this.headers['Dest TON']]) {
            session.dstTon = parseInt(String(row[this.headers['Dest TON']]).trim());
        }
        if (row[this.headers['Dest NPI']]) {
            session.dstNpi = parseInt(String(row[this.headers['Dest NPI']]).trim());
        }
        return session;
    }

    private extractSupplier(row: string[]): Supplier {
        let supplier = this.suppliersService.create();
        if (row[this.headers['Supplier_Title']]) {
            supplier.title = String(row[this.headers['Supplier_Title']]).trim();
        }
        if (row[this.headers['Route_Type']]) {
            supplier.routeType = String(row[this.headers['Route_Type']]).trim();
        }
        if (row[this.headers['Service_Type']]) {
            supplier.serviceType = String(row[this.headers['Service_Type']]).trim();
        }
        if (row[this.headers['Comment']]) {
            supplier.comment = String(row[this.headers['Comment']]).trim();
            supplier.comment = supplier.comment.length ? supplier.comment : null;
        }
        if (row[this.headers['Attributes (Comma separated)']]) {
            supplier.attributeList = this.suppliersService.prepareAttrs(
                this.extractAttributes(String(row[this.headers['Attributes (Comma separated)']]).trim())
            );
        }
        if (row[this.headers['TLV Tag [HEX]']]) {
            supplier.tlvDtos.push({
                tagHex: String(row[this.headers['TLV Tag [HEX]']]).trim(),
                valueHex: String(row[this.headers['TLV value [HEX]']]).trim()
            });
        }
        if (row[this.headers['UDH Tag [HEX]']]) {
            supplier.udhDtos.push({
                tagHex: String(row[this.headers['UDH Tag [HEX]']]).trim(),
                valueHex: String(row[this.headers['UDH value [HEX]']]).trim()
            });
        }
        return supplier;
    }

    private extractAttributes(raw: string): string[] {
        return raw.split(',')
            .map(_ => _.trim())
            .filter(_ => _.length)
            .map(attr => {
                attr = attr.toUpperCase()
                    .split(' ').join('_')
                    .split('-').join('_');
                if (attr === '2_WAY') { attr = 'TWO_WAY'; }
                if (attr === 'SMSC_DLR') { attr = 'SMSC'; }
                return attr;
            });
    }

    onSubmitImport() {
        this.loading = true;
        this.service.import(this.items).subscribe({
            next: r => {
                this.loading = false;
                this.notificationService.success(`Import finished. Sessions: ${r.sessionsCreated}. Suppliers: ${r.suppliersCreated}. Assignments: ${r.assignmentsCreated}.`, 'Import connections');
                this.importResult = r;
                this.finished.emit(r)
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Import sessions',
                    message: 'An error occurred while importing sessions',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }
    onSubmitClose() {
        this.finished.emit(this.importResult);
    }
}

interface ValidationError {
    index: number;
    messages: string[];
}
