import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import {PricingService} from "../../../shared/services/pricing.service";
import {
    AnalyticsTariff,
    PostpaidCurrentPlanItem,
    Tier
} from "../../../shared/models/pricing.model";
import { UsersService } from '../../../shared/services/users.service';
import {AuthUser} from "../../../shared/models/user.model";
import { Part } from "../../billing-progress/billing-progress.component";
import { NumberFormatPipe } from "../../../shared/pipes/number-format.pipe";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: 'app-special-pricing-group-client',
    templateUrl: './special-pricing-group-client.component.html',
    styleUrls: ['special-pricing-group-client.component.scss']
})

export class SpecialPricingGroupClientComponent implements OnInit {

    loading = false;
    tiers: Tier[] = [];
    user: AuthUser;

    currentBalance = 0;
    testsCount = 0;
    commitmentMt = 0;

    itemsMt: PostpaidCurrentPlanItem[] = [];
    progressMt: Progress;
    itemsMo: PostpaidCurrentPlanItem[] = [];
    progressMo: Progress;
    itemsVoice: PostpaidCurrentPlanItem[] = [];
    progressVoice: Progress;

    analyticsTariff: AnalyticsTariff;
    private planUpdate = false;

    constructor(
        public pricingService: PricingService,
        public userService: UsersService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        private numberFormat: NumberFormatPipe,
        private currency: CurrencyPipe,
        titleService: Title
    ) {
        titleService.setTitle('Billing');
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.userService.balanceUpdates.subscribe(_ => this.update());
        });
    }

    private update() {
        if (this.planUpdate) {return;}
        this.planUpdate = true;
        this.pricingService.getPostpaidPlan().subscribe({
            next: data => {
                this.planUpdate = false;
                this.currentBalance = (this.getUsageSum(data.itemsMt) + this.getUsageSum(data.itemsMo) + this.getUsageSum(data.itemsVoice)) * -1;
                this.commitmentMt = this.calculateCommitment(data.itemsMt, data.tiers);
                this.testsCount = data.testsCount;
                if (data.itemsMt.length) {
                    let usageTests = 0;
                    data.itemsMt.forEach(i => {
                        usageTests += i.usageTests;
                        i.usageTests = usageTests;
                    });
                    this.progressMt = this.createProgress(data.itemsMt);
                    this.itemsMt = data.itemsMt;
                }
                if (data.itemsMo.length) {
                    this.progressMo = this.createProgress(data.itemsMo);
                    this.itemsMo = data.itemsMo;
                }
                if (data.itemsVoice.length) {
                    this.progressVoice = this.createProgress(data.itemsVoice);
                    this.itemsVoice = data.itemsVoice;
                }
                if (data.plan.analyticsTariff) {
                    data.plan.analyticsTariff.rate = data.plan.analyticsTariff.rate / 10;
                }
                this.analyticsTariff = data.plan.analyticsTariff;
                this.loading = false;
            },
            error: err => {
                this.loading = false;
                this.planUpdate = false;
            }
        });
    }

    private createProgress(items:  PostpaidCurrentPlanItem[]): Progress {
        if (items.length === 1) {
            return {
                total: items[0].usageTests,
                current: items[0].usageTests,
                parts: [
                    {max: items[0].usagePercent, label: this.createLabel(items[0], items, 0)}
                ]
            }
        }
        const parts =  items.map(_ => _);
        const progress = {
            total: 0,
            current: items.map(_ => _.usageTests).reduce((a, b) => a + b, 0),
            parts: parts.map((item, index) => {
                return {
                    max: isFinite(item.max) ? item.max : item.min,
                    label: this.createLabel(item, parts, index),
                    partUsagePercent: item.usagePercent
                };
            })
        };
        progress.total = progress.parts.length * 100;
        progress.current = parts.map(_ => _.usagePercent).reduce((a,b) => a + b);
        return progress;
    }

    private createLabel(item: PostpaidCurrentPlanItem, items: PostpaidCurrentPlanItem[], index: number): string {
        let label = '';

        if (!item.usageTests) {return label;}
        if (index === 0) {
            label = '<span class="text-muted">1</span>';
        }

        const nextItem = items[index + 1];

        if (item.usagePercent > 0 && (!nextItem || !nextItem.usagePercent)) {
            const calculated = index === 0 ? 'calculated' : 'recalculated';
            return `<span class="text-primary">${this.numberFormat.transform(item.usageTests)}</span> tests ${calculated} at ${this.currency.transform(item.ratePerTest, 'EUR', 'symbol', '1.1-3')} per test`;
        }

        return label;
    }

    private calculateCommitment(items: PostpaidCurrentPlanItem[], tiers: Tier[]): number {
        let commitment = 0;
        tiers.forEach((t, index) => {
            const item = items[index];
            if (!item || !item.usageTests) {return;}
            if (t.commitment && t.commitment > commitment) {commitment = t.commitment;}
        });
        if (!commitment && tiers.length && tiers[0].commitment) {
            return tiers[0].commitment;
        }
        return commitment;
    }

    private getUsageSum(items: PostpaidCurrentPlanItem[]): number {
        let lastItemWithTests: PostpaidCurrentPlanItem = null;
        let usageTests = 0;
        items.forEach(i => {
            if (i.usageTests) {
                lastItemWithTests = i;
            }
            usageTests += i.usageTests;
        });
        if (lastItemWithTests) {
            return usageTests * lastItemWithTests.ratePerTest;
        }
        return 0;
    }

    getFullNameServiceType(serviceTypeCode: string): string {
        const map = {
            MT: 'Mobile terminated',
            API: 'Api',
            MO: 'Mobile Originated',
            STATISTICS: ''
        };
        if (map[serviceTypeCode]) {
            return map[serviceTypeCode];
        }
        return serviceTypeCode;
    }
}

interface Progress {displayPercent?: number, total: number, current: number, parts: Part[]}
