import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { AppsctiptsMtTier } from "../models/appscripts-billing.model";
import { map } from "rxjs/operators";

@Injectable()
export class AppscriptsBillingService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    mtTiers(): Observable<AppsctiptsMtTier[]> {
        let url = this.utils.buildUrl('ROLE/appscr/data/billing/mt-tiers');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<AppsctiptsMtTier[]>(url, options).pipe(
            map(tiers => {
                tiers.forEach((t, i) => {
                    this.prepareMtTier(t);
                    let text = '';
                    t.isFirst = i === 0;
                    if (t.isFirst) {
                        text += 'From 1';
                    } else {
                        text += `From ${tiers[i - 1].smsCount + 1}`
                    }
                    text += ` to ${t.smsCount}`;
                    t.isLast = i === tiers.length - 1;
                    if (t.isLast) {
                        const val = t.isFirst ? t.smsCount : tiers[i - 1].smsCount + 1;
                        text = `From ${val}`;
                    }
                    t.smsCountText = text;
                });
                return tiers;
            })
        );
    }

    mtTierByPhoneNumber(phoneNumber: string): Observable<AppsctiptsMtTier> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/billing/mt-tiers/by-number/${phoneNumber}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<AppsctiptsMtTier>(url, options).pipe(
            map(t => this.prepareMtTier(t))
        );
    }

    saveMtTier(tier: AppsctiptsMtTier): Observable<AppsctiptsMtTier> {
        let url = this.utils.buildUrl('ROLE/appscr/data/billing/mt-tiers');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (tier.id) {
            return this.http.put<AppsctiptsMtTier>(url, tier, options);
        } else {
            return this.http.post<AppsctiptsMtTier>(url, tier, options);
        }
    }

    deleteMtTier(id: number): Observable<AppsctiptsMtTier> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/billing/mt-tiers/${id}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete<AppsctiptsMtTier>(url, options);
    }

    createMtTier(): AppsctiptsMtTier {
        return {
            id: null,
            createdAt: null,
            name: '',
            smsCount: 1,
            rate: 0,
            isFirst: false,
            isLast: false
        }
    }

    private prepareMtTier(t: AppsctiptsMtTier): AppsctiptsMtTier {
        if (typeof t.createdAt === 'string' && t.createdAt) {
            t.createdAt = new Date(t.createdAt)
        }
        if (typeof t.changedAt === 'string' && t.changedAt) {
            t.changedAt = new Date(t.changedAt)
        }
        return t;
    }
}