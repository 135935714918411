import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { InviteCollection } from "../models/invite.model";
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";

@Injectable()
export class InviteService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(userId: number, params: InviteRequestParams): Observable<InviteCollection> {
        let queryParams = {
            page: params.page,
            size: params.size
        };

        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/invite-list', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<InviteCollection>(url, options).pipe(
            map(_ => {
                const now = new Date();
                const data = _;
                data.content.forEach(invoice => {
                    invoice.isExpired = (new Date(invoice.expiredAt)) >= now;
                });
                return data;
            })
        );
    }

    create(userId: number, email: string): Observable<any> {
        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/invite', {inviteEmail: email});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    delete(id: number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/usr/users/invite/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

}

export class InviteRequestParams {
    size: number = 20;
    page: number = 1;
}
