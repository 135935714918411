
import { Clipboard } from '@angular/cdk/clipboard';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { CellClassRules, CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, IDetailCellRendererParams, RowSelectedEvent, SortChangedEvent, StatusPanelDef } from 'ag-grid-community';
import { Observable, debounceTime, map, throwError } from 'rxjs';
import { AGTableBase } from '../../shared/components/table/ag-table-base';
import { ConflictedSession, SessionSupplierList } from '../../shared/models/session-supplier.model';
import { Session } from '../../shared/models/session.model';
import { Supplier, SuppliersCollection } from '../../shared/models/supplier.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, SearchSessionSupplierParams, SessionSupplierActionData, SessionsService } from '../../shared/services/sessions.service';
import { SuppliersService } from '../../shared/services/suppliers.service';
import { UsersService } from '../../shared/services/users.service';
import { ExpandComponent } from './expand/expand.component';
import { NoSupplierOverlayComponent } from './no-supplier-overlay/no-supplier-overlay.component';
import { SessionActionsComponent } from './session-actions/session-actions.component';
import { SupplierActionsComponent } from './supplier-actions/supplier-actions.component';
import { SupplierTableFooterComponent } from './supplier-table-footer/supplier-table-footer.component';
import { catchError } from "rxjs/operators";
import { BasicUser } from "../../shared/models/user.model";


@Component({
    selector: 'app-session-table',
    templateUrl: './session-supplier-table.component.html',
    styleUrls: ['./session-supplier-table.component.scss'],
})

export class SessionSupplierTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @Input() compact = false;
    @Output() actions: EventEmitter<SessionSupplierActionData> = new EventEmitter();
    @Output() changeBatch = new EventEmitter<unknown>();

    @ViewChild('suppliersModalTpl', { read: TemplateRef, static: false }) suppliersModalTpl: any;
    sessionId;

    @ViewChild('errorModalTpl', { read: TemplateRef, static: false }) errorModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('dumpModalTpl', { read: TemplateRef, static: false }) dumpModalTpl: any;
    dumpModal: DialogRef;

    dumpSpinner: boolean = false;
    dumpSizes = [30, 60, 120, 300];
    dumpData = {
        fileSize: 0,
        fileSizeKb: 0,
        downloadUrl: null,
        sessionId: null,
        progress: '0',
        finish: false
    };
    conflictedSessions: ConflictedSession[] = [];
    conflictedSessionsMax = false;

    isAdmin = false;

    batch = false;
    errorText: any;
    errorDetails: any;
    loading: boolean = false;

    private updateInterval: any;

    // ng grid 
    cellClassRules: CellClassRules = {
        'cp': params => true,
    };
    supplierStatusBar: {
        statusPanels: StatusPanelDef[];
    } = {
            statusPanels: [
                {
                    statusPanel: SupplierTableFooterComponent,
                    key: 'supplierStatusBarKey',
                }
            ],
        };
    sortMap: { [key: string]: string } = {
        'host': 'hostIp',
        'login': 'systemId',
    }

    supplierSortState: string[] = [];

    columnDefs: ColDef[] = [
        {
            headerName: '',
            field: 'expandCollapse', maxWidth: 32, minWidth: 32, lockPinned: true, pinned: 'left', lockPosition: 'left', lockVisible: true,
            cellRenderer: ExpandComponent,
            suppressMenu: true, suppressMovable: true, suppressColumnsToolPanel: true, suppressAutoSize: true, suppressSizeToFit: true,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'SESSION ID', field: 'id',
            hide: true, minWidth: 100,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'HOST', field: 'host',
            maxWidth: 250, minWidth: 250,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line d-flex align-items-center gap-0_5">
                    ${params.data.hostIp}:${params.data.hostPort} <span class="fas fa-circle ${params.data.online ? 'text-sucess-s2' : 'text-error-r2'}"></span>
                </div>`;
            },
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'LOGIN', field: 'login',
            maxWidth: 250, minWidth: 250,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line">${params.data.systemId}</div>`;
            },
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'USER', field: 'user',
            minWidth: 200, maxWidth: 200,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line">${params.data.user?.username}</div>`;
            },
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'ENABLED', field: 'enabled',
            maxWidth: 200, minWidth: 200, sortable: true, comparator: () => 0,
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.enabled ? 'checked' : '';
                return `<div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onEnableOrDisable(event),
        },
        {
            headerName: 'SUPPLIERS', field: 'supplierCount',
            flex: 1, maxWidth: 1000,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line gray-wrap">${params.data.supplierCount}</div>`;
            },
            tooltipField: 'supplierCount',
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'SYSTEM TYPE', field: 'systemType',
            hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line">${params.data.systemType ? params.data.systemType : ''}</div>`;
            },
            cellClassRules: this.cellClassRules,
            onCellClicked: (event: CellClickedEvent) => this.expandOrCollapseRow(event),
        },
        {
            headerName: 'ACTIONS', field: 'actions', maxWidth: 280, minWidth: 280,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: SessionActionsComponent,
        }
    ];

    detailCellRendererParams: IDetailCellRendererParams = {
        detailGridOptions: {
            columnDefs: [
                {
                    headerName: '', field: 'supplierBatch', checkboxSelection: true,
                    maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
                    lockPinned: true, lockPosition: 'left', lockVisible: true,
                    suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true,
                },
                {
                    field: 'id', headerName: 'ID', hide: true,
                    sortable: true, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
                    minWidth: 100
                },
                {
                    field: 'title', headerName: 'SUPPLIER NAME', maxWidth: 250, minWidth: 250,
                    sortable: true, initialSort: null, sortingOrder: ['desc', 'asc', null],
                    cellRenderer: (params: ICellRendererParams) => {
                        return `<div class="one-line">${params.data.title}</div>`;
                    },
                },
                {
                    field: 'routeType', headerName: 'ROUTE TYPE',
                    sortable: true, initialSort: null, sortingOrder: ['desc', 'asc', null],
                    maxWidth: 250, minWidth: 250
                },
                {
                    field: 'userId', headerName: 'USER',
                    minWidth: 200, maxWidth: 200,
                    cellRenderer: (params: ICellRendererParams) => {
                        return `<div class="one-line">${params.data.user?.username}</div>`;
                    },
                },
                {
                    field: 'serviceType', headerName: 'SERVICE TYPE',
                    sortable: true, initialSort: null, sortingOrder: ['desc', 'asc', null],
                    maxWidth: 200, minWidth: 200
                },
                {
                    field: 'tlv',
                    headerName: 'TLV',
                    flex: 1,
                    maxWidth: 1000,
                    cellRenderer: (params: ICellRendererParams) => {
                        return `<div class="one-line">${this.getSimulatedTlv(params.data.tlvDtos)}</div>`;
                    },
                },
                {
                    field: 'comment',
                    headerName: 'COMMENT', hide: true,
                    cellRenderer: (params: ICellRendererParams) => {
                        return `<div class="one-line">${params.data.comment ? params.data.comment : ''}</div>`;
                    },
                },
                {
                    headerName: 'ACTIONS', field: 'actions', maxWidth: 110, minWidth: 100,
                    pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
                    suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
                    tooltipField: 'actions',
                    headerTooltip: 'Right click on a row to see more options.',
                    headerClass: 'action-cell',
                    cellClass: 'action-cell',
                    cellRenderer: SupplierActionsComponent,
                }
            ],
            defaultColDef: this.defaultColDef,
            rowHeight: 36,
            headerHeight: 36,
            icons: this.customIcons,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            noRowsOverlayComponent: NoSupplierOverlayComponent,
            statusBar: this.supplierStatusBar,
            getRowId: (params: GetRowIdParams) => this.getSessionSupplierRowId(params),
            onRowSelected: (event: RowSelectedEvent) => {
                const selectedRows = [];
                this.gridApi.forEachDetailGridInfo(dgi => {
                    selectedRows.push(...dgi.api.getSelectedRows());
                });
                const shouldSessionBatchBeDisabled = selectedRows.length > 0;
                this.gridApi.updateGridOptions({ isRowSelectable: () => !shouldSessionBatchBeDisabled });
                this.changeBatch.emit(selectedRows);
            },
            onGridReady: (params: GridReadyEvent) => {
                const supplierTableColumnState = this.localStorage.get(`supplier_table_state_${this.userService.authUser.id}`);
                params.api.applyColumnState({ state: supplierTableColumnState, applyOrder: true });
            },
            getMainMenuItems: (params: GetContextMenuItemsParams) => this.getSessionSupplierMainMenuItems(params),
            onColumnMoved: (event: ColumnMovedEvent) => this.onColumnMoved(event),
            onColumnResized: (event: ColumnResizedEvent) => this.onColumnResized(event),
            onColumnVisible: (event: ColumnVisibleEvent) => this.onColumnVisible(event),
            onSortChanged: (event: SortChangedEvent) => this.onSupplierSortChange(event),
        },
        getDetailRowData: (params) => {
            params.successCallback(params.data.suppliers);
        },
    } as IDetailCellRendererParams;

    rowData!: Session[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: SessionsService,
        public suppliersService: SuppliersService,
        public userService: UsersService,
        public modal: ModalService,
        private localStorage: LocalStorageService,
        private clipBoard: Clipboard
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.paginationPageSize = this.localStorage.get(`session_table_size_${this.userService.authUser.id}`) || 20;
        this.actionSubscription = this.service.action$.subscribe(
            action => this.onAction(action)
        );
        this.suppliersService.getSupplierPageChange().subscribe((pageNumber: number) => {
            this.updateSupplier(pageNumber);
        });
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.saveColumnState(event);
        });
        this.stayUpdated();
    }

    stayUpdated(): void {
        this.updateInterval = setInterval(() => {
            let anyRowExpanded = false;
            this.gridApi.forEachNode(node => {
                if (node.expanded) {
                    anyRowExpanded = true;
                }
            });
            if (!anyRowExpanded) {
                const searchParams = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`);
                this.update(false, searchParams);
            }
        }, 1000 * 30);
    }

    saveColumnState(event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) {
        const columnState = event.api.getColumnState();
        if (columnState[0]?.colId === 'expandCollapse') {
            // Session table 
            this.localStorage.set(`session_supplier_table_state_${this.userService.authUser.id}`, columnState);
        } else {
            // Supplier table || Sub table ..
            this.localStorage.set(`supplier_table_state_${this.userService.authUser.id}`, columnState);
        }
    }

    onSortChange(event: SortChangedEvent) {
        const columnState = this.gridApi.getColumnState();
        this.sortState = columnState.map(c => {
            const colId = this.sortMap[c.colId] || c.colId;
            return c.sort ? `${colId},${c.sort}` : null;
        });
        this.update();
    }

    onSupplierSortChange(event: SortChangedEvent) {
        this.gridApi.forEachDetailGridInfo(dgi => {
            const columnState = dgi.api.getColumnState();
            this.supplierSortState = columnState.map(c => {
                const colId = this.sortMap[c.colId] || c.colId;
                return c.sort ? `${colId},${c.sort}` : null;
            });
        });
        this.updateSupplier();
    }

    canShowActionDump() {
        return this.isAdmin;
    }

    canShowActionDelete(row) {
        return this.canShowActionEdit(row);
    }

    getSimulatedTlv(tagsAndValues: { id: number, tag: number, value: string, tagHex: number, valueHex: number }[]) {
        if (!tagsAndValues || tagsAndValues.length === 0) {
            return '';
        }
        const getFieldLength = (value) => value.length / 2;
        const getPaddedLength = (value, padLength) => value.toString().padStart(padLength, '0');

        let tlvRender = '';
        for (let { tagHex, value, valueHex } of tagsAndValues) {
            const lengthStr = value.length < 196 ? getPaddedLength(getFieldLength(valueHex), 2) : getPaddedLength(getFieldLength(valueHex), 4);
            const currentTlv = `${tagHex}${lengthStr}${valueHex}`;
            tlvRender += currentTlv;
        }

        const tlvLength = tlvRender.length / 2;
        const calculatedLength = tlvLength < 98 ? getPaddedLength(tlvLength, 2) : getPaddedLength(tlvLength, 4);
        return `${calculatedLength}${tlvRender}`;
    }

    onEnableOrDisable(event: CellClickedEvent) {
        if ((event.event.target as HTMLElement).classList.contains('form-check-input')) {
            this.loading = true;
            const session: Session = event.data;
            session.enabled = !session.enabled;
            this.service.save(session).subscribe({
                next: (res) => {
                    this.notificationService.success('Session updated', 'Sessions');
                    this.loading = false;
                    this.update();
                },
                error: (error) => {
                    this.notificationService.error('Session updating error', 'Sessions');
                    this.loading = false;
                }
            });
        }
    }

    expandOrCollapseRow(event: CellClickedEvent) {
        this.gridApi.setRowNodeExpanded(event.node, !event.node.expanded);
        if (event.node.expanded) {
            // Setting this here to access it into NoRowsOverlayComponent ...
            this.service.setActiveSessionForSupplier(event.node.data);
            this.updateSupplier();
        } else {
            this.gridApi.updateGridOptions({ isRowSelectable: () => true });
            const selectedSessions = this.gridApi.getSelectedRows();
            this.changeBatch.emit(selectedSessions);
        }
    }

    onPageChange(event) {
        if (event === this.currentPageNumber) {
            return;
        }
        this.currentPageNumber = event;
        this.update();
    }

    onSessionRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        const shouldSupplierBatchBeDisabled = selectedRows.length > 0;
        let isSupplierSelected: boolean = false;
        this.gridApi.forEachDetailGridInfo(dgi => {
            dgi.api.updateGridOptions({ isRowSelectable: () => !shouldSupplierBatchBeDisabled });
            if (dgi.api.getSelectedRows().length > 0) {
                isSupplierSelected = true;
            }
        });
        if (!isSupplierSelected) {
            this.changeBatch.emit(selectedRows);
        }
    }

    update(spinnerShow: boolean = true, searchParams: SearchSessionSupplierParams = {}) {
        this.setLoadingState(spinnerShow);
        this.getSearchObservable(searchParams).subscribe({
            next: response => this.handleResponse(response),
            error: error => this.handleError(error)
        });
    }

    private updateSupplier(pageNumber: number = 1): void {
        const params: AllRequestParams = new AllRequestParams();
        params.size = 20;
        params.page = pageNumber - 1;
        params.sort = this.supplierSortState;
        let savedUserSearchDetails = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`);
        const session = this.service.getActiveSessionForSupplier();
        this.setGridRowExpansion(session.id);
        this.showLoadingOverlays();

        this.service.searchSuppliers(session.id, params, savedUserSearchDetails).subscribe({
            next: (supplierCollection: SuppliersCollection) => {
                const suppliers: Supplier[] = supplierCollection.content;
                this.updateRowWithSuppliers(session.id, suppliers);
                if (suppliers.length) this.hideLoadingOverlays();
            },
            error: (error) => {
                this.handleSupplierError(error);
            }
        });
    }

    private setGridRowExpansion(sessionId: string | number): void {
        this.gridApi.forEachNode(node => node.setExpanded(node.data.id === sessionId));
    }

    private showLoadingOverlays(): void {
        this.gridApi.forEachDetailGridInfo(dgi => dgi.api.showLoadingOverlay());
    }

    private updateRowWithSuppliers(sessionId: string | number, suppliers: Supplier[]): void {
        const row = this.gridApi.getRowNode(`${sessionId}`);
        row.updateData({ ...row.data, suppliers });
        this.service.setActiveSessionForSupplier(row.data);
        this.gridApi.forEachDetailGridInfo(dgi => {
            dgi.api.redrawRows();
        });
    }

    private hideLoadingOverlays(): void {
        this.gridApi.forEachDetailGridInfo(dgi => dgi.api.hideOverlay());
    }

    private handleSupplierError(error: any): void {
        this.notificationService.error('Error while loading suppliers', 'Suppliers');
        this.service.setActiveSessionForSupplier(null);
        this.hideLoadingOverlays();
    }

    getSessionSupplierMainMenuItems(params: GetContextMenuItemsParams) {
        return params.defaultItems.splice(1);
    }

    private getSearchObservable(searchParams?: SearchSessionSupplierParams): Observable<any> {
        const params = new AllRequestParams();
        params.size = this.paginationPageSize;
        params.page = this.currentPageNumber - 1;
        params.sort = this.sortState;
        params.search = searchParams?.searchString;
        return this.service.search(params, searchParams).pipe(
            map(sessionSupplierList => this.transformSessions(sessionSupplierList))
        );
    }

    private setLoadingState(state: boolean): void {
        this.loading = state;
    }

    private handleResponse(response: any): void {
        this.rowData = response;
        this.gridApi.updateGridOptions({ rowData: this.rowData });
        this.gridApi.forEachNode(node => {
            if (node.expanded) {
                this.service.setActiveSessionForSupplier(node.data);
                this.updateSupplier();
            }
        });
        this.loading = false;
    }

    private handleError(error: any): void {
        this.loading = false;
        this.notificationService.error({
            title: 'SMPP & Suppliers',
            message: 'An error occurred while loading SMPP session',
            serviceName: 'NTC',
            requestMessage: error.statusText,
            requestCode: error.status,
            ts: error.timestamp ? error.timestamp : null
        });
    }

    private transformSessions(sessionSupplierList: SessionSupplierList): Session[] {
        sessionSupplierList.content.forEach(item => {
            item.sessionDto.user = item.users.find(user => user.userId === item.sessionDto.userId);
        });
        this.totalRowsCount = sessionSupplierList.totalElements;
        return sessionSupplierList.content.map(item => ({ ...item.sessionDto }));
    }

    resetBatch() {
        this.gridApi.deselectAll();
        this.gridApi.forEachDetailGridInfo(dgi => {
            dgi.api.deselectAll();
        });
    }

    onAction(event: SessionSupplierActionData) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            }).catch(_ => { });
        }
        if (event.name === 'deleteSupplier') {
            let dialogRef = this.modal.alert().size('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.deleteSupplier(event.row as Supplier);
                }
            }).catch(_ => { });
        }

        if (event.name === 'showLastError' && 'lastError' in event.row) {
            this.errorText = event.row.lastError ?? 'No error';
            this.errorDetails = false;
            this.modal.alert().component(this.errorModalTpl).open();
        }

        if (event.name === 'dump') {
            this.dumpData.fileSize = 0;
            this.dumpData.downloadUrl = null;
            this.dumpData.sessionId = event.row.id;
            this.dumpData.finish = false;
            this.dumpData.progress = '0';
            this.conflictedSessions = [];
            this.conflictedSessionsMax = false;
            this.dumpModal = this.modal.alert().component(this.dumpModalTpl).open();
            this.dumpSpinner = true;
            this.service.conflictedSessions(this.dumpData.sessionId).pipe(
                catchError(e => {
                    this.notificationService.error({
                        title: 'SMPP & Suppliers',
                        message: 'An error occurred while detect SMPP conflicts',
                        serviceName: 'NTC',
                        requestMessage: e.statusText,
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                    this.dumpSpinner = false;
                    return throwError(() => e);
                })
            ).subscribe(cs => {
                if (!cs.length) {
                    this.dumpSpinner = false;
                    return;
                }
                const userIds = new Set<number>(cs.map(_ => _.userId));
                this.userService.basicListByIds(Array.from(userIds)).pipe(
                    catchError(e => {
                        this.notificationService.error({
                            title: 'SMPP & Suppliers',
                            message: 'An error occurred while detect SMPP conflicts (load users)',
                            serviceName: 'NTC',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                        this.conflictedSessions = cs;
                        this.dumpSpinner = false;
                        return throwError(() => e);
                    })
                ).subscribe(usersList => {
                    let userMap = new Map<number, BasicUser>();
                    usersList.forEach(u => userMap.set(u.id, u));
                    cs.forEach(s => {
                        s.user = userMap.has(s.userId) ? userMap.get(s.userId) : null;
                    });
                    this.conflictedSessions = cs;
                    this.dumpSpinner = false;
                });
            });
        }
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`session_table_size_${this.userService.authUser.id}`, size);
        this.update();
    }

    delete(session: Session) {
        this.loading = true;
        if (session.suppliers?.length > 0) {
            this.notificationService.error(`This session has ${session.suppliers?.length} suppliers. Please delete/move them before deleting this session.`, 'SMPP & Suppliers');
            this.loading = false;
            return;
        }
        this.service.delete(session.id).subscribe({
            next: (res) => {
                this.notificationService.success('Session removed', 'Sessions');
                this.loading = false;
                this.update();
            },
            error: (error) => {
                this.notificationService.error('Session removing error', 'Sessions');
                this.loading = false;
            }
        });
    }

    deleteSupplier(supplier: Supplier): void {
        this.loading = true;
        this.suppliersService.delete(supplier.id).subscribe({
            next: (res) => {
                this.notificationService.success('Supplier removed', 'Suppliers');
                this.loading = false;
                this.update();
            },
            error: (error) => {
                this.notificationService.error('Supplier removing error', 'Suppliers');
                this.loading = false;
            }
        });
    }

    canShowActionEdit(row: Session) {
        if (this.isAdmin) {
            return true;
        }
        return !row.visibleAll;
    }

    onClickDump(duration) {
        let intervalDuration = 0;
        this.dumpData.progress = '0.1';
        let originDuration = duration;

        duration += 10;

        let interval = setInterval(() => {
            intervalDuration += 0.5;
            if (intervalDuration === duration) {
                clearInterval(interval);
                this.dumpData.progress = '0';
                if (!this.dumpData.downloadUrl) {
                    this.dumpSpinner = true;
                }
            }
            this.dumpData.progress = (100 / (duration / intervalDuration)).toFixed(0);
        }, 500);

        this.service.dump(this.dumpData.sessionId, originDuration).then(data => {
            this.dumpSpinner = false;
            this.dumpData.downloadUrl = data.url;
            this.dumpData.fileSize = data.size;
            this.dumpData.fileSizeKb = Math.round(data.size / 1024);
            this.dumpData.finish = true;
        }, error => {
            clearInterval(interval);
            this.dumpData.progress = '0';
            this.dumpSpinner = false;
            this.notificationService.error('Request error', error.statusText);
        })
    }

    formatHost(row: Session) {
        let text = [row.hostIp];
        if (row.hostPort) {
            text.push(String(row.hostPort));
        }
        let icon = '<i class="fa fa-circle fa-sm text-%color%" title="%title%"></i>';
        let iconName = row.online ? 'sucess-s2' : 'error-r2';
        if (!row.online && row.lastError === 'standby') {
            iconName = 'accent-a7';
        }
        let title = { 'sucess-s2': 'Online', 'error-r2': 'Offline', 'accent-a7': 'Connecting' };
        return text.join(':') + ' ' + icon.replace('%color%', iconName).replace('%title%', title[iconName]);
    }

    // ng grid
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const savedUserSearchDetails = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`);
        const columnState = this.localStorage.get(`session_supplier_table_state_${this.userService.authUser.id}`);
        this.update(true, savedUserSearchDetails);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    public getSessionSupplierRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };
    // ng grid

    ngOnDestroy() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
        this.columnChange$.unsubscribe();
        this.actionSubscription.unsubscribe();
    }
}
