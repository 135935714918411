import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { AllRequestParams, ContentTemplateService } from '../../shared/services/content-template.service';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { TemplatesService } from '../../shared/services/templates.service';
import {
    ContentTemplate,
    ContentTemplatesCollection,
    ContentTemplateText
} from "../../shared/models/content-templates";
import { FormControl } from '@angular/forms';


@Component({
    selector: 'app-content-templates-list',
    templateUrl: './content-templates-list.component.html',
    styleUrls: ['./content-templates-list.component.scss']
})

export class ContentTemplatesListComponent implements OnInit {

    MAX_TOTAL_ITEMS_COUNT = 1000;

    MAX_FAVORITES = 7;

    @Output() selectedChange = new EventEmitter<ContentTemplate[]>();

    @Input() selected: ContentTemplate[] = [];

    sizes = [5, 10, 15];
    size = 5;
    page = 1;

    favoriteRows: ContentTemplate[] = [];
    nonFavoriteRows: ContentTemplate[] = [];
    totalCountRows = 0;

    loading = false;
    isAllFavoritesSelected: boolean = false;
    isAllTemplateSelected: boolean = false;

    searchFormControl: FormControl = new FormControl();

    private requestParams = new AllRequestParams();

    constructor(
        public contentTemplateService: ContentTemplateService,
        public notificationService: NotificationService,
        public storage: LocalStorageService,
        public templateService: TemplatesService,
    ) {
        this.size = storage.get('sender-list-size', this.size);
    }

    ngOnInit() {
        this.update();
        this.searchFormControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(_ => {
            this.requestParams.search = _;
            this.page = 1;
            this.update();
        });
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.size;
        this.requestParams.page = this.page - 1;
        this.requestParams.withText = true;

        const success = (results: any[]) => {
            let contentTemplates = results[0] as ContentTemplatesCollection;
            let contentTemplatesFav = results.length > 1 ? results[1] as ContentTemplate[] : [];
            // remove favorite texts from all content templates
            if (contentTemplatesFav.length) {
                let favIds = [];
                contentTemplatesFav.forEach(ctFav => {
                    ctFav.smsTemplateTextDtos.forEach(ctFavText => {
                        ctFavText.userFavourite = true;
                        favIds.push(ContentTemplateService.generateTextId(ctFav, ctFavText));
                    });
                });
                contentTemplates.content.forEach(ct => {
                    ct.smsTemplateTextDtos = ct.smsTemplateTextDtos.filter(ctText => !favIds.includes(ContentTemplateService.generateTextId(ct, ctText)));
                });
                contentTemplates.content = contentTemplates.content.filter(ct => ct.smsTemplateTextDtos.length > 0);
            }
            // contentTemplates.content.forEach(ct => {
            //     ct.smsTemplateTextDtos.forEach(text => text.userFavourite = false);
            // });
            // mark selected
            let selectedIds = [];
            this.selected.forEach(ct => {
                ct.smsTemplateTextDtos.filter(_ => _.selected).forEach(text => selectedIds.push(ContentTemplateService.generateTextId(ct, text)));
            });
            const markSelected = (contentTemplates: ContentTemplate[]) => {
                let isAllContentTemplateSelected: boolean = true;
                contentTemplates.forEach(ct => {
                    let selectedCount = 0;
                    ct.smsTemplateTextDtos.forEach(text => {
                        const id = ContentTemplateService.generateTextId(ct, text);
                        text.selected = selectedIds.includes(id);
                        if (text.selected) { selectedCount++; }
                    });
                    ct.selected = selectedCount > 0;
                    if (!ct.selected) isAllContentTemplateSelected = false;
                });
                return isAllContentTemplateSelected;
            };
            if (selectedIds.length) {
                this.isAllTemplateSelected = markSelected(contentTemplates.content);
                this.isAllFavoritesSelected = markSelected(contentTemplatesFav);
            }
            this.favoriteRows = contentTemplatesFav;
            this.nonFavoriteRows = contentTemplates.content;
            this.totalCountRows = contentTemplates.totalElements;
            this.loading = false;
        }
        const error = err => {
            this.notificationService.error({
                title: 'Content Templates',
                message: 'An error occurred while getting content templates.',
                serviceName: 'NTC',
                requestMessage: err.statusText,
                requestCode: err.status,
                ts: err.timestamp ? err.timestamp : null
            });
            this.loading = false;
        };

        let requests = [];
        requests.push(this.contentTemplateService.all(this.requestParams));
        if (!(this.requestParams.search?.length > 0 || this.requestParams.page > 0)) {
            requests.push(this.contentTemplateService.getFavourites());
        }
        forkJoin(requests).subscribe({
            next: success,
            error: error
        });
    }

    onClickFavoriteSelectAll(): void {
        this.isAllFavoritesSelected = !this.isAllFavoritesSelected;
        this.favoriteRows.forEach(ct => {
            ct.selected = this.isAllFavoritesSelected;
            ct.smsTemplateTextDtos.forEach(text => text.selected = this.isAllFavoritesSelected);
        });
        if (!this.isAllFavoritesSelected) {
            this.favoriteRows.forEach(ct => this.markUnselect(ct));
        }
        this.detectChanges();
    }

    onClickSelectAll(): void {
        this.isAllTemplateSelected = !this.isAllTemplateSelected;
        this.nonFavoriteRows.forEach(ct => {
            ct.selected = this.isAllTemplateSelected;
            ct.smsTemplateTextDtos.forEach(text => text.selected = this.isAllTemplateSelected);
        });
        if (!this.isAllTemplateSelected) {
            this.nonFavoriteRows.forEach(ct => this.markUnselect(ct));
        }
        this.detectChanges();
    }

    onFavRowClick(contentTemplate: ContentTemplate): void {
        contentTemplate.selected = !contentTemplate.selected;
        contentTemplate.smsTemplateTextDtos.forEach(text => text.selected = contentTemplate.selected);
        this.isAllFavoritesSelected = this.favoriteRows.every(ct => ct.selected);
        if (!contentTemplate.selected) { this.markUnselect(contentTemplate); }
        this.detectChanges();
    }

    onRowClick(contentTemplate: ContentTemplate): void {
        contentTemplate.selected = !contentTemplate.selected;
        contentTemplate.smsTemplateTextDtos.forEach(text => text.selected = contentTemplate.selected);
        this.isAllTemplateSelected = this.nonFavoriteRows.every(ct => ct.selected);
        if (!contentTemplate.selected) { this.markUnselect(contentTemplate); }
        this.detectChanges();
    }

    onFavItemClick(contentTemplate: ContentTemplate, text: ContentTemplateText): void {
        text.selected = !text.selected;
        contentTemplate.selected = contentTemplate.smsTemplateTextDtos.some(text => text.selected);
        this.isAllFavoritesSelected = this.favoriteRows.every(ct => ct.selected);
        if (!text.selected) { this.markUnselect(contentTemplate, text); }
        this.detectChanges();
    }

    onItemClick(contentTemplate: ContentTemplate, text: ContentTemplateText): void {
        text.selected = !text.selected;
        contentTemplate.selected = contentTemplate.smsTemplateTextDtos.some(text => text.selected);
        this.isAllTemplateSelected = this.nonFavoriteRows.every(ct => ct.selected);
        if (!text.selected) { this.markUnselect(contentTemplate, text); }
        this.detectChanges();
    }

    onChangeFavorite(event: Event, text: ContentTemplateText, maxFavorites = 0): void {
        event.stopPropagation();
        if (maxFavorites) {
            let countFavorites = 0;
            this.favoriteRows.forEach(fr => countFavorites += fr.smsTemplateTextDtos.length);
            if (countFavorites > maxFavorites) {
                this.notificationService.error({
                    title: 'Live number testing',
                    message: `Sorry you cannot select more than ${maxFavorites} favorites.`,
                    service: 'LNT',
                    requestMessage: '',
                    requestCode: 400,
                    ts: null
                });
                return;
            }
        }
        let favValue = !text.userFavourite;
        this.loading = true;
        this.contentTemplateService.updateFavorite(text.id, favValue).subscribe({
            next: (res) => {
                this.notificationService.success('Favourite mark updated.', 'Content Templates');
                this.update();
            }, error: (error) => {
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while marking/unmarking the SMS text template as favorite.',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    private markUnselect(contentTemplate: ContentTemplate, text: ContentTemplateText = null) {
        if (text === null) {
            this.selected.filter(ct => ct.name === contentTemplate.name).forEach(ct => {
                ct.smsTemplateTextDtos.forEach(t => t.selected = false);
            });
            return;
        }
        let map = new Map<string, ContentTemplateText>();
        this.selected.forEach(ct => {
            ct.smsTemplateTextDtos.forEach(text => map.set(ContentTemplateService.generateTextId(ct, text), text));
        });
        const id = ContentTemplateService.generateTextId(contentTemplate, text);
        if (map.has(id)) {
            map.get(id).selected = false;
        }
    }

    private detectChanges(): void {
        this.selected.forEach((ct, index) => {
            if (ct.smsTemplateTextDtos.every(_ => !_.selected)) {
                delete this.selected[index];
            }
        });
        let mapSelected = new Map<string, ContentTemplate>();
        this.selected.forEach((ct, index) => {
            ct.index = index + 1;
            mapSelected.set(ct.name, ct);
        });

        const merge = (contentTemplatesParts: ContentTemplate[][]): ContentTemplate[] => {
            let allTemplates: ContentTemplate[] = [];
            contentTemplatesParts.forEach(contentTemplates => {
                contentTemplates.forEach(ct => {
                    let exists = allTemplates.filter(_ => _.name === ct.name);
                    if (exists.length) {
                        exists[0].smsTemplateTextDtos.push(...ct.smsTemplateTextDtos.map(_ => Object.assign({}, _)));
                    } else {
                        allTemplates.push({
                            id: ct.id,
                            description: ct.description,
                            name: ct.name,
                            smsTemplateTextDtos: ct.smsTemplateTextDtos.map(_ => Object.assign({}, _))
                        });
                    }
                });
            });
            return allTemplates;
        };


        let selectedOnCurrentPage: ContentTemplate[] = [];
        merge([this.favoriteRows, this.nonFavoriteRows]).forEach(ct => {
            if (ct.smsTemplateTextDtos.every(_ => !_.selected)) { return; }
            ct.fromServer = !!ct.id;
            if (mapSelected.has(ct.name)) { ct.index = mapSelected.get(ct.name).index; }
            selectedOnCurrentPage.push(ct);
        });
        const selectedSenders = new Set<string>(selectedOnCurrentPage.map(_ => _.name));
        let selected = this.selected.filter(ct => !selectedSenders.has(ct.name));
        selectedOnCurrentPage.forEach(ct => {
            if (!ct.index) { ct.index = selected.length + 1; }
            selected.push(ct);
        });

        this.selectedChange.emit(selected.sort((a, b) => (a.index < b.index ? -1 : 1)));
    }

    onChangePage(page) {
        this.page = page;
        this.update();
    }

    onChangeSize(size) {
        if (size === this.size) {
            this.update();
            return;
        }
        this.size = size;
        this.page = 1;
        this.storage.set('sender-list-size', this.size);
        this.update();
    }

}