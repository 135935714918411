import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { BreadcrumbService } from "../../shared/components/breadcrumbs/breadcrumbs";
import { SettingService } from "../../shared/services/setting.service";
import { NotificationService } from "../../shared/services/notification.service";
import { UsersService } from '../../shared/services/users.service';
import { Setting } from "../../shared/models/setting.model";

@Component({
    selector: 'setting-index-component',
    templateUrl: './setting-index.component.html',
    styleUrls: ['./setting-index.component.scss'],
})

export class SettingIndexComponent implements OnInit {

    models: { model: Setting, val: string | number | boolean, spinner: boolean }[] = [];
    groupedModels: { [key: string]: any[] } = {};
    sortedGroupKeys: string[] = [];
    objectKeys = Object.keys;
    isAdmin: boolean = null;

    loading = false;

    names = {
        mtc_disabled: 'Manual testing disabled',
        mtc_disabled_for_client: 'Manual testing disabled (for clients)',
        mtc_disabled_message: 'Manual testing disabled message',
        ntc_disabled: 'Live testing disabled',
        ntc_disabled_for_client: 'Live testing disabled (for clients)',
        ntc_disabled_message: 'Live testing disabled message',
        motc_disabled: 'MO testing disabled',
        motc_disabled_for_client: 'MO testing disabled (for clients)',
        motc_disabled_message: 'MO testing disabled message',
        vtc_disabled: 'Voice testing disabled',
        vtc_disabled_for_client: 'Voice testing disabled (for clients)',
        vtc_disabled_message: 'Voice testing disabled message',
    };

    constructor(
        public breadcrumbs: BreadcrumbService,
        public service: SettingService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public userService: UsersService
    ) {
        titleService.setTitle('Settings');
        userService.can('admin').then(_ => this.isAdmin = _).catch(e => { });
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('Settings', ['settings'], true);
        this.update();
    }

    update() {
        this.loading = true;
        this.service.all().subscribe(settings => {
            this.models = settings.map(_ => {
                return { model: _, val: this.service.toValue(_), spinner: false, type: this.getType(_) }
            });

            this.groupedModels = this.groupByType(this.models);
            this.sortedGroupKeys = this.sortGroupKeys(this.groupedModels);
            this.loading = false;
        });
    }

    getType(model) {
        if (model.name.includes('ntc')) return 'Live number testing';
        if (model.name.includes('motc')) return 'MO testing';
        if (model.name.includes('mtc')) return 'Manual testing';
        if (model.name.includes('vtc')) return 'Voice testing';
    }

    private sortGroupKeys(groupedModels: { [key: string]: any[] }): string[] {
        return ['Live number testing', 'Manual testing', 'MO testing', 'Voice testing'].filter(key => key in groupedModels); // Make sure the name follows the type returned by getType() ...
    }

    private groupByType(models: any[]): { [key: string]: any[] } {
        return models.reduce((acc, model) => {
            const key = model.type;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(model);
            return acc;
        }, {} as { [key: string]: any[] });
    }

    onSettingChange(val, model) {
        if (val instanceof Event) {
            return;
        }
        model.model.valRaw = this.service.toString(val, model.model.valType);
        model.spinner = true;

        this.service.save(model.model).subscribe(_ => {
            // this.service.updateSettings();
            model.spinner = false;
            this.notificationService.success(
                'Setting "' + this.getTitle(model.model.name) + '" updated',
                'Settings'
            );
        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Settings',
                message: 'An error occurred while updating the setting',
                serviceName: 'MTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        });
    }

    isAnySpinnerActive(): boolean {
        return Object.values(this.groupedModels).some(group =>
            group.some(model => model.spinner)
        );
    }

    getTitle(name) {
        if (typeof this.names[name] !== "undefined") {
            return this.names[name];
        }

        return name;
    }
}