import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { InvoicesFormComponent } from '../invoices-form/invoices-form.component';

@Component({
    selector: 'app-invoices-create',
    templateUrl: './invoices-create.component.html',
})

export class InvoicesCreateComponent {

    @ViewChild(InvoicesFormComponent, {static: false}) form;

    loading = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Create invoice');
    }

    afterSave() {
        this.router.navigate(['/invoices']).then();
    }
}
