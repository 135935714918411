import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { PhoneReportService } from '../../../shared/services/phone-report.service';
import { PhoneReport } from '../../../shared/models/phone-report.model';

@Component({
    selector: 'app-phones-reports-table-actions',
    templateUrl: './phones-reports-table-actions.component.html',
    styleUrls: ['./phones-reports-table-actions.component.scss']
})
export class PhonesReportsTableActionsComponent {

    currentRow: PhoneReport;

    constructor(private phoneReportService: PhoneReportService) {

    }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.phoneReportService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }


}
