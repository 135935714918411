import { Component, Input, SimpleChanges, OnChanges, ViewChild, TemplateRef } from "@angular/core";
import {
    ColDef,
    GridApi, GridOptions,
    IServerSideDatasource, RowNode,
    SortChangedEvent
} from "ag-grid-community";
import { AnalyticResult, GroupByCol, WizardQuery } from "../../shared/models/analytic.model";
import { IServerSideGetRowsParams } from "ag-grid-community/dist/lib/interfaces/iServerSideDatasource";
import { NotificationService } from "../../shared/services/notification.service";
import { AnalyticService, AnalyticsSource } from "../../shared/services/analytic.service";
import 'ag-grid-enterprise';
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { GroupCellRenderer, GroupColumnDef } from "./group-cell-renderer.component";
import { AnalyticsTableHeaderComponent } from "./analytics-table-header.component";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import { ModalService } from "../../shared/services/modal.service";
import { StatusRendererComponent } from "./status-renderer.component";

@Component({
    selector: 'app-analytics-table',
    templateUrl: './analytics-table.component.html',
    styleUrls: [],
})

export class AnalyticsTableComponent implements OnChanges {

    @Input() source: AnalyticsSource;
    @Input() from: string;
    @Input() to: string;
    @Input() userIds: number[] = [];
    @Input() demoMode = false;
    @Input() startGroupBy?: GroupByCol = null;

    @ViewChild('limitModalTpl', { read: TemplateRef, static: false }) limitModalTpl: any;

    theme = AgGridUtils.theme;

    gridApi: GridApi<AnalyticResult>;

    gridOptions: GridOptions<AnalyticResult> = {
        onSortChanged: (e: SortChangedEvent) => {
            AnalyticService.saveSort(this.storage, this.source.toLowerCase(), e.api.getColumnDefs());
        },
        onRowGroupOpened: () => {
            this.gridApi.autoSizeColumn(this.gridApi.getAllGridColumns()[0]);
        },
        onGridReady: e => {
            this.gridApi = e.api;
            this.configTable();
        },
        getServerSideGroupKey: (r: AnalyticResult) => r.title,
        isServerSideGroup: (r: AnalyticResult) => r.group,
        serverSideDatasource: this.getDataSource(),
        popupParent: document.body,
        treeData: true,
        suppressMovableColumns: true,
        suppressContextMenu: true,
        enableCellTextSelection: true,
        suppressCellFocus: true,
        alwaysMultiSort: true,
        rowModelType: 'serverSide',
        tooltipShowDelay: 500,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    }

    private tooltipHeaders = {
        receiptStatuses: `
            <span class="text-danger">•</span> Test number not available<br>
            <span class="text-danger">•</span> Test number offline<br>
            <span class="text-danger">•</span> Network offline<br>
            <span class="text-danger">•</span> Negative<br>
            <span class="text-danger">•</span> Internal error<br>
            <span class="text-danger">•</span> SMPP Connection offline<br>
            <span class="text-warning">•</span> Text or Sender ID replaced<br>
            <span class="text-navy">•</span> Positive<br>
        `,
        dlrStatuses: `
            <span class="text-danger">•</span> Expired/Deleted/Undeliverable<br>
            <span class="text-danger">•</span> Rejected/Skipped/SUBMIT_SM failed<br>
            <span class="text-warning">•</span> Partially delivered<br>
            <span class="text-warning">•</span> Accepted/Pending/Scheduled<br>
            <span class="text-warning">•</span> Enroute/Unknown<br>
            <span class="text-warning">•</span> No DLR received<br>
            <span class="text-navy">•</span> Delivered<br>
        `,
        deliverability: '% of tests with ‘Positive’ or ‘Sender/Text replaced’ Rec.status',
        senderReplacedPercent: '% of tests with ‘Sender replaced’ Rec.status',
        textReplacedPercent: '% of tests with ‘Text replaced’ Rec.status',
        concatDeliverability: '% of delivered concatenated SMS',
        delay: 'The median DLR / Rec. delay'
    }

    private definitions: { [key: string]: { cellRendererParamsColumns: GroupColumnDef[], columns: ColDef<AnalyticResult>[] } } = {
        GLOBAL: {
            cellRendererParamsColumns: [
                { title: 'Country', groupByColumns: ['COUNTRY'], dependsOnGroupByColumns: [] },
                { title: 'Network', groupByColumns: ['NETWORK'], dependsOnGroupByColumns: [] },
                { title: 'Sender type', groupByColumns: ['SENDER_TYPE'], dependsOnGroupByColumns: [] },
                { title: 'Supplier title', groupByColumns: ['SUPPLIER_TITLE'], dependsOnGroupByColumns: [] },
                { title: 'Supplier route type', groupByColumns: ['SUPPLIER_ROUTE_TYPE'], dependsOnGroupByColumns: [] },
                { title: 'SMSC provider', groupByColumns: ['SMSC_PROVIDER_NAME'], dependsOnGroupByColumns: [] },
                { title: 'SMSC phone', groupByColumns: ['SMSC_PHONE'], dependsOnGroupByColumns: [], minLevel: 1 },
            ],
            columns: [
                { field: 'totalTests', headerName: 'Total', headerComponent: AnalyticsTableHeaderComponent, menuTabs: [], sortable: true },
                { field: 'dlrStatuses', headerName: 'DLR status', cellRenderer: StatusRendererComponent, menuTabs: [], headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.dlrStatuses } },
                { field: 'receiptStatuses', headerName: 'Receipt status', cellRenderer: StatusRendererComponent, menuTabs: [], headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.receiptStatuses } },
                { field: 'deliverability', headerName: 'Deliverability', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.deliverability } },
                { field: 'senderReplacedPercent', headerName: 'Sender replaced', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.senderReplacedPercent } },
                { field: 'textReplacedPercent', headerName: 'Text replaced', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.textReplacedPercent } },
                { field: 'concatDeliverability', headerName: 'Concat SMS', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.concatDeliverability } },
                { field: 'totalSuppliers', headerName: 'Suppliers count', menuTabs: [], flex: 1, sortable: true, headerComponent: AnalyticsTableHeaderComponent },
            ]
        },
        NTC: {
            cellRendererParamsColumns: [
                { title: 'Country', groupByColumns: ['COUNTRY'], dependsOnGroupByColumns: [] },
                { title: 'Network', groupByColumns: ['NETWORK'], dependsOnGroupByColumns: [] },
                { title: 'Sender type', groupByColumns: ['SENDER_TYPE'], dependsOnGroupByColumns: [] },
                { title: 'Supplier title', groupByColumns: ['SUPPLIER_TITLE'], dependsOnGroupByColumns: [] },
                { title: 'Supplier route type', groupByColumns: ['SUPPLIER_ROUTE_TYPE'], dependsOnGroupByColumns: [] },
                { title: 'SMSC provider', groupByColumns: ['SMSC_PROVIDER_NAME'], dependsOnGroupByColumns: [] },
                { title: 'SMSC phone', groupByColumns: ['SMSC_PHONE'], dependsOnGroupByColumns: [], minLevel: 1 },
            ],
            columns: [
                { field: 'totalTests', headerName: 'Total', headerComponent: AnalyticsTableHeaderComponent, menuTabs: [], sortable: true },
                { field: 'dlrStatuses', headerName: 'DLR status', cellRenderer: StatusRendererComponent, menuTabs: [], headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.dlrStatuses } },
                { field: 'receiptStatuses', headerName: 'Receipt status', cellRenderer: StatusRendererComponent, menuTabs: [], headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.receiptStatuses } },
                { field: 'deliverability', headerName: 'Deliverability', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.deliverability } },
                { field: 'senderReplacedPercent', headerName: 'Sender replaced', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.senderReplacedPercent } },
                { field: 'textReplacedPercent', headerName: 'Text replaced', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.textReplacedPercent } },
                { field: 'concatDeliverability', headerName: 'Concat SMS', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.concatDeliverability } },
                { field: 'delay', headerName: 'DLR / Rec. latency median', valueGetter: AnalyticService.delayGetter, cellRenderer: AnalyticService.delayRenderer, menuTabs: [], flex: 1, sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.delay } },
            ]
        },
        MTC: {
            cellRendererParamsColumns: [
                { title: 'Country', groupByColumns: ['COUNTRY'], dependsOnGroupByColumns: [] },
                { title: 'Network', groupByColumns: ['NETWORK'], dependsOnGroupByColumns: [] },
                { title: 'Sender type', groupByColumns: ['SENDER_TYPE'], dependsOnGroupByColumns: [] },
                { title: 'SMSC provider', groupByColumns: ['SMSC_PROVIDER_NAME'], dependsOnGroupByColumns: [] },
                { title: 'SMSC phone', groupByColumns: ['SMSC_PHONE'], dependsOnGroupByColumns: [], minLevel: 1 },
            ],
            columns: [
                { field: 'totalTests', headerName: 'Total', headerComponent: AnalyticsTableHeaderComponent, menuTabs: [], sortable: true },
                { field: 'receiptStatuses', headerName: 'Receipt status', cellRenderer: StatusRendererComponent, menuTabs: [], headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.receiptStatuses } },
                { field: 'deliverability', headerName: 'Deliverability', valueFormatter: v => v.value + '%', menuTabs: [], sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.deliverability } },
                { field: 'delay', headerName: 'Rec. latency median', cellRenderer: AnalyticService.delayRenderer, menuTabs: [], flex: 1, sortable: true, headerComponent: AnalyticsTableHeaderComponent, headerComponentParams: { tooltipContent: this.tooltipHeaders.delay } },
            ]
        }
    }

    private resetTable: boolean;

    private searchPhrase = '';

    constructor(
        public notificationService: NotificationService,
        public service: AnalyticService,
        public storage: LocalStorageService,
        public modal: ModalService,
    ) { }

    private configTable() {
        const def = this.definitions[this.source]
        AnalyticService.applySortToColumns(this.storage, this.source.toLowerCase(), def.columns);
        this.gridApi.updateGridOptions({columnDefs: def.columns,});
        this.gridApi.setAutoGroupColumnDef(this.createGroupColumnDef(def.cellRendererParamsColumns));
    }

    search(phrase: string) {
        this.searchPhrase = phrase;
        this.resetTable = true;
        this.gridApi.refreshServerSide({ purge: true });
    }

    updateStartGroupBy(column?: GroupByCol) {
        this.startGroupBy = column;
        this.resetTable = true;
        this.gridApi.collapseAll();
        this.gridApi.refreshServerSide({ purge: true });
    }

    private createGroupColumnDef(columns: GroupColumnDef[]): ColDef<AnalyticResult> {
        return {
            headerName: 'Group',
            /*headerComponent: AnalyticsTableSearchHeaderComponent,
            headerComponentParams: {
                onSearch: phrase => {
                    this.searchPhrase = phrase;
                    this.resetTable = true;
                    this.gridApi.refreshServerSide({purge: true});
                }
            },*/
            cellRenderer: GroupCellRenderer,
            cellRendererParams: {
                columns: columns,
                hints: {
                    COUNTRY: 'Country',
                    NETWORK: 'Network',
                    SENDER_TYPE: 'Sender type',
                    SUPPLIER_TITLE: 'Supplier title',
                    SUPPLIER_ROUTE_TYPE: 'Supplier route type',
                    SMSC_PROVIDER_NAME: 'SMSC provider',
                    SMSC_PHONE: 'SMSC phone'
                },
                slicesLimit: this.demoMode ? 3 : 0,
                onLimitReached: () => this.onLimitReached()
            },
            pinned: 'left',
            suppressAutoSize: true,
            suppressMenu: true,
            field: 'title',
        };
    }

    private getDataSource(): IServerSideDatasource {
        return {
            getRows: (params: IServerSideGetRowsParams<AnalyticResult>) => {
                const query = this.buildQuery(params);
                const isLastLevel = this.isLastLevel(query)
                const observer = {
                    next: (rows: AnalyticResult[]) => {
                        let nextGroupByColumn: GroupByCol = (params.parentNode && params.parentNode.data) ?
                            params.parentNode.data.nextGroupByColumn
                            : undefined;
                        if (this.startGroupBy && query.groupByColumns.length === 1 && query.groupByColumns[0] === this.startGroupBy) {
                            nextGroupByColumn = this.startGroupBy;
                        }
                        rows.forEach(row => {
                            row.title = this.extractTitle(nextGroupByColumn, row);
                            row.group = !isLastLevel && row.totalTests > 0;
                            row.groupByColumn = nextGroupByColumn;
                        });
                        params.success({
                            rowData: rows.sort((a, b) => a.title.localeCompare(b.title))
                        });
                        this.gridApi.autoSizeAllColumns();
                    },
                    error: (err) => {
                        params.fail();
                        this.notificationService.error({
                            title: 'Analytics',
                            message: 'An error occurred while loading analytics',
                            requestMessage: err.statusText,
                            requestCode: err.status
                        });
                    }
                };
                switch (this.source) {
                    case "GLOBAL":
                        this.service.globalAnalyticWizard(query).subscribe(observer);
                        break;
                    case "NTC":
                        this.service.ntcAnalyticWizard(query).subscribe(observer);
                        break;
                    case "MTC":
                        this.service.mtcAnalyticWizard(query).subscribe(observer);
                        break;
                }
            }
        };
    }

    private isLastLevel(query: WizardQuery): boolean {
        let usedColumns = new Set;
        query.equalFilters.forEach(_ => usedColumns.add(_.col));
        query.groupByColumns.forEach(_ => usedColumns.add(_));

        let allGroupColumns = new Set;
        const def = this.definitions[this.source];
        def.cellRendererParamsColumns.forEach(c => {
            c.groupByColumns.forEach(_ => allGroupColumns.add(_));
        });

        return Array.from(usedColumns).length === Array.from(allGroupColumns).length;
    }

    private extractTitle(col: GroupByCol, row: AnalyticResult): string {
        switch (col) {
            case "COUNTRY":
                return `${row.countryName} (${row.mcc})`;
            case "NETWORK":
                let mncList = row.mnc ? row.mnc.split(',') : [];
                let text = row.network;
                if (mncList.length > 1) {
                    text += ` (<span title="${mncList.join(', ')}">...</span>)`;
                } else if (mncList.length) {
                    text += ` (${row.mnc})`;
                }
                return text;
            case "SUPPLIER_TITLE":
                return row.supplierTitle;
            case "SUPPLIER_ROUTE_TYPE":
                return row.routeType;
            case "SMSC_PROVIDER_NAME":
                if (row.smscAggregator) {
                    return `<span class="text-warning" title="SMSC Aggregator">${row.smscProviderName}</span>`;
                }
                return row.smscProviderName;
            case "SMSC_PHONE":
                return row.smsCenterPhone;
            case "SENDER_TYPE":
                return row.senderType;
        }
        return 'All';
    }

    private buildQuery(params: IServerSideGetRowsParams<AnalyticResult>): WizardQuery {
        const query: WizardQuery = {
            dateRange: { from: this.from, to: this.to },
            likeFilter: (this.searchPhrase && this.searchPhrase.length > 0) ? this.searchPhrase : null,
            groupByColumns: [],
            equalFilters: [],
            mainaccountIds: this.userIds.length ? this.userIds : []
        };

        if (this.startGroupBy) {
            query.groupByColumns.push(this.startGroupBy)
        }

        if (this.resetTable) {
            this.resetTable = false;
            return query;
        }

        if (params.parentNode && params.parentNode.data && params.parentNode.data.nextGroupByColumn) {
            query.groupByColumns.push(params.parentNode.data.nextGroupByColumn);
        }

        const extractPattern = (row: AnalyticResult): string => {
            switch (row.groupByColumn) {
                case "COUNTRY":
                    return row.countryName;
                case "NETWORK":
                    return row.network;
                case "SUPPLIER_TITLE":
                    return row.supplierTitle;
                case "SUPPLIER_ROUTE_TYPE":
                    return row.routeType;
                case "SMSC_PROVIDER_NAME":
                    return row.smscProviderName;
                case "SMSC_PHONE":
                    return row.smsCenterPhone;
                case "SENDER_TYPE":
                    return row.senderType;
            }
            return null;
        };

        const p = params.parentNode as RowNode<AnalyticResult>;
        AnalyticService.extractPath(p)
            .filter(_ => _.groupByColumn)
            .forEach(r => {
                query.equalFilters.push({
                    col: r.groupByColumn,
                    pattern: extractPattern(r)
                });
            });

        return query;
    }

    private onLimitReached() {
        this.modal.alert().component(this.limitModalTpl).open();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined) ||
            (changes.userIds)
        ) {
            if (this.gridApi) {
                this.resetTable = true;
                this.gridApi.refreshServerSide({ purge: true });
            }
        }

        if (changes.source && this.gridApi) {
            this.gridApi.collapseAll();
            this.configTable();
            this.resetTable = true;
            this.gridApi.refreshServerSide({ purge: true });
        }
    }

}