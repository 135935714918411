import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, of, throwError } from 'rxjs';
import { map } from "rxjs/operators";
import {
    AccountUser, AuthUser,
    BasicUserCollection,
    LoginCredentials, MfaCredentials, MfaSetup, RegistrationByInviteModel, RegistrationModel,
    Role,
    SubaccountInviteResponseModel,
    TrustedDeviceCollection,
    TrustedDeviceRequestParams,
    UpdatePasswordModel,
    UserAdmin,
    UserAdminCollection,
    UserFormData,
    ZapierModel,
    formToCreateModel, formToUpdateModel, BasicUser
} from '../models/user.model';
import { RestUtils } from './rest-utils';
import { Validators as Vld } from './validation.service';

@Injectable()
export class UsersService {

    static TOTP_LENGTH = 6;

    utils = new RestUtils();

    headers = new HttpHeaders();

    sourceItems = [
        { id: '', label: '' },
        { id: 'NETWORKING', label: 'Networking' },
        { id: 'ADS', label: 'ADS' },
        { id: 'GOOGLE', label: 'Google' },
        { id: 'CONFERENCE', label: 'Conference' },
        { id: 'REFERRAL', label: 'Referral' },
        { id: 'OTHER', label: 'Other' }
    ]

    authUser: AuthUser = null;
    isAuth = false;

    authUpdates: Subject<AuthUser> = new Subject();
    balanceUpdates: BehaviorSubject<number> = new BehaviorSubject(0);
    private balanceValue = 0;

    private actionSubject: Subject<UserActionData> = new Subject<UserActionData>();
    action$ = this.actionSubject.asObservable();

    private isMoAvailableForUserSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient
    ) {
        this.headers = this.headers.set('Content-Type', 'application/json');

        setInterval(() => {
            this.updateBalance();
        }, 15 * 1000);
    }

    announceAction(action: UserActionData) {
        this.actionSubject.next(action);
    }

    static getValidatorForTestTtlMin() {
        return Vld.compose([Vld.required, Vld.min(1), Vld.max(720), Vld.integer()]);
    }

    public setMoAvailableForUser(isMoAvailable: boolean) {
        this.isMoAvailableForUserSubject$.next(isMoAvailable);
    }

    public getMoAvailableForUser(): Observable<boolean> {
        return this.isMoAvailableForUserSubject$.asObservable();
    }

    isMoAvailableForUser(): Observable<boolean> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/mo/enabled');
        let options = this.utils.getHttpHeaderOptions(this.headers, false);
        return this.http.get<boolean>(url, options);
    }

    isVoiceAvailableForUser(): Observable<boolean> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/voice/enabled');
        let options = this.utils.getHttpHeaderOptions(this.headers, false);
        return this.http.get<boolean>(url, options);
    }

    private updateBalance() {
        if (this.isAuth) {
            this.getBalance().subscribe(currentBalance => {
                if (currentBalance !== this.balanceValue) {
                    this.balanceValue = currentBalance;
                    this.balanceUpdates.next(currentBalance);
                }
            });
        }
    }

    getBalance(): Observable<number> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/balance');
        let options = this.utils.getHttpHeaderOptions(this.headers, false);

        return this.http.get<{ balanceEuro: number }>(url, options).pipe(map(_ => {
            return _.balanceEuro;
        }));
    }

    getAuthUser(): Promise<AuthUser> {
        return new Promise((resolve, reject) => {
            if (this.authUser !== null) {
                const user = Object.assign({}, this.authUser);
                resolve(user);
                return;
            }
            this.i().subscribe(user => {
                resolve(this.authUser);
            }, e => {
                this.isAuth = false;
                RestUtils._role = Role.GUEST;
                reject(e);
            });
        });
    }

    init(): Promise<boolean> {
        return new Promise(resolve => {
            this.getAuthUser().then(user => {
                this.authUpdates = new BehaviorSubject(user);
                resolve(true);
            }).catch(e => {
                this.isAuth = false;
                RestUtils._role = Role.GUEST;
                resolve(true);
            });
        });
    }

    clearAuthUser() {
        this.isAuth = false;
        this.authUser = null;
        RestUtils._role = Role.GUEST;
    }

    i() {
        let url = this.utils.buildUrl('usr/users/self');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<AuthUser>(url, options).pipe(map(_ => {
            let data = _;
            data.testTtlMin = data.testTtl / 60;
            data.minBalance = Math.floor(data.minBalance / 1000);
            this.isAuth = true;
            if (!this.authUser || this.authUser.id !== data.id) {
                this.authUser = data;
                RestUtils._role = data.role;
                this.updateBalance();
                this.authUpdates.next(this.authUser);
            } else {
                this.authUser.email = data.email;
                this.authUser.defaultLntTemplate = data.defaultLntTemplate;
                this.authUser.enabled = data.enabled;
                this.authUser.showAllResults = data.showAllResults;
                this.authUser.testRetriesEnabled = data.testRetriesEnabled;
                this.authUser.testTtl = data.testTtl;
                this.authUser.testTtlMin = data.testTtlMin;
                this.authUser.minBalance = data.minBalance;
            }

            return data;
        }));
    }

    basicList(limit: number = 10, page: number = 1, search: string, roles: string[], paymentType: string = '') {
        let queryParams = {
            page: page - 1,
            size: limit,
            search: search ? search : '',
            roles: roles.length ? roles.join(',') : '',
            paymentType: paymentType
        };

        let url = this.utils.buildUrl('ROLE/usr/users/basic-list', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<BasicUserCollection>(url, options);
    }

    basicListByIds(ids: number[]) {
        let url = this.utils.buildUrl('ROLE/usr/users/basic-list/by-ids', {
            ids: ids.join(',')
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<BasicUser[]>(url, options);
    }

    all(params: UsersRequestParams): Observable<UserAdminCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort.length ? params.sort : [],
            search: params.search ? params.search : ''
        };

        let url = this.utils.buildUrl('ROLE/usr/users/', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<UserAdminCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<UserFormData>(url, options).pipe(map(_ => {
            let user = _;
            if (user.testTtl) {
                user.testTtl = user.testTtl / 60;
            }
            return user;
        }));
    }

    updateEnable(userId: number, enable: boolean) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/enable', { enabled: enable ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, {}, options);
    }

    updateShowAllResults(userId: number, showAllResults: boolean) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/show-all-results', { showAllResults: showAllResults ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, {}, options);
    }

    updateMoEnabled(userId: number, moEnabled: boolean) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/mo-enabled', { enabled: moEnabled ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, {}, options);
    }

    updateMoAllowedNumbersEnabled(userId: number, enabled: boolean) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/mo-allowed-numbers-enabled', { enabled: enabled ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, {}, options);
    }

    toggleSubaccountOrDeputy(userId: number, role: string) {
        const roleChangeTo = role === Role.DEPUTY ? Role.SUB : Role.DEPUTY
        const url = this.utils.buildUrl('ROLE/usr/users/' + userId + '/role/' + roleChangeTo, {});
        const options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    delete(id: number) {
        let url = this.utils.buildUrl('ROLE/usr/users/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    create() {
        return {
            id: null,
            enabled: true,
            userRole: 'mainaccount',
            paymentType: 'PREPAID',
            testRetriesEnabled: true,
            emailVerified: false,
            sendVerificationEmail: true,
            rankingIndex: 3,
            testTtl: 60
        };
    }

    save(data: any): Observable<any> {
        let newData = Object.assign({}, data);

        if (typeof newData.testTtl !== undefined) {
            newData.testTtl = parseInt(newData.testTtl) * 60;
        }

        let id = newData.id;
        let url = this.utils.buildUrl('ROLE/usr/users/' + id);

        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, newData, options);
    }

    updateByAdmin(data: UserFormData) {
        let id = parseInt(String(data.id));
        let url = this.utils.buildUrl('ROLE/usr/users/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, formToUpdateModel(data), options);
    }

    createByAdmin(data: UserFormData) {
        let url = this.utils.buildUrl('ROLE/usr/users');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, formToCreateModel(data), options);
    }

    selfUpdate(user: AccountUser) {
        let data = Object.assign({}, user);
        if (data.testTtl) {
            data.testTtl = parseInt(String(data.testTtl)) * 60;
        }
        if (data.minBalance) {
            data.minBalance = parseInt(String(data.minBalance)) * 1000;
        }

        let url = this.utils.buildUrl('usr/users/self');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, data, options).pipe(map(_ => {
            this.authUser = null;
            return _;
        }));
    }

    resetPassword(userId: number, passwordModel: UpdatePasswordModel) {
        let url = this.utils.buildUrl('guest/usr/users/' + userId + '/reset-password');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, passwordModel, options);
    }

    updatePassword(userId: number, passwordModel, guestMode = false) {
        const role = guestMode ? 'guest' : 'ROLE'
        let url = this.utils.buildUrl(role + '/usr/users/' + userId + '/password');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, passwordModel, options);
    }

    updateEmail(newEmail, guestMode = false) {
        const role = guestMode ? 'guest' : 'ROLE'
        let url = this.utils.buildUrl(role + '/usr/change-email', { email: newEmail });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, newEmail, options);
    }

    ping() {
        if (this.isAuth) {
            let url = this.utils.buildUrl('ROLE/usr/users/self/last-active');
            let options = this.utils.getHttpHeaderOptions(this.headers);

            this.http.post(url, null, options).subscribe(r => { }, error => { });
        }
    }

    getTypes() {
        return [
            {
                id: 'PREPAID',
                title: 'Prepayment'
            },
            {
                id: 'POSTPAID',
                title: 'Postpayment'
            }
        ];
    }

    getCreateRoles() {
        return [
            /*{
             id: 'admin',
             title: 'Admin'
             },*/
            {
                id: 'mainaccount',
                title: 'Main account'
            },
            {
                id: 'deputy',
                title: 'Deputy account'
            },
            {
                id: 'subaccount',
                title: 'Subaccount'
            },
        ];
    }

    can(roleName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const role = RestUtils.getRole();
            if (roleName === 'client') {
                resolve(role === Role.MAIN || role === Role.DEPUTY || role === Role.SUB);
                return;
            }
            resolve(role === roleName);
        });
    }

    impersonate(userId: number) {
        let url = this.utils.buildUrl('admin/usr/users/' + userId + '/impersonate');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options).pipe(map(_ => {
            this.clearAuthUser();
        }));
    }

    logout(url: string[], impersonateUrl: string[]): Promise<string[]> {
        const hasPrevToken = this.isAuth ? this.authUser.impersonated : false;
        let urlRequest = this.utils.buildUrl('ROLE/usr/logout');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return new Promise(resolve => {
            this.http.post(urlRequest, null, options)
                .subscribe(() => {
                    this.clearAuthUser();
                    resolve(hasPrevToken ? impersonateUrl : url);
                }, () => {
                    this.clearAuthUser();
                    resolve(url);
                });
        });
    }

    login(credentials: LoginCredentials) {
        let url = this.utils.buildUrl('guest/usr/login');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, credentials, options).pipe(map(_ => {
            this.clearAuthUser();
            return _;
        }));
    }

    registration(data: RegistrationModel) {
        let url = this.utils.buildUrl('guest/usr/users/register');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, data, options);
    }

    registrationByInvite(data: RegistrationByInviteModel, invite: string): Observable<SubaccountInviteResponseModel> {
        let url = this.utils.buildUrl('guest/usr/users/register/by-invite', { invite: invite });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<SubaccountInviteResponseModel>(url, data, options);
    }

    recovery(username: string) {
        let url = this.utils.buildUrl('guest/usr/request-password-reset', { username: username });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    validateRecoveryToken(userId, token) {
        let url = this.utils.buildUrl('guest/usr/validate-password-reset', { userId: userId, token: token });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get(url, options);
    }

    verifyToken(token: string, userId: number) {
        let url = this.utils.buildUrl('guest/usr/verify-email', { userId: userId, token: token });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get(url, options);
    }

    resendVerificationEmail(userId: number) {
        let url = this.utils.buildUrl(`admin/usr/users/${userId}/resend-verification-by-admin`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    convertToSubaccount(userId: number, parentId: number) {
        let url = this.utils.buildUrl(`admin/usr/users/${userId}/role/subaccount`, { parentId: parentId });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    saveDefaultTemplate(templateId: number) {
        let queryParams = { templateId: templateId };
        if (!templateId) {
            delete queryParams.templateId;
        }
        let url = this.utils.buildUrl('ROLE/usr/users/self/defaultLntTemplate', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, {}, options);
    }

    countSubaccounts(parentEmail: string): Observable<number> {
        const url = this.utils.buildUrl('ROLE/usr/users/', {
            size: 1,
            page: 0,
            search: parentEmail
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<UserAdminCollection>(url, options).pipe(map(response => {
            return response.content.length ? response.content[0].countSubaccounts : 0;
        }));
    }

    mfaRecovery(credentials: MfaCredentials): Observable<any> {
        let url = this.utils.buildUrl('guest/usr/mfa/recovery');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, credentials, options);
    }

    mfaSetup(): Observable<MfaSetup> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/mfa/totp-setup');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<MfaSetup>(url, {}, options);
    }

    mfaEnable(mfaCode: string): Observable<any> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/mfa-enable');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, { mfaCode: mfaCode }, options);
    }

    mfaDisable(mfaCode: string): Observable<any> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/mfa-disable');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, { mfaCode: mfaCode }, options);
    }

    mfaDisableByAdmin(userId: number) {
        let url = this.utils.buildUrl(`admin/usr/users/${userId}/mfa-disable-by-admin`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    mfaGenerateCodes() {
        let url = this.utils.buildUrl('ROLE/usr/users/self/mfa/generate-recovery-codes');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<string[]>(url, {}, options);
    }

    resendEmailCode(login: string): Observable<any> {
        let url = this.utils.buildUrl('/guest/usr/request-otp-for-user', {username: login});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    sendRegistrationToZapier(model: ZapierModel): Observable<any> {
        let url = 'https://hooks.zapier.com/hooks/catch/12493969/33f0emb/';
        return this.http.post<any>(url, JSON.stringify(model), { headers: new HttpHeaders({}) });
    }

    sendInviteToZapier(model: ZapierModel): Observable<any> {
        let url = 'https://hooks.zapier.com/hooks/catch/12493969/3fqcat6/';
        return this.http.post<any>(url, JSON.stringify(model), { headers: new HttpHeaders({}) });
    }

    allDevices(requestParams: TrustedDeviceRequestParams): Observable<TrustedDeviceCollection> {
        let url = this.utils.buildUrl('ROLE/usr/trusted-device', {
            page: requestParams.page,
            size: requestParams.size,
            sort: requestParams.sort.length ? requestParams.sort : [],
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TrustedDeviceCollection>(url, options);
    }

    deleteDevice(deviceId: number) {
        let url = this.utils.buildUrl('ROLE/usr/trusted-device/' + deviceId,);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete<void>(url, options);
    }

    checkPasswordValidity(password: string): Observable<string[]|null> {
        let url = this.utils.buildUrl('guest/usr/users/validate-password');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, { password }, options).pipe(
            catchError((e: HttpErrorResponse) => {
                if (e.status === 400) {
                    const errors = e.statusText.split('-').filter(_ => _.trim() !== 'Invalid password:');
                    if (!errors.length) {
                        errors.push('Invalid password');
                    }
                    return of({'passwordBackend': errors});
                }
                return of(null);
            }),
            map((res: string[]|null) => res ? res : null)
        );
    }

    otpValidate(code: string) {
        let url = this.utils.buildUrl('ROLE/usr/otp/validate');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<void>(url, { code: code, otpChannel: 'EMAIL' }, options);
    }

    otpResend(action: string) {
        let url = this.utils.buildUrl('ROLE/usr/otp/generate');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<void>(url, { action: action, otpChannel: 'EMAIL' }, options);
    }

    validateInvite(invite: string): Observable<boolean> {
        let url = this.utils.buildUrl('guest/usr/validate-invite', {'invite': invite});
        let options = this.utils.getHttpHeaderOptions(this.headers, false);
        return this.http.get<boolean>(url, options);
    }

    getCallbackUrlLiveTesting() {
        let url = this.utils.buildUrl('ROLE/usr/users/self/callback-url-live');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get(url, {...options, responseType: "text"}).pipe(
            map(r => r ? r : '')
        );
    }

    updateCallbackUrlLiveTesting(callbackUrl: string) {
        let url = this.utils.buildUrl('ROLE/usr/users/self/callback-url-live', {callbackUrl: callbackUrl});
        if (!callbackUrl) {
            url += '?callbackUrl=';
        }
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put<void>(url, null, options);
    }

    getGeneralCallbackUrlLiveTesting() {
        let url = this.utils.buildUrl('ROLE/usr/users/self/general-callback-url-live');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get(url, {...options, responseType: "text"}).pipe(
            map(r => r ? r : '')
        );
    }

    updateGeneralUpdateCallbackUrl(callbackUrl: string) {
        let url = this.utils.buildUrl('ROLE/usr/users/self/general-callback-url-live', {callbackUrl: callbackUrl});
        if (!callbackUrl) {
            url += '?callbackUrl=';
        }
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put<void>(url, null, options);
    }
}

export class UsersRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}



export interface UserActionData {
    name: string;
    row: UserAdmin;
    column: string;
}
