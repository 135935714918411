
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { UsersService } from '../../shared/services/users.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-reset-password-token',
    templateUrl: './reset-password-token.component.html',
    styleUrls: ['./reset-password-token.component.scss']
})
export class ResetPasswordTokenComponent implements OnInit {

    formShow: boolean = false;
    loading: boolean = false;
    errorMessage = null;

    newPassword;
    repeatNewPassword;
    passwordType: string = 'password';

    form: FormGroup;

    userId: string;
    token: string;

    isPasswordLoading: boolean = false;
    passwordValidateErrors: string[] = [];

    passwordSubscription: Subscription;

    constructor(
        public title: Title,
        public router: Router,
        public route: ActivatedRoute,
        public userService: UsersService,
        formBuilder: FormBuilder,
        public notificationService: NotificationService,
        public validationService: ValidationService
    ) {
        this.title.setTitle('Check token');

        let comparePasswordValidator = () => {
            if (!this.form) {
                return null;
            }
            const a = this.form.controls.newPassword.value;
            const b = this.form.controls.repeatNewPassword.value;
            if (a === b) {
                return null;
            }
            return {
                comparePassword: false
            };
        };

        this.form = formBuilder.group({
            newPassword: ['', Vld.compose([Vld.required, Vld.maxLength(50), Vld.password(true)])],
            repeatNewPassword: ['', Vld.compose([Vld.required, comparePasswordValidator])],
        });
        validationService.addFormatter('comparePassword', validationService.createTextFormatter('Passwords must match'));
    }

    ngOnInit() {
        this.formShow = false;
        this.route.queryParams.subscribe(params => {
            this.userId = params['userId'];
            this.token = params['token'];
            this.userService.validateRecoveryToken(this.userId, this.token).subscribe(() => {
                this.title.setTitle('Update password');
                this.formShow = true;
            }, err => {
                this.errorMessage = 'Token is invalid or has expired';
            })
        });
        this.passwordSubscription = this.form.get('newPassword').valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
        ).subscribe((value: string) => {
            if (value?.trim().length > 0) {
                this.isPasswordLoading = true;
                this.userService.checkPasswordValidity(value).subscribe({
                    next: (res) => {
                        this.passwordValidateErrors = [];
                        this.isPasswordLoading = false;
                    },
                    error: (err) => {
                        if (err.status === 400) {
                            this.passwordValidateErrors = err.statusText.split('-');
                        }
                        this.isPasswordLoading = false;
                    }
                });
            } else {
                this.passwordValidateErrors = [];
            }
        });
    }

    onSubmit() {
        this.loading = true;
        this.userService.resetPassword(parseInt(this.userId), {token: this.token, newPassword: this.newPassword}).subscribe(() => {
            this.loading = false;
            this.notificationService.success('Password updated', 'Recovery');
            this.formShow = false;
        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Recovery',
                message: 'An error occurred while recovery request',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        })
    }

    isPasswordType() {
        return this.passwordType === 'password';
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    ngOnDestroy() {
        this.passwordSubscription.unsubscribe();
    }
}
