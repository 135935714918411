import {
    Component,
    EventEmitter, Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { TemplatesService } from "../../../shared/services/templates.service";
import { TextTemplateComponent } from "../../text-template/text-template.component";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NotificationService } from "../../../shared/services/notification.service";
import { ValidationService, Validators as Vld } from "../../../shared/services/validation.service";
import { CustomUtils } from "../../../shared/services/custom-utils";
import { MoText } from "../../../shared/models/mo-testing.model";
import { MoTestingService } from "../../../shared/services/mo-testing.service";
import { LocalStorageService } from '../../../shared/services/localStorage.service';

@Component({
    selector: 'app-mo-text-input',
    templateUrl: 'mo-text-input.component.html',
    styleUrls: ['mo-text-input.component.scss']
})
export class MoTextInputComponent implements OnInit {

    @Output() changeCount: EventEmitter<number> = new EventEmitter()

    @ViewChild(TextTemplateComponent, {static: false}) textTemplate: TextTemplateComponent;

    @ViewChild('telqIdModalTpl', { read: TemplateRef, static: false }) telqIdModalTpl: any;

    texts: MoText[] = [];
    textsLength = 0;

    inputState = {
        currentLength: 0,
        maxLength: 160,
        valid: true
    };

    templateModel: MoText = {
        text: null,
        hasTelqId: false,
        telqIdType: TemplatesService.TELQ_ID_TYPE_DEFAULT,
        telqIdCase: TemplatesService.TELQ_ID_CASE_DEFAULT,
        telqIdLength: TemplatesService.TELQ_ID_LEN_DEFAULT
    };
    telqIdSettings = {
        telqIdType: TemplatesService.TELQ_ID_TYPE_DEFAULT,
        telqIdCase: TemplatesService.TELQ_ID_CASE_DEFAULT,
        telqIdLength: TemplatesService.TELQ_ID_LEN_DEFAULT
    };
    telqIdSettingsUpdateModel: MoText;
    telqIdSettingsTypes = TemplatesService.TELQ_ID_TYPES
    telqIdSettingsCases = TemplatesService.TELQ_ID_CASES

    telqIdSettingsForm: FormGroup;
    @Input() form: FormGroup;

    telqIdModal: DialogRef;

    loading = false;

    private textValidator: ValidatorFn = (control: FormControl): ValidationErrors => {
        let value: string = String(control.value);
        let max = Vld.gsm7Reg.test(value) ? 160 : 70;
        this.inputState.maxLength = max;
        this.inputState.currentLength = value.length;
        if (value.length > max) {
            this.inputState.valid = false;
            return {maxlength : {requiredLength: max, currentLength: value.length}};
        }
        this.inputState.valid = true;
        return  null;
    };

    constructor(
        public templateService: TemplatesService,
        public notificationService: NotificationService,
        public validationService: ValidationService,
        formBuilder: FormBuilder,
        public modal: ModalService,
        private localStorageService: LocalStorageService,
    ) {
        this.modal = modal;
        this.templateService = templateService;
        this.notificationService = notificationService;
        this.validationService = validationService;
        this.telqIdSettingsForm = formBuilder.group({
            telqIdType: [''],
            telqIdCase: [''],
            telqIdLength: ['', Vld.compose([Vld.min(TemplatesService.TELQ_ID_LEN_MIN), Vld.max(TemplatesService.TELQ_ID_LEN_MAX)])],
        });
    }

    ngOnInit() {
        if (!this.form.contains('text')) {
            this.form.addControl('text', new FormControl('', this.textValidator))
        } else if (!this.form.controls.text.hasValidator(this.textValidator)) {
            this.form.controls.text.addValidators(this.textValidator);
        }
        this.templateModel.hasTelqId = this.localStorageService.get('mo-testing-has-telq-id') || false;
        this.templateService.getDefaultTelqIdDetails().subscribe({
            next: params => {
                if (params) {
                    this.templateModel.telqIdType = params.telqIdType;
                    this.templateModel.telqIdCase = params.telqIdCase;
                    this.templateModel.telqIdLength = params.telqIdLength;
                }
            },
            error: err => {}
        });
        this.calculateCount();
    }

    telqIdModalOpen(model: MoText) {
        if (this.telqIdModal) {
            return;
        }
        this.telqIdSettingsUpdateModel = model;
        this.telqIdSettings.telqIdType = this.telqIdSettingsUpdateModel.telqIdType;
        this.telqIdSettings.telqIdCase = this.telqIdSettingsUpdateModel.telqIdCase;
        this.telqIdSettings.telqIdLength = this.telqIdSettingsUpdateModel.telqIdLength;
        this.telqIdModal = this.modal.alert().isBlocking(true).component(this.telqIdModalTpl).open();
        this.telqIdModal.onDestroy.subscribe(() => {
            this.telqIdModal = null;
            if (this.templateModel.text) {
                this.onChangeText(this.templateModel.text);
            }
        });
    }

    telqIdModalApply(saveAsDefault: boolean) {
        if (!this.telqIdModal) {
            return;
        }
        const apply = () => {
            this.telqIdSettingsUpdateModel.telqIdType = this.telqIdSettings.telqIdType;
            this.telqIdSettingsUpdateModel.telqIdCase = this.telqIdSettings.telqIdCase;
            this.telqIdSettingsUpdateModel.telqIdLength = this.telqIdSettings.telqIdLength;
            this.telqIdModal.close(true);
        };
        if (!saveAsDefault) {
            apply();
            return;
        }
        this.loading = true;
        this.templateService.saveDefaultTelqIdDetails(this.telqIdSettings).subscribe({
            next: result => {
                this.notificationService.success('Default Test Id Text Set', 'Live Number Testing');
                this.loading = false;
                apply();
            },
            error: e => {
                this.notificationService.error({
                    title: 'MO testing',
                    message: 'An error occurred while setting default Test Id Text',
                    service: 'LNT',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    onInputTextarea(event) {
        this.onChangeText(String(event.target.value));
    }

    onChangeText(text: string) {
        this.templateModel.text = text;
        let fakeId = '';
        if (this.templateModel.hasTelqId) {
            fakeId = '0'.repeat(this.templateModel.telqIdLength);
        }
        text = text.replace(TextTemplateComponent.PLACEHOLDER_MARKER, fakeId);
        this.form.controls.text.patchValue(text);
        this.calculateCount();
    }

    onClickText(event, template: MoText) {
        console.log(event.target);
        if (event.target.classList.contains('icon-telq-id')) {
            this.telqIdModalOpen(template);
        } else {
            this.editTemplate(template);
        }
    }

    editTemplate(template: MoText) {
        this.templateModel = Object.assign({}, template);
        this.removeText(template);
        this.setText(template.text);
    }

    getTexts(): MoText[] {
        let texts = this.texts.map(_ => _);
        if (this.templateModel.text) {
            let template = this.createText();
            texts.push(template);
        }
        if (!texts.length && this.templateModel.hasTelqId) {
            texts.push({
                text: TextTemplateComponent.PLACEHOLDER_MARKER,
                telqIdType: TemplatesService.TELQ_ID_TYPE_DEFAULT,
                telqIdCase: TemplatesService.TELQ_ID_CASE_DEFAULT,
                telqIdLength: TemplatesService.TELQ_ID_LEN_DEFAULT,
            });
        }
        return texts;
    }

    removeText(text: MoText) {
        this.texts = this.texts.filter(_ => _.id !== text.id);
        this.calculateCount();
    }

    addText(text: MoText) {
        const id =  MoTestingService.generateTextId(text);
        const exists = this.texts.filter(_ => _.id === id).length > 0;
        if (exists) {
            this.notificationService.error('Text already exists', 'MO testing')
            return;
        }
        let model = Object.assign({}, text);
        model.id = id;
        this.texts.push(model);
        if (this.templateModel.text) {
            this.templateModel.text = null;
            this.inputState.currentLength = 0;
            this.inputState.maxLength = 160;
            this.inputState.valid = true;
            this.setText('');
        }
        this.calculateCount();
    }

    telqIdSettingsShowCase(type) {
        return TemplatesService.showCaseForType(type);
    }

    telqIdSettingsShowLength(type) {
        return TemplatesService.showLengthForType(type);
    }

    onChangeTelqIdSettingsType() {
        const type = this.telqIdSettings.telqIdType;
        if (typeof TemplatesService.TELQ_ID_LENGTH_DEFAULT[type] !== 'undefined') {
            const length = TemplatesService.TELQ_ID_LENGTH_DEFAULT[type];
            this.telqIdSettings.telqIdLength = length;
            this.telqIdSettingsForm.controls.telqIdLength.patchValue(length);
        }
        if (typeof TemplatesService.TELQ_ID_CASES_DEFAULT[type] !== 'undefined') {
            const caseId = TemplatesService.TELQ_ID_CASES_DEFAULT[type];
            this.telqIdSettings.telqIdCase = caseId;
            this.telqIdSettingsForm.controls.telqIdCase.patchValue(caseId);
        }
    }

    onClickHasTelqId() {
        this.templateModel.hasTelqId = !this.templateModel.hasTelqId;
        this.setText(this.templateModel.text ? this.templateModel.text : '');
        this.localStorageService.set('mo-testing-has-telq-id', this.templateModel.hasTelqId);
    }

    private setText(text: string) {
        if (this.templateModel.hasTelqId) {
            this.textTemplate.setEditorContent(text);
        } else {
            text = text.replace(TextTemplateComponent.PLACEHOLDER_MARKER, '');
            this.onChangeText(text.trim());
        }
    }

    private calculateCount(): void {
        let count = this.texts.length + ((this.templateModel.text && this.inputState.valid) ? 1 : 0);
        if (!count && this.templateModel.hasTelqId) {
            count = 1;
        }
        if (count !== this.textsLength) {
            this.textsLength = count;
            this.changeCount.emit(this.textsLength);
        }
    }

    private createText() {
        let template = Object.assign({}, this.templateModel);
        if (!template.telqIdType) {
            template.telqIdType = TemplatesService.TELQ_ID_TYPE_DEFAULT;
        }
        if (!template.telqIdCase) {
            template.telqIdCase = TemplatesService.TELQ_ID_CASE_DEFAULT;
        }
        if (!template.telqIdLength) {
            template.telqIdLength = TemplatesService.TELQ_ID_LEN_DEFAULT;
        }

        return template;
    }

    reset() {
        if (this.templateModel.text) {
            let template = this.createText();
            this.texts.push(template);
        }
        this.templateModel.text = null;
        this.setText('');
        this.texts = [];
        this.calculateCount();
    }

    getTrimmedText(text: string): string {
        text = CustomUtils.text(text, 60);
        text = text.replace(
            TextTemplateComponent.PLACEHOLDER_MARKER,
            `<span class="icon-telq-id"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>`);
        return text;
    }
}
