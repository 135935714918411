import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RestUtils } from "./rest-utils";
import { forkJoin, Observable } from "rxjs";
import { CoverageNetwork, CoverageNetworkRequest } from "../models/coverage.model";
import { NetInfoService } from "./net-info.service";
import { map } from "rxjs/operators";
import { Network } from "../models/network.model";
import { LocalStorageService } from "./localStorage.service";
import { ColDef } from "ag-grid-community";


@Injectable()
export class CoverageService {
    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient, private netInfo: NetInfoService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    networks(): Observable<CoverageNetwork[]> {
        let url = this.utils.buildUrl('ROLE/coverage/networks');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return forkJoin([
            this.http.get<CoverageNetwork[]>(url, options),
            this.netInfo.networks()
        ]).pipe(map(data => {
            const networkNames = new Map<string, Network>();
            data[1].forEach(n => {
                networkNames.set(`${n.mcc}${n.mnc}`, n)
            });
            return data[0].map(n => {
                n.countryName = '';
                n.providerName = '';
                n.isoCode = '';
                n.createdAt = new Date(n.createdAt);
                n.onlineChangedAt = new Date(n.onlineChangedAt);
                if (
                    n.createdAt.getFullYear() === n.onlineChangedAt.getFullYear() &&
                    n.createdAt.getMonth() === n.onlineChangedAt.getMonth() &&
                    n.createdAt.getDay() == n.onlineChangedAt.getDay() &&
                    n.createdAt.getHours() == n.onlineChangedAt.getHours() &&
                    n.createdAt.getMinutes() == n.onlineChangedAt.getMinutes()
                ) {
                    n.onlineChangedAt = null;
                }
                const key = `${n.mcc}${n.mnc}`;
                if (networkNames.has(key)) {
                    const names = networkNames.get(key);
                    n.countryName = names.country.name;
                    n.providerName = names.name;
                    n.isoCode = names.country.isoAlpha2;
                }
                return n;
            }).sort((a, b) => a.countryName.localeCompare(b.countryName))
        }));
    }

    requests(networkId: number): Observable<CoverageNetworkRequest[]> {
        let url = this.utils.buildUrl(`ROLE/coverage/networks/${networkId}/requests`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<CoverageNetworkRequest[]>(url, options).pipe(
            map(requests => {
                return requests.map(v => {
                    v.createdAt = new Date(v.createdAt);
                    return v;
                });
            })
        );
    }

    createRequest(networkId: number): Observable<void> {
        let url = this.utils.buildUrl(`ROLE/coverage/networks/${networkId}/requests`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<void>(url, null, options);
    }

    deleteRequest(networkId: number): Observable<void> {
        let url = this.utils.buildUrl(`ROLE/coverage/networks/${networkId}/requests`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete<void>(url, options);
    }

    static saveSort(storage: LocalStorageService, columns: ColDef[], key: string) {
        let data = {};
        columns.filter(_ => _.sortable && _.sort !== null && _.field).forEach(c => data[c.field] = c.sort);
        if (Object.keys(data).length) {
            storage.set(`${key}-sort`, data);
        } else {
            storage.remove(`${key}-sort`);
        }
    }

    static applySortToColumns(storage: LocalStorageService, columns: ColDef[], key: string) {
        let data = storage.get(`${key}-sort`, {});
        columns.filter(_ => _.sortable && !_.sort && _.field && data[_.field]).forEach(c => c.sort = data[c.field]);
    }

    static networksToPoints(networks: CoverageNetwork[]): IPoint[] {
        const pointsMap = new Map<string, IPoint>();
        networks.forEach(n => {
            const countryKey = String(n.countryName) + String(n.mcc);
            if (!pointsMap.has(countryKey)) {
                pointsMap.set(countryKey, {
                    x: 0,
                    y: 0,
                    value: 0,
                    online: 0,
                    offline: 0,
                    requests: 0,
                    country: {
                        name: n.countryName,
                        code: n.isoCode,
                        mcc: n.mcc
                    },
                    networks: []
                });
            }
            const point = pointsMap.get(countryKey);
            point.online += (n.online ? 1 : 0);
            point.requests += n.requests;
            point.networks.push({mnc: n.mnc, name: n.providerName, online: n.online, requests: n.requests});
        });
        let points: IPoint[] = [];
        pointsMap.forEach(p => {
            if (p.networks.length) {
                const online = p.networks.filter(_ => _.online).length;
                p.value = online ? Math.floor((online / p.networks.length) * 100) : 0;
                p.networks = p.networks.sort((a,b) => (b.online ? 1 : 0) - (a.online ? 1 : 0));
                p.offline = p.networks.length - p.online;
            }
            points.push(p);
        });
        const rowSize = Math.floor(Math.sqrt(pointsMap.size));
        let x = 0;
        let y = rowSize;
        return points.sort((a,b) => a.country.name.localeCompare(b.country.name)).map(p => {
            if (x > rowSize) {
                x = 0;
                y--;
            }
            p.x = x;
            p.y = y;

            x++;
            return p;
        });
    }
}

export interface IPoint {
    x: number,
    y: number,
    value: number,
    online: number,
    offline: number,
    requests: number,
    country: {
        name: string,
        code: string,
        mcc: string
    },
    networks: INetwork[]
}
interface INetwork {
    mnc: string
    name: string,
    online: boolean,
    requests: number
}
