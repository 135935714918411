import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { SessionsService } from '../../../shared/services/sessions.service';
import { Session } from '../../../shared/models/session.model';
import { UsersService } from '../../../shared/services/users.service';
import { AuthUser, Role } from '../../../shared/models/user.model';

@Component({
    selector: 'app-session-actions',
    templateUrl: './session-actions.component.html',
    styleUrls: ['./session-actions.component.scss']
})
export class SessionActionsComponent implements OnInit {

    currentRow: Session;
    currentUser: AuthUser;

    constructor(private sessionService: SessionsService, private userService: UsersService) { }

    ngOnInit(): void {
    }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.currentUser = this.userService.authUser;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.sessionService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

    canShowActionDump() {
        return this.currentUser.role === Role.ADMIN;
    }
}
