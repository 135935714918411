

export class Timeout {
    private readonly interval: any;
    private readonly start: number;
    private counter: number;

    constructor(private timeout: number) {
        this.start = Date.now();
        this.counter = 0;
        this.interval = setInterval(() => {
            this.counter = Math.floor((Date.now() - this.start) / 1000);
            if (this.isFinish()) {
                this.clear();
            }
        }, 1000);
    }

    isFinish() {
        return this.counter >= this.timeout;
    }

    getSeconds() {
        return this.timeout - this.counter;
    }

    formatSeconds() {
        const timeout = this.getSeconds();
        if (timeout <= 0) {
            return '';
        }
        let minutes = Math.floor(timeout / 60);
        let seconds = Math.floor(timeout - (minutes * 60));
        let minStr = minutes < 10 ? '0' + minutes : minutes;
        let secStr = seconds < 10 ? '0' + seconds : seconds;

        return minStr + ':' + secStr;
    }

    clear() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}