import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { PhoneReport, PhoneReportCollection, PhoneReportCreate } from "../models/phone-report.model";
import { RestUtils } from './rest-utils';
import {map} from "rxjs/operators";
import { Subject } from 'rxjs';

@Injectable()
export class PhoneReportService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    private actionSubject: Subject<PhoneReportActionData> = new Subject<PhoneReportActionData>();
    action$ = this.actionSubject.asObservable();

    issues = {
        DOES_NOT_SHOW: 'Does not show test results',
        EXPIRED_SIM: 'Expired SIM/incorrect number',
        WHITELISTED: 'Whitelisted',
        PORTED: 'Test number ported'
    };

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: PhoneReportActionData) {
        this.actionSubject.next(action);
    }

    all(params: PhoneReportRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort.length ? params.sort : [],
            search: params.search ? params.search : '',
        };

        let url = this.utils.buildUrl(`ROLE/usr/phone-reports`, queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PhoneReportCollection>(url, options).pipe(map(_ => {
            const data = _;
            data.content = data.content.map(_ => this.prepare(_));
            return data;
        }));
    }

    create(report: PhoneReportCreate) {
        let url = this.utils.buildUrl(`ROLE/usr/phone-reports`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, report, options);
    }

    delete(reportId: number) {
        let url = this.utils.buildUrl(`ROLE/usr/phone-reports/${reportId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    prepare(report: PhoneReport) {
        report.createdAt = new Date(report.createdAt);
        return report;
    }

    createModel(): PhoneReportCreate {
        return {
            description: null,
            issue: 'DOES_NOT_SHOW',
            phone: null
        }
    }
}

export class PhoneReportRequestParams {
    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }


}


export interface PhoneReportActionData {
    name: String;
    row: PhoneReport;
    column: string;
}