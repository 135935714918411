import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { DialogRef, ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SchedulerService } from "../../shared/services/scheduler.service";
import { UsersService } from '../../shared/services/users.service';
import { SchedulerTableComponent } from '../scheduler-table/scheduler-table.component';

@Component({
    selector: 'app-scheduler-index',
    templateUrl: './scheduler-index.component.html',
    styleUrls: ['./scheduler-index.component.scss'],
})

export class SchedulerIndexComponent {

    @ViewChild(SchedulerTableComponent, { static: false }) table: SchedulerTableComponent;
    @ViewChild('schedulerCreateModalTpl', { read: TemplateRef, static: false }) schedulerCreateModalTpl: any;
    schedulerCreateModal: DialogRef;

    hiddenMode: boolean;
    showMyTasks: boolean;
    isAdmin: boolean;

    serviceDisabled = null;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: SchedulerService,
        public usersService: UsersService,
        titleService: Title,
        private modal: ModalService,
        private location: Location
    ) {
        titleService.setTitle('Scheduled tasks');
        this.router = router;
        this.notificationService = notificationService;
        this.hiddenMode = this.service.getHiddenMode();
        this.showMyTasks = this.service.getShowMyTasksFlag();
        this.usersService.can('admin').then(admin => {
            this.isAdmin = admin;
        });
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    onScheduleAfterSave(data: boolean): void {
        this.schedulerCreateModal.close();
        this.clearUrlQuery();
        this.table.update();
    }

    clearUrlQuery(): void {
        // Clear the URL with any ids present ...
        const currentPath = this.location.path();
        const currentHistoryState = this.location.getState();
        const newPath = currentPath.split('?')[0];
        this.location.replaceState(newPath, '', currentHistoryState);
    }

    schedulerAction(event) {
        if (event.name === 'clone') {
            const currentUrl = this.router.url.split('?')[0];
            const navigationExtras: NavigationExtras = {
                queryParams: { 'id': event.row.data.id },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            };
            this.router.navigate([currentUrl], navigationExtras);
            this.schedulerCreateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.schedulerCreateModalTpl).open();
            this.schedulerCreateModal.result.then((res: boolean) => {
                this.router.navigate([currentUrl]);
            });
        }
    }

    onChangeHiddenMode() {
        this.service.saveHiddenMode(this.hiddenMode);
        this.table.setHiddenMode(this.hiddenMode);
    }

    onShowMyTasksFlag() {
        this.service.saveShowMyTasksFlag(this.showMyTasks);
        this.table.setShowMyTasksFlag(this.showMyTasks);
    }

    onChangeInputSearch(phrase) {
        this.table.requestParams.search = phrase;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    onClickCreateSchedule(): void {
        this.schedulerCreateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.schedulerCreateModalTpl).open();
        this.schedulerCreateModal.result.then((res: boolean) => {
            this.clearUrlQuery();
        });
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
