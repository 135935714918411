
import { DatePipe } from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import { ColumnTypes } from '../../../shared/components/table/table.component';
import {
    AppscriptsNumber,
    AppscriptsUpdateNumber,
    AppscriptsNumbersFilter, AppscriptsNumberDetails, AppscriptsUpdateUser
} from '../../../shared/models/appscripts-user.model';
import { AppscriptsUserRequestParams, AppscriptsUserService } from '../../../shared/services/appscripts-user.service';
import {ActionEvent, CellClickEvent, SortEvent} from "../../../shared/components/table/table.component";
import {DialogRef, ModalService} from "../../../shared/services/modal.service";
import { MoNumberVerificationInformation } from '../../../shared/models/mo-number-verification-information.model';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as Vld } from "../../../shared/services/validation.service";

@Component({
    selector: 'app-appscripts-user-table',
    templateUrl: 'appscripts-user-table.component.html',
    styleUrls: ['appscripts-user-table.component.scss']
})

export class AppscriptsUserTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<any> = new EventEmitter();
    @Input() filter: AppscriptsNumbersFilter;

    @ViewChild('infoModalTpl', { read: TemplateRef, static: false }) infoModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('errorsModalTpl', { read: TemplateRef, static: false }) errorsModalTpl: any;
    @ViewChild('balanceModalTpl', { read: TemplateRef, static: false }) balanceModalTpl: any;

    storageContextName = "appscripts-numbers";

    balanceUserId;

    numberDetailsPhone: string;
    numberDetails: AppscriptsNumberDetails;
    numberVerificationInfo: MoNumberVerificationInformation;
    numberModel: AppscriptsUpdateNumber;
    userModel: AppscriptsUpdateUser;
    numberForm: FormGroup;
    userForm: FormGroup;
    currentSection: string = '';
    userData = {
        registeredAt: null,
        balance: 0,
        lastActive: null
    }

    loading: boolean = true;
    showNumberVerificationResetButton: boolean = true;

    requestParams = new AppscriptsUserRequestParams()
    private balanceModal: DialogRef;

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsUserService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe,
        formBuilder: FormBuilder,
        public validationService: ValidationService
    ) {
        super();
        this.numberForm = formBuilder.group({
            country: ['', Vld.compose([Vld.required])],
            mcc: ['', Vld.compose([Vld.required, Vld.digits(true), Vld.maxLength(3)])],
            mnc: ['', Vld.compose([Vld.required, Vld.digits(true), Vld.maxLength(3)])],
            originalMnc: ['', Vld.compose([Vld.digits(true), Vld.maxLength(3)])],
            provider: ['', Vld.compose([Vld.required])],
            originalProvider: [''],
            phoneNumber: ['', Vld.compose([Vld.required])],
            blocked: [''],
            showNumberMt: [''],
            showNumberMo: [''],
            updateHlr: [''],
        });

        this.userForm = formBuilder.group({
            email: ['', Vld.compose([Vld.email(true)])],
            paymentMethodName: [''],
            paymentAddress: [''],
            referrerCode: [''],
            referralCode: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
        });
    }

    ngOnInit() {
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id', sort: true },
            { title: 'Phone', prop: 'phoneNumber', cellClassName: 'editable fit-content', sort: true },
            { title: 'Network', prop: 'network', format: this.formatNetwork, type: ColumnTypes.HTML, cellClassName: 'fit-content' },
            { title: 'Last active', prop: 'lastPing', format: row => this.formatDate(row.lastPing), sort: true, cellClassName: 'fit-content' },
            { title: 'Balance', prop: 'ub.balance', format: row => (row.balance > 0) ? (row.balance / 100) : 0, cellClassName: 'editable', sort: true },
            { title: 'MT SMS', prop: 'mtSmsCount', cellClassName: 'editable', sort: true },
            { title: 'MO SMS', prop: 'moSmsCount', cellClassName: 'editable', sort: true },
            { title: 'Voice Count', prop: 'voiceCallCount', sort: false },
            { title: 'Show MT', prop: 'showNumberMt', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Show MO', prop: 'showNumberMo', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Allow MO', prop: 'moEnabledForUser', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Allow VT', prop: 'voiceEnabledForUser', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Blocked', prop: 'blocked', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Update HLR', prop: 'updateHlr', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
        ]));
        this.tableActions = [
            { icon: 'fa fa-info text-navy', title: 'Info', name: 'info' },
            { icon: 'far fa-trash-alt', title: 'Delete', name: 'delete' },
        ];
        //this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onChangeSize(size) {
        super.onChangeSize(size);
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    update() {
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        if (this.filter) {
            this.requestParams.filter = this.filter;
        }

        this.loading = true;
        this.service
            .all(this.requestParams)
            .subscribe((data) => {
                this.setData(this.createRows(data.content), data.totalElements);
                this.loading = false;
            }, () => this.loading = false);
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'phoneNumber') {
            this.onInfo(event.row.data as AppscriptsNumber);
            this.actions.emit(event);
        }

        if (event.column.prop === 'mtSmsCount') {
            this.router.navigate(['/appscripts/sms'], { queryParams: { phonenumber: event.row.data.phoneNumber } });
        }

        if (event.column.prop === 'moSmsCount') {
            this.router.navigate(['/appscripts/outgoing-sms'], { queryParams: { phonenumber: event.row.data.phoneNumber } });
        }

        if (event.column.prop === 'ub.balance') {
            this.balanceUserId = event.row.data.userId;
            this.balanceModal = this.modal.alert().component(this.balanceModalTpl).open();
            this.balanceModal.onDestroy.subscribe(() => {
                this.flashRow(event.row.data.id);
            });
        }
    }

    onAfterSaveBalance() {
        this.balanceModal.close(true);
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        const row = event.row.data as AppscriptsNumber

        if (event.name === 'switch') {
            if (event.column.prop === 'moEnabledForUser') {
                let val = row.moEnabledForUser;
                this.service.updateMoEnabledForUserById(row.userId, val).subscribe({
                    next: () => {
                        this.notificationService.success('Number updated', 'Appscripts');
                    },
                    error: e => {
                        row.moEnabledForUser = !val;
                        this.notificationService.error({
                            title: 'Appscripts numbers',
                            message: 'Number updating an error.',
                            service: 'APPSRIPTS',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            } else if (event.column.prop === 'voiceEnabledForUser') {
                let val = row.voiceEnabledForUser;
                this.service.updateVoiceEnabledForUserById(row.userId, val).subscribe({
                    next: () => {
                        this.notificationService.success('Number updated', 'Appscripts');
                    },
                    error: e => {
                        row.moEnabledForUser = !val;
                        this.notificationService.error({
                            title: 'Appscripts numbers',
                            message: 'Number updating an error.',
                            service: 'APPSRIPTS',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            } else {
                let data: AppscriptsUpdateNumber = {
                    phoneNumber: row.phoneNumber,
                    mcc: row.mcc,
                    mnc: row.mnc,
                    country: row.country,
                    provider: row.provider,
                    originalProvider: row.originalProvider,
                    originalMnc: row.originalMnc,
                    showNumberMt: row.showNumberMt,
                    showNumberMo: row.showNumberMo,
                    blocked: row.blocked,
                    updateHlr: row.updateHlr,
                };
                this.service.updateNumber(row.id, data).subscribe(() => {
                    this.notificationService.success('Number updated', 'Appscripts');
                }, e => {
                    this.notificationService.error({
                        title: 'Appscripts numbers',
                        message: 'Number updating an error.',
                        service: 'APPSRIPTS',
                        requestMessage: e.statusText,
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                });
            }
        }

        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(row);
                }
            }).catch(_ => { });
        }

        if (event.name === 'info') {
            this.onInfo(row);
        }
    }

    private onInfo(row: AppscriptsNumber) {
        this.numberDetailsPhone = row.phoneNumber
        this.numberDetails = null;
        this.numberVerificationInfo = null;
        this.userModel = null;
        this.numberModel = null;
        this.currentSection = 'appscripts-numbers-details-main';
        this.modal.alert().dialogClass('modal-dialog large-modal').component(this.infoModalTpl).open().onDestroy.subscribe(() => {
            this.flashRow(row.id);
        });
        this.service.numberDetails(row.id).subscribe({
            next: details => this.numberDetails = details,
            error: e => {}
        });
        this.service.getVerificationCount(row.phoneNumber).subscribe({
            next: d => this.numberVerificationInfo = d,
            error: e => {}
        });

        this.service.one(row.id).subscribe((model) => {
            this.userModel = {
                id: model.userId,
                email: model.email,
                referrerCode: model.referrerCode,
                referralCode: model.referralCode,
                paymentMethodName: model.paymentMethodName,
                paymentAddress: model.paymentAddress
            };
            this.numberModel = {
                id: model.id,
                phoneNumber: model.phoneNumber,
                mcc: model.mcc,
                mnc: model.mnc,
                country: model.country,
                provider: model.provider,
                originalProvider: model.originalProvider,
                originalMnc: model.originalMnc,
                showNumberMt: model.showNumberMt,
                showNumberMo: model.showNumberMo,
                blocked: model.blocked,
                updateHlr: model.updateHlr
            };
            this.userData.balance = model.balance;
            this.userData.registeredAt = model.registeredAt ? new Date(model.registeredAt) : null;
            this.userData.lastActive = model.lastPing ? new Date(model.lastPing) : null;
        });
    }

    delete(user: AppscriptsNumber) {
        this.loading = true;
        this.service
            .deleteUser(user.userId)
            .subscribe(() => {
                this.update();
                this.notificationService.success('User removed', 'Appscripts')
            }, e => {
                this.loading = false;
            });
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    formatDate(date, format = 'medium') {
        if (typeof date === 'number' && date) {
            return this.datePipe.transform(new Date(date * 1000), format);
        }

        if (typeof date === 'string' && date) {
            return this.datePipe.transform(new Date(date), format);
        }

        if (date instanceof Date) {
            return this.datePipe.transform(date, format);
        }

        return '-'
    }

    formatNetwork(row: AppscriptsNumber) {
        let text = `${row.country}(${row.mcc}) / ${row.provider}(${row.mnc})`;
        if (row.originalProvider) {
            text += ` <i class="icon-ported"></i> ${row.originalProvider}(${row.originalMnc})`;
        }
        return text;
    }

    onClickReset(): void {
        this.showNumberVerificationResetButton = false;
        this.service.resetVerificationCount(this.numberVerificationInfo.phoneNumber).subscribe({
            next: (res) => {
                this.service.getVerificationCount(this.numberVerificationInfo.phoneNumber).subscribe((res) => {
                    this.notificationService.success('Verification count reset successful', 'Modems Users');
                    this.numberVerificationInfo = res;
                    this.showNumberVerificationResetButton = true;
                });
            },
            error: (e) => {
                this.notificationService.error({
                    title: 'Appscripts numbers',
                    message: 'An error occurred while resetting.',
                    service: 'MO-TESTING',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.showNumberVerificationResetButton = true;
            }
        })
    }

    onSubmitNumber() {
        this.loading = true;
        this.service.updateNumber(this.numberModel.id, this.numberModel)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('Number updated', 'Appscripts');
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }

    onSubmitUser() {
        this.loading = true;
        this.service.updateUser(this.userModel.id, this.userModel)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('User updated', 'Appscripts');
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }

    scrollTo(sectionId: string) {
        document.querySelector('#' + sectionId).scrollIntoView();
        this.currentSection = sectionId;
    }
}
