import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { AppscriptsPaymentMethod } from '../models/appscripts-payment-method.model';
import { RestUtils } from './rest-utils';

@Injectable()
export class AppscriptsPaymentMethodService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: AppscriptsPaymentMethodParams): Observable<AppscriptsPaymentMethod[]> {
        let queryParams = {
            search: params.search ? params.search : ''
        };
        let url = this.utils.buildUrl('ROLE/appscr/data/payment-methods', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsPaymentMethod[]>(url, options);
    }

    one(id: number): Observable<AppscriptsPaymentMethod> {
        let url = this.utils.buildUrl('ROLE/appscr/data/payment-methods/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsPaymentMethod>(url, options);
    }

    save(method: AppscriptsPaymentMethod): Observable<AppscriptsPaymentMethod> {
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (method.id) {
            let url = this.utils.buildUrl('ROLE/appscr/data/payment-methods/' + method.id);
            return this.http.put<AppscriptsPaymentMethod>(url, method, options);
        }

        let url = this.utils.buildUrl('ROLE/appscr/data/payment-methods');
        return this.http.post<AppscriptsPaymentMethod>(url, method, options);
    }

    delete(id: number): Observable<any> {
        let url = this.utils.buildUrl('ROLE/appscr/data/payment-methods/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    create(): AppscriptsPaymentMethod {
        return {
            id: null,
            name: '',
            description: '',
            enabled: true,
            withdrawalAvailable: true
        }
    }

}

export class AppscriptsPaymentMethodParams {
    size: number = 20;
    page: number = 1;

    search: string = '';

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push((direction === 'desc' ? '-' : '') + propertyName);
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

