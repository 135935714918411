import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mo-shortcode-create',
    templateUrl: './mo-shortcode-create.component.html',
    styleUrls: ['./mo-shortcode-create.component.scss']
})
export class MoShortcodeCreateComponent {

    @ViewChild(MoShortcodeCreateComponent, { static: false }) form: MoShortcodeCreateComponent;

    loading = false;

    constructor(public router: Router, titleService: Title) {
        titleService.setTitle('Create short code');
    }

    afterSave() {
        this.router.navigate(['/mo-testing/short-codes']).then();
    }

}
