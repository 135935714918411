
import {
    Component,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    ViewContainerRef,
    Input, TemplateRef
} from '@angular/core';
import {FormBuilder, FormGroup,} from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import {InvoiceService} from "../../shared/services/invoice.service";
import {Invoice, InvoiceByAdmin} from "../../shared/models/invoice.model";
import {PricingGroup, PricingGroupCollection} from "../../shared/models/pricing.model";
import {PricingService} from "../../shared/services/pricing.service";
import { UsersService } from '../../shared/services/users.service';
import {BasicUser} from "../../shared/models/user.model";
import {DialogRef, ModalService} from "../../shared/services/modal.service";

@Component({
    selector: 'app-invoices-form',
    templateUrl: './invoices-form.component.html',
    styleUrls: ['./invoices-form.component.scss']
})

export class InvoicesFormComponent {

    model: InvoiceByAdmin = {
        pricingGroupId: null,
        userId: null,
        paymentType: null,
        paid: false
    };

    pricingGroups: {id: number, title: string}[] = [];
    invoiceUser: BasicUser;

    @Output() onAfterSave = new EventEmitter();

    @Input() showButtons = true;

    loading = false;

    form: FormGroup;

    showUsers = false;

    constructor(
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: InvoiceService,
        public userService: UsersService,
        public pricingService: PricingService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        this.form = formBuilder.group({
            pricingGroupId: ['', Vld.compose([Vld.required])],
            userId: ['', Vld.compose([Vld.required])],
            paymentType: ['', Vld.compose([Vld.required])],
            paid: ['']
        });
    }

    setInvoiceUser(user) {
        this.model.userId = user.id;
        this.model.paymentType = user.paymentType;
        this.form.controls.userId.patchValue(user.id);
        this.form.controls.paymentType.patchValue(user.paymentType);
        this.pricingService.groupsByUserId(this.model.userId).subscribe((groups: PricingGroup[]) => {
            this.pricingGroups = groups.map(_ => {
                return {
                    id: _.id,
                    title: _.title + ' (' + _.totalCommitment + ' EUR)'
                };
            });
            this.model.pricingGroupId = this.pricingGroups[0].id;
            this.loading = false;
        });
        this.invoiceUser = user;
    }

    onSubmit() {
        this.loading = true;
        this.service.create(this.model)
            .subscribe((res: Invoice) => {
                this.loading = false;
                this.onAfterSave.emit();
                this.notificationService.success(
                    'Invoice created',
                    'Invoices'
                );
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while creating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            });
    }

    onSelectInvoiceUser(user) {
        if (user instanceof Event) {
            return;
        }
        this.showUsers = false;
        this.loading = true;
        this.userService.one(user.id).subscribe(u => {
            u.id = user.id;
            this.setInvoiceUser(u);
        });
    }

    onClickForm(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.dropdown-menu')) {
            this.showUsers = false;
        }
    }
}
