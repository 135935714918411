
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef, RowSelectedEvent } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../shared/components/table/ag-table-base';
import { AuthUser, Role, UserAdmin } from "../../shared/models/user.model";
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UserActionData, UsersRequestParams, UsersService } from '../../shared/services/users.service';
import { UsersActionsComponent } from './users-actions/users-actions.component';
declare var moment: any;

@Component({
    selector: 'app-users-table',
    templateUrl: 'users-table.component.html',
})

export class UsersTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('subaccountModalTpl', { read: TemplateRef, static: false }) subaccountModalTpl: any;
    @Output() actions: EventEmitter<UserActionData> = new EventEmitter();
    @Output() changeBatch = new EventEmitter<UserAdmin[]>();

    isAdmin: boolean = false;
    onAfterDelete = new EventEmitter();
    messageForModal: string;
    storageContextName = 'users-table';

    requestParams = new UsersRequestParams();

    MAX_TOTAL_ITEMS_COUNT = 5000;
    loading: boolean = false;
    user: AuthUser;

    batchSelectedIds: Set<number> = new Set();

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id',
            maxWidth: 75, minWidth: 75,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Role/Email', field: 'username',
            flex: 1, tooltipField: 'username',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `
                    <div class="d-flex justify-content-start align-items-center gap-0_5">
                        <span class="d-flex me-0_8">${this.getIconForRole(_)}</span>
                        <div class="d-flex flex-column justify-content-center align-items-start">
                            <span class="one-line">${_.email}</span>
                            <span class="one-line">(${_.username})</span>
                        <div>
                    </div>
                `;
            }
        },
        {
            headerName: 'Linked accounts', field: 'parentEmail',
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                if (!_.parentEmail) {
                    return `<div class="one-line clickable"><span>Subaccounts: ${_.countSubaccounts}</span></div>`;
                }
                return _.parentEmail ? `<div class="two-lines clickable"><span>Parent: ${_.parentEmail}</span></div>` : `<div class="one-line">Parent not found</div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onAccountClick(event),
        },
        {
            headerName: 'Company', field: 'companyName',
            flex: 0.6,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line">${params.value}</div>`;
            }
        },
        {
            headerName: 'Last active', field: 'last_active',
            maxWidth: 90, minWidth: 90, sortable: true, comparator: () => 0, initialSort: null,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="two-lines"> ${this.formatLastLogin(params)}</div>`;
            }
        },
        {
            headerName: 'Balance (€)', field: 'balance',
            maxWidth: 120, minWidth: 120,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line d-flex justify-content-center align-items-center gap-0_8">${this.formatCurrency(params.data)}</div>`;
            }
        },
        {
            headerName: 'Owner', field: 'ownerUserEmail',
            flex: 0.5, tooltipField: 'ownerUserEmail',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.getOwnerUsername(_)}</div>`;
            }
        },
        {
            headerName: 'Status', field: 'emailVerified',
            cellClass: 'justify-content-center', flex: 0.3, tooltipField: 'emailVerified',
            cellRenderer: (params: ICellRendererParams) => {
                const _: UserAdmin = params.data;
                const emailVerifiedHtml = `<i title="${_.emailVerified ? 'Email verified' : 'Email is not verified'}" class="far fa-envelope fs-3 me-0_5 ${_.emailVerified ? 'text-sucess-s3' : 'text-error-r3'}"></i>`;
                const mfaEnabledHtml = `<i title="HMAC-based OTP is ${_.mfaEnabled ? 'enabled' : 'disabled'}" class="fas fa-mobile-alt fs-3 me-0_5 ${_.mfaEnabled ? 'text-sucess-s3' : 'text-error-r3'}"></i>`;
                return `<div class="one-line text-center d-flex justify-content-center align-items-center gap-15">${emailVerifiedHtml} ${mfaEnabledHtml}</div>`;
            }
        },
        {
            headerName: 'Enabled', field: 'enabled',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellClass: 'justify-content-center', flex: 0.3,
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.enabled ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onEnableOrDisableUser(event),
        },
        {
            headerName: 'Payment Terms', field: 'paymentType',
            flex: 0.3, hide: true,
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: UsersActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];

    rowData!: UserAdmin[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: UsersService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private localStorage: LocalStorageService,
    ) {
        super();
        service.getAuthUser().then(user => {
            this.user = user;
        }).catch(e => { });
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => {
                this.onAction(action);
            }
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`users_table_state_${this.user.id}`, currentColumnState);
        });
        this.paginationPageSize = this.localStorage.get(`users_table_component_size_${this.user.id}`) || 20;
        this.update();
    }

    onAction(event: UserActionData) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            this.messageForModal = `You are going to delete "${event.row.email}". You won't be able to revert this!`;
            let dialogRef = this.modal.alert().component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            });
        }

        if (event.name === 'login') {
            this.loading = true;
            this.service.impersonate(event.row.id).subscribe({
                next: () => {
                    this.router.navigate(['/dashboard']);
                },
                error: error => {
                    this.notificationService.error({
                        title: 'Users',
                        message: error.message ? error.message : 'An error occurred while creating the token',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                    this.loading = false;
                }
            });
        }

        if (event.name === 'resendVerificationEmail') {
            this.messageForModal = `You are going to resend verification to "${event.row.email}". You won't be able to revert this!`;
            let dialogRef = this.modal.alert().component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.resendVerificationEmail(event.row);
                }
            });
        }

        if (event.name === 'mfaDisable') {
            this.messageForModal = `You are going to disable 2FA for "${event.row.email}". You won't be able to revert this!`;
            let dialogRef = this.modal.alert().component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.mfaDisable(event.row);
                }
            });
        }
    }

    private onAccountClick(event: CellClickedEvent): void {
        this.actions.emit({ name: 'accountClick', row: event.data, column: 'account' });
    }

    private onEnableOrDisableUser(event: CellClickedEvent): void {
        if ((event.event.target as HTMLElement).classList.contains('form-check-input')) {
            this.loading = true;
            const user: UserAdmin = event.data;
            user.enabled = !user.enabled;
            this.service.updateEnable(event.data.id, user.enabled).subscribe({
                next: _ => {
                    this.notificationService.success('User updated', 'Users');
                    this.update();
                },
                error: error => {
                    this.notificationService.error('User updating an error', 'Users');
                }
            });
        }
    }

    private resendVerificationEmail(user: UserAdmin) {
        this.loading = true;
        this.service.resendVerificationEmail(user.id).subscribe({
            next: () => {
                this.notificationService.success('Verification email sent', 'Users')
                this.loading = false;
            },
            error: (e) => {
                this.notificationService.error({
                    title: 'Users',
                    message: e.message ? e.message : 'An error occurred while resending the email',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    private mfaDisable(user: UserAdmin) {
        this.loading = true;
        this.service.mfaDisableByAdmin(user.id).subscribe({
            next: () => {
                this.notificationService.success('2FA disabled', 'Users')
                this.loading = false;
                user.mfaEnabled = false;
            },
            error: (e) => {
                this.notificationService.error({
                    title: 'Users',
                    message: e.message ? e.message : 'An error occurred while 2FA disabling',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`users_table_component_size_${this.user.id}`, size);
        this.update();
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState;

        this.service.all(this.requestParams).subscribe({
            next: res => {
                this.rowData = res.content;
                this.totalRowsCount = res.totalElements;
                this.loading = false;
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Users',
                    message: 'Loading users an error',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    delete(user) {
        this.loading = true;
        this.service.delete(user.id).subscribe({
            next: () => {
                this.update();
                this.notificationService.success('User removed', 'Users')
            },
            error: error => {
                this.notificationService.error('Trying to remove user failed', 'Users')
                this.loading = false;
            }
        });
    }

    private getOwnerUsername(user: UserAdmin): string {
        if (user.ownerUserEmail) {
            return user.ownerUserEmail.split('@')[0];
        }
        return 'N/A';
    }

    private getIconForRole(user: UserAdmin): string {
        switch (user.role) {
            case Role.ADMIN:
                return '<i class="icon-admin"></i>';
            case Role.MAIN:
                return '<i class="icon-chess-king"></i>';
            case Role.DEPUTY:
                return '<i class="icon-chess-queen"></i>';
            case Role.SUB:
                return '<i class="icon-chess-pawn"></i>';
            default:
                return '';
        }
    }

    formatCurrency(value: UserAdmin): string {
        const currency = value != null ? this.currencyPipe.transform(value.balance, 'EUR', '', '1.2-2') : 'N';
        const isPostPaid = value.paymentType === 'POSTPAID';
        let formattedResult = '';
        if (currency[0] === '-') {
            formattedResult += `<span class="text-error-r3">${currency}</span>`;
        } else {
            formattedResult += `<span>${currency}</span>`;
        }
        if (isPostPaid) {
            formattedResult += `<i title="Postpaid customer" class="icon-postpaid"></i>`;
        }
        return formattedResult;
    }

    formatLastLogin(params: ICellRendererParams) {
        const lastActiveDate = params.data.lastActive;
        if (lastActiveDate) {
            const activeDate = moment(lastActiveDate).format('DD/MM/YY HH:mm:ss').split(" ");
            return `<div class="two-lines">${activeDate[1]} <br>${activeDate[0]}</div>`
        }
        return `<div class="one-line">N/A</div>`;
    }

    formatNumericBoolean(n) {
        if (typeof n === 'boolean') {
            return n ? 'yes' : 'no'
        }
        return n === '1' ? 'yes' : 'no'
    }

    // ng grid
    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        let result: (MenuItemDef)[] = [
            {
                name: 'Resend Verification Email',
                icon: '<span class="icon-refresh fs-4"></span>',
                action: () => {
                    this.actions.emit({ name: 'resendVerificationEmail', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'Disable 2FA',
                icon: '<span class="icon-negative fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'mfaDisable', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'Edit user',
                icon: '<span class="icon-edit fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'edit', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'Delete user',
                icon: '<span class="far fa-trash-alt fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'delete', row: currentRowClicked, column: 'actions' });
                },
            }
        ];
        if (!(this.formatNumericBoolean(currentRowClicked.emailVerified) === 'no' && currentRowClicked.role === 'mainaccount')) {
            result = result.filter(item => item.name !== 'Resend Verification Email');
        }
        if (!currentRowClicked.mfaEnabled) {
            result = result.filter(item => item.name !== 'Disable 2FA');
        } else {
            result = result.filter(item => item.name !== 'Delete user');
        }
        return result;
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`users_table_state_${this.user.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }

    // ng grid

    ngOnDestroy(): void {
        this.actionSubscription.unsubscribe();
    }
}
