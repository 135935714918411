import {Component, OnInit, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import {PricingService} from "../../../shared/services/pricing.service";
import {InvoiceService} from "../../../shared/services/invoice.service";
import { PrepaidCurrentPlan, PrepaidCurrentPlanItem, PricingGroup } from "../../../shared/models/pricing.model";
import {forkJoin} from "rxjs";
import { UsersService } from '../../../shared/services/users.service';
import { Part } from "../../billing-progress/billing-progress.component";
import { CurrencyPipe } from "@angular/common";
import { AuthUser } from "../../../shared/models/user.model";

@Component({
    selector: 'app-packages-index',
    templateUrl: './packages-index.component.html',
    styleUrls: ['./packages-index.component.scss'],
})

export class PackagesIndexComponent implements OnInit {

    user: AuthUser;
    showCurrentPlan = false;
    models: PricingGroup[] = [];
    idsActive: number[] = [];
    loading = false;

    currentBalance = 0;
    currentPlan: PrepaidCurrentPlan;

    progress: {total: number, current: number, parts: Part[]};

    constructor(
        public pricingService: PricingService,
        public invoiceService: InvoiceService,
        public userService: UsersService,
        public router: Router,
        public notificationService: NotificationService,
        private currency: CurrencyPipe,
        titleService: Title
    ) {
        titleService.setTitle('Select package');
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.showCurrentPlan = this.user.impersonated;
            forkJoin([
                this.pricingService.myPrepaidPricingGroups(),
                this.pricingService.myPrepaidActivePricingGroup()
            ]).subscribe({
                next: results => {
                    this.addActiveGroupId(results[1]);
                    const groups = results[0].map(group => {
                        group.bundles = group.bundles.map(bundle => {
                            bundle.creditValidity = PricingService.formatValidForSeconds(bundle.validForSeconds);
                            bundle.offerVisibleUntil = null;
                            if (typeof bundle.visibleUntil === 'string' && bundle.visibleUntil.indexOf('9999') === -1) {
                                bundle.offerVisibleUntil = new Date(bundle.visibleUntil);
                            }
                            return bundle;
                        });
                        return group;
                    });
                    this.models = groups.sort((a,b) => a.totalCommitment - b.totalCommitment);
                    this.loading = false;
                },
                error: error => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'Pricing',
                        message: 'An error occurred while creating the pricing groups',
                        serviceName: 'API GATEWAY',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                }
            });
        });

        this.userService.balanceUpdates.subscribe(_ => this.update(_));
    }

    private addActiveGroupId(id: number) {
        if (!this.idsActive.includes(id)) {
            this.idsActive.push(id);
        }
    }

    private update(balance: number) {
        this.currentBalance = balance;
        this.pricingService.getPrepaidPlan().subscribe(plan => {
            plan.items.forEach(_ => this.addActiveGroupId(_.pricingGroupId));
            this.currentPlan = plan;
            this.progress = this.createProgress(plan.items);
        });
    }

    private createProgress(items: PrepaidCurrentPlanItem[]) {
        return {
            total: items.length * 100,
            current: items.map(_ => _.progressPercent).reduce((a, b) => a + b, 0),
            parts: items.map(i => {
                const usedTests = Math.floor(i.used / i.ratePerTest);
                const usedTestsLabel = usedTests ? `${usedTests}/` : '';
                return {
                    max: 0,
                    label: `<span class="text-primary">${this.money(i.used)}</span>/${this.money(i.total)} balance used`,
                    tooltip: `Tests: ${usedTestsLabel}<strong>${i.testsCount}</strong><br>Per test: <strong>${this.money(i.ratePerTest)}</strong>`
                }
            })
        };
    }

    onSelectPricingGroup(pricingGroupId: number) {
        this.loading = true;
        this.invoiceService.create({pricingGroupId: pricingGroupId, userId: this.user.id}).subscribe({
            next: invoice => {
                this.loading = false;
                this.notificationService.success(
                    'Invoice created',
                    'Invoices'
                );
                this.router.navigate(['invoices'], {queryParams: {payment: invoice.id}}).then();
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while creating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }

    getFullNameServiceType(serviceTypeCode: string): string {
        const map = {
            MT: 'Mobile terminated',
            API: 'Api',
            MO: 'Mobile Originated',
            STATISTICS: ''
        };
        if (map[serviceTypeCode]) {
            return map[serviceTypeCode];
        }
        return serviceTypeCode;
    }

    private money(val: number): string {
        return this.currency.transform(val / 1000, 'EUR', 'symbol', '1.1-3');
    }
}
