
import { map } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy, OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { DestinationsService } from '../../shared/services/destinations.service';
import { LiveNumberTestingService } from '../../shared/services/live-number-testing.service';
import { ManualNumberTestingService } from "../../shared/services/manual-number-testing.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { UsersService } from '../../shared/services/users.service';
import { MoTestingService } from "../../shared/services/mo-testing.service";
import { forkJoin, Observable } from "rxjs";
import { Destination } from "../../shared/models/destination.model";
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AuthUser, Role } from '../../shared/models/user.model';
import { VoiceTestingService } from "../../shared/services/voice-testing.service";
declare var moment: any;

@Component({
    selector: 'app-destinations-select',
    templateUrl: 'destinations-select.component.html',
    styleUrls: ['destinations-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DestinationsSelectComponent implements OnDestroy, OnInit, AfterViewInit {

    @Input() recent: boolean = false;
    @Input() countryName: string = '';
    @Input() shortCodeCountries: string[] = [];
    @Input() source: 'live' | 'manual' | 'mo' | 'voice' = 'live';
    @Input() showManualAddNumbers = true;

    @Output() onFilterChange = new EventEmitter;
    @Output() onChange = new EventEmitter;

    @ViewChild('filterModalTpl', { read: TemplateRef, static: false }) filterModalTpl: any;
    @ViewChild('addPhonenumbersModalTpl', { read: TemplateRef, static: false }) addPhonenumbersModalTpl: any;
    addPhonenumbersModal: DialogRef;

    @ViewChild('destinationWrapper') destinationWrapper: ElementRef;
    rowsVisible = 8;
    rowsVisibleForFilters = 15;

    @HostListener('window:resize')
    onResize() {
        if (!this.destinationWrapper) { return; }
        const height = this.destinationWrapper.nativeElement.clientHeight - 30;
        if (!height && height <= 0) { return; }
        const rows = Math.max(Math.floor(height / 30), 8);
        if (isNaN(rows)) {
            console.error('Destination list Invalid size: ', rows);
        } else {
            if (rows !== this.rowsVisible) {
                this.rowsVisible = rows;
                this.cdr.detectChanges();
            }
        }
    }


    @ViewChild('scrollViewportSelected') private cdkVirtualScrollViewportSelected: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportAll') private cdkVirtualScrollViewportAll: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportFavorites') private cdkVirtualScrollViewportFavorites: CdkVirtualScrollViewport;
    onChangeTab() {
        setTimeout(() => {
            this.cdkVirtualScrollViewportSelected.checkViewportSize();
            this.cdkVirtualScrollViewportAll.checkViewportSize();
            this.cdkVirtualScrollViewportFavorites.checkViewportSize();
        }, 100);
    }

    models: Destination[] = [];
    favourites: { [key: string]: boolean } = {};
    idsMap: Object = {};
    toggleAll: boolean = false;
    toggleAllFavourites: boolean = false;
    addPhonenumbers: string = '';
    searchQuery: string = '';
    networkUpdatesEventSource = null;
    timeoutId = null
    updateSubscription = null
    selectedModelsBefore = [];
    hasErrorInManualNumber = false;
    hidePorted = false;
    showOnlyPorted = false;
    isAllDisabled = false;
    user: AuthUser;

    loading: boolean = false;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;
    exportData = {
        filename: undefined,
        download_url: undefined
    };
    exportSpinner = false;
    exportDays = 'online';

    selectedCount = 0;
    maxSelectedCount = 100;
    private exportModal: DialogRef;

    constructor(
        private destinationService: DestinationsService,
        private userService: UsersService,
        private liveNumberTestingService: LiveNumberTestingService,
        private manualNumberTestingService: ManualNumberTestingService,
        private moTestingService: MoTestingService,
        private voiceTestingService: VoiceTestingService,
        private notificationService: NotificationService,
        private localStorage: LocalStorageService,
        private modal: ModalService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.loadPortedFilter();
        this.user = this.userService.authUser;
    }

    ngAfterViewInit() {
        if (this.recent) {
            if (this.source === 'live') {
                let savedUserSearchDetails = this.localStorage.get(`lnt_user_search_${this.user.id}_search`);
                this.update(savedUserSearchDetails).subscribe();
            } else if (this.source === 'mo') {
                let savedUserSearchDetails = this.localStorage.get(`mo_user_search_${this.user.id}_search`);
                this.update(savedUserSearchDetails).subscribe();
            } else if (this.source === 'manual') {
                let savedUserSearchDetails = this.localStorage.get(`mnt_user_search_${this.user.id}_search`);
                this.update(savedUserSearchDetails).subscribe();
            } else if (this.source === 'voice') {
                let savedUserSearchDetails = this.localStorage.get(`vt_user_search_${this.user.id}_search`);
                this.update(savedUserSearchDetails ? {destinations: savedUserSearchDetails.networks} : null).subscribe();
            }
        }
        this.onResize();
    }

    getSelectedModels(shouldUseFilters: boolean = true): Destination[] {
        const selectedDestinations = this.models.filter(_ => _.selected);
        if (shouldUseFilters) {
            return this.filterDestinations(selectedDestinations);
        }
        return selectedDestinations;
    }

    getFavourites(): Destination[] {
        const favouriteDestinations = this.models.filter(_ => _.isFavourite);
        return this.filterDestinations(favouriteDestinations);
    }

    getDestinations(): Destination[] {
        if (this.source === 'mo' && this.shortCodeCountries.length > 0) {
            return this.filterDestinations(
                this.models.filter(model => this.shortCodeCountries.includes(model.countryName))
            );
        }
        return this.filterDestinations(
            this.countryName ? this.models.filter(_ => _.countryName === this.countryName || _.custom) : this.models
        );
    }

    private filterDestinations(destinations: Destination[]): Destination[] {
        if (this.hidePorted) {
            return destinations.filter(_ => !_.originalMnc || _.custom)
        }
        if (this.showOnlyPorted) {
            return destinations.filter(_ => !!_.originalMnc || _.custom)
        }
        return destinations;
    }

    update(model: any = null) {
        if (this.loading) { return; }
        this.loading = true;
        if (!this.recent) {
            return this.getOnlineDestinations().pipe(map(destinations => {
                this.selectedModelsBefore = this.getSelectedModels()
                this.models = []
                destinations.forEach(destination => {
                    this.prepareCountry(destination);
                    this.models.push(destination);
                });
                this.listenToNetworkUpdateEvents();
                this.models.sort(this.compareCountries);
                this.loading = false;
                this.cdr.detectChanges();
            }));
        } else {
            if (this.source === 'mo' && model && model.origins?.length > 0) {
                model.destinations = model.origins;
            }
            return this.getRecentDestinations().pipe(map(destinations => {
                this.models = [];
                this.selectedCount = 0;
                let mccmncs = [];
                if (model && model.destinations) {
                    const selected = model.destinations.map(country => `${country.mcc}_${country.mnc}`);
                    mccmncs = selected.length > this.maxSelectedCount ? selected.slice(0, this.maxSelectedCount) : selected;
                }
                destinations.forEach(destination => {
                    destination.selected = mccmncs.includes(`${destination.mcc}_${destination.mnc}`)
                    this.prepareCountry(destination);
                    if (destination.selected) {
                        this.selectedCount++;
                    }
                });
                this.models = destinations.sort(this.compareCountries);
                this.loading = false;
                this.cdr.detectChanges();
            }));
        }
    }

    private getOnlineDestinations(): Observable<Destination[]> {
        let online: Observable<Destination[]>;
        switch (this.source) {
            case "live":
                online = this.destinationService.online();
                break;
            case "manual":
                online = this.destinationService.online();
                break;
            case "mo":
                online = this.destinationService.moOnline();
                break;
            case "voice":
                online = this.destinationService.voiceOnline();
                break;
            default:
                throw new Error('Undefined source')
        }

        return forkJoin([online, this.updateFavourites()]).pipe(
            map(res => res[0])
        );
    }

    private getRecentDestinations(): Observable<Destination[]> {
        let recent: Observable<Destination[]>;
        switch (this.source) {
            case "live":
                recent = this.liveNumberTestingService.recentDestinations();
                break;
            case "manual":
                recent = this.manualNumberTestingService.recentDestinations();
                break;
            case "mo":
                recent = this.moTestingService.recentDestinations();
                break;
            case "voice":
                recent = this.voiceTestingService.recentDestinations();
                break;
            default:
                throw new Error('Undefined source')
        }

        return forkJoin([recent, this.updateFavourites()]).pipe(
            map(res => res[0])
        );
    }

    private updateFavourites() {
        return this.destinationService.getFavourites().pipe(
            map(favourites => {
                favourites.forEach(f => {
                    const key = [f.mcc, f.mnc, f.originalMnc].join('');
                    this.favourites[key] = true;
                });
            })
        );
    }

    prepareCountry(country: Destination) {
        if (typeof country.key === 'undefined') {
            country.key = [country.mcc, country.mnc, country.originalMnc].join('');
        }

        country.online = true;
        country.selected = !!country.selected;
        country.custom = false;
        country.isFavourite = !!this.favourites[country.key];

        if (typeof country.__search === 'undefined') {
            country.__search = [
                country.mcc,
                country.mnc,
                country.originalMnc,
                country.countryName,
                country.providerName,
                country.originalProviderName
            ].map(s => s ? (String(s)).trim() : '').join('').toLowerCase();
        }

        if (this.searchQuery) {
            country._filterTextResult = false;
        }
    }

    reset() {
        this.selectedCount = 0;
        this.models.map(_ => _.selected = false);
        this.toggleAll = false;
        this.toggleAllFavourites = false;
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }
        this.searchQuery = '';
        this.cdr.detectChanges();
    }

    onClickToggleAll() {
        this.toggleAll = !this.toggleAll;
        this.toggleAllFavourites = this.toggleAll;
        let query = typeof this.searchQuery !== 'undefined' && this.searchQuery.length;
        let selectedCount = this.models.filter(_ => _.selected).length;
        this.getDestinations().filter(c => {
            if (query) {
                return typeof c._filterTextResult === 'undefined' || c._filterTextResult;
            }
            return true;
        }).forEach(c => {
            if (this.toggleAll) {
                selectedCount++;
            } else {
                selectedCount--;
            }
            if (this.recent && this.toggleAll && selectedCount > this.maxSelectedCount) {
                return;
            }
            c.selected = this.toggleAll;
        });
        this.selectedCount = this.models.filter(_ => _.selected).length;
        this.onChange.emit();
        this.cdr.detectChanges();
    }

    onClickToggleAllFavorites() {
        this.toggleAll = false;
        this.toggleAllFavourites = !this.toggleAllFavourites;
        let selectedCount = this.models.filter(_ => _.selected).length;
        this.getFavourites().forEach(c => {
            if (this.toggleAllFavourites) {
                selectedCount++;
            } else {
                selectedCount--;
            }
            if (this.recent && this.toggleAll && selectedCount > this.maxSelectedCount) {
                return;
            }
            c.selected = this.toggleAllFavourites;
        });
        this.selectedCount = this.models.filter(_ => _.selected).length;
        this.onChange.emit();
        this.cdr.detectChanges();
    }

    toggle(country) {
        if (this.isDisable(country)) return;
        country.selected = !country.selected;
        if (country.selected) {
            this.selectedCount++;
        } else {
            this.selectedCount--;
        }
        this.toggleAll = (this.recent && this.selectedCount === this.maxSelectedCount) || this.selectedCount === this.models.length
        this.toggleAllFavourites = this.toggleAll;

        this.onChange.emit();
        this.cdr.detectChanges();
    }

    onClickAddPhonenumbers() {
        this.addPhonenumbersModal = this.modal.alert().isBlocking(true).dialogClass('modal-dialog small-modal').component(this.addPhonenumbersModalTpl).open();
    }

    onSubmitAddPhonenumbers() {
        const numbers = this.addPhonenumbers.split("\n").map(phone => {
            phone = phone.trim();
            if (phone.indexOf('+') === 0) {
                phone = phone.substr(1);
            }
            return phone;
        });
        if (numbers.length > 10 && this.user.role !== Role.ADMIN) {
            this.notificationService.error({
                title: 'Destinations',
                message: 'You can only add up to 10 destinations at once.',
                service: 'Countries and Networks',
            });
            return;
        }
        numbers.filter(_ => _.length).map(_ => this.createPhonenumber(_, false))
        this.addPhonenumbers = '';
        this.reindex();
        this.onChange.emit();
        this.cdr.detectChanges();
        this.addPhonenumbersModal.close();
    }

    createPhonenumber(phone, reindex = true) {
        phone = phone.trim();
        const model = {
            key: phone,
            mcc: '0',
            mnc: '0',
            countryName: '',
            providerName: '',
            phonenumber: phone,
            selected: true,
            online: true,
            custom: true,
            originalProviderName: null,
            originalMnc: null,
            __search: phone
        };
        this.models.unshift(model);
        this.models = [...this.models];

        if (reindex) {
            this.reindex();
            this.cdr.detectChanges();
        }
        return model;
    }

    reindex() {
        let i = 0;
        this.idsMap = {};
        this.models.map(c => {
            this.idsMap[c.key] = i;
            i++;
        });
    }

    toggleByParams(mcc, mnc, originalMnc) {
        this.models.filter(c => c.mcc === mcc && c.mnc === mnc && c.originalMnc === originalMnc).map(c => this.toggle(c));
        this.cdr.detectChanges();
    }

    selectByParams(mcc, mnc, originalMnc) {
        this.models.filter(c => !c.selected && c.mcc === mcc && c.mnc === mnc && c.originalMnc === originalMnc).map(c => this.toggle(c));
    }

    onSearchQueryChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;

        if (value !== this.searchQuery) {
            this.searchQuery = value;
            this.cdr.detectChanges();
        }
    }

    sseErrorHandler() {
        return (error) => {
            const retryIntervalInMillis = 10000;
            if (this.networkUpdatesEventSource.readyState !== EventSource.CLOSED) {
                this.networkUpdatesEventSource.close();
            }

            this.timeoutId = setTimeout(() => {
                const config = this.getNetworkUpdatesConfig();
                this.networkUpdatesEventSource = new EventSource(config.url);
                this.networkUpdatesEventSource.addEventListener(config.event, this.networkUpdateHandler());
                this.networkUpdatesEventSource.onerror = this.sseErrorHandler();

                this.update().subscribe(() => {
                    this.models.filter(model => {
                        const afterFilter = this.selectedModelsBefore.filter(_ => _.mcc === model.mcc && _.mnc === model.mnc && _.original_mnc === model.originalMnc).length > 0;
                        return afterFilter
                    }).map(_ => _.selected = true);

                    this.cdr.detectChanges();
                })
            }, retryIntervalInMillis)
        };
    }

    listenToNetworkUpdateEvents() {
        if (this.networkUpdatesEventSource == null) {
            const config = this.getNetworkUpdatesConfig();
            this.networkUpdatesEventSource = new EventSource(config.url);
            this.networkUpdatesEventSource.addEventListener(config.event, this.networkUpdateHandler());
            this.networkUpdatesEventSource.onerror = this.sseErrorHandler();
        }
    }

    removeNetworkUpdateEventsListener() {
        const config = this.getNetworkUpdatesConfig();
        this.networkUpdatesEventSource.removeEventListener(config.event, this.networkUpdateHandler());
    }

    private getNetworkUpdatesConfig() {
        switch (this.source) {
            case "live":
                return {url: this.destinationService.getNetworkUpdatesUrl(), event: 'network-update'};
            case "manual":
                return {url: this.destinationService.getNetworkUpdatesUrl(), event: 'network-update'};
            case "mo":
                return {url: this.destinationService.getMoNetworkUpdatesUrl(), event: 'mo-network-update'};
            case "voice":
                return {url: this.destinationService.getVoiceNetworkUpdatesUrl(), event: 'voice-network-update'};
            default:
                throw new Error('Undefined source');
        }
    }

    ngOnDestroy() {
        if (this.networkUpdatesEventSource != null) {
            this.removeNetworkUpdateEventsListener()
            this.networkUpdatesEventSource.close()
        }
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
        if (this.updateSubscription != null) {
            this.updateSubscription.unsubscribe()
        }
    }

    networkUpdateHandler() {
        return (message) => {
            let data = JSON.parse(message.data);
            const parsedData = data.map(e => JSON.parse(e))
            for (const event of parsedData) {
                if (event.action === 'OFF') {
                    event.networks.map(network => {
                        network = this.destinationService.mapToDestination(network);
                        let key = [network.mcc, network.mnc, network.originalMnc].join('');
                        this.models.forEach((n, i) => {
                            if (key === n.key) {
                                n.online = false;
                                if (!n.selected) {
                                    this.models.splice(i, 1);
                                }
                            }
                        });
                    });
                }
                if (event.action === 'ON') {
                    event.networks.forEach(network => {
                        network = this.destinationService.mapToDestination(network);
                        if (this.hidePorted && network.originalProviderName) {
                            return false;
                        }
                        if (this.showOnlyPorted && !network.originalProviderName) {
                            return false;
                        }
                        this.prepareCountry(network);
                        let existsNetwork = this.models.filter(n => n.key === network.key).map(n => n.online = true).length;
                        if (!existsNetwork) {
                            let index;
                            this.models.filter((n, i) => {
                                index = n.countryName === network.countryName ? i : index;
                            });
                            this.prepareCountry(network);
                            if (index === null) {
                                this.models.push(network);
                            } else {
                                this.models.splice(index + 1, 0, network);
                            }
                        }
                    });
                }
            }
            this.models.sort(this.compareCountries)
            this.cdr.detectChanges();
        };
    }

    onManualNumbersChange(event: any): void {
        this.addPhonenumbers = event.target.value;
        if (/^(\d|\s)*$/.test(this.addPhonenumbers)) {
            this.hasErrorInManualNumber = false;
        } else {
            this.hasErrorInManualNumber = true;
        }
    }

    compareCountries(modelA, modelB) {
        // Use toUpperCase() to ignore character casing
        const countryA = modelA.countryName ? modelA.countryName.toUpperCase() : '';
        const countryB = modelB.countryName ? modelB.countryName.toUpperCase() : '';

        let comparison = 0;
        if (countryA > countryB) {
            comparison = 1;
        } else if (countryA < countryB) {
            comparison = -1;
        }

        // Same countryName name - compare providers
        if (comparison === 0) {
            const providerA = modelA.providerName ? modelA.providerName.toUpperCase() : "";
            const providerB = modelB.providerName ? modelB.providerName.toUpperCase() : "";
            if (providerA > providerB) {
                comparison = 1;
            } else if (providerA < providerB) {
                comparison = -1;
            }
        }

        // Same countryName name, same provider - compare originalProviders
        if (comparison === 0) {
            const originalProviderA = modelA.originalProviderName ? modelA.originalProviderName.toUpperCase() : "";
            const originalProviderB = modelB.originalProviderName ? modelB.originalProviderName.toUpperCase() : "";
            if (originalProviderA > originalProviderB) {
                comparison = 1;
            } else if (originalProviderA < originalProviderB) {
                comparison = -1;
            }
        }
        return comparison;
    }

    onHidePortedClick(event: any): void {
        event.stopPropagation();
        this.hidePorted = !this.hidePorted;
        this.showOnlyPorted = false;

        this.savePortedFilter();
        this.onChange.emit();
        this.cdr.detectChanges();
    }

    onShowOnlyPorted(event: any): void {
        event.stopPropagation();
        this.showOnlyPorted = !this.showOnlyPorted;
        this.hidePorted = false;

        this.savePortedFilter();
        this.onChange.emit();
        this.cdr.detectChanges();
    }

    savePortedFilter() {
        if (this.recent) { return; }
        const portedFilter = {
            showOnlyPorted: this.showOnlyPorted,
            hidePorted: this.hidePorted
        };
        const key = `destinations-ported-filter-${this.source}`;
        this.localStorage.set(key, portedFilter);
    }

    loadPortedFilter() {
        if (this.recent) { return; }
        const key = `destinations-ported-filter-${this.source}`;
        const portedFilter = this.localStorage.get(key) as { showOnlyPorted: boolean, hidePorted: boolean };
        if (portedFilter) {
            this.showOnlyPorted = portedFilter.showOnlyPorted;
            this.hidePorted = portedFilter.hidePorted;
        }
    }

    getTooltip(d: Destination) {
        const tooltipText = `${d.countryName} (${d.mcc}) / ${d.providerName} (${d.mnc})`;
        if (d.originalProviderName && d.originalMnc) {
            return `${tooltipText} <i class="icon-ported-white"></i> ${d.originalProviderName} (${d.originalMnc})`
        }
        if (tooltipText.length < 50) return null;
        return tooltipText;
    }

    onClickFilter(): void {
        this.modal.alert().dialogClass('modal-dialog  modal-destinations-filter').component(this.filterModalTpl).open();
    }

    onClickExport() {
        this.exportData = {
            filename: undefined,
            download_url: undefined
        };
        this.exportDays = 'online';
        this.exportModal = this.modal.alert().component(this.exportModalTpl).open();
    }

    onClickStartExport() {
        this.exportSpinner = true;
        let filter = this.getExportFilter(this.exportDays);
        let params = filter ? { filter: filter } : {};
        this.liveNumberTestingService.exportDestinations(params).then(exportResponse => {
            this.exportData.filename = exportResponse.filePath;
            this.exportData.download_url = exportResponse.downloadCallback;
            this.exportSpinner = false;
        }).catch(error => {
            this.exportSpinner = false;
            this.notificationService.error({
                title: 'Export destinations',
                message: `Error : ${error.response.status}`,
                serviceName: 'Export service'
            });
        });
    }

    getExportFilter(days) {
        const filters = {
            '30days': {
                startDate: moment().subtract(30, 'days').format("YYYY-MM-DDT[00:00:00+00:00]"),
                endDate: moment().format("YYYY-MM-DDT[23:59:59+00:00]")
            },
            '90days': {
                startDate: moment().subtract(90, 'days').format("YYYY-MM-DDT[00:00:00+00:00]"),
                endDate: moment().format("YYYY-MM-DDT[23:59:59+00:00]")
            }
        }

        return typeof filters[days] !== "undefined" ? filters[days] : null;
    }

    disableAll(isDisabled: boolean) {
        this.isAllDisabled = isDisabled;
        this.cdr.detectChanges();
    }

    isDisable(destination: Destination) {
        return this.isAllDisabled || (!destination.online && !destination.selected) || (this.recent && !destination.selected && (this.selectedCount >= this.maxSelectedCount));
    }

    setCountry(country) {
        this.countryName = country;
        this.cdr.detectChanges();
    }

    setShortCodeCountries(countries) {
        this.shortCodeCountries = countries;
        this.cdr.detectChanges();
    }

    onDeleteFilterClick() {
        this.reset();
        this.onChange.emit();
    }

    onFavouriteClick(destination: Destination) {
        if (destination.isFavourite) {
            this.destinationService.deleteFavorite(destination.mcc, destination.mnc, destination.originalMnc).subscribe({
                next: () => {
                    destination.isFavourite = false;
                    delete this.favourites[destination.key];
                    this.cdr.detectChanges();
                    this.notificationService.success('Favourite destination updated', 'Destinations');
                },
                error: () => {
                    this.cdr.detectChanges();
                    this.notificationService.error({
                        title: 'Destinations',
                        message: 'There was a problem marking/unmarking this destination as favourite.',
                        service: 'API_GATEWAY'
                    });
                }
            });
            return;
        }
        this.destinationService.createFavorite(destination).subscribe({
            next: () => {
                destination.isFavourite = true;
                this.favourites[destination.key] = true;
                this.cdr.detectChanges();
                this.notificationService.success('Favourite destination updated', 'Destinations');
            },
            error: () => {
                this.cdr.detectChanges();
                this.notificationService.error({
                    title: 'Destinations',
                    message: 'There was a problem marking/unmarking this destination as favourite.',
                    service: 'API_GATEWAY'
                });
            }
        });
        return;
    }
}



