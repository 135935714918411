import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, switchMap, Observable, throwError, retry } from 'rxjs';
import { BackendChannelService, BackendChannelType } from "./backend-channel.service";
import { UsersService } from "./users.service";
import { BrowserUtils } from "./browser-utils";
import { map } from "rxjs/operators";

@Injectable()
export class UrlSwitcherInterceptor implements HttpInterceptor {

    constructor(private backendChannel: BackendChannelService, private userService: UsersService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentChannel = this.backendChannel.$current.value;
        const baseUrl = this.backendChannel.getBaseUrl(currentChannel);
        const isPingRequest = req.url.includes('guest/usr/ping');
        return next.handle(req.clone({
            url: req.url.replace('API_BASE_URL', baseUrl)
        })).pipe(
            catchError((error: HttpErrorResponse) => {
                const notSentRequest = error.status === 0 && !BrowserUtils.isUnload;
                if (isPingRequest && notSentRequest && currentChannel === 'proxy') {
                    this.backendChannel.$connectStatus.next({status: 'reconnecting', channel: 'main'});
                    return next.handle(req.clone({
                        url: req.url.replace('API_BASE_URL', this.backendChannel.getBaseUrl('main'))
                    })).pipe(
                        retry({delay: 1000, count: 2}),
                        map(event => {
                            if (event instanceof HttpResponse && event.status === 200) {
                                this.backendChannel.switchType('main');
                            }
                            return event;
                        }),
                        catchError(e => {
                            this.backendChannel.$connectStatus.next({status: 'reconnecting', channel: 'proxy'});
                            return throwError(() => e);
                        })
                    );
                }
                return throwError(() => error);
            })
        );
    }
}