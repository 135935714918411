import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { UsersService } from '../../shared/services/users.service';
import { Timeout } from "../../shared/services/timeout";


@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})

export class ResetPasswordFormComponent {

    loading = false;
    model = {
        username: ''
    };
    successMessage = null;

    form: FormGroup;
    resendTimeout: Timeout;
    
    constructor(
        public userService: UsersService,
        formBuilder: FormBuilder,
        public notificationService: NotificationService,
        public validationService: ValidationService
    ) {
        this.form = formBuilder.group({
            username: ['', [Vld.required]],
        });
    }

    onSubmit() {
        this.loading = true;
        this.userService.recovery(this.model.username).subscribe(() => {
            this.successMessage = this.model.username;
            this.loading = false;
            this.notificationService.success('Email sent successfully.', 'Password Recovery');
            if (this.resendTimeout && !this.resendTimeout.isFinish()) {
                this.resendTimeout.clear();
            }
            this.resendTimeout = new Timeout(120);
        }, error => {
            this.notificationService.error({
                title: 'Recovery',
                message: 'An error occurred while recovery request',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.loading = false;
        })
    }

    alert(text, type) {
        this.notificationService[type](text, 'Recovery');
    }
}
