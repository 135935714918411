import { Injectable, ViewContainerRef } from "@angular/core";
import { ConfirmedActionComponent } from "../components/confirmed-action/confirmed-action.component";
import { Observable } from "rxjs";
import { UsersService } from "./users.service";

@Injectable({providedIn: 'root'})
export class ConfirmedActionsService {

    private vRef: ViewContainerRef;
    private static currentAction: string;

    constructor() {
    }

    static setCurrentAction(action: string) {
        ConfirmedActionsService.currentAction = action;
    }

    confirm(): Observable<void> {
        return new Observable<void>(observer => {
            if (!this.vRef) {
                observer.error();
                observer.complete();
                return;
            }
            const com = this.vRef.createComponent(ConfirmedActionComponent);
            if (ConfirmedActionsService.currentAction) {
                com.instance.action = ConfirmedActionsService.currentAction;
            }
            com.instance.result.subscribe(result => {
                if (result) { observer.next(); } else { observer.error(new CancelError()); }
                observer.complete();
                com.destroy();
            });
        });
    }

    setRootViewContainerRef(vRef: ViewContainerRef) {
        this.vRef = vRef;
    }

}
export class CancelError extends Error {}