import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSettings } from "../models/api.model";
import { RestUtils } from './rest-utils';

@Injectable()
export class UserApiService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    getSettings(userId: number): Observable<ApiSettings> {
        let url = this.utils.buildUrl('ROLE/api/settings', {userId: userId});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ApiSettings>(url, options);
    }

    saveSettings(settings: ApiSettings): Observable<ApiSettings> {
        let url = this.utils.buildUrl('ROLE/api/settings');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return settings.appId ? this.http.put<ApiSettings>(url, settings, options) : this.http.post<ApiSettings>(url, settings, options);
    }

    saveAppKey(appKey) {
        let url = this.utils.buildUrl('ROLE/api/settings/appKey');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, { appKey: appKey }, options);
    }

    createSettings(): ApiSettings {
        return {
            userId: null,
            enabled: false,
            appKey: null,
            appId: null,
            updatedAt: null,
            allowedIps: null
        }
    }
}