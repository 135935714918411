import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { CoverageNetwork } from "../../shared/models/coverage.model";
import { CoverageService, IPoint } from "../../shared/services/coverage.service";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import { GridApi, GridOptions, ICellRendererParams, SortChangedEvent } from "ag-grid-community";
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";

@Component({
    selector: 'app-coverage-map',
    templateUrl: './coverage-map.component.html',
    styleUrls: ['coverage-map.component.scss']
})

export class CoverageMapComponent implements AfterViewInit {

    loading = false;

    @Input() networks: CoverageNetwork[] = [];
    points: IPoint[] = [];

    theme = AgGridUtils.theme;

    gridApi: GridApi<IPoint>;

    gridOptions: GridOptions<IPoint> = {
        onGridReady: e => {
            this.gridApi = e.api;
        },
        onSortChanged: (e: SortChangedEvent) => {
            CoverageService.saveSort(this.storage, e.api.getColumnDefs(), 'analytics-coverage-countries');
        },
        columnDefs: [
            {
                headerName: 'Country(MCC)',
                field: 'country',
                valueGetter: (params) => {
                    return `${params.data.country.name}(${params.data.country.mcc})`;
                },
                sortable: true,
                editable: false,
                suppressMenu: true
            },
            {
                headerName: 'Networks',
                field: 'networks',
                valueGetter: (params) => {
                    return params.data.networks.length;
                },
                sortable: true,
                editable: false,
                suppressMenu: true,
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Online',
                field: 'online',
                valueGetter: (params) => {
                    return params.data.online;
                },
                sortable: true,
                editable: false,
                suppressMenu: true,
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Offline',
                field: 'offline',
                valueGetter: (params) => {
                    return params.data.offline;
                },
                sortable: true,
                editable: false,
                suppressMenu: true,
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Requests',
                field: 'requests',
                valueGetter: (params) => {
                    return params.data.requests;
                },
                sortable: true,
                editable: false,
                suppressMenu: true,
                getQuickFilterText: params => '',
            },
        ],
        popupParent: document.body,
        suppressMovableColumns: true,
        suppressContextMenu: true,
        enableCellTextSelection: true,
        suppressCellFocus: true,
        alwaysMultiSort: true,
        rowModelType: 'clientSide',
        tooltipShowDelay: 500,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    }

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        private notificationService: NotificationService,
        private storage: LocalStorageService,
    ) {
    }

    ngOnInit() {
        CoverageService.applySortToColumns(this.storage,  this.gridOptions.columnDefs, 'analytics-coverage-countries');
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.gridApi.setGridOption('quickFilterText', searchValue.trim());
        });
    }

    ngAfterViewInit() {
        this.points = CoverageService.networksToPoints(this.networks);

    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}

