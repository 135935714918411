export interface EnvDef {
    production: boolean,
    releaseVersion: string,
    storagePrefix: string,
    apiUrl: string,
    proxyApiUrl: string,
    appscriptsUrl: string,
    statusPageId: string,
    networksOnlineUrl: string,
    networkUpdatesUrl: string,
    moNetworksOnlineUrl: string,
    moNetworkUpdatesUrl: string,
    voiceNetworksOnlineUrl: string,
    voiceNetworkUpdatesUrl: string,
    forcedUpdate: boolean,
    postponeUpdates: string[],
    ignoreUpdateUserIds: Set<number>,
    agGridLicenseKey: string,
    recaptchaSiteKey: string,
    recaptchaBaseUrl: string,
    countriesApiUrl: string,
    backendChannelResetTag: number
}

export const defaults = {
    storagePrefix: 'telq_',
    releaseVersion: 'default',
    apiUrl: 'http://localhost:8080',
    proxyApiUrl: 'http://localhost:8080',
    appscriptsUrl: 'https://appscripts.telqtele.com/v2.2',
    statusPageId: '8mnnnzvs0gsr',
    networksOnlineUrl: 'https://sse.dev.telqtele.com/networks/online',
    networkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/updates',
    moNetworksOnlineUrl: 'https://sse.dev.telqtele.com/networks/mo-online',
    moNetworkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/mo-updates',
    voiceNetworksOnlineUrl: 'https://sse.dev.telqtele.com/networks/voice-online',
    voiceNetworkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/voice-updates',
    forcedUpdate: false,
    postponeUpdates: ['/live-testing', '/manual-testing', '/scheduler/tasks/create'],
    recaptchaSiteKey: '6LcTiYMlAAAAAO6gzYFOXtRPErMzf0SqKOMTiFB4',
    recaptchaBaseUrl: 'https://recaptcha.net/recaptcha/api.js',
    countriesApiUrl: 'https://api.dev.moneysmsapp.com/v2.4/platform/countries',
    backendChannelResetTag: 1,
    ignoreUpdateUserIds: new Set([
        2752,
        2694,
        2672,
        2671,
        2670,
        2554,
        2553,
        2552,
        2551
    ])
}