import { Destination } from "./destination.model";
import { BasicUser } from "./user.model";
import { BaseCollection } from "./base-collection.model";

export interface MoTestingRequest {
    destinations: string[];
    dynamicTexts: MoText[];
    origins: Destination[];
    protocolId?: number;
    encodingId?: number;
    amountX: number;
    commentText?: string;
    ttl?: number;
}

export interface MoText {
    id?: string;
    hasTelqId?: boolean;
    text: string;
    telqIdType: string;
    telqIdCase: string;
    telqIdLength: number;
}

export const MO_TEST_SENT_STATUSES = [
    {
        id: 'WAIT',
        label: 'Wait'
    }, {
        id: 'SENT',
        label: 'Sent'
    }, {
        id: 'SENT_ERROR',
        label: 'Sent error'
    }, {
        id: 'EXPIRED',
        label: 'Expired'
    }, {
        id: 'INTERNAL_ERROR',
        label: 'Internal error'
    }, {
        id: 'UNKNOWN_ERROR',
        label: 'Unknown error'
    }, {
        id: 'TEST_NUMBER_OFFLINE',
        label: 'Test number offline'
    }, {
        id: 'TEST_NUMBER_NOT_AVAILABLE',
        label: 'Test number not available'
    }, {
        id: 'NETWORK_OFFLINE',
        label: 'Network offline'
    }
];

export const MO_TEST_DLR_STATUSES = [
    {
        id: 'WAIT',
        label: 'Wait'
    }, {
        id: 'DELIVERED',
        label: 'Delivered'
    }, {
        id: 'EXPIRED',
        label: 'Expired'
    }, {
        id: 'INTERNAL_ERROR',
        label: 'Internal error'
    }
];

export interface MoTestingResult {
    id: number;
    telqId: string;
    destination: string;
    createdAt: Date;
    receivedAt: Date;
    testGroupId: number;
    price: number;
    sentStatus?: string;
    dlrStatus?: string;
    user: BasicUser;
    receiptDelay: number;
    originDto: Destination;
    textDelivered: string;
    senderDelivered: string;
    createdAtFormat?: string;
}

export interface MoTestingResultsCollection extends BaseCollection {
    content: MoTestingResult[]
}

export interface MoTestingSearch {
    createdAtFrom?: string;
    createdAtTo?: string;
    ids?: number[];
    userIds?: number[];
    telqIds?: string[];
    origins?: Destination[];
    destinations?: string[];
    sentStatuses?: string[];
    dlrStatuses?: string[];
    text?: string;
}

export interface MoTestDetails {
    callerIp: string;
    ttl: number;
    statuses: MoTestDetailsStatus[];
}

export interface MoTestDetailsStatus {
    isDlr: boolean;
    statusCode: number;
    statusDescription: string;
    pdu: string;
    phoneTimestamp: string | Date;
    serverReceivedAt: string | Date;
    totalNumberOfSegments: number;
    segmentNumber: number;
    codeDescription?: string,
    codeReferenceLink?: string,
    isStatusAfterExpired?: boolean
}

