import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { ConnectionsImportResult, ConnectionImportRow } from "../models/connection-import.model";

@Injectable()
export class ConnectionsImportService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    import(rows: ConnectionImportRow[]): Observable<ConnectionsImportResult> {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions-suppliers/upload`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<ConnectionsImportResult>(url, rows, options);
    }

    getImportExampleFileUrl() {
        return window.location.origin + '/templates/import_sessions_suppliers.xlsx';
    }
}
