
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from "../../shared/components/table/table.component";
import { ContentTemplate, ContentTemplateImportErrorCollection, ContentTemplateText } from '../../shared/models/content-templates';
import { ImportResult } from "../../shared/models/import-result.model";
import { AllRequestParams, ContentTemplateService } from '../../shared/services/content-template.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { TemplatesService } from '../../shared/services/templates.service';
import { UsersService } from '../../shared/services/users.service';
import { BrowserUtils } from "../../shared/services/browser-utils";

@Component({
    selector: 'app-content-template-table',
    templateUrl: './content-templates-table.component.html',
    styleUrls: ['./content-templates-table.component.scss'],
})
export class ContentTemplatesTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete = new EventEmitter();

    @ViewChild('importModalTpl', { read: TemplateRef, static: false }) importModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('smsTextTemplateModalTpl', { read: TemplateRef, static: false }) smsTextTemplateModalTpl: any;

    isAdmin: boolean = false;
    requestParams = new AllRequestParams();
    dialogRef: any;
    importModal;
    importLoading: boolean = false;
    importFile: File = null;
    importResult: ImportResult;
    currentTitle: string = '';
    storageContextName = 'content-template-table';
    currentContentTemplateId: number;
    importTemplatesFileList;
    backendImportErrors: ContentTemplateImportErrorCollection;

    constructor(
        public notificationService: NotificationService,
        public service: TemplatesService,
        public contentTemplateService: ContentTemplateService,
        public userService: UsersService,
        public modal: ModalService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        let columns = [
            { title: 'ID', prop: 'id', sort: true, cellClassName: 'w-sm' },
            { title: 'SenderId', prop: 'name', sort: true, cellClassName: 'editable', type: ColumnTypes.HTML, format: _ => `<span>${BrowserUtils.escapeHtml(_.name)}</span>` },
        ];
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit', title: 'Edit content template' },
            { icon: 'far fa-trash-alt', name: 'delete', title: 'Delete content template', show: row => this.canShowActionDelete(row) },
        ];

        this.userService.can('admin').then(isAdmin => {
            this.isAdmin = isAdmin;
            this.setColumns(this.createColumns(columns));
            this.update();
        });

    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'name') {
            this.openSmsTextTemplates(event.row.data);
        }
    }

    openSmsTextTemplates(row) {
        this.currentContentTemplateId = row.id;
        this.dialogRef = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.smsTextTemplateModalTpl).open();
        this.dialogRef.result.then(result => { });
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.contentTemplateService.all(this.requestParams).subscribe({
            next: (collection: any) => {
                this.setData(this.createRows(collection.content), collection.totalElements);
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while loading content templates',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            this.dialogRef = this.modal.alert().size('modal-dialog small-modal').component(this.deleteModalTpl).open();
            this.dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        } else if (event.name === 'switch' && event.column.prop === 'visibleAll') {
            this.handleVisibleAllToggle(event);
        }
    }

    handleVisibleAllToggle(event: any): void {
        const data = {
            id: event.row.data.id,
            visibleAll: event.row.data.visibleAll
        }
        this.contentTemplateService.updateVisibleAllToggle(data).subscribe({
            next: (res) => {
                this.notificationService.success('Content template updated', 'Content Templates');
            },
            error: (err) => {
                event.row.data[event.column.prop] = !event.row.data[event.column.prop];
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while updating the content templates',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
            }
        });
    }

    onAfterAction(data: any): void {
        this.update();
        this.dialogRef.close();
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    delete(contentTemplate: ContentTemplate): void {
        this.loading = true;
        this.contentTemplateService.delete(contentTemplate.id).subscribe({
            next: (res) => {
                this.notificationService.success('Content template removed', 'Content Templates');
                this.onAfterDelete.emit(res);
                this.update();
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while deleting the template',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }

    canShowActionDelete(row) {
        return this.isAdmin;
    }
}
