import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { CallbackHistoryCollection } from '../models/callback-history.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";

@Injectable()
export class CallbackHistoryService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: AllRequestParams): Observable<CallbackHistoryCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : [],
            service: params.service ? params.service : null
        };
        let url = this.utils.buildUrl('ROLE/callbacks/callback-history', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<CallbackHistoryCollection>(url, options).pipe(
            map(c => {
                c.content = c.content.map(_ => {
                    _.sentAt = new Date(_.sentAt);
                    return _;
                });
                return c;
            })
        );
    }
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    service: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
