
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

    mfa = false;
    username = ''

    constructor(
        title: Title,
        route: ActivatedRoute,
        private router: Router
    ) {
        title.setTitle('Recovery password');
        route.queryParams.subscribe(params => {
            if (params.mfa) {
                this.mfa = true;
                title.setTitle('Recovery code');
            }
            if (params.username) {
                this.username = params.username;
            }
        })
    }

    onLogin() {
        this.router.navigate(['/dashboard']).then();
    }
}