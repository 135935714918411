
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map } from "rxjs/operators";
import { ContentText } from "../../../shared/models/content-templates";
import { DataCoding } from "../../../shared/models/data-coding.model";
import { TestCaseTemplatesCollection } from '../../../shared/models/test-case-template.model';
import { TestGroupInfo } from '../../../shared/models/test-group-info.model';
import { TestGroup } from '../../../shared/models/test-group.model';
import { AuthUser } from "../../../shared/models/user.model";
import { DatadogService } from '../../../shared/services/datadog.service';
import {
    CreateTestGroup,
    LiveNumberTestingService, TelqText,
} from '../../../shared/services/live-number-testing.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { PricingService } from "../../../shared/services/pricing.service";
import { SelectPair, SessionsService } from '../../../shared/services/sessions.service';
import { TemplatesService } from '../../../shared/services/templates.service';
import { AllRequestParams, TestCaseTemplatesService } from '../../../shared/services/test-case-template.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';
import { RoutesSelectComponent } from '../../routes-select/routes-select.component';
import { UdhTlvInputComponent } from '../../udh-tlv-input/udh-tlv-input.component';
import { LntContentTemplatesComponent } from "../lnt-content-templates/lnt-content-templates.component";

@Component({
    selector: 'app-lnt-form',
    templateUrl: 'lnt-form.component.html',
    styleUrls: ['lnt-form.component.scss'],
})

export class LntFormComponent implements OnDestroy, AfterViewInit {

    @ViewChild(RoutesSelectComponent, { static: false }) connections: RoutesSelectComponent;
    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild(LntContentTemplatesComponent, { static: false }) contentTemplates: LntContentTemplatesComponent;

    @ViewChild('templateSaveModalTpl', { read: TemplateRef, static: false }) templateSaveModalTpl: any;
    @ViewChild('confirmModalTpl', { read: TemplateRef, static: false }) confirmModalTpl: any;
    confirmModal: DialogRef;
    @ViewChild('testSettingsModalTpl', { read: TemplateRef, static: false }) testSettingsModalTpl: any;
    @ViewChild('contentTemplateImportModalTpl', { read: TemplateRef, static: false }) contentTemplateImportModalTpl: any;
    contentTemplateImportModal: DialogRef;
    @ViewChild('schedulerCreateModalTpl', { read: TemplateRef, static: false }) schedulerCreateModalTpl: any;
    schedulerCreateModal: DialogRef;

    @Input() showButtons: boolean = true;
    @Input() autoRun: boolean = true;
    @Input() pauseUpdate: boolean = false;
    @Input() hiddenSearchButton: boolean = false;
    @Output() onAfterSave = new EventEmitter;
    @Output() onChangeCountResults = new EventEmitter;
    @Output() onClickSearchButton = new EventEmitter;
    @Output() onLoad = new EventEmitter;

    runForScheduler: boolean = false;
    advancedSettings: boolean = false;
    advancedSettingsTab: string = 'other';

    @ViewChild('udhListComponent', { read: UdhTlvInputComponent, static: false }) udhListComponent: UdhTlvInputComponent;
    @ViewChild('tlvListComponent', { read: UdhTlvInputComponent, static: false }) tlvListComponent: UdhTlvInputComponent;
    valid: boolean = false;
    loading: boolean = false;

    countResults = 0;

    npiItems: SelectPair[] = [{ id: -1, label: 'Automatic' }];
    tonItems: SelectPair[] = [{ id: -1, label: 'Automatic' }];
    dcsItems: SelectPair[] = [{ id: -1, label: 'Automatic' }];

    templatesList = [];
    isTemplateNameDuplicate = false;
    onAfterSaveEmmit: boolean = true;
    testCaseTemplateId: number;
    testCaseTemplateTitle: string;
    maxTestsCount = 8000;
    maxTestsError = '';

    form: FormGroup;
    templateSaveForm: FormGroup;

    user: AuthUser;

    model: any;

    telqIdSettingsTypes = TemplatesService.TELQ_ID_TYPES;
    telqIdSettingsCases = TemplatesService.TELQ_ID_CASES;

    schedullerUpdate: any;

    testsPrice: number = 0;
    currentSection: string = '';
    priorityOptions: number[] = LiveNumberTestingService.PRIORITY_OPTIONS;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public liveNumberTesting: LiveNumberTestingService,
        public sessionService: SessionsService,
        public userService: UsersService,
        public validationService: ValidationService,
        public modal: ModalService,
        public testTemplateService: TestCaseTemplatesService,
        public templateService: TemplatesService,
        public storage: LocalStorageService,
        public pricingService: PricingService,
        private dgService: DatadogService,
    ) {
        this.model = liveNumberTesting.create();
        let smppTimeStampValidation = () => {
            if (this.form) {
                const scheduleDeliveryTimeControl = this.form.get('scheduleDeliveryTime');
                if (!scheduleDeliveryTimeControl.value?.length) {
                    return null;
                }
                const value = scheduleDeliveryTimeControl.value + '';
                if (value.length !== 16) {
                    return { 'smppTimeStamp': true };
                }
                let chunks = [];
                let currentStr = '';
                for (let i = 0; i < value.length; i++) {
                    if (i !== 0 && i % 2 == 0) {
                        chunks.push(parseInt(currentStr));
                        currentStr = value[i];
                    } else {
                        currentStr += value[i];
                    }
                }
                const [YY, MM, DD, hh, mm, ss, ...others] = chunks;
                if (YY < 0 || YY > 37 || MM < 1 || MM > 12 || DD < 1 || DD > 31 || hh < 0 || hh > 23 || mm < 0 || mm > 59 || ss < 0 || ss > 59) {
                    return { 'smppTimeStamp': true };
                }
            }
            return null;
        }
        this.form = formBuilder.group({
            count_results: ['', Vld.compose([Vld.min(1), Vld.max(this.maxTestsCount)])],
            dcs: ['', Vld.compose([Vld.integer(true)])],
            npi: ['', Vld.compose([Vld.integer(true)])],
            ton: ['', Vld.compose([Vld.integer(true)])],
            pid: ['', Vld.compose([Vld.integer(true)])],
            ttl_min: ['', UsersService.getValidatorForTestTtlMin()],
            validityPeriod: ['', Vld.compose([Vld.integer(true), Vld.min(60, true), Vld.max(86400, true)])],
            scheduleDeliveryTime: ['', Vld.compose([Vld.maxLength(16), smppTimeStampValidation])],
            replaceIfPresent: [''],
            priority: [LiveNumberTestingService.DEFAULT_PRIORITY],
            testRepeatsPerIteration: ['', Vld.compose([Vld.integer(true), Vld.min(1, true), Vld.max(999, true)])],
            commentText: ['', Vld.compose([Vld.maxLength(500)])],
            textAsTlv: ['']
        });
        sessionService.npiItems.forEach(_ => this.npiItems.push(_));
        sessionService.tonItems.forEach(_ => this.tonItems.push(_));

        this.templateSaveForm = formBuilder.group({
            templateTitle: ['', Vld.compose([Vld.required])]
        });
    }

    ngAfterViewInit() {
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.init();
        }).catch(e => {
            this.init();
        });
    }

    setValidationChecks(): void {
        let forms = [
            // this.tlvListComponent.form,
            // this.udhListComponent.form,
            this.form
        ];
        forms.map(_ => _.statusChanges.subscribe(() => {
            this.valid = forms.map(_ => _.valid).filter(_ => _).length === forms.length;
        }));
        const testRepeatsPerIterationFormControl: FormControl = this.form.get('testRepeatsPerIteration') as FormControl;
        testRepeatsPerIterationFormControl.valueChanges.subscribe(_ => {
            if (_ > 999) {
                testRepeatsPerIterationFormControl.setValue(1);
                this.notificationService.error('Test repeats per iteration must be less than 999', 'Live Number Testing');
            }
        });
    }

    isSettingsFormValid(): boolean {
        return this.tlvListComponent?.form.valid && this.udhListComponent?.form.valid;
    }

    isLNTSettingsApplied(): boolean {
        const defaultModel = this.liveNumberTesting.create();
        const keysInSettings = [
            'dcs',
            'ton',
            'npi',
            'commentText',
            'validityPeriod',
            'scheduleDeliveryTime',
            'replaceIfPresent',
            'priority',
            'textAsTlv'
        ];
        if (this.model.ttl_min != this.user?.testTtlMin) return true;
        if (this.model.udh?.length > 0 || this.model.tlv?.length > 0) return true;
        for (const key of keysInSettings) {
            if (this.model[key] != defaultModel[key]) {
                return true;
            }
        }
        return false;
    }

    init() {
        this.setValidationChecks();
        this.loading = true;
        forkJoin([
            this.liveNumberTesting.encodings(),
            this.showButtons ? this.updateTestTemplates(true) : of(null),
            this.connections.update(true),
            this.countries.update()
        ]).subscribe(_ => {
            (_[0] as DataCoding[]).map(enc => {
                this.dcsItems.push({ id: enc.id, label: `[${enc.dataCodingHex}] ${enc.name}` })
            });
            const success = () => {
                this.onLoad.emit();
                this.loading = false;
            };
            if (this.showButtons) {
                const defaultLntTemplateId = this.user.defaultLntTemplate;
                if (!defaultLntTemplateId) {
                    success();
                    return;
                }
                const defaultTemplateIndex = this.templatesList.findIndex(_ => _.id === defaultLntTemplateId);

                if (defaultTemplateIndex > -1) {
                    const defaultTemplate = this.templatesList[defaultTemplateIndex];
                    defaultTemplate.isDefault = true;
                    this.templatesList.splice(defaultTemplateIndex, 1);
                    this.templatesList.unshift(defaultTemplate);
                    this.testTemplateService.one(defaultLntTemplateId).subscribe(t => {
                        this.setTemplateTestCaseId(defaultLntTemplateId);
                        success();
                    });
                } else {
                    this.setTemplateTestCaseId(defaultLntTemplateId, false);
                    success();
                }
            } else {
                success();
            }
        }, (errors) => {
            this.loading = false;
        }, () => this.loading = false);

        this.model.ttl_min = this.user.testTtlMin;
        this.form.controls.ttl_min.patchValue(this.model.ttl_min);
    }

    update() {
        if (this.pauseUpdate) {
            return;
        }
        this.connections.update();
    }

    onSubmit() {
        this.runForScheduler = false;
        this.loading = true;
        this.confirmModal = this.modal.alert().component(this.confirmModalTpl).open();
        this.pricingService.getTestPrices(this.countResults).subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.loading = false;
                this.confirmModal.result.then(result => {
                    if (result) {
                        this.save();
                    }
                });
            },
            complete: () => {
                this.loading = false;
            }
        });
    }



    createModel(addEmptyText = true): CreateTestGroup {
        let model = {
            contentTexts: this.contentTemplates.getContentTexts(),
            destinations: [],
            sessionsSuppliers: [],
            udh: [],
            tlv: [],
            srcNpi: parseInt(this.model.npi) === -1 ? null : parseInt(this.model.npi),
            srcTon: parseInt(this.model.ton) === -1 ? null : parseInt(this.model.ton),
            protocolId: this.model.pid,
            encodingId: parseInt(this.model.dcs) === -1 ? null : parseInt(this.model.dcs),
            userId: null,
            ttl: this.model.ttl_min * 60,
            validityPeriod: this.model.validityPeriod,
            scheduleDeliveryTime: this.model.scheduleDeliveryTime,
            replaceIfPresent: this.model.replaceIfPresent,
            priority: this.model.priority,
            testRepeatsPerIteration: this.model.testRepeatsPerIteration,
            amountX: this.model.testRepeatsPerIteration,
            commentText: this.model.commentText.trim() ? this.model.commentText.trim() : null,
            textAsTlv: this.model.textAsTlv,
        };
        model.destinations = this.countries.getSelectedModels(false).map(_ => {
            if (_.custom) {
                return {
                    countryName: _.countryName ? _.countryName : null,
                    mcc: null,
                    mnc: null,
                    originalMnc: null,
                    originalProviderName: null,
                    providerName: null,
                    phone: _.phonenumber,
                    manualNumber: true
                };
            }
            return {
                countryName: _.countryName,
                mcc: _.mcc,
                mnc: _.mnc,
                originalMnc: _.originalMnc,
                originalProviderName: _.originalProviderName,
                providerName: _.providerName,
                manualNumber: false
            };
        });
        model.sessionsSuppliers = this.connections.getSelectedModels().map(_ => {
            return {
                supplierId: _.supplierId,
                sessionId: _.sessionId
            }
        });
        model.udh = this.model.udh?.length > 0 ? this.model.udh : this.model.udhDtos;
        model.tlv = this.model.tlv?.length > 0 ? this.model.tlv : this.model.tlvDtos;
        return model;
    }

    save() {
        let model = this.createModel();
        this.loading = true;
        // Is logged to Datadog ...
        if (model.destinations?.length > 20) {
            console.debug(`Test from ${this.user.id} has many destinations selected: `, model.destinations.length, model.contentTexts, model.sessionsSuppliers);
        }
        console.debug(`Test started by ${this.user.id}: `, model);
        return this.liveNumberTesting.run(model).subscribe({
            next: (res) => {
                this.notificationService.success('Test case created', 'Test cases');
                console.debug(`Test successfully created by ${this.user.id}: `, res); 
                this.loading = false;
                // if (this.onAfterSaveEmmit) {
                this.form.get('testRepeatsPerIteration').patchValue(1);
                this.countries.reset();
                this.connections.reset();
                // }
                this.calculateCountResults();
                if (this.onAfterSaveEmmit) {
                    this.onAfterSave.emit(res);
                }
                return model;
            },
            error: (e) => {
                this.notificationService.error({
                    title: 'Live number testing',
                    message: 'An error occurred while running the tests',
                    service: 'LNT',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    showErrors(errors) {
        this.notificationService.error('Creating an error', 'Test cases');
    }

    calculateCountResults() {
        if (!this.countries || !this.connections) {
            return;
        }
        this.countResults =
            (this.countries.getSelectedModels(false).length * this.model.testRepeatsPerIteration) *
            this.connections.getSelectedModels().length *
            (this.contentTemplates.getContentTexts().length);

        this.form.controls.count_results.patchValue(this.countResults);
        this.onChangeCountResults.emit(this.countResults);
        if (this.countResults > this.maxTestsCount) {
            this.maxTestsError = 'Max ' + this.maxTestsCount + ' tests in one batch';
        } else {
            this.maxTestsError = '';
        }
    }

    clickSearchButton() {
        this.onClickSearchButton.emit();
    }

    onClickScheduleButton(e) {
        if (e instanceof Event) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.storage.setMem('testGroup', this.createModel());
        this.schedulerCreateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.schedulerCreateModalTpl).open();
        this.confirmModal.close(false);
    }

    onScheduleAfterSave(data: boolean) {
        this.schedulerCreateModal.close(data);
        if (data) {
            this.resetAllSelectedValues();
        }
    }

    inputListText(text: String) {
        if (!text) {
            return text;
        }
        let maxLen = 40;
        if (text.length > maxLen) {
            return text.substr(0, maxLen - 1) + '...';
        }

        return text;
    }

    reset() {
        this.tlvListComponent?.reset();
        this.udhListComponent?.reset();
        this.connections?.reset();
        this.countries?.reset();
        this.model.commentText = '';
        this.model.dcs = -1;
        this.model.ton = -1;
        this.model.pid = null;
        this.model.npi = -1;
        this.model.ttl_min = this.user.testTtlMin;
        this.model.ttl = this.user.testTtlMin * 60;
        this.model.validityPeriod = null;
        this.model.scheduleDeliveryTime = null;
        this.model.priority = LiveNumberTestingService.DEFAULT_PRIORITY;
        this.model.replaceIfPresent = false;
        this.model.textAsTlv = false;
        this.model.udh = [];
        this.model.tlv = [];
        this.model.udhDtos = [];
        this.model.tlvDtos = [];
        this.testCaseTemplateId = null;
        this.calculateCountResults();
    }

    resetAllSelectedValues(): void {
        this.contentTemplates.reset();
        this.reset();
    }

    ngOnDestroy() {
        if (this.schedullerUpdate) {
            clearInterval(this.schedullerUpdate);
        }
    }

    loadTestCase(testGroupId, testRepeatsPerIteration = 1) {
        this.loading = true;
        forkJoin([
            this.liveNumberTesting.testGroupInfo(testGroupId),
            this.liveNumberTesting.oneTestGroup(testGroupId)
        ]).subscribe(_ => {
            const info: TestGroupInfo = _[0];
            const group: TestGroup = _[1];
            group.amountX = testRepeatsPerIteration;
            group.testRepeatsPerIteration = testRepeatsPerIteration;
            this.setTestGroup(group, info);
        }, err => {
            this.notificationService.error({
                title: 'Live number testing',
                message: 'An error occurred while loading test group',
                service: 'LNT',
                requestMessage: err.statusText,
                requestCode: err.status,
                ts: err.timestamp ? err.timestamp : null
            });
        }, () => this.loading = false);
    }


    setTestGroup(group: TestGroup, info: TestGroupInfo) {
        this.reset();
        this.contentTemplates.setContentTexts(this.extractContentTexts(info));
        info.supplierSessionsIds.forEach(_ => this.connections.selectByParams(_.sessionId, _.supplierId));
        info.destinations.forEach(_ => {
            if (_.mcc === null && _.mnc === null) {
                this.countries.createPhonenumber(_.phone);
            } else {
                this.countries.selectByParams(_.mcc, _.mnc, _.originalMnc ? _.originalMnc : null);
            }
        });
        this.countries.onChange.emit();
        if (group.encodingId !== null) {
            this.model.dcs = group.encodingId;
            this.form.controls.dcs.patchValue(this.model.dcs);
        }

        if (group.srcNpi !== null) {
            this.model.npi = group.srcNpi;
            this.form.controls.npi.patchValue(this.model.npi);
        }

        if (group.protocolId !== null) {
            this.model.pid = group.protocolId;
            this.form.controls.pid.patchValue(this.model.pid);
        }

        if (group.srcTon !== null) {
            this.model.ton = group.srcTon;
            this.form.controls.ton.patchValue(this.model.ton);
        }

        if (group.validityPeriod !== null) {
            this.model.validityPeriod = group.validityPeriod
            this.form.controls.validityPeriod.patchValue(this.model.validityPeriod)
        }

        if (group.scheduleDeliveryTime !== null) {
            this.model.scheduleDeliveryTime = group.scheduleDeliveryTime
            this.form.controls.scheduleDeliveryTime.patchValue(this.model.scheduleDeliveryTime)
        }

        if (group.replaceIfPresent !== null) {
            this.model.replaceIfPresent = group.replaceIfPresent
            this.form.controls.replaceIfPresent.patchValue(this.model.replaceIfPresent)
        }

        const priority = group.priority ? group.priority : LiveNumberTestingService.DEFAULT_PRIORITY;
        this.model.priority = priority;
        this.form.controls.priority.patchValue(priority);

        if (group.textAsTlv !== null) {
            this.model.textAsTlv = group.textAsTlv
            this.form.controls.textAsTlv.patchValue(this.model.textAsTlv)
        }

        if (group.tlvDtos.length) {
            this.model.tlvDtos = group.tlvDtos;
        }

        if (group.udhDtos.length) {
            this.model.udhDtos = group.udhDtos;
        }

        if (group.commentText) {
            this.model.commentText = group.commentText;
            this.form.controls.commentText.patchValue(group.commentText);
        }

        const ttl = (group.ttl && group.ttl > 59) ? group.ttl : this.user.testTtlMin;
        this.model.ttl_min = ttl / 60;
        this.form.controls.ttl_min.patchValue(this.model.ttl_min);

        const testRepeatsPerIteration = (group.testRepeatsPerIteration && group.testRepeatsPerIteration > 1) ? group.testRepeatsPerIteration : 1;
        this.model.testRepeatsPerIteration = testRepeatsPerIteration;
        this.form.controls.testRepeatsPerIteration.patchValue(testRepeatsPerIteration);

        this.calculateCountResults();
    }

    private extractContentTexts(info: TestGroupInfo): ContentText[] {
        if (info.contentTexts && info.contentTexts.length) {
            return info.contentTexts;
        }

        // Necessary for backward compatibility ...
        if (info.senders.length && info.dynamicTexts.length) {
            let contentTexts: ContentText[] = [];
            const addTemplate = (sender: string, text: TelqText) => {
                contentTexts.push({
                    custom: true,
                    senderId: sender,
                    text: text.text,
                    telqIdCase: text.telqIdCase,
                    telqIdLength: text.telqIdLength,
                    telqIdType: text.telqIdType
                });
            };
            if (info.senders.length === info.dynamicTexts.length) {
                info.senders.reverse().forEach((sender, i) => {
                    let text = info.dynamicTexts[i];
                    addTemplate(sender, text);
                });
            } else {
                info.senders.forEach((sender) => {
                    info.dynamicTexts.forEach((text) => {
                        addTemplate(sender, text);
                    });
                });
            }
            return contentTexts;
        }

        return [];
    }

    setTemplateTestCaseId(id, check = true, testRepeatsPerIteration = 1) {
        if (check) {
            let testCaseTemplates = this.templatesList.filter(_ => _.id === id);
            if (!testCaseTemplates.length) {
                return;
            }
        }

        this.testTemplateService.one(id).subscribe(_ => {
            const exists = this.templatesList.find(_ => _.id === id);
            if (!exists) {
                this.templatesList.unshift({ ..._, isDefault: true });
            }
            this.templateSaveForm.get('templateTitle').setValue(_.title);
            this.testCaseTemplateTitle = _.title;
            let group = this.testTemplateService.testCaseTemplateConvertTestGroup(_);
            group.amountX = testRepeatsPerIteration;
            group.testRepeatsPerIteration = testRepeatsPerIteration;
            this.setTestGroup(
                group,
                this.testTemplateService.testCaseTemplateConvertTestGroupInfo(_)
            );
            this.testCaseTemplateId = id;
        });
    }

    onClickTestCaseTemplateSave(dialogRef?: DialogRef) {
        let testCaseTemplates = this.templatesList.filter(_ => _.id === this.testCaseTemplateId);
        if (!testCaseTemplates.length) {
            return;
        }
        this.loading = true;
        this.onAfterSaveEmmit = false;
        this.testTemplateService.saveTestGroup(this.createModel(false), testCaseTemplates[0]).subscribe(_ => {
            this.loading = false;
            this.onAfterSaveEmmit = true;
            this.updateTestTemplates();
            this.notificationService.success('Test template updated', 'Test templates');
            dialogRef?.close();
            this.testCaseTemplateTitle = testCaseTemplates[0].title;
            this.testCaseTemplateId = testCaseTemplates[0].id;
        });
    }

    saveTestCaseTemplate(title, schedulerTemplate) {
        let model = this.createModel();
        return this.testTemplateService.saveTestGroup(model, { id: null, title: title, schedulerTemplate: schedulerTemplate });
    }

    onTestTemplateKeyUp(): void {
        this.isTemplateNameDuplicate = false;
    }

    onClickTestCaseTemplateSaveAs() {
        // const currentTestTemplateInUse: TestCaseTemplate = this.templatesList.find(_ => _.id === this.testCaseTemplateId);
        // this.templateSaveForm.get('templateTitle').setValue(currentTestTemplateInUse?.title);
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.templateSaveModalTpl).open();
    }

    updateTestTemplates(returnObservable = false): any {
        let params = new AllRequestParams();
        params.page = 0;
        params.size = 30;
        params.setSort('id', 'desc');
        const success = (templates: TestCaseTemplatesCollection) => {
            this.templatesList = [];
            templates.content.map(_ => this.templatesList.push(_));
            return templates;
        };
        if (returnObservable) {
            return this.testTemplateService.allLntTemplates(params).pipe(map(success));
        }
        this.testTemplateService.allLntTemplates(params).subscribe(success);
    }

    onTemplateSaveFormCancel(dialogRef: any): void {
        this.templateSaveForm.get('templateTitle').reset();
        this.templateSaveForm.get('templateTitle').setValue('');
        this.isTemplateNameDuplicate = false;
        dialogRef.close();
    }

    isTemplateSaveValid(): boolean {
        return this.templateSaveForm.valid;
    }

    onSubmitTemplateSave(dialogRef: any): void {
        const templateTitle = this.templateSaveForm.get('templateTitle').value;
        this.loading = true;
        let params = new AllRequestParams();
        params.page = 0;
        params.size = 5;
        params.search = templateTitle;
        this.testTemplateService.all(params).subscribe(collection => {
            const existingTitles = collection.content.map(template => template.title);
            const isUnique = existingTitles.filter(t =>
                t.toLowerCase() === templateTitle.toLowerCase()
            ).length === 0;
            if (isUnique) {
                this.isTemplateNameDuplicate = false;
            } else {
                this.isTemplateNameDuplicate = true;
                this.loading = false;
                return;
            }
            this.onAfterSaveEmmit = false;
            const createdModel: CreateTestGroup = this.createModel(false);
            this.testTemplateService.saveTestGroup(createdModel, { id: null, title: templateTitle }).subscribe(_ => {
                this.loading = false;
                dialogRef.close(true);
                this.onAfterSaveEmmit = true;
                this.testCaseTemplateId = _.id;
                this.testCaseTemplateTitle = _.title;
                this.updateTestTemplates();
                this.templateSaveForm.get('templateTitle').reset();
                this.notificationService.success('Test template created', 'Test templates');
            }, err => {
                this.notificationService.error({
                    title: 'Live number testing',
                    message: 'An error occurred while creating/updating a text template',
                    service: 'LNT',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.loading = false;
                this.templateSaveForm.get('templateTitle').reset();
                dialogRef.close(true);
            });
        })
    }

    openTestSettingsModal(): void {
        const settingsModalReference = this.modal.alert().component(this.testSettingsModalTpl).open();
        settingsModalReference.result.then(result => {
            if (result) {
                this.model.udh = [];
                this.model.tlv = [];
                this.udhListComponent.getModels().map(_ => this.model.udh.push(_));
                this.tlvListComponent.getModels().map(_ => this.model.tlv.push(_));
            } else {
                this.model.udh = [];
                this.model.tlv = [];
            }
        });
    }

    onClickUdhTab(): void {
        this.udhListComponent.reset();
        if (this.model.udhDtos?.length > 0) {
            this.model.udhDtos.map(_ => {
                this.udhListComponent?.addControls({
                    tagHex: _.tagHex,
                    valueHex: _.valueHex
                });
            });
        } else {
            this.model.udh?.map(_ => {
                this.udhListComponent?.addControls({
                    tagHex: _.tagHex,
                    valueHex: _.valueHex
                });
            });
        }
    }

    onClickTlvTab(): void {
        this.tlvListComponent.reset();
        if (this.model.tlvDtos?.length > 0) {
            this.model.tlvDtos.map(_ => {
                this.tlvListComponent?.addControls({
                    tagHex: _.tagHex,
                    valueHex: _.valueHex
                });
            });
        } else {
            this.model.tlv?.map(_ => {
                this.tlvListComponent?.addControls({
                    tagHex: _.tagHex,
                    valueHex: _.valueHex
                });
            });
        }
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : 'contentTexts';
    }

    onConfirmSwipe(event: unknown) {
        this.confirmModal.close(true);
    }

    scrollTo(section) {
        this.currentSection = section;
        document.querySelector('#' + section)
            .scrollIntoView();
    }
}
