
import {Component, Output, EventEmitter, Input, OnInit} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import {PhoneReportService} from "../../shared/services/phone-report.service";
import {PhoneReportCreate} from "../../shared/models/phone-report.model";

@Component({
    selector: 'app-phone-reports-form',
    templateUrl: './phone-reports-form.component.html'
})

export class PhoneReportsFormComponent implements OnInit {

    @Output() onAfterSave = new EventEmitter();
    @Input() model: PhoneReportCreate;

    issues = [];
    loading = false;
    backButtonShow = false;

    form: FormGroup;

    constructor(
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: PhoneReportService,
        public validationService: ValidationService
    ) {
        this.model = service.createModel();
        for(let i in service.issues) {
            this.issues.push({id: i, label: service.issues[i]});
        }
        this.form = formBuilder.group({
            issue: ['', Vld.compose([Vld.required])],
            phone: ['', Vld.compose([Vld.required, Vld.globalPhoneNumber()])],
            description: ['', Vld.compose([Vld.maxLength(255)])]
        });
    }

    ngOnInit() {
        this.model.issue = this.issues[0].id;
        this.form.controls.issue.patchValue(this.issues[0].id);
    }

    copyPhoneNumberToClipboard(): void {
        const phoneNumber = document.getElementById('phoneNumber').innerText;
        navigator.clipboard.writeText(phoneNumber)
            .then(() => console.log('Copied to clipboard'))
            .catch(err => console.error('Error copying to clipboard:', err));
    }

    onSubmit() {
        this.loading = true;
        if (this.model.description) {
            this.model.description = this.model.description.trim();
        }
        this.service.create(this.model)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('Report created', 'Phone reports');
                this.onAfterSave.emit(res);
            }, (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Phone reports',
                    message: 'An error occurred while creating report',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
    }
}
