
import { Pipe } from '@angular/core';

@Pipe({ name: 'filterText' })

export class FilterTextPipe  {

    transform(value: any[], term: string = '', properties: string[] = []): any[] {
        term = term.trim().toLowerCase();
        if (!term) {
            return value;
        }
        let stringSearchProperty = properties.length === 1 ? properties[0] : '';
        return value.filter(item => {
            let res = stringSearchProperty ? this.searchInString(item[stringSearchProperty], term) : this.searchInObject(item, term, properties);
            item._filterTextResult = res;
            return res;
        });
    }

    searchInObject(obj: Object, term: string, properties: string[] = []): boolean {
        for (let key in obj) {
            if (properties.length && properties.indexOf(key) === -1) {
                continue;
            }
            let val = obj[key];
            if (this.isString(val) && this.searchInString(val, term)) {
                return true;
            }
            if (this.isObject(val) && this.searchInObject(val, term)) {
                return true;
            }
        }

        return false;
    }

    searchInString(str: string, term: string): boolean {
        return str.toLowerCase().indexOf(term) !== -1;
    }

    isObject(val: any): boolean  {
        return typeof val === 'object';
    }

    isString(val: any): boolean  {
        return typeof val === 'string' || val instanceof String;
    }
}