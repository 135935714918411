import {Injectable, TemplateRef} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class ModalService {

    public modals: Subject<DialogRef> = new Subject<DialogRef>();

    alert() {
        return new DialogRef(this, 'alert');
    }

    confirm(title = 'Are you sure?', text = 'You won\'t be able to revert this.') {
        return (new DialogRef(this, 'confirm')).dialogClass('modal-dialog small-modal').title(title).body(text);
    }
}

interface DialogContext {
    type: 'alert' | 'confirm',
    title?: string,
    body?: string,
    component?: TemplateRef<any>,
    size?: string,
    dialogClass?: string,
    isBlocking: boolean
}

export class DialogRef {

    private readonly context: DialogContext;

    onDestroy = new Subject();

    private resultResolve;
    result: Promise<any> = new Promise((resolve) => {
        this.resultResolve = resolve;
    });

    constructor(readonly service: ModalService, type: 'alert' | 'confirm') {
        this.context =  {
            type: type,
            isBlocking: false
        };
    }

    title(title: string) {
        this.context.title = title;
        return this;
    }

    body(body: string) {
        this.context.body = body;
        return this;
    }

    size(size: string) {
        this.context.size = size;
        return this;
    }

    dialogClass(dialogClass: string) {
        this.context.dialogClass = dialogClass;
        return this;
    }

    component(template: TemplateRef<any>) {
        this.context.component = template;
        return this;
    }

    open() {
        this.service.modals.next(this);
        return this;
    }

    isBlocking(blocking: boolean) {
        this.context.isBlocking = blocking;
        return this;
    }

    close(result: any = true) {
        this.resultResolve(result);
        this.onDestroy.next(null);
    }

    getContext() {
        return this.context;
    }
}