import { EventEmitter, Inject, Injectable } from "@angular/core";
import { LocalStorageService } from "./localStorage.service";
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from "rxjs";
import { BrowserUtils } from "./browser-utils";
import { RestUtils } from "./rest-utils";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { resolve } from "@angular/compiler-cli";

export type BackendChannelType = 'main'|'proxy';
export type BackendChannelConnectStatus = 'connecting'|'reconnecting'|'connected'|'failed';
interface ChannelDto {
    channel: BackendChannelType,
    version: string,
    createdAt: number,
    resetTag?: number
}
interface ConnectStatus {
    status: BackendChannelConnectStatus,
    channel: BackendChannelType
}
@Injectable()
export class BackendChannelService {

    $connectStatus = new Subject<ConnectStatus>();

    $current = new BehaviorSubject<BackendChannelType>('proxy');
    private readonly DEFAULT = 'proxy'

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(
        @Inject('API_BASE_URL') private baseUrl: string,
        @Inject('PROXY_API_BASE_URL') private proxyBaseUrl: string,
        @Inject('BACKEND_CHANNEL_RESET_TAG') private resetTag: number,
        private storage: LocalStorageService,
        private http: HttpClient
    ) {
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.$current.next(this.getChannelType());
        this.$connectStatus.subscribe(s => this.renderStatus(s));
    }

    init(): Promise<boolean> {
        return new Promise((resolve) => {
            let pingRequest: Subscription;
            let check = () => {
                if (pingRequest && !pingRequest.closed) {
                    pingRequest.unsubscribe();
                    this.$connectStatus.next({status: 'reconnecting', channel: this.$current.value});
                }
                pingRequest = this.testPing().pipe(
                    catchError((e: HttpErrorResponse) => {
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.$connectStatus.next({status: 'connected', channel: this.$current.value});
                    clearInterval(pingInterval);
                    resolve(true);
                });
            };
            const pingInterval = setInterval(check, 15 * 1000);
            this.$connectStatus.next({status: 'connecting', channel: this.$current.value});
            check();
        });
    }

    testPing(): Observable<void> {
        let url = this.utils.buildUrl('guest/usr/ping');
        let options = this.utils.getHttpHeaderOptions(this.headers, false);
        return this.http.get<void>(url, options);
    }

    getBaseUrl(type: 'main'|'proxy') {
        if (type === 'main') {return this.baseUrl;}
        if (type === 'proxy') {return this.proxyBaseUrl;}
    }

    prepareUrl(url: string): string {
        return url.replace('API_BASE_URL', this.getBaseUrl(this.$current.value));
    }

    switchType(type: BackendChannelType) {
        const state: ChannelDto = {
            channel: type,
            version: BrowserUtils.getCurrentVersion(),
            createdAt: Date.now(),
            resetTag: this.resetTag
        };
        this.storage.set('backend-channel', state);
        this.$current.next(type);
    }

    private getChannelType(): BackendChannelType {
        const channelDto = this.storage.get('backend-channel') as ChannelDto;
        if (channelDto) {
            const dtoResetTag = channelDto.resetTag ? channelDto.resetTag : 0;
            if (this.resetTag > dtoResetTag) {
                this.storage.remove('backend-channel');
                return this.DEFAULT;
            }
            switch (channelDto.channel) {
                case 'main':
                    return 'main';
                case 'proxy':
                    return 'proxy';
            }
        }

        return this.DEFAULT;
    }

    private renderStatus(s: ConnectStatus) {
        let widget = document.getElementById('backend-channel-connect-status');
        if (!widget) {
            widget = document.createElement('div');
            widget.id = 'backend-channel-connect-status';
            let alert = document.createElement('div');
            alert.classList.add('alert');
            widget.appendChild(alert);
            document.body.appendChild(widget);
        }
        let statusText: { [key in BackendChannelConnectStatus]: string } = {
            connecting: `Connecting to server [${s.channel}]...`,
            reconnecting: `Reconnecting to server [${s.channel}]...`,
            failed: '',
            connected: `Connected to server [${s.channel}]`
        };
        widget.getElementsByClassName('alert').item(0).textContent = statusText[s.status];
        if (s.status === 'connected') {
            setTimeout(() => widget.remove(), 500);
            return;
        }
    }
}