import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-mo-shortcode-update',
    templateUrl: './mo-shortcode-update.component.html',
    styleUrls: ['./mo-shortcode-update.component.scss']
})
export class MoShortcodeUpdateComponent {

    loading = false;

    constructor(
        public userService: UsersService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title,
    ) {
        titleService.setTitle('Update short code');
    }

    afterSave() {
        this.router.navigate(['/mo-testing/short-codes']).then();
    }

}
