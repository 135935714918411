
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin } from "rxjs";
import { TableBase } from "../../../shared/components/table/table-base";
import { ActionEvent, CellClickEvent, ColumnDef, ColumnTypes } from "../../../shared/components/table/table.component";
import { PricingGroupCollection, Tier } from "../../../shared/models/pricing.model";
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { AllRequestParams, PricingService } from "../../../shared/services/pricing.service";
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'app-special-pricing-group-table',
    templateUrl: './special-pricing-group-table.component.html',
    styleUrls: ['./special-pricing-group-table.component.scss'],
})

export class SpecialPricingGroupTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    isAdmin: boolean = false;
    requestParams = new AllRequestParams();

    constructor(
        public notificationService: NotificationService,
        public service: PricingService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id', sort: false, cellClassName: 'w-xxs' },
            { title: 'Company name', prop: 'companyName', sort: false, cellClassName: 'w-sm' },
            { title: 'Customers', prop: 'users', format: this.formatUsers, sort: false, cellClassName: 'w-md' },
            { title: 'Type', prop: 'paymentType', sort: false, cellClassName: 'w-sm' },
            { title: 'Title', prop: 'title', cellClassName: 'editable', sort: false },
            { title: 'Products active', prop: 'serviceList', sort: false, format: this.formatServices, cellClassName: 'w-sm' },
            { title: 'Monthly commitment/top up (eur)', prop: 'totalCommitment', sort: false, format: this.formatTotalCommitment, cellClassName: 'w-sm' },
            { title: 'Created on', prop: 'createdAt', sort: false, format: row => this.formatDate(row.createdAt) },
            { title: 'Last modified on', prop: 'lastModified', sort: false, format: row => this.formatDate(row.lastModified) },
            { title: 'Status', prop: 'status', cellClassName: 'x-sm', sort: false },
            { title: 'Deactivate/ revive offer', prop: 'enabled', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'w-xxs', sort: false },
            { title: 'New pricing scheduled', prop: 'scheduledChange', cellClassName: 'w-xxs', format: this.displayScheduleChange, sort: false }
        ]));
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit' },
            { icon: 'far fa-trash-alt', name: 'delete', title: 'Hide' }
        ];
        this.loading = false;
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onChangeSize(size: number) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service.allSpecialGroups(this.requestParams).subscribe((collection: PricingGroupCollection) => {
            this.setData(this.createRows(collection.content), collection.totalElements);
            this.loading = false;
        }, (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'Special pricing',
                message: 'An error occurred while loading the pricing group',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        })
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'title') {
            const positionRow = this.rows.indexOf(event.row);
            const nextRow = positionRow + 1;
            const isOpen = typeof this.rows[nextRow] !== 'undefined' && this.rows[nextRow].tables;
            if (isOpen) {
                this.rows = this.rows.filter((el, index) => index !== nextRow)
                return;
            }
            const gId = event.row.data.id;

            const columnsBundle: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'serviceType', sort: false },
                { title: 'Rate per product/test (EUR)', prop: 'ratePerUnit', sort: false },
                { title: 'Top up value (EUR)', prop: 'commitment', sort: false },
                { title: 'Pricing visible from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom), sort: false },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil), sort: false },
                { title: 'Purchased credit/access available until', prop: 'validForSeconds', format: row => PricingService.formatValidForSeconds(row.validForSeconds ? row.validForSeconds : null) },
            ]);
            const columnsTier: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'serviceType', sort: false },
                { title: 'Rate per product/test (EUR)', prop: 'ratePerUnit', sort: false },
                { title: 'Quota', prop: 'quota', sort: false },
                { title: 'Commitment  (EUR)', prop: 'commitment', sort: false },
                { title: 'Valid from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom) },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil) }
            ]);
            const columnsAnalytics: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'rate', format: () => 'Analytics' },
                { title: 'Rate per product (EUR)', prop: 'rate' },
                { title: 'Valid from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom) },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil) }
            ]);

            if (event.row.data.paymentType === 'PREPAID') {
                this.loading = true;
                forkJoin([this.service.bundles(gId), this.service.analyticsTariffs(gId, 'special')]).subscribe({
                    next: results => {
                        this.loading = false;
                        let row = this.createRow({});
                        let tables = [{
                            columns: columnsBundle,
                            rows: this.createRows(results[0])
                        }];
                        if (results[1] && results[1].length) {
                            tables.push({
                                columns: columnsAnalytics,
                                rows: this.createRows(results[1])
                            });
                        }
                        row.tables = tables;
                        this.rows.splice(positionRow + 1, 0, row);
                    },
                    error: err => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Special pricing',
                            message: 'An error occurred while loading the bundles',
                            serviceName: 'GATEWAY',
                            requestMessage: err.statusText,
                            requestCode: err.status
                        });
                    }
                });
            }
            if (event.row.data.paymentType === 'POSTPAID') {
                this.loading = true;
                forkJoin([this.service.tiers(gId), this.service.analyticsTariffs(gId, 'special')]).subscribe({
                    next: results => {
                        this.loading = false;
                        let tiers = this.sortTiers(results[0]);
                        let row = this.createRow({});
                        let tables = [{
                            columns: columnsTier,
                            rows: this.createRows(tiers)
                        }];
                        if (results[1] && results[1].length) {
                            tables.push({
                                columns: columnsAnalytics,
                                rows: this.createRows(results[1])
                            });
                        }
                        row.tables = tables;
                        this.rows.splice(positionRow + 1, 0, row);
                    },
                    error: err => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Special pricing',
                            message: 'An error occurred while loading the tiers',
                            serviceName: 'GATEWAY',
                            requestMessage: err.statusText,
                            requestCode: err.status
                        });
                    }
                });
            }
        }
    }

    private sortTiers(tiers: Tier[]): Tier[] {
        let sortedTiers: Tier[] = [];
        let tiersByService: {[serviceType: string]: Tier[]} = {};
        tiers.forEach(t => {
            if (!tiersByService[t.serviceType]) {
                tiersByService[t.serviceType] = [];
            }
            tiersByService[t.serviceType].push(t);
        });
        for (let serviceType in tiersByService) {
            tiersByService[serviceType].sort((a, b) => {
                if (a.quota > b.quota) { return 1; }
                if (a.quota < b.quota) { return -1; }
                return 0;
            });
            tiersByService[serviceType].forEach(_ => sortedTiers.push(_));
        }

        return sortedTiers;
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'switch' && event.column.prop === 'enabled') {
            const oldValue = !event.row.data.enabled;
            event.row.data.enabled = !oldValue;
            this.service
                .changeEnabledGroup(event.row.data.id, event.row.data.enabled)
                .subscribe(
                    () => this.notificationService.success('Pricing group updated', 'General pricing'),
                    error => {
                        this.notificationService.error({
                            title: 'Special pricing',
                            message: 'Pricing group updating an error',
                            serviceName: 'GATEWAY',
                            requestMessage: error.statusText,
                            requestCode: error.status
                        });
                        event.row.data.enabled = oldValue;
                    }
                );
        }

        this.actions.emit(event);
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    delete(group) {
        this.loading = true;
        this.service
            .deleteSpecialGroup(group.id)
            .subscribe(() => {
                this.notificationService.success('Pricing group removed', 'Special pricing');
                this.onAfterDelete.emit();
                this.update();
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Special pricing',
                    message: 'Pricing group deleting an error',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
    }

    formatTotalCommitment(row) {
        if (!row) {
            return '';
        }
        const pt = row.paymentType === 'PREPAID' ? 'top up' : 'commitment';
        return row.totalCommitment + ' EUR ' + pt;
    }

    formatServices(row): string {
        return row.serviceList.join(', ');
    }

    formatUsers(row): string {
        return row.users.map(_ => _.email).join(', ');
    }

    formatDate(timestamp) {
        if (timestamp === null) {
            return '';
        }
        return this.datePipe.transform(new Date(timestamp), 'MMM d, y');
    }

    displayScheduleChange(row) {
        return row.scheduledChange ? 'Yes' : 'No';
    }
}
