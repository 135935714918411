import {Component, Inject, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-token',
    templateUrl: './token.component.html'
})
export class TokenComponent implements OnInit {

    errorMessage = '';

    constructor(
        title: Title,
        public router: Router,
        public route: ActivatedRoute,
        public userService: UsersService,
        public notificationService: NotificationService,
    ) {
        title.setTitle('Check token');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            let userId = parseInt(params['userId']);
            let token = params['token'];
            this.userService.verifyToken(token, userId).subscribe(() => {
                this.notificationService.success('Your account is activated', 'Registration');
                this.router.navigate(['/login']).then();
            }, res => {
                this.errorMessage = 'Token is invalid or has expired';
            });
        });
    }
}