import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import { ActionEvent, ColumnTypes, SortEvent } from '../../../shared/components/table/table.component';
import {
    AppscriptsPaymentMethodParams,
    AppscriptsPaymentMethodService
} from '../../../shared/services/appscripts-payment-method.service';
import { UsersService } from '../../../shared/services/users.service';
import {ModalService} from "../../../shared/services/modal.service";

@Component({
    selector: 'app-appscripts-payment-method-table',
    templateUrl: 'appscripts-payment-method-table.component.html'
})

export class AppscriptsPaymentMethodTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<any> = new EventEmitter();

    requestParams = new AppscriptsPaymentMethodParams();
    storageContextName = 'appscripts-payment-method-table'

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsPaymentMethodService,
        public userService: UsersService,
        public modal: ModalService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id', sort: false },
            { title: 'Name', prop: 'name', sort: false },
            { title: 'Enabled', prop: 'enabled', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: false },
            { title: 'Autopay', prop: 'withdrawalAvailable', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: false }
        ]));
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit' },
            { icon: 'far fa-trash-alt', name: 'delete' }
        ]
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            }).catch(_ => { });
        }

        if (event.name === 'switch') {
            const data = event.row.data;
            this.service.save(data).subscribe({
                next: () => {
                    this.notificationService.success('Payment method updated', 'Modems');
                    this.loading = false;
                },
                error: err => {
                    this.notificationService.error('Payment method an error', 'Modems');
                    this.loading = false;
                }
            });
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.service.all(this.requestParams).subscribe({
            next: rows => {
                this.currentSize = rows.length;
                this.setData(this.createRows(rows), rows.length);
                this.loading = false;
            },
            error: () => this.loading = false
        });
    }

    delete(paymentMethod) {
        this.loading = true;
        this.service.delete(paymentMethod.id).subscribe({
            next: res => {
                this.update();
                this.notificationService.success('Payment method removed', 'Appscripts');
                this.onAfterDelete.emit(res)
            },
            error: () => this.loading = false
        });
    }
}
