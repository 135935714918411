import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges, TemplateRef
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticsService } from '../../shared/services/statistics.service';
import * as Highcharts from 'highcharts';
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { ModalService } from "../../shared/services/modal.service";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";
import { AllProfitDetails } from "../../shared/models/statistics.model";
import { FormControl } from "@angular/forms";
import { SettingService } from '../../shared/services/setting.service';
declare var moment: any;

@Component({
    selector: 'app-dashboard-profit-widget',
    templateUrl: './dashboard-profit-widget.component.html',
    styleUrls: ['dashboard-profit-widget.component.scss', '../shared.scss']
})

export class DashboardProfitWidgetComponent implements AfterViewInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('dailyProfitChartContainer', { static: false }) dailyProfitChartContainer;
    @ViewChild('profitDetailStatisticPeriodModalTpl', { read: TemplateRef, static: false }) profitDetailStatisticPeriodModalTpl: any;

    @ViewChild('chartContainer', { static: false }) chartContainer;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options = {
        title: { text: 'Profit', x: -20, style: { display: 'none' } },
        chart: { height: 190, style: { fontFamily: StatisticsService.chartFontFamily } },
        colors: StatisticsService.colors,
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            tickWidth: 0,
            allowDecimals: true,
            crosshair: StatisticsService.chartCrosshair
        },
        yAxis: [{ title: { text: null } }],
        tooltip: StatisticsService.chartTooltip,
        legend: { enabled: false },
        series: [{ type: 'line', name: 'Profit', data: [] }],
        credits: { enabled: false },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.3,
            }
        }
    };

    profitDetailStatisticPeriodDates = null;
    profitDetailStatisticPeriodRows: AllProfitDetails[] = [];
    profitDetailStatisticPeriodLoading = false;
    profitDetailStatisticPeriodSum = 0;
    profitDetailStatisticPeriodSort = '';
    profitDetailStatisticPeriodSearch = ''

    @Input() from: string;
    @Input() to: string;

    loading = false;

    private request: Subscription;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    @HostListener('window:resize') onResize() { this.resize(); }
    resize() {
        if (!this.chartContainer || !this.chart) { return; }
        this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, <number>this.chartOptions.chart.height);
    }

    constructor(
        public statistics: StatisticsService,
        public notificationService: NotificationService,
        public modal: ModalService,
        private localStorage: LocalStorageService,
    ) {
        this.profitDetailStatisticPeriodSort = localStorage.get('db_profit_sort', '-profit');
        this.profitDetailStatisticPeriodDates = [
            moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
            moment().endOf('month').format('YYYY-MM-DDT23:59:59')
        ];
    }

    ngAfterViewInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
        this.resize();
        this.update();
    }

    update() {
        this.loading = true;
        if (this.request && !this.request.closed) { this.request.unsubscribe(); }
        this.request = this.statistics.dailyProfit().subscribe({
            next: items => {
                this.loading = false;
                if (!this.chart) { return; }
                this.chart.hideLoading();
                if (items.length) {
                    this.chart.update({
                        xAxis: { tickInterval: 24 * 2600 * 1000, labels: { step: 1 } },
                        series: [
                            {
                                type: 'areaspline', data: items.map(_ => {
                                    return [Math.floor(_.startAt.getTime()), _.profit];
                                })
                            },
                        ]
                    });
                } else {
                    this.chart.showLoading('No data');
                }
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Dashboard',
                    message: 'An error occurred while loading statistics',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                this.chart.showLoading('Error on loading');
            }
        });
    }

    updateProfitDetailStatistics() {
        this.profitDetailStatisticPeriodLoading = true;
        const start = this.profitDetailStatisticPeriodDates !== null ? this.profitDetailStatisticPeriodDates[0] : '';
        const end = this.profitDetailStatisticPeriodDates !== null ? this.profitDetailStatisticPeriodDates[1] : '';
        this.statistics.profitDetails(start, end).subscribe((rows) => {
            this.profitDetailStatisticPeriodLoading = false;
            this.profitDetailStatisticPeriodSum = rows.length ? parseFloat(rows.map(_ => _.profit).reduce((sum, _) => sum + _).toFixed(3)) : 0;
            this.profitDetailStatisticPeriodRows = rows.map(_ => {
                _.profit = _.profit ? parseFloat(_.profit.toFixed(3)) : 0;
                return _;
            });
        }, (error) => {
            this.profitDetailStatisticPeriodLoading = false;
            this.notificationService.error({
                title: 'Dashboard',
                message: 'An error occurred while loading profit details',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        });
    }

    onClickButtonOpenProfitDetail() {
        this.modal.alert().isBlocking(false)
            .dialogClass('modal-dialog full-modal')
            .component(this.profitDetailStatisticPeriodModalTpl)
            .open()
            .onDestroy.subscribe(() => {
                this.profitDetailStatisticPeriodDates = [
                    moment().startOf('month').format("YYYY-MM-DDT00:00:00"),
                    moment().endOf('month').format("YYYY-MM-DDT23:59:59")
                ];
            });
        this.updateProfitDetailStatistics();
    }

    onClickButtonExportProfitDetail() {
        const start = this.profitDetailStatisticPeriodDates !== null ? this.profitDetailStatisticPeriodDates[0] : '';
        const end = this.profitDetailStatisticPeriodDates !== null ? this.profitDetailStatisticPeriodDates[1] : '';
        this.profitDetailStatisticPeriodLoading = true;
        this.statistics.profitDetailsExport(start, end).subscribe(blob => {
            const url = window.URL.createObjectURL(blob);
            this.profitDetailStatisticPeriodLoading = false;
            window.open(url);
        }, (error) => {
            this.profitDetailStatisticPeriodLoading = false;
            this.notificationService.error({
                title: 'Dashboard',
                message: 'An error occurred while export profit details',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        });
    }

    onChangeDateProfitDetailStatistic(dates) {
        this.profitDetailStatisticPeriodDates = [dates.startStr, dates.endStr];
        this.updateProfitDetailStatistics();
    }

    setProfitDetailStatisticPeriodSort(column) {
        let sort = this.profitDetailStatisticPeriodSort;
        if (sort === ('-' + column)) {
            sort = column;
        } else if (sort === column) {
            sort = '-' + column;
        } else {
            sort = column;
        }
        this.profitDetailStatisticPeriodSort = sort;
        this.localStorage.set('db_profit_sort', sort);
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.profitDetailStatisticPeriodSearch = phrase;
    }

    setChart(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        /*if (
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined)
        ) {
            this.update();
        }*/
    }
}
