
import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { catchError } from "rxjs/operators";
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import {
    ScheduledTask,
    ScheduledTaskReportRule,
    ScheduledTasksCollection
} from '../../shared/models/scheduled-task.model';
import { TestCaseTemplate } from '../../shared/models/test-case-template.model';
import { Role } from '../../shared/models/user.model';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, SchedulerService } from '../../shared/services/scheduler.service';
import { TestCaseTemplatesService } from '../../shared/services/test-case-template.service';
import { UsersService } from '../../shared/services/users.service';
import { LntResultsTableComponent } from '../../test/lnt/lnt-results-table/lnt-results-table.component';
import { ExportService } from '../../shared/services/export.service';
declare var moment: any;

@Component({
    selector: 'app-scheduler-table',
    templateUrl: './scheduler-table.component.html',
    styleUrls: ['./scheduler-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SchedulerTableComponent extends TableBase implements OnInit, OnDestroy {

    @Output() actions = new EventEmitter<ActionEvent>();

    @ViewChild(LntResultsTableComponent, { static: false }) results: LntResultsTableComponent;
    @ViewChild('resultsModalTpl', { read: TemplateRef, static: false }) resultsModalTpl: TemplateRef<HTMLTemplateElement>;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: TemplateRef<HTMLTemplateElement>;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;

    requestParams = new AllRequestParams();
    exportSize: string | number = 'Custom';
    customExportSize = 1000;
    exportSizes = ['Custom', 'All'];
    exportData = {
        url: undefined,
        size: undefined,
        sizeKb: undefined
    };
    exportSpinner = false;

    currentTask: ScheduledTask;
    currentTaskId: number;
    testCaseTemplate: TestCaseTemplate;
    storageContextName = 'scheduler-schedule-table';


    scheduler: ReturnType<typeof setInterval>;
    settingsLoader = false;

    constructor(
        public notificationService: NotificationService,
        public service: SchedulerService,
        public templateService: TestCaseTemplatesService,
        public userService: UsersService,
        public router: Router,
        public modal: ModalService,
        public cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        private exportService: ExportService
    ) {
        super();
        cdr.detach();
    }

    ngOnInit() {
        this.loading = true;
        let hideColumns = [];
        let columns = [
            { title: 'ID', prop: 'id', sort: true, cellClassName: 'w-sm' },
            { title: 'Name', prop: 'title', cellClassName: 'w-lg' },
            { title: 'Start date', prop: 'startAt', format: _ => this.formatDate(_.startAt), type: ColumnTypes.DATE, cellClassName: 'w-sm' },
            {
                title: 'Finish date', prop: 'finishAt', format: (row) => {
                    if (row.sendOnlyOnce) return '';
                    if (row.runUntilDisabled) return ['Run Until', 'Disabled']
                    return this.formatDate(row.finishAt);
                }, type: ColumnTypes.DATE, cellClassName: 'w-sm'
            },
            { title: 'Test settings', prop: 'showSettings', type: ColumnTypes.HTML, format: _ => '<span>View</span>', cellClassName: 'w-sm editable' },
            { title: 'Tests results', prop: 'showResults', type: ColumnTypes.HTML, format: _ => '<span>View</span>', cellClassName: 'w-sm editable' },
            {
                title: 'Send tests every', prop: 'repeatEvery', format: (_) => {
                    return _.sendOnlyOnce ? 'Sent only once' : this.formatDuration(_);
                }, cellClassName: 'w-md'
            },
            { title: 'Next schedule', prop: 'repeatEvery', format: row => this.formatNextSchedule(row), sort: false, cellClassName: 'w-md' },
            { title: 'Status', prop: 'state', format: this.formatState, sort: false, cellClassName: 'w-sm' },
            {
                title: 'User', prop: 'user', sort: false, format: _ => {
                    return _.user ? (_.user.username ? _.user.username : _.user.email) : 'empty';
                }, cellClassName: 'w-md'
            }
        ]
        this.tableActions = [
            {
                icon: 'icon-show', name: 'visible', show: row => {
                    if (this.requestParams && this.requestParams.hidden && row.state !== 'SCHEDULED') {
                        return true;
                    }
                    return false;
                }, title: 'Visible'
            },
            {
                icon: 'icon-hide', name: 'hidden', show: row => {
                    if (this.requestParams && this.requestParams.hidden) {
                        return false;
                    }
                    return row.state !== 'STOPPED' || row.state !== 'FINISHED';
                }, title: 'Hidden'
            },
            { icon: 'icon-stop', name: 'stop', show: row => row.state === 'WAITING' || row.state === 'SCHEDULED', title: 'Stop', },
            {
                icon: 'icon-play', name: 'schedule', show: row => {
                    if (this.requestParams && this.requestParams.hidden) {
                        return false;
                    }
                    return (row.state === 'PAUSED' || row.state === 'STOPPED') && Date.now() < Date.parse(row.startAt);
                }, title: 'Schedule'
            },
            { icon: 'icon-copy', name: 'clone', title: 'Clone' }
        ];
        this.userService.getAuthUser().then(data => {
            const userRole = data.role;
            const showAllResults = data.showAllResults;
            if (userRole === Role.SUB && !showAllResults) {
                hideColumns.push('User');
            }
            hideColumns = hideColumns.filter((_, index) => hideColumns.indexOf(_) === index);
            const finalColumns = columns.filter(c => hideColumns.indexOf(c.title) === -1);
            this.setColumns(this.createColumns(finalColumns));
            this.requestParams.hidden = this.service.getHiddenMode();
            this.requestParams.ownTasksOnly = this.service.getShowMyTasksFlag();
            this.update();
            this.cdr.detectChanges();
        }).catch(error => {
            this.notificationService.error({
                title: 'Scheduler',
                message: 'An error occurred while displaying task',
                serviceName: 'SCH',
                requestMessage: error.statusText || "",
                requestCode: error.status || "",
                ts: error.timestamp ? error.timestamp : null
            });
        });
        this.scheduler = setInterval(() => {
            this.update(false);
        }, 1000 * 30);
    }

    setHiddenMode(hiddenMode: boolean): void {
        this.requestParams.hidden = hiddenMode;
        this.update();
    }

    setShowMyTasksFlag(showMyTasks: boolean): void {
        this.requestParams.ownTasksOnly = showMyTasks;
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);

        if (['schedule', 'stop', 'hidden'].indexOf(event.name) !== -1) {
            this.loading = true;
            this.service[event.name](event.row.data.id, true).subscribe(() => {
                this.update(true);
            }, error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while update task',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            });
        }

        if (event.name === 'visible') {
            this.loading = true;
            this.service.hidden(event.row.data.id, false).subscribe(() => {
                this.update(true);
            }, error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while update task',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            });
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update(spinner = true) {
        if (spinner) {
            this.loading = true;
            this.cdr.detectChanges();
        }
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service
            .all(this.requestParams)
            .subscribe((collection: ScheduledTasksCollection) => {
                this.setData(this.createRows(collection.content), collection.totalElements);
                this.loading = false;
                this.cdr.detectChanges();
            }, (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while loading tasks',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() {
        if (this.scheduler) {
            clearInterval(this.scheduler);
        }
    }

    formatDate(timestamp) {
        if (!timestamp) {
            return '';
        }
        const timeStamp = moment(timestamp).format('DD/MM/YY HH:mm:ss');
        return timeStamp.split(" ");
    }

    formatDuration(row: ScheduledTask) {
        if (row.sendOnlyOnce) {
            return '';
        }
        if (row.randomized) {
            return `randomized/(${row.repeatsPerDayRandomized} per day)`;
        }
        if (row.repeatEvery) {
            let t = SchedulerService.secondsToHuman(row.repeatEvery);
            const map = {
                d: 'days',
                m: 'minutes',
                h: 'hours',
                s: 'seconds'
            };
            return moment.duration(t.value, map[t.unit]).humanize();
        }
        return ''
    }

    formatNextSchedule(row: ScheduledTask) {
        if (row.state !== 'WAIT' && row.state !== 'SCHEDULED') {
            return '';
        }
        const h = SchedulerService.calculateSchedule(row, 0, 1);
        return h.history.length ? (this.datePipe.transform(h.history[0], 'dd MMM HH:mm')) : '';
    }

    formatState(row: ScheduledTask): string {
        const map = {
            SCHEDULED: 'Scheduled', STOPPED: 'Stopped', FINISHED: 'Finished', PAUSED: 'Paused'
        };
        return map[row.state];
    }

    formatDays(days: string[]): string {
        if (!days || !days.length) {
            return '';
        }
        return SchedulerService.DAYS.map(d => {
            if (days.indexOf(d.value) !== -1) {
                return d.label;
            }
            return false;
        }).filter(_ => _).join(', ');
    }

    formatRule(rule: ScheduledTaskReportRule): string {
        return SchedulerService.formatReportRule(rule);
    }

    onCellClick(event: CellClickEvent) {
        this.currentTask = event.row.data;
        this.currentTaskId = event.row.data.id;
        if (event.column.prop === 'showResults') {
            this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.resultsModalTpl).open();
        }

        if (event.column.prop === 'showSettings') {
            this.settingsLoader = true;
            this.modal.alert().component(this.detailsModalTpl).open();
            forkJoin([
                this.service.one(this.currentTaskId).pipe(catchError((error, caught) => {
                    this.notificationService.error({
                        title: 'Scheduler',
                        message: 'An error occurred while loading task',
                        serviceName: 'SCH',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                    return caught;
                })),
                this.templateService.one(this.currentTask.testCaseTemplateId).pipe(catchError((error, caught) => {
                    this.notificationService.error({
                        title: 'Scheduler',
                        message: 'An error occurred while loading template',
                        serviceName: 'NTC',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                    return caught;
                }))
            ]).subscribe(data => {
                this.settingsLoader = false;
                if (data[0].reportRuleDtos) {
                    this.currentTask.reportRuleDtos = data[0].reportRuleDtos
                }
                this.currentTask.daysOfWeek = data[0].daysOfWeek;
                this.testCaseTemplate = data[1];
                this.currentTask.fromHour = ('' + (<number>this.currentTask.fromHour).toFixed(2)).replace('.', ':');
                if (this.currentTask.fromHour.length === 4) {
                    // For eg: '9:00' should be shown as '09:00' ...
                    this.currentTask.fromHour = '0' + this.currentTask.fromHour;
                }
                if (this.currentTask.fromHour === '0') {
                    this.currentTask.fromHour = '00:00';
                }
                this.currentTask.tillHour = ('' + (<number>this.currentTask.tillHour).toFixed(2)).replace('.', ':');
                if (this.currentTask.tillHour.length === 4) {
                    this.currentTask.tillHour = '0' + this.currentTask.tillHour;
                }
                if (this.currentTask.tillHour === '0') {
                    this.currentTask.tillHour = '00:00';
                }
            });
        }
    }

    onClickExport() {
        this.exportSize = 0;
        this.exportSpinner = false;
        this.exportData = {
            url: undefined,
            size: undefined,
            sizeKb: undefined
        };
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.exportModalTpl).open();
    }

    export(limit) {
        if (limit === 'Custom') limit = this.customExportSize;
        this.exportSpinner = true;
        this.exportService.export('lnt', limit === 'All' ? 0 : limit, { taskIds: [this.currentTask.id] }).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Scheduler',
                message: 'An error occurred while export tests',
                serviceName: 'Scheduler',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }
}
