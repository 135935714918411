import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestUtils } from './rest-utils';
import {
    AnalyticResult,
    StatusStat,
    WizardQuery
} from "../models/analytic.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CustomUtils } from "./custom-utils";
import { LocalStorageService } from "./localStorage.service";
import { ColDef, ITooltipParams, RowNode } from "ag-grid-community";

@Injectable()
export class AnalyticService {

    static statusesRenderer = params => {
        const stats: StatusStat[] = params.value;
        if (!stats || !stats.length) {
            return `<div class="text-center text-muted">n/a</div>`;
        }
        let title: string[] = [];
        let items: string[] = stats.map(s => {
            title.push(`${s.name} ${s.valPercent}% (${s.val})`)
            return `<div class="color-${s.color}" style="width:${parseInt(String(s.valPercent))}%;"></div>`;
        });
        return `<div class="ag-statuses-bar">${items.join('')}</div>`
    };

    static delayRenderer = params => {
        const delay: number[] = params.data.delay;
        if (!delay || !delay.length) {
            return `<div class="text-center text-muted">n/a</div>`;
        }
        const max = 300;
        const delayStr = delay.map(d => {
            const humanValue = CustomUtils.secToHumanTime(d);
            return d >= max ? `<span class="text-warning">${humanValue}</span>` : humanValue;
        }).join(' / ');
        return `<div class="text-center">${delayStr}</div>`;
    }

    static delayGetter = params => {
        if (params.data.delay && params.data.delay.length) {
            return params.data.delay[0];
        }
        return 0;
    }

    static statusesTooltipGetter = (field: string) => {
        return (params: ITooltipParams) => {
            const statuses = params.data[field] as StatusStat[];
            return statuses.map(_ => {
                return `${_.name} ${_.valPercent}% (${_.val})\n\r`
            });
        };
    }

    utils = new RestUtils();

    headers = new HttpHeaders({
        'Content-Type': ['application/json']
    });

    constructor(public http: HttpClient) {
        
    }

    globalAnalyticWizard(query: WizardQuery): Observable<AnalyticResult[]> {
        let url = this.utils.buildUrl('ROLE/aphr/global/analytic-wizard', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<AnalyticResult[]>(url, query, options).pipe(
            map(rows => {
                rows.forEach(r => this.prepareRowGlobal(r, query));
                return rows;
            })
        );
    }

    private prepareRowGlobal(r: AnalyticResult, query: WizardQuery): AnalyticResult {
        r.title = '';
        r.group = false;
        r.dlrStatuses = [
            {name: 'Expired', valPercent: r.expiredPercent, val: r.expired, color: 'danger'},
            {name: 'Deleted', valPercent: r.deletedPercent, val: r.deleted, color: 'danger'},
            {name: 'Undeliverable', valPercent: r.undeliverablePercent, val: r.undeliverable, color: 'danger'},
            {name: 'Rejected', valPercent: r.rejectedPercent, val: r.rejected, color: 'danger'},
            {name: 'Skipped', valPercent: r.skippedPercent, val: r.skipped, color: 'danger'},
            {name: 'SUBMIT_SM failed', valPercent: r.submitSmFailedPercent, val: r.submitSmFailed, color: 'danger'},
            {name: 'Scheduled', valPercent: r.scheduledPercent, val: r.scheduled, color: 'warning'},
            {name: 'Enroute', valPercent: r.enroutePercent, val: r.enroute, color: 'warning'},
            {name: 'Accepted', valPercent: r.acceptedPercent, val: r.accepted, color: 'warning'},
            {name: 'Pending', valPercent: r.pendingPercent, val: r.pending, color: 'warning'},
            {name: 'No DLR received', valPercent: r.noDlrReceivedPercent, val: r.noDlrReceived, color: 'warning'},
            {name: 'Partially delivered', valPercent: r.partiallyDeliveredPercent, val: r.partiallyDelivered, color: 'warning'},
            {name: 'Delivered', valPercent: r.deliveredPercent, val: r.delivered, color: 'navy'},
            {name: 'Unknown', valPercent: r.unknownPercent, val: r.unknown, color: 'gray'},
        ].filter(_ => _.valPercent);
        r.receiptStatuses =  [
            {name: 'Not delivered', val: r.notDelivered, valPercent: r.notDeliveredPercent, color: 'danger'},
            {name: 'Network offline', val: r.networkOffline, valPercent: r.networkOfflinePercent, color: 'danger'},
            {name: 'Sender replaced', val: r.senderReplaced, valPercent: r.senderReplacedPercent, color: 'warning'},
            {name: 'Text replaced', val: r.textReplaced, valPercent: r.textReplacedPercent, color: 'warning'},
            {name: 'Test Number not available', val: r.testNumberNotAvailable, valPercent: r.testNumberNotAvailablePercent, color: 'warning'},
            {name: 'Internal error', val: r.internalError, valPercent: r.internalErrorPercent, color: 'warning'},
            {name: 'Test number offline', val: r.testNumberOffline, valPercent: r.testNumberOfflinePercent, color: 'warning'},
            {name: 'Smpp session offline', val: r.smppSessionOffline, valPercent: r.smppSessionOfflinePercent, color: 'warning'},
            {name: 'Pending', val: r.pending, valPercent: r.pendingPercent, color: 'warning'},
            {name: 'Positive', val: r.positive, valPercent: r.positivePercent, color: 'navy'},
            {name: 'Wait', val: r.wait, valPercent: r.waitPercent, color: 'gray'},

        ].filter(_ => _.valPercent);

        let delay = [];
        if (r.smppDelayMedian) {delay.push(r.smppDelayMedian);}
        if (r.receiptDelayMedian) {delay.push(r.receiptDelayMedian);}
        r.delay = delay;

        return r;
    }

    ntcAnalyticWizard(query: WizardQuery): Observable<AnalyticResult[]> {
        let url = this.utils.buildUrl('ROLE/aphr/ntc/analytic-wizard', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<AnalyticResult[]>(url, query, options).pipe(
            map(rows => {
                rows.forEach(r => this.prepareRowGlobal(r, query));
                return rows;
            })
        );
    }

    mtcAnalyticWizard(query: WizardQuery): Observable<AnalyticResult[]> {
        let url = this.utils.buildUrl('ROLE/aphr/mtc/analytic-wizard', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<AnalyticResult[]>(url, query, options).pipe(
            map(rows => {
                rows.forEach(r => this.prepareRowGlobal(r, query));
                return rows;
            })
        );
    }

    hasAnalytics(): Observable<boolean> {
        let url = this.utils.buildUrl('ROLE/usr/users/self/pricing-groups/analytics-subscription', {});
        let options = this.utils.getHttpHeaderOptions(new HttpHeaders());

        return this.http.get(url, {
            headers: options.headers,
            withCredentials: options.withCredentials,
            responseType: 'text'
        }).pipe(
            map(bodyText => {
                return bodyText === 'true';
            })
        );
    }

    static extractPath(node: RowNode<AnalyticResult>): AnalyticResult[] {
        let nodes: AnalyticResult[] = [];
        let currentNode = node;
        while (currentNode && currentNode.displayed) {
            nodes.unshift(currentNode.data);
            currentNode = currentNode.parent;
        }
        return nodes;
    }

    static saveSort(storage: LocalStorageService, key: string, columns: ColDef[]) {
        let data = {};
        columns.filter(_ => _.sortable && _.sort !== null).forEach(c => data[c.field] = c.sort);
        if (Object.keys(data).length) {
            storage.set('analytics-' + key + '-sort', data);
        } else {
            storage.remove('analytics-' + key + '-sort');
        }

    }

    static applySortToColumns(storage: LocalStorageService, key: string, columns: ColDef[]) {
        let data = storage.get('analytics-' + key + '-sort', {});
        columns.filter(_ => _.sortable && !_.sort && data[_.field]).forEach(c => c.sort = data[c.field]);
    }
}

export type AnalyticsSource = 'GLOBAL'|'NTC'|'MTC';


