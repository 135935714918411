import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { ValidationService, Validators as Vld } from '../../services/validation.service';
import { TemplatesService } from '../../services/templates.service';


@Component({
    selector: 'titlemodal',
    templateUrl: './titlemodal.component.html',
    styleUrls: ['./titlemodal.scss']
})

export class TitleModal implements OnInit {

    @Input('header') header: string;
    @Input('titleLabel') titleLabel: string;
    @Input('title') title: string;
    @Input('dialogRef') dialogRef: any;
    @Input('source') source: string;
    @Input('parent') parent: string; 

    name: string;
    titleModel: any = {}
    isLoading: boolean = false;
    isDuplicate: boolean = false;

    titleForm: FormGroup;

    constructor(
        formBuilder: FormBuilder,
        public templatesService: TemplatesService,
        public validationService: ValidationService,
        public notificationService: NotificationService
    ) {
        this.titleForm = formBuilder.group({
            title: ['', Vld.compose([Vld.required])]
        });
    }

    ngOnInit() {
        if (this.parent === 'testcasetemplate') {
            this.titleForm.get('title').setValidators([Vld.required]);
        } 
        this.titleModel.title = this.title;
    }

    onCancelClick(): void {
        this.isDuplicate = false;
        this.dialogRef.close(false);
    }

    getService(): any {
        return this.templatesService;
    }

    onSubmitClick(): void {
        this.isLoading = true;
        this.isDuplicate = false;
        if (this.source === 'textTemplates') {
            const service = this.getService();
            service.all({
                page: 0, size: 5, search: this.titleModel.title
            }).subscribe((collection) => {
                const titleArray = collection.content.map(template => template.title);
                this.isDuplicate = titleArray.filter(title => title === this.titleModel.title).length !== 0;
                if (!this.isDuplicate) {
                    this.dialogRef.close(this.titleModel.title);
                }
                this.isLoading = false;
            });
        } else {
            this.dialogRef.close(this.titleModel.title);
        }
    }

    onEnterKeyPress(): void {
        if (this.isTitleFormValid()) {
            this.onSubmitClick();
        }
    }

    isTitleFormValid(): boolean {
        return this.titleForm.valid;
    }

}
