
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { ManualNumberTestingService } from '../../../shared/services/manual-number-testing.service';
import { CreateTestGroup } from '../../../shared/services/manual-number-testing.service';
import { TestGroupInfo } from '../../../shared/models/test-group-info.model';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import {ModalService} from "../../../shared/services/modal.service";
import { PricingService } from "../../../shared/services/pricing.service";
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';

@Component({
    selector: 'app-mnt-form',
    templateUrl: 'mnt-form.component.html',
    styleUrls: ['mnt-form.component.scss'],
    inputs: ['model'],
    outputs: ['onAfterSave'],
})

export class MntFormComponent implements AfterViewInit {

    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild('confirmModalTpl', { read: TemplateRef, static: false }) confirmModalTpl: any;

    @Input() showButtons = true;
    @Input() autoRun = true;
    @Input() pauseUpdate = false;
    @Input() hiddenSearchButton = false;

    @Output() onChangeCountResults = new EventEmitter;
    @Output() onClickSearchButton = new EventEmitter;

    loading = true;
    backButtonShow = true;
    onAfterSave = new EventEmitter();
    countResults = 0;

    onAfterSaveEmmit = true;

    form: FormGroup;

    testsPrice: number = 0;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        public service: ManualNumberTestingService,
        public modal: ModalService,
        public pricingService: PricingService,
    ) {
        this.form = formBuilder.group({
            count_results: ['', Vld.compose([Vld.min(1)])],
        });
    }

    ngAfterViewInit() {
        this.countries.update().subscribe(_ => {
            this.loading = false;
        });
    }

    onSubmit() {
        this.loading = true;
        let dialogRef = this.modal.alert().component(this.confirmModalTpl).open();
        this.pricingService.getTestPrices(this.countResults).subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.loading = false;
                dialogRef.result.then(result => {
                    if (result) {
                        dialogRef.result.then(result => {
                            if (result) {
                                this.save();
                            }
                        });
                    }
                });
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    save() {
        this.loading = true;
        return this.service.run(this.createModel()).subscribe(_ => {
            this.loading = false;
            this.notificationService.success('Test case created', 'Test cases');
            if (this.onAfterSaveEmmit) {
                this.onAfterSave.emit();
                this.countries.reset();
            }
            this.calculateCountResults();
        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Manual testing',
                message: 'An error occurred while running the tests',
                serviceName: 'MTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        });
    }

    calculateCountResults() {
        this.countResults = this.countries.getSelectedModels().length;
        this.form.controls.count_results.patchValue(this.countResults);
        this.onChangeCountResults.emit(this.countResults);
    }

    clickSearchButton() {
        this.onClickSearchButton.emit();
    }

    reset() {
        this.countries.reset();
        this.calculateCountResults();
    }

    createModel(): CreateTestGroup {
        let testGroup = {
            testDtos: []
        };
        testGroup.testDtos = this.countries.getSelectedModels().map(_ => {
            if (_.custom) {
                return {
                    destinationDto: {
                        countryName: _.countryName ? _.countryName : null,
                        mcc: null,
                        mnc: null,
                        originalMnc: null,
                        originalProviderName: null,
                        providerName: null,
                        phone: _.phonenumber,
                        manualNumber: true
                    }
                };
            }
            return {
                destinationDto: {
                    countryName: _.countryName,
                    mcc: _.mcc,
                    mnc: _.mnc,
                    originalMnc: _.originalMnc,
                    originalProviderName: _.originalProviderName,
                    providerName: _.providerName,
                    manualNumber: false
                }
            };
        });

        return testGroup;
    }

    loadTestCase(testGroupId) {
        this.loading = true;
        this.service.testGroupInfo(testGroupId).subscribe(_ => {
            const info: TestGroupInfo = _[0];
            this.setTestGroup(info);
        }, err => { }, () => this.loading = false);
    }

    setTestGroup(info: TestGroupInfo) {
        this.reset();
        info.destinations.map(_ => {
            if (parseInt(_.mcc) === 0 && parseInt(_.mnc) === 0) {
                this.countries.createPhonenumber(_.phone);
            } else {
                this.countries.selectByParams(_.mcc, _.mnc, _.originalMnc ? _.originalMnc : null);
            }
        });
        this.countries.onChange.emit();

        this.calculateCountResults();
    }
}
