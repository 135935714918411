import { Component, OnInit } from '@angular/core';
import { Filter } from '../../shared/filter/filter';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UsersService } from '../../shared/services/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';

@Component({
    selector: 'app-scheduler-search',
    templateUrl: './scheduler-search.component.html',
    styleUrls: ['./scheduler-search.component.scss']
})
export class SchedulerSearchComponent implements OnInit {

    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'ids', title: 'ID', show: true },
        { filterKey: 'dateReported', title: 'Date reported', show: true },
        { filterKey: 'testIds', title: 'Test ID', show: true },
        { filterKey: 'scheduleName', title: 'Schedule Name', show: true },
        { filterKey: 'testNumbers', title: 'Test Number', show: true },
        { filterKey: 'countryNetworks', title: 'Country/Network', show: true },
        { filterKey: 'suppliers', title: 'Supplier', show: true },
    ];
    form: FormGroup;

    constructor(
        private localStorage: LocalStorageService,
        private usersService: UsersService,
        private formBuilder: FormBuilder,
        public validationService: ValidationService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ids: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            testIds: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            scheduleName: ['', Vld.compose([Vld.maxLength(255)])],
            testNumbers: ['', Vld.compose([Vld.maxLength(255)])],
        });
        const existingFilters = this.localStorage.get(`scheduler_reports_${this.usersService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'ids':
                return this.form.get('ids').value?.length > 0;
            case 'testIds':
                return this.form.get('testIds').value?.length > 0;
            case 'scheduleName':
                return this.form.get('scheduleName').value?.length > 0;
            case 'testNumbers':
                return this.form.get('testNumbers').value?.length > 0;
            default:
                return true;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'ids':
                this.form.get('ids').setValue('');
                break;
            case 'testIds':
                this.form.get('testIds').setValue('');
                break;
            case 'scheduleName':
                this.form.get('scheduleName').setValue('');
                break;
            case 'testNumbers':
                this.form.get('testNumbers').setValue('');
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        console.log("Submit clicked");
        console.log(this.form.getRawValue());
    }

    resetAll(): void {
        this.form.reset();
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
    }

    onChangeDate(dates): void {
        console.log(dates);
    }

    onFilterChange(filter: Filter): void {
        console.log("On filter change: ", filter);
    }

    onRouteChange(): void {
        this.onSubmitClick();
    }

    onDestinationChange(): void {
        console.log("On destination change.");
    }


}
