import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConflictedSession, SessionSupplierCollection, SessionSupplierList } from '../models/session-supplier.model';
import { SessionsCollection, Session } from '../models/session.model';
import { Supplier, SuppliersCollection } from '../models/supplier.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";
import { BackendChannelService } from "./backend-channel.service";

@Injectable()
export class SessionsService {

    utils = new RestUtils();
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private actionSubject: Subject<SessionSupplierActionData> = new Subject<SessionSupplierActionData>();
    action$ = this.actionSubject.asObservable();

    private activeSessionForSupplier: Session;

    constructor(public http: HttpClient, private backendChannel: BackendChannelService) {
        this.http = http;
    }

    setActiveSessionForSupplier(session: Session) {
        this.activeSessionForSupplier = session;
    }

    getActiveSessionForSupplier(): Session {
        return this.activeSessionForSupplier;
    }

    announceAction(action: SessionSupplierActionData) {
        this.actionSubject.next(action);
    }

    all(params: AllRequestParams) {
        let url = this.utils.buildUrl('ROLE/ntc/sessions', {
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SessionsCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Session>(url, options);
    }

    save(session: Session): Observable<Session> {
        const saveSession = {
            ...session,
            password: session.password || '',
            concatenationType: 'udh',
            user: undefined,
        };

        const url = this.utils.buildUrl(`ROLE/ntc/sessions`, {});
        const options = this.utils.getHttpHeaderOptions(this.headers);

        return saveSession.id
            ? this.http.put(url, saveSession, options)
            : this.http.post(url, saveSession, options);
    }

    search(params: AllRequestParams, searchParams: SearchSessionSupplierParams) {
        let url = this.utils.buildUrl('ROLE/ntc/sessions/search', {
            page: params.page,
            size: params.size,
            sort: params.sort && params.sort.length ? params.sort : [],
            searchString: params.search ? params.search : '',
        });
        delete searchParams?.searchString;
        if (!searchParams) {
            searchParams = this.getEmptySearch();
        }
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<SessionSupplierList>(url, searchParams, options);
    }

    searchSuppliers(sessionId: number, params: AllRequestParams, searchParams: SearchSessionSupplierParams): Observable<SuppliersCollection> {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions/${sessionId}/suppliers/search`, {
            page: params.page,
            size: params.size,
            sort: params.sort && params.sort.length ? params.sort : [],
            searchString: searchParams?.searchString || '',
        });
        delete searchParams?.searchString;
        if (!searchParams) {
            searchParams = this.getEmptySearch();
        }
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<SuppliersCollection>(url, searchParams, options);
    }

    onFavouriteChange(sessionId: number, supplierId: number, isFavourite: boolean): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/session/${sessionId}/supplier/${supplierId}/favourite`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (isFavourite) {
            return this.http.post(url, {}, options);
        }
        return this.http.delete(url, options);
    }

    delete(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    reassignSuppliers(sessionId: number, supplierIds: number[]) {
        let url = this.utils.buildUrl(`ROLE/ntc/suppliers/assign`, { sessionId });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, supplierIds, options);
    }

    assingments(params: AllRequestParams) {
        let queryParams = {
            sort: ['online,desc', 'supp.title,asc'],
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : null,
            attributeString: params.attributes ? params.attributes : null,
            shouldSearchHidden: params.shouldSearchHidden
        };
        let url = this.utils.buildUrl(`ROLE/ntc/sessions-suppliers`, queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SessionSupplierCollection>(url, options);
    }

    asssignmentsFavourites() {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions-suppliers`, {
            page: 0,
            size: 1000,
            sort: ['online,desc', 'supp.title,asc'],
            onlyFavourites: 'true'
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SessionSupplierCollection>(url, options);
    }

    conflictedSessions(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/sessions/${id}/conflicted-sessions`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<ConflictedSession[]>(url, options).pipe(
            map(sessions => {
                return sessions.sort((a, b) => Number(b.enabled) - Number(a.enabled));
            })
        );
    }

    dumpStatus(fileName: string) {
        let url = this.utils.buildUrl(`ROLE/ssm/dumps/status`, { name: fileName });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<DumpStatus>(url, options);
    }

    dump(id: number, duration: number): Promise<DumpStatus> {
        return new Promise((then, error) => {
            let url = this.utils.buildUrl(`ROLE/ntc/sessions/${id}/dumps`, { duration: duration });
            let options = this.utils.getHttpHeaderOptions(this.headers);
            this.http.get(url, options).pipe(map(_ => {
                return _;
            })).subscribe((data: DumpStart) => {
                let fileName = data.fileName;
                let repeat = 0;
                let maxRepeat = 120;
                let interval = setInterval(() => {
                    this.dumpStatus(fileName).subscribe(response => {
                        if (response.finish === true) {
                            clearInterval(interval);
                            let downloadUrl = this.utils.buildUrl(`ROLE/ssm/dumps/download`, { name: fileName });
                            then({
                                url: this.backendChannel.prepareUrl(downloadUrl),
                                size: response.size
                            });
                            return;
                        }
                        repeat++;
                        if (maxRepeat === repeat) {
                            clearInterval(interval);
                            throw new Error('Timeout');
                        }
                    }, errorStatus => {

                    });
                }, 5000);
            }, e => {
                error(e)
            })
        });
    }

    create(): Session {
        return {
            id: null,
            userId: null,
            dstNpi: 1,
            dstTon: 1,
            enabled: true,
            useSSL: false,
            visibleAll: false,
            concatenationType: 'udh',
            systemType: null,
            hostIp: '',
            hostPort: null,
            password: null,
            systemId: null,
            throughput: 5,
            windowSize: 1,
            windowWaitTimeout: 60000,
            bindTimeout: null,
            connectTimeout: null,
            enableCounters: null,
            interfaceVersion: null,
            requestExpiryTimeout: null,
            useLogPduOption: null,
            windowMonitorInterval: null,
            writeTimeout: null,
        };
    }

    npiItems: SelectPair[] = [
        {
            id: 0,
            label: '[0x00] Unknown'
        }, {
            id: 1,
            label: '[0x01] ISDN (E163/E164)'
        }, {
            id: 3,
            label: '[0x03] X121'
        }, {
            id: 4,
            label: '[0x04] TELEX'
        }, {
            id: 6,
            label: '[0x06] Land mobile'
        }, {
            id: 8,
            label: '[0x08] National'
        }, {
            id: 9,
            label: '[0x09] Private'
        }, {
            id: 10,
            label: '[0x0A] ERMES'
        }, {
            id: 14,
            label: '[0x0E] Internet'
        }, {
            id: 18,
            label: '[0x12] WAP CLIENT ID'
        }
    ];

    tonItems: SelectPair[] = [
        {
            id: 0,
            label: '[0x00] Unknown'
        }, {
            id: 1,
            label: '[0x01] International'
        }, {
            id: 2,
            label: '[0x02] National'
        }, {
            id: 3,
            label: '[0x03] Network'
        }, {
            id: 4,
            label: '[0x04] Subscriber'
        }, {
            id: 5,
            label: '[0x05] Alphanumeric'
        }, {
            id: 6,
            label: '[0x06] Abbreviated'
        }
    ];

    dcsItems: SelectPair[] = [
        {
            id: 0,
            label: '[00000000] MC Specific'
        }, {
            id: 1,
            label: '[00000001] IA5 (CCITT T.50)/ASCII (ANSI X3.4)'
        }, {
            id: 2,
            label: '[00000010] Octet unspecified (8-bit binary)'
        }, {
            id: 3,
            label: '[00000011] Latin 1 (ISO-8859-1)'
        }, {
            id: 4,
            label: '[00000100] Octet unspecified (8-bit binary)'
        }, {
            id: 5,
            label: '[00000101] JIS (X 0208-1990)'
        }, {
            id: 6,
            label: '[00000110] Cyrillic (ISO-8859-5)'
        }, {
            id: 7,
            label: '[00000111] Latin/Hebrew (ISO-8859-8)'
        }, {
            id: 8,
            label: '[00001000] UCS2 (ISO/IEC-10646)'
        }, {
            id: 9,
            label: '[00001001] Pictogram Encoding'
        }, {
            id: 10,
            label: '[00001010] ISO-2022-JP (Music Codes)'
        }, {
            id: 11,
            label: '[00001011] Reserved'
        }, {
            id: 12,
            label: '[00001100] Reserved'
        }, {
            id: 13,
            label: '[00001101] Extended Kanji JIS (X 0212-1990)'
        }, {
            id: 14,
            label: '[00001110] KS C 5601'
        }, {
            id: 15,
            label: '[00001111] Reserved'
        }, {
            id: 16,
            label: '[10111111] Reserved'
        }, {
            id: 17,
            label: '[1100xxxx] GSM MWI control'
        }, {
            id: 18,
            label: '[1101xxxx] GSM MWI control'
        }, {
            id: 19,
            label: '[1110xxxx] Reserved'
        }, {
            id: 20,
            label: '[1111xxxx] GSM message class control'
        }
    ];

    getEmptySearch(): SearchSessionSupplierParams {
        return {
            sessionIds: null,
            host: null,
            logins: null,
            supplierIds: null,
            supplierNames: null,
            routeType: null,
            serviceType: null,
            userIds: null,
            usersCollection: null
        };
    }
}

type DumpStatus = { url: string, size: number, finish?: boolean };
type DumpStart = { fileName: string };
export type SelectPair = { id: string | number, label: string | number };

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search?: string;
    attributes?: string;
    shouldSearchHidden?: boolean;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}


export interface SessionSupplierActionData {
    name: string;
    row: Session | Supplier;
    column: string;
}

export interface SearchSessionSupplierParams {
    sessionIds?: number[];
    host?: string;
    logins?: string[];
    userIds?: number[];
    supplierIds?: number[];
    supplierNames?: string[];
    routeType?: string;
    serviceType?: string;
    usersCollection?: any[];
    searchString?: string;
}