import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import { RestUtils } from './rest-utils';
import { BrowserUtils } from "./browser-utils";
import { httpStatuses } from "./http-status";


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    restUtils = new RestUtils();

    constructor(
        public notificationService: NotificationService
    ) {
    }

    handleUpdates(request: HttpRequest<any>, response: HttpResponse<any>): void {
        if (!environment['forcedUpdate']) {
            if (['DELETE', 'POST', 'PUT'].includes(request.method) && response.status === 200) {
                setTimeout(() => {
                    this.notificationService.setUpdates(response);
                }, 1000);
            }
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone();
        return next.handle(newReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const newEvent = event.clone();
                    this.restUtils.handleResponse(newEvent);
                    this.handleUpdates(newReq, newEvent);
                    return newEvent;
                }
                return event;
            }),
            catchError(e => this.handleError(e))
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0 && !BrowserUtils.isUnload) {
            this.notificationService.info('Connection issue encountered. Check your internet connection', 'Network');
        }
        if (!error.ok && error.statusText === 'OK') {
            let statusText = 'Error ' + error.status;
            if (httpStatuses[String(error.status)]) {
                statusText = httpStatuses[String(error.status)];
            }
            error = new HttpErrorResponse({
                error: error.error,
                headers: error.headers,
                status: error.status,
                statusText: statusText,
                url: error.url
            });
        }
        return this.restUtils.handleError(error);
    }
}