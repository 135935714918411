import { TestGroup } from "../models/test-group.model";
import { TestResult } from "../models/test-result.model";
import { UserAdmin, Role } from "../models/user.model";
import { CreateTestGroup } from "./live-number-testing.service";


export class CustomUtils {

    static htmlDecode(str: string): string {
        let textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    }

    static text(text: string, max = 40): string {
        if (!text) {
            return text;
        }
        if (text.length > max) {
            return text.substr(0, max - 1) + '...';
        }

        return text;
    }

    static trimModelFields(model): any {
        return model;
    }

    static cloneObject(obj: Object): Object {
        let newObj = {}
        for (let i in obj) { newObj[i] = obj[i] }
        return newObj
    }

    static strToBytes(str: string): number[] {
        let data = [];
        for (let i = 0; i < str.length; i++) {
            data.push(str.charCodeAt(i));
        }
        return data;
    }

    static strToHex(str: string): string[] {
        let data = [];
        for (let i = 0; i < str.length; i++) {
            data.push(str.charCodeAt(i).toString(16));
        }
        return data;
    }

    static camelCaseToDash(myStr: string): string {
        return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    static generateRandomString(length: number): string {
        return Math.random().toString(36).slice(2, length + 2);
    }

    static generateRandomNumber(limit: number = 10000): number {
        return Math.round(Math.random() * limit);
    }

    static secToHumanTime(sec: number): string {
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60);
        let parts = [];
        if (hours) { parts.push(hours + 'h'); }
        if (minutes) { parts.push(minutes + 'm'); }
        if (seconds || !parts.length) { parts.push(seconds + 's'); }
        return parts.join(' ');
    }

    static getInitials(fullName): string {
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }

    static escapeHTML(html: string): string {
        const replace = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            "'": '&#39;',
            '"': '&quot;'
        }
        return html.replace(/[&<>'"]/g, tag => (replace[tag] || tag));
    }

    static getMappedTestToTestGroupStructure(test, setting: TestGroup): CreateTestGroup {
        return {
            contentTexts: [{
                custom: false,
                senderId: test.senderSent,
                text: test.textSent,
                telqIdType: test.telqIdType,
                telqIdLength: test.telqIdLength,
                telqIdCase: test.telqIdCase
            }],
            destinations: [test.destinationDto],
            sessionsSuppliers: [{
                sessionId: test.sessionId,
                supplierId: test.supplierDto.id
            }],
            userId: null,
            udh: setting.udhDtos,
            tlv: setting.tlvDtos,
            srcNpi: setting.srcNpi,
            srcTon: setting.srcTon,
            protocolId: setting.protocolId,
            encodingId: setting.encodingId,
            ttl: setting.ttl,
            validityPeriod: setting.validityPeriod,
            scheduleDeliveryTime: setting.scheduleDeliveryTime,
            replaceIfPresent: setting.replaceIfPresent,
            testRepeatsPerIteration: setting.testRepeatsPerIteration,
            amountX: setting.amountX || 1,
            commentText: setting.commentText
        }
    }

    static getIconForStatus(statusID: string): string {
        switch (statusID) {
            case 'SCHEDULED':
                return 'far fa-meh text-accent-a2';
            case 'ENROUTE':
                return 'far fa-meh text-accent-a2';
            case 'DELIVERED':
                return 'far fa-smile text-sucess-s2';
            case 'RECEIVED':
                return 'far fa-smile text-sucess-s2';
            case 'EXPIRED':
                return 'far fa-frown text-error-r3';
            case 'DELETED':
                return 'far fa-frown text-error-r3';
            case 'UNDELIVERABLE':
                return 'far fa-frown text-error-r3';
            case 'ACCEPTED':
                return 'far fa-meh text-accent-a2';
            case 'UNKNOWN':
                return 'far fa-meh text-accent-a2';
            case 'REJECTED':
                return 'far fa-frown text-error-r3';
            case 'SKIPPED':
                return 'far fa-frown text-error-r3';
            case 'PENDING':
                return 'far fa-meh text-accent-a2';
            case 'NO_DLR_RECEIVED':
                return 'far fa-frown text-accent-a2';
            case 'PARTIALLY_DELIVERED':
                return 'far fa-smile text-accent-a2';
            case 'SUBMIT_SM_FAILED':
                return 'far fa-frown text-error-r3';
            case 'WAIT':
                return 'icon-wait spin';
            case 'SENDER_REPLACED':
                return 'icon-attention text-accent-a2';
            case 'TEXT_REPLACED':
                return 'icon-attention text-accent-a2';
            case 'SMPP_SESSION_OFFLINE':
                return 'icon-smpp-connection-offline text-error-r3';
            case 'POSITIVE':
                return 'icon-positive text-sucess-s2';
            case 'NOT_DELIVERED':
                return 'icon-negative text-error-r3';
            case 'TEST_NUMBER_NOT_AVAILABLE':
                return 'icon-test-number-not-available text-accent-a2';
            case 'INTERNAL_ERROR':
                return 'icon-bug-red ms-0_2 fs-4 text-error-r3';
            case 'TEST_NUMBER_OFFLINE':
                return 'icon-negative text-accent-a2';
            case 'NUMBER_OFFLINE':
                return 'icon-negative text-accent-a2';
            case 'NETWORK_OFFLINE':
                return 'icon-network-offline text-error-r3';
            case 'SENT':
                return 'icon-done text-sucess-s2';
            case 'SENT_ERROR':
                return 'icon-negative text-error-r2';
            case 'UNKNOWN_ERROR':
                return 'icon-enroute-unknown text-accent-a2';
            case 'CANCELED':
                return 'icon-no-dlr-received text-error-r2';
            default:
                return 'icon-help';
        }
    }

    static getGuestUrls(): string[] {
        return [
            '/login',
            '/reset-password',
            '/registration',
            '/email/verify',
            '/token/recovery'
        ]
    }

    static createId(idType: string, idLength: number): string {
        let possibleValues = '';

        switch (idType) {
            case 'ALPHA':
                possibleValues = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                break;
            case 'NUMERIC':
                possibleValues = '0123456789';
                break;
            case 'ALPHA_NUMERIC':
                possibleValues = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                break;
            case 'WHATSAPP_CODE':
                return '123-456';
        }

        let result = '';
        for (let i = 0; i < idLength; i++) {
            result += possibleValues.charAt(Math.floor(Math.random() * possibleValues.length));
        }
        return result;
    }

    static generateId(telqIdCase: string, telqIdLength: number, telqIdType: string, value: string): string {
        let id = this.createId(telqIdType, telqIdLength);
        switch (telqIdCase) {
            case 'UPPER':
                id = id.toUpperCase();
                break;
            case 'LOWER':
                id = id.toLowerCase();
                break;
        }
        return value.replace("{TEST_ID_TEXT}", id);
    }

    static extractDomain(url: string): string {
        if (url) {
            const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
            const match = url.match(domainRegex);
            return match ? `@${match[1]}` : "";
        }
        return '';
    }
}
