import { Component, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { DropdownCtx } from "../../../shared/directives/dropdown.directive";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../../shared/filter/filter";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";
import { CallbacksHistoryFilter } from "../../../shared/models/callback-history.model";

@Component({
    selector: 'app-callback-history-filter',
    templateUrl: 'callback-history-filter.component.html',
    styleUrls: ['../../../shared/filter/filter.scss'],
})

export class CallbackHistoryFilterComponent {

    @Input() model: CallbacksHistoryFilter = {
        service: null
    };

    @Output() onSubmit: EventEmitter<CallbacksHistoryFilter> = new EventEmitter();

    loading = false;

    @ViewChild('dateReceivedInput', {read: InputSearchDatePeriod, static: false}) dateReceivedInput: InputSearchDatePeriod;
    @ViewChild('dateCreatedInput', {read: InputSearchDatePeriod, static: false}) dateCreatedInput: InputSearchDatePeriod;

    filters: IFilterDef[] = [
        //{ title: 'Date received', show: true, order: 1 },
        { title: 'Service', show: true, order: 2 },
    ];
    readonly serviceValues = [
        {title: 'Live number testing', value: 'LNT'},
        {title: 'Manual testing', value: 'MT'},
        {title: 'Scheduler', value: 'scheduler'},
    ];
    private readonly filterStorageKey = 'callback-history-filter';

    constructor(private storage: LocalStorageService) {
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
            }
        });
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Service':
                this.model.service = value
                filter.value = this.model.service;
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onSubmitClick() {
        this.model = CustomUtils.trimModelFields(this.model);
        let model = Object.assign({}, this.model);
        if (model.service) {
            const serviceVal = this.serviceValues
                .filter(_ => _.title === model.service)
                .map(_ => _.value);
            model.service = serviceVal[0];
        }
        this.onSubmit.emit(model);
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex)
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}