import {Component, ElementRef, ViewChild} from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular'
import { IHeaderParams } from 'ag-grid-community'

@Component({
    selector: 'app-analytics-table-header',
    template: `
        <div class="ag-cell-label-container" role="presentation">
            <div class="ag-header-cell-label" role="presentation">
                <div class="input-group input-group-sm">
                    <input #inputSearch type="text" (keydown)="onKeyDown($event)" placeholder="Search" class="form-control"/>
                    <div class="input-group-btn">
                        <button (click)="onClickSearch()" type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
   `,
    styles: [
        `.input-group { width: 100%; }`,
        `.input-group input { font-weight: normal; }`
    ]
})
export class AnalyticsTableSearchHeaderComponent implements IHeaderAngularComp {

    public params: Params;

    @ViewChild('inputSearch', {read: ElementRef}) public inputSearch: ElementRef;

    private text: string = '';

    agInit(params: Params): void {
        this.params = params;
        params.eGridHeader.style.paddingLeft = '8px';
        params.eGridHeader.style.paddingRight = '8px';
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }

    onKeyDown(event: KeyboardEvent) {
        if(event.key === 'Enter') {
            this.onClickSearch();
        }
    }

    onClickSearch() {
        const value = this.inputSearch.nativeElement.value;
        const text = value ? value.trim() : '';
        if (text !== this.text) {
            this.text = text;
            if (this.params.onSearch) {
                this.params.onSearch(this.text);
            }
        }
    }
}

interface Params extends IHeaderParams{
    onSearch: Function
}