import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Destination } from '../models/destination.model';
import { PduDetails } from '../models/pdu-details.model';
import { TestGroupInfo } from '../models/test-group-info.model';
import { TestGroup } from '../models/test-group.model';
import { ManualTestDto } from '../models/test-manual.model';
import { TestDetails, TestResult, TestResultsCollection } from '../models/test-result.model';
import { RestUtils } from './rest-utils';
import { BasicUser } from '../models/user.model';
declare var moment: any;

@Injectable()
export class ManualNumberTestingService {

    //PDU decoding JS library
    // @ts-ignore
    pdu = require('pdu');

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    private actionSubject: Subject<MntActionData> = new Subject<MntActionData>();
    action$ = this.actionSubject.asObservable();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: MntActionData) {
        this.actionSubject.next(action);
    }

    testGroupInfo(testGroupId: number) {
        let url = this.utils.buildUrl(`ROLE/mtc/test-groups/${testGroupId}/info`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestGroupInfo>(url, options);
    }

    results(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
            userIds: []
        };
        if (params.userIds && params.userIds.length) {
            queryParams.userIds = params.userIds;
        } else {
            delete queryParams.userIds;
        }
        let url = this.utils.buildUrl('ROLE/mtc/test-results', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestResultsCollection>(url, options);
    }

    searchResults(params: AllRequestParams, searchParams: SearchParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
        };

        let url = this.utils.buildUrl('ROLE/mtc/test-results/search', queryParams);

        for (let i in queryParams) {
            searchParams[i] = queryParams[i];
        }

        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<TestResultsCollection>(url, searchParams, options);
    }

    run(group: CreateTestGroup) {
        let url = this.utils.buildUrl(`ROLE/mtc/test-groups`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, group, options);
    }

    getResultDetailsText(result: TestResult): Observable<TestDetails[]> {
        return forkJoin([
            this.pduDetails(result.id),
            this.oneTestGroup(result.testGroupId)
        ]).pipe(map(data => {
            let text = [];
            const pdu: PduDetails = data[0];
            const group: TestGroup = data[1];

            const testCaseSummary = [];

            text.push('ID: ' + result.id);
            text.push('Test group ID: ' + group.id);
            text.push('Date: ' + moment(result.createdAt).format('DD MMM YYYY HH:mm:ss'));
            text.push('Country: ' + this.formatDestination(result.destinationDto));
            text.push('Receipt Status: ' + result.testStatus);
            text.push('Receipt delay: ' + (result.receiptDelay ? result.receiptDelay + 's' : 'N/A'));
            text.push('Sender delivered: ' + result.senderDelivered);
            text.push('Text delivered: ' + result.textDelivered);
            if (result.smscInfoDto) {
                text.push('SMSC: ' + result.smscInfoDto.phone);
            }
            text.push('Phone: ' + result.destinationDto.phone);
            if (group.ttl && (group.ttl % 60) === 0) {
                text.push('TTL: ' + parseInt(String(group.ttl / 60)) + ' min');
            } else {
                text.push('TTL: ' + group.ttl + ' sec');
            }

            if (group.callerIp) {
                text.push('IP (Browser/Client): ' + group.callerIp);
            }
            testCaseSummary.push({
                id: 'general',
                title: 'General',
                data: text
            });
            text = [];

            if (pdu.deliveredSmsPduData && pdu.deliveredSmsPduData.pdusDelivered && pdu.deliveredSmsPduData.pdusDelivered.length) {
                text.push('');
                pdu.deliveredSmsPduData.pdusDelivered.map((_, i) => {
                    text.push('SMPP PACKET #' + (i + 1));
                    text.push('PDU: ' + _);
                    try {
                        let decodedPdu = this.pdu.parse(_, true);
                        text.push('Decoded delivered PDU');
                        text.push('');
                        text.push('  SMSC in hex: ' + decodedPdu['smsc_hex']);
                        text.push('  SMSC translated value: ' + decodedPdu['smsc']);
                        text.push('  SMSC Type: ' + decodedPdu['smsc_type']);
                        text.push('');
                        text.push('  Sender ID in hex: ' + decodedPdu['sender_hex']);
                        text.push('  Sender ID translated value: ' + decodedPdu['sender']);
                        text.push('  Sender ID Type: ' + decodedPdu['sender_type']);
                        text.push('');
                        text.push('  Protocol Identifier in hex: ' + decodedPdu['protocol_identifier']);
                        text.push('');
                        text.push('  Encoding in hex: ' + decodedPdu['encoding_hex']);
                        text.push('  Encoding translated value: ' + decodedPdu['encoding']);
                        text.push('');
                        text.push('  Time stamp in hex: ' + decodedPdu['time_hex']);
                        text.push('  Time stamp translated value: ' + decodedPdu['time']);
                        text.push('');
                        text.push('  Text in hex: ' + decodedPdu['text_hex']);
                        text.push('  Text translated value: ' + decodedPdu['text']);
                        text.push('');
                        if (decodedPdu['udh'] == null) {
                            text.push('  * No UDH is present in the PDU * ');
                        } else {
                            text.push('  UDH in hex: ' + decodedPdu['udh_hex']);
                            text.push('    UDH length: ' + decodedPdu['udh']['length']);
                            text.push('    UDH IEI: ' + decodedPdu['udh']['iei']);
                            text.push('    UDH Header Length: ' + decodedPdu['udh']['header_length']);
                            text.push('    UDH Reference Number: ' + decodedPdu['udh']['reference_number']);
                            text.push('    UDH Parts: ' + decodedPdu['udh']['parts']);
                            text.push('    UDH Current part: ' + decodedPdu['udh']['current_part']);
                        }
                        testCaseSummary.push({
                            id: 'smpp',
                            title: 'SMPP',
                            data: text
                        });
                        text = [];
                    } catch (e) {
                        console.log(e);
                    }
                });
            }

            return testCaseSummary;
        }));
    }

    formatDestination(dest: Destination, icon = false): string {
        if (dest.mnc === null && dest.mcc === null) {
            return (dest.countryName ? dest.countryName : 'Unknown') + '/' + dest.phone;
        }

        let str = (dest.countryName ? dest.countryName : 'Unknown') + '(' + dest.mcc + ')/' + (dest.providerName ? dest.providerName : 'Unknown') + '(' + dest.mnc + ')';

        if (dest.originalMnc) {
            let iconStr = icon ? '<i class="icon-ported"></i>' : '<=';
            let provider = dest.originalProviderName ? dest.originalProviderName : 'Unknown';
            let originalStr = ' ' + iconStr + ' ' + provider + '(' + dest.originalMnc + ')';
            if (icon) {
                originalStr = `<span title="Ported from ${provider}">${originalStr}</span>`;
            }
            str += originalStr;
        }

        return str;
    }

    oneTestGroup(testGroupId) {
        let url = this.utils.buildUrl(`ROLE/mtc/test-groups/${testGroupId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestGroup>(url, options);
    }

    pduDetails(testId: number) {
        let url = this.utils.buildUrl(`ROLE/mtc/tests/${testId}/pdu-details`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PduDetails>(url, options);
    }

    recentDestinations() {
        let url = this.utils.buildUrl('ROLE/mtc/recent-destinations', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Destination[]>(url, options);
    }

    getFileFromExportResult(result) {
        // split by "/" and return last item
        return result.filePath.split('/').slice(-1);
    }
}

export interface CreateTestGroup {
    testDtos: ManualTestDto[];
    ttl?: number;
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    userIds: number[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface SearchParams {
    createdAtFrom?: string;
    createdAtTo?: string;
    destinations?: Destination[];
    ids?: string[];
    telqIds?: string[];
    senders?: string[];
    testStatuses?: string[];
    userIds?: number[];
    testGroupIds?: string[];
    usersCollection?: BasicUser[];
    taskIds?: number[];
}

export interface Status {
    id: number;
    label: string;
}

export interface MntFilterModel {
    ids: string,
    telqIds: string,
    testGroupIds: string,
    from: string | null,
    to: string | null,
    phoneNumbers: string,
}


interface MntActionData {
    name: string;
    row: TestResult;
    column: string;
}

