
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { ModalService } from "../../shared/services/modal.service";
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-subaccount-form',
    templateUrl: 'subaccount-form.component.html',
    styleUrls: ['./subaccount-form.component.scss']
})

export class SubaccountFormComponent implements AfterViewInit {

    @Input() model: any;
    @Output() onAfterSave = new EventEmitter()

    currentPasswordType: string = 'password';
    newPasswordType: string = 'password';
    repeatPassword = null;

    loading = false;
    backButtonShow = true;

    form: FormGroup;

    constructor(
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public service: UsersService,
        public validationService: ValidationService,
        public modal: ModalService,
    ) {
        let comparePasswordValidator = () => {
            if (this.form) {
                const passwordFormControl = this.form.get('password');
                const repeatPasswordFormControl = this.form.get('repeatPassword');
                if (passwordFormControl.value !== repeatPasswordFormControl.value) {
                    return {
                        comparePassword: true
                    };
                }
            }
            return null;
        };

        this.form = formBuilder.group({
            fullName: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            email: ['', Vld.compose([Vld.required, Vld.email(true)])],
            password: ['', Vld.compose([Vld.maxLength(50), Vld.password(true)]), [Vld.passwordBackend(service)]],
            repeatPassword: ['', Vld.compose([comparePasswordValidator])],
            enabled: [''],
            username: ['', Vld.compose([Vld.username(true)])],
            phone: ['', Vld.compose([Vld.required, Vld.globalPhoneNumber()])]
        });
        validationService.addFormatter('comparePassword', validationService.createTextFormatter('Passwords must match'));
    }

    ngAfterViewInit() {
        this.form.patchValue(this.model); // To trigger validation ...
    }

    onSubmit() {
        this.loading = true;
        if (this.model.username.trim().length < 4) {
            this.notificationService.error('Please enter a username with at least 4 characters', 'Users');
            return;
        }
        if (!this.model.password) {
            this.model.password = null;
        }

        this.service.save(this.model).subscribe(() => {
            this.loading = false;
            this.notificationService.success(
                'User ' + (this.model.id ? 'updated' : 'created'),
                'Users'
            );
            this.onAfterSave.emit();
        }, e => {
            this.loading = false;
            this.validationService.handleRequestError(e, 'Users');
        })
    }

    isPasswordType(field) {
        field += 'Type';
        return this[field] === 'password';
    }

    togglePasswordType(field) {
        field += 'Type';
        this[field] = this[field] === 'password' ? 'text' : 'password';
    }
}