
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InputSearch } from '../../shared/components/input/input.search';
import { UsersService } from '../../shared/services/users.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SubaccountsTableComponent } from '../subaccounts-table/subaccounts-table.component';
import { InviteService } from '../../shared/services/invite.service';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { Observable, Subscription } from 'rxjs';
import { ModalService } from "../../shared/services/modal.service";
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-subaccounts-index',
    templateUrl: 'subaccounts-index.component.html'
})

export class SubaccountsIndexComponent {

    @ViewChild('invitesModalTpl', { read: TemplateRef, static: false }) invitesModalTpl: any;
    @ViewChild(SubaccountsTableComponent, { static: false }) table: SubaccountsTableComponent;
    @ViewChild(InputSearch, { static: false }) search: InputSearch;

    spinner: boolean = false;
    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public userService: UsersService,
        public inviteService: InviteService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Subaccounts');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    userAction(event) {
        // Might be needed for future purposes ...
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.userService.delete(row.data.id).pipe(map(() => {
                this.notificationService.success('Delete users', 'Users');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Delete user an error', 'Users');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchEnabled() {
        const tasks = this.selectedRows.map(row => {
            return this.userService.updateEnable(row.data.id, true).pipe(map(() => {
                this.notificationService.success('Enabled users', 'Users');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Enabled user an error', 'Users');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchDisabled() {
        const tasks = this.selectedRows.map(row => {
            return this.userService.updateEnable(row.data.id, false).pipe(map(() => {
                this.notificationService.success('Disabled users', 'Users');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Disabled user an error', 'Users');
                this.completeTask();
                return caught;
            }))
        });
        this.startTasks(tasks);
    }

    onChangeInputSearch(phrase: string) {
        this.table.requestParams.search = phrase;
        this.table.page = 1;
        this.table.update();
    }

    handleSearch(phrase) {
        this.search.setVal(phrase);
    }

    invitesShow() {
        this.modal.alert().dialogClass('modal-dialog large-modal').isBlocking(true).component(this.invitesModalTpl).open();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}