
import {Component, Output, EventEmitter, ElementRef, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { AppscriptsPaymentMethod } from '../../../shared/models/appscripts-payment-method.model';
import { AppscriptsPaymentMethodService } from '../../../shared/services/appscripts-payment-method.service';

@Component({
    selector: 'app-appscripts-payment-method-form',
    templateUrl: 'appscripts-payment-method-form.component.html',
})

export class AppscriptsPaymentMethodFormComponent {

    @Input() model: AppscriptsPaymentMethod;

    @Input() showButtons = true;

    @Output() onAfterSave: EventEmitter<AppscriptsPaymentMethod> = new EventEmitter();

    backButtonShow = true;
    loading = false;

    form: FormGroup;

    constructor(
        public elementRef: ElementRef,
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: AppscriptsPaymentMethodService,
        public validationService: ValidationService
    ) {
        this.model = service.create();
        this.form = formBuilder.group({
            name: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            description: [''],
            enabled: [''],
            withdrawalAvailable: ['']
        });
    }

    onSubmit() {
        this.loading = true;
        this.model = CustomUtils.trimModelFields(this.model);
        this.service.save(this.model)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success(
                    'Payment method ' + (this.model.id ? 'updated' : 'created'),
                    'Appscripts'
                );
                this.onAfterSave.emit(res);
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }
}