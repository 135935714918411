import { Observable } from 'rxjs';

export class BatchTasks {

    tasks: Observable<any>[] = [];

    count = 0;

    constructor(tasks: Observable<any>[]) {
        this.tasks = tasks;
    }

    run(batchSize = 5) {
        if (this.count >= batchSize) {
            return;
        }
        if (!this.tasks.length) {
            this.count = 0;
            return;
        }
        const task = this.tasks.pop();
        this.count++;
        task.subscribe(null, null, () => {
            this.count--;
            this.run(batchSize);
        });
        this.run(batchSize);
    }
}