import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import { Observable } from 'rxjs';
import { AuditLog, AuditLogSearchParams } from "../models/audit-log.model";
import { ExportResult, ExportStatus } from '../models/export.model';
import { RestUtils } from './rest-utils';
import { BackendChannelService } from "./backend-channel.service";

@Injectable()
export class AuditLogService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient, private zone: NgZone, private backendChannel: BackendChannelService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    logsV2(params: AuditLogSearchParams): Observable<AuditLog[]> {
        if (!params.size) {
            params.size = 20;
        }
        const url = this.backendChannel.prepareUrl(this.utils.buildUrl(`ROLE/log/v2/logs`, params));
        const options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<AuditLog[]>(url, options)
    }

    logs(params: AuditLogSearchParams): Observable<any> {
        if (!params.size) {
            params.size = 20;
        }
        const url = this.backendChannel.prepareUrl(this.utils.buildUrl(`ROLE/log/logs`, params));
        const options = this.utils.getHttpHeaderOptions(this.headers);
        return new Observable(observer => {
            const eventSource = new EventSource(url, options);
            eventSource.addEventListener('log', event => this.zone.run(() => {
                const log = JSON.parse(event.data);
                observer.next({type: 'log', value: log});
            }));
            eventSource.addEventListener('count', event => this.zone.run(() => {
                observer.next({type: 'count', value: JSON.parse(event.data).count});
            }));
            eventSource.onerror = error => this.zone.run(() => {
                observer.complete();
                eventSource.close();
            });
            return () => eventSource.close();
        });
    }

    export(requestBody: Object = {}): Promise<ExportResult> {
        return new Promise((resolve, reject) => {
            let url = this.backendChannel.prepareUrl(this.utils.buildUrl('ROLE/exp/export-async/audit-logs'));
            let options = this.utils.getHttpHeaderOptions(this.headers);
            this.http.post<ExportResult>(url, requestBody, options).subscribe(d => {
                let statusCallBackUrl = d.statusCallback;
                let repeat = 0;
                let maxRepeat = 20;
                let interval = setInterval(() => {
                    let exportUrl = this.utils.buildUrl(`ROLE/exp/${statusCallBackUrl}`);
                    this.http.get<ExportStatus>(exportUrl, options).subscribe(response => {
                        if (response.status === 'EXPORTED') {
                            clearInterval(interval);
                            d.downloadCallback = this.backendChannel.prepareUrl(
                                this.utils.buildUrl(`ROLE/exp${d.downloadCallback}`)
                            );
                            resolve(d);
                        }
                        repeat++;
                        if (['FAILED', 'EXPIRED'].includes(response.status)) {
                            clearInterval(interval);
                            reject({ response, exportDetails: null });
                        }
                        if (maxRepeat === repeat) {
                            clearInterval(interval);
                            reject({ response, exportDetails: null });
                        }
                    });
                }, 3000);
            })
        });
    }

    static extractDeatils(log: AuditLog): AuditLogDetails {
        let details: AuditLogDetails = {
            raw: log.requestDetails,
            parsed: new Map<string, string|number>(),
            parsingError: false
        };
        let parsed: Object;
       /* if (log.resource.includes('/client/token') && log.requestDetails.includes('Token requested for appId')) {
            const matches = log.requestDetails.match(/\d+/);
            if (matches.length) {
                details.parsed.set('App ID', String(matches[0]));
                return details;
            }
        }
        if (log.resource === 'user.self-update') {
            try { parsed = JSON.parse(log.requestDetails)} catch (e) { details.parsingError = true; }
            if (parsed) {
                if (typeof parsed['userId'] !== undefined) {details.parsed.set('User ID', String(parsed['userId']));}
                if (typeof parsed['oldData'] !== undefined) {details.parsed.set('Old', String(parsed['oldData']));}
                if (typeof parsed['newData'] !== undefined) {details.parsed.set('New', String(parsed['newData']));}
            }
            return details;
        }
        if (log.resource && log.resource.includes('/ntc/test-groups')) {
            try { parsed = JSON.parse(log.requestDetails)} catch (e) { details.parsingError = true; }
            if (parsed) {
                if (typeof parsed['testGroupId'] !== undefined) {details.parsed.set('Test group ID', String(parsed['testGroupId']));}
                if (typeof parsed['ttl'] !== undefined) {details.parsed.set('TTL', String(parsed['ttl']));}
                if (typeof parsed['testsCount'] !== undefined) {details.parsed.set('Tests', String(parsed['testsCount']));}
                if (typeof parsed['destinationsCount'] !== undefined) {details.parsed.set('Destinations', String(parsed['destinationsCount']));}
                if (typeof parsed['suppliersCount'] !== undefined) {details.parsed.set('Suppliers', String(parsed['suppliersCount']));}
                if (typeof parsed['sendersCount'] !== undefined) {details.parsed.set('Senders', String(parsed['sendersCount']));}
                if (typeof parsed['textsCount'] !== undefined) {details.parsed.set('Texts', String(parsed['textsCount']));}
                if (typeof parsed['contentTextsCount'] !== undefined) {details.parsed.set('Content texts', String(parsed['contentTextsCount']));}
                if (typeof parsed['repeats'] !== undefined) {details.parsed.set('Repeats', String(parsed['repeats']));}
            }
            return details;
        }
        if (log.resource && log.resource.includes('/mtc/test-groups')) {
            try { parsed = JSON.parse(log.requestDetails)} catch (e) { details.parsingError = true; }
            if (parsed) {
                if (typeof parsed['testGroupId'] !== undefined) {details.parsed.set('Test group ID', String(parsed['testGroupId']));}
                if (typeof parsed['ttl'] !== undefined) {details.parsed.set('TTL', String(parsed['ttl']));}
                if (typeof parsed['testCount'] !== undefined) {details.parsed.set('Tests', String(parsed['testCount']));}
            }
            return details;
        }

        if (log.resource && log.resource.includes('/motc/test-groups')) {
            try { parsed = JSON.parse(log.requestDetails)} catch (e) { details.parsingError = true; }
            if (parsed) {
                if (typeof parsed['testGroupId'] !== undefined) {details.parsed.set('Test group ID', String(parsed['testGroupId']));}
                if (typeof parsed['ttl'] !== undefined) {details.parsed.set('TTL', String(parsed['ttl']));}
                if (typeof parsed['testsCount'] !== undefined) {details.parsed.set('Tests', String(parsed['testsCount']));}
                if (typeof parsed['destinationsCount'] !== undefined) {details.parsed.set('Destinations', String(parsed['destinationsCount']));}
                if (typeof parsed['originsCount'] !== undefined) {details.parsed.set('Origins', String(parsed['originsCount']));}
                if (typeof parsed['textsCount'] !== undefined) {details.parsed.set('Texts', String(parsed['textsCount']));}
                if (typeof parsed['repeats'] !== undefined) {details.parsed.set('Repeats', String(parsed['repeats']));}
            }
            return details;
        }

        if (log.resource.includes('user.login [impersonated]')) {
            const idMatches = log.requestDetails.match(/id=\d+/);
            if (idMatches && idMatches.length) {details.parsed.set('Admin ID', String(idMatches[0]).replace('id=', ''));}
            return details;
        }

        if (log.resource.includes('user.logout [impersonated]')) {
            const idMatches = log.requestDetails.match(/\d+/);
            if (idMatches && idMatches.length) {details.parsed.set('Admin ID', String(idMatches[0]));}
            return details;
        }*/

        if ((details.raw.startsWith('{') && details.raw.endsWith('}')) || (details.raw.startsWith('[') && details.raw.endsWith(']'))) {
            const raw = details.raw.endsWith('",}') ? details.raw.replace('",}', '"}') : details.raw;
            try {
                const parsedJson = JSON.parse(raw);
                details.raw = JSON.stringify(parsedJson, null, 4);
                return details
            } catch (e) {
                console.log(e)
            }
        }

        return details;
    }
}

export interface AuditLogDetails {
    raw: string,
    parsingError: boolean,
    parsed?: Map<string, string|number>
}
