import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { VoiceTestingService } from "../../../../shared/services/voice-testing.service";
import { VoiceTestResult } from "../../../../shared/models/voice-testing.model";

@Component({
    selector: 'app-vt-results-table-actions',
    templateUrl: './vt-results-table-actions.component.html',
    styleUrls: ['./vt-results-table-actions.component.scss']
})
export class VtResultsTableActionsComponent {

    constructor(private service: VoiceTestingService) { }

    currentRow: VoiceTestResult;

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.service.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }



}

