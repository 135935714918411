import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { UsersService } from '../../shared/services/users.service';


@Component({
    selector: 'app-mfa-recovery-form',
    templateUrl: 'mfa-recovery-form.component.html',
    styleUrls: ['mfa-recovery-form.component.scss']
})

export class MfaRecoveryFormComponent {

    loading = false;

    @Input() username: string;

    mfaCode = '';


    form: FormGroup;

    @Output() login = new EventEmitter();
    
    constructor(
        public userService: UsersService,
        formBuilder: FormBuilder,
        public notificationService: NotificationService,
        public validationService: ValidationService
    ) {
        this.form = formBuilder.group({
            mfaCode: ['', [Vld.required]],
        });
    }

    onSubmit() {
        this.loading = true;
        this.userService.mfaRecovery({username: this.username, recoveryCode: this.mfaCode}).subscribe(() => {
            this.loading = false;
            this.login.emit();
            this.notificationService.success('Welcome!', 'Login');
        }, error => {
            this.notificationService.error({
                title: 'Recovery',
                message: error.statusText === 'Invalid recovery code' ? 'Wrong recovery code!' : error.statusText,
                requestCode: error.status
            });
            this.loading = false;
        })
    }


}
