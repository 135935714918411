import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { Title } from '@angular/platform-browser';
import { CallbackHistoryFilterComponent } from '../callback-history-filter/callback-history-filter.component';
import { RowDef } from '../../../shared/components/table/table.component';
import {ModalService} from "../../../shared/services/modal.service";
import { CallbackHistoryTableComponent } from "../callback-history-table/callback-history-table.component";
import { CallbacksHistoryFilter } from "../../../shared/models/callback-history.model";

@Component({
    selector: 'app-callback-history-index',
    templateUrl: 'callback-history-index.component.html',
})

export class CallbackHistoryIndexComponent implements OnInit, AfterViewInit {

    @ViewChild(CallbackHistoryFilterComponent, {static: false}) filter: CallbackHistoryFilterComponent;
    @ViewChild(CallbackHistoryTableComponent, {static: false}) table: CallbackHistoryTableComponent;

    backButtonShow: boolean = false;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
    ) {
        titleService.setTitle('Callback history');
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    onFilter(data) {
        let params: CallbacksHistoryFilter = {};
        if (data.service) {
            params.service = data.service;
        }
        this.table.filter = params;
        this.table.page = 1;
        this.table.update();
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }
}