
import { Component, Inject, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { AppscriptsPaymentRequest } from '../../../shared/models/appscripts-payment-request.model';
import { AppscriptsPaymentRequestService } from '../../../shared/services/appscripts-payment-request.service';

@Component({
    selector: 'app-appscripts-payment-request-form',
    templateUrl: 'appscripts-payment-request-form.component.html',
})

export class AppscriptsPaymentRequestFormComponent {

    @Input() model: AppscriptsPaymentRequest
    @Input() buttonsShow = false;

    @Output() onAfterSave: EventEmitter<any> = new EventEmitter()

    newStatus = ''

    loading = false;
    regions = [];

    form: FormGroup;

    statuses = [
        {id: '', label: 'Select status'},
        {id: 'PAID', label: 'Paid'},
        {id: 'FAILED', label: 'Failed'},
    ]

    constructor(
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: AppscriptsPaymentRequestService,
        public validationService: ValidationService
    ) {
        this.notificationService = notificationService;
        this.service = service;

        this.form = formBuilder.group({
            status: ['', Vld.compose([Vld.required])],
        });
        this.validationService = validationService;
    }

    onSubmit() {
        this.loading = true;
        this.service.updateStatus(this.model.id, this.newStatus)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('Payment request updated', 'Appscripts');
                this.onAfterSave.emit();
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }
}