import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentTemplate, ContentTemplatesCollection, ContentTemplateText } from '../models/content-templates';
import { RestUtils } from './rest-utils';

@Injectable({
    providedIn: 'root'
})
export class ContentTemplateService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: AllRequestParams): Observable<ContentTemplatesCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : '',
            sort: (params.sort && params.sort.length) ? params.sort : [],
            withText: params.withText,
            searchSenderOnly: params.searchSenderOnly 
        };
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates`, queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<ContentTemplatesCollection>(url, options);
    }

    one(id: number): Observable<ContentTemplate> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ContentTemplate>(url, options);
    }

    createUpdateSmsTemplates(data): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (data.id) {
            return this.http.put(url, data, options);
        }
        return this.http.post(url, data, options);
    }

    updateVisibleAllToggle(data): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.patch(url, data, options);
    }

    updateDefaultToggle(smsTemplateTextId: Number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/text/default/${smsTemplateTextId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, {}, options);
    }

    updateGlobalDefaultAsAdminToggle(smsTemplateTextId: Number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/text/default/${smsTemplateTextId}?globalDefault=true`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, {}, options);
    }

    updateFavorite(smsTextTemplateId: number, isFavorite: boolean): Observable<ContentTemplate[]> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/text/favourite/${smsTextTemplateId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (isFavorite) {
            return this.http.post<any>(url, {}, options);
        }
        return this.http.delete<ContentTemplate[]>(url, options);
    }

    delete(id: number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete(url, options);
    }

    deleteTextTemplate(textTemplateId: number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/text/${textTemplateId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete<any>(url, options);
    }

    getFavourites(): Observable<ContentTemplate[]> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/favourites`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ContentTemplate[]>(url, options);
    }

    create(): ContentTemplate {
        return {
            id: null,
            userId: null,
            visibleAll: false,
            name: '',
            description: '',
            smsTemplateTextDtos: []
        };
    }

    formatStructrue(contentTemplates: ContentTemplate[], withFavourites: boolean = false): any[] {
        return contentTemplates.map(ct => {
            const currentTextTemplates = ct.smsTemplateTextDtos.map((stt) => ({
                id: stt.id,
                content: stt.value,
                description: stt.description,
                favorite: stt.userFavourite,
                telqIdCase: stt.telqIdCase,
                telqIdLength: stt.telqIdLength,
                telqIdType: stt.telqIdType
            }));
            if (withFavourites) {
                return {
                    id: ct.id,
                    senderId: ct.name,
                    textTemplates: currentTextTemplates,
                    nonFavTextTemplates: [],
                    favTextTemplates: currentTextTemplates
                }
            }
            return {
                id: ct.id,
                senderId: ct.name,
                textTemplates: currentTextTemplates
            }
        });
    }

    getImportExampleFileUrl() {
        return window.location.origin + '/templates/content_template.xlsx';
    }

    import(data: ContentTemplate[]): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/sms-templates/import`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, data, options);
    }

    static generateTextId(contentTemplate: ContentTemplate, text: ContentTemplateText): string {
        let parts = [contentTemplate.name, text.value, text.telqIdType];
        if (text.telqIdType === 'ALPHA' || text.telqIdType === 'ALPHA_NUMERIC') {
            parts.push(text.telqIdCase);
        }
        if (text.telqIdType !== 'WHATSAPP_CODE') {
            parts.push(String(text.telqIdLength));
        }
        return parts.join('_');
    }

}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;
    withText: boolean = false;
    searchSenderOnly: boolean = false;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
