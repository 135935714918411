import { Component, Input } from '@angular/core';

@Component({
    selector: 'deletemodal',
    templateUrl: './deletemodal.component.html',
    styleUrls: ['./deletemodal.scss']
})

export class DeleteModal {

    @Input('message') message: any;
    @Input('dialogRef') dialogRef: any;

    onCancelClick(): void {
        this.dialogRef.close(false);
    }

    onSubmitClick(): void {
        this.dialogRef.close(true);
    }

}
