import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {InvoiceService} from "../../shared/services/invoice.service";
import {Invoice} from "../../shared/models/invoice.model";

@Component({
    selector: 'app-invoices-view',
    templateUrl: './invoices-view.component.html',
})

export class InvoicesViewComponent implements OnInit {

    constructor(
        public service: InvoiceService,
        public router: Router,
        public route: ActivatedRoute,
        public titleService: Title
    ) {
        this.titleService.setTitle('Invoice');
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.titleService.setTitle('Invoice ' + params.id);
            this.router.navigate(['/invoices'], {
                queryParams: {
                    details: params.id
                }
            }).then();
        });
    }
}

