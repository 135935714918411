
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, ColumnTypes } from '../../shared/components/table/table.component';
import { Invite, InviteCollection } from "../../shared/models/invite.model";
import { InviteRequestParams, InviteService } from '../../shared/services/invite.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';


@Component({
    selector: 'app-invite-table',
    templateUrl: './invite-table.component.html'
})

export class InviteTableComponent extends TableBase implements OnInit {
    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<any> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    isAdmin: boolean = false;
    requestParams = new InviteRequestParams();

    loading: boolean = false;

    userId: number;

    constructor(
        public notificationService: NotificationService,
        public service: InviteService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        const columns = [
            {
                title: 'Email', prop: 'email', type: ColumnTypes.HTML, format: (row: Invite) => {
                    let text = row.email;
                    if (row.used) {
                        text += ' <span class="highlighted success">Joined</span>'
                    }
                    if (row.isExpired) {
                        text += ' <span class="highlighted error">Expired</span>'
                    }
                    return text;
                }, cellClassName: 'w-lg'
            },
            {
                title: 'Created', prop: 'createdAt', format: _ => {
                    return this.datePipe.transform(new Date(_.createdAt), 'medium');
                }, cellClassName: 'w-md'
            },
            {
                title: 'Invite expiration', prop: 'expiresAt', format: _ => {
                    return this.datePipe.transform(new Date(_.expiresAt), 'medium');
                }, cellClassName: 'w-md'
            }
        ];
        this.tableActions = [
            { icon: 'far fa-trash-alt', name: 'delete', show: row => this.canShowActionDelete(row) },
        ]
        this.setColumns(this.createColumns(columns));
        this.userService.getAuthUser().then(user => {
            this.userId = user.id;
            this.update();
        }).catch(error => {
            this.notificationService.error({
                title: 'Invites',
                message: 'An error occurred while loading user',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        });
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.service.all(this.userId, this.requestParams).subscribe((collection: InviteCollection) => {
            this.setData(this.createRows(collection.content), collection.totalElements);
            this.loading = false;
        }, (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'Invites',
                message: 'An error occurred while loading the invites',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        })
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }
        this.actions.emit(event);
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    delete(invite) {
        this.loading = true;
        this.service
            .delete(invite.id)
            .subscribe(() => {
                this.notificationService.success('Invite removed', 'Invites');
                this.onAfterDelete.emit();
                this.update();
                this.loading = false
            }, () => this.loading = false);
    }

    canShowActionDelete(row: Invite) {
        return !row.used && !row.isExpired;
    }
}
