
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { ModalService } from "../../shared/services/modal.service";
import { NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';
import { NetworkTableComponent } from '../network-table/network-table.component';

@Component({
    selector: 'app-network-index',
    templateUrl: './network-index.component.html',
    styleUrls: [],
})

export class NetworkIndexComponent {

    @ViewChild(NetworkTableComponent, {static: false}) table: NetworkTableComponent;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public netInfoService: NetInfoService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Networks');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    onCreate() {
        this.table.onCreate();
    }

    batchClear(): void {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) {return;}
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.netInfoService.deleteNetwork(row.data.id).pipe(map(() => {
                this.notificationService.success('Networks deleted', 'Networks');
                row.batch = false;
                this.completeTask()
            }),catchError((err, caught) => {
                this.notificationService.error('Network delete an error', 'Networks');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.table.requestParams.search = phrase.trim();
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.load();
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
