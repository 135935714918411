import { Component, Input } from '@angular/core';
import { BrowserUtils } from "../../shared/services/browser-utils";

@Component({
    selector: 'app-mfa-recovery-codes',
    templateUrl: 'mfa-recovery-codes.component.html',
    styleUrls: ['mfa-recovery-codes.component.scss']
})

export class MfaRecoveryCodesComponent {

    @Input() codes: string[] = [];

    onClickDownload() {
        const content = new Blob([this.getContentAsText()], {
            type: 'text/plain'
        });
        BrowserUtils.downloadFile(content, "mfa-recovery-codes-telq.txt")
    }

    onClickCopy() {
        BrowserUtils.copyToClipboard(this.getContentAsText());
    }

    private getContentAsText(): string {
        return this.codes.join("\r\n")
    }
}