
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { UdhTlv } from "../../shared/models/udh-tlv.model";
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';

@Component({
    selector: 'app-udh-tlv-input',
    templateUrl: 'udh-tlv-input.component.html',
    styleUrls: ['udh-tlv-input.component.scss']
})

export class UdhTlvInputComponent {

    @Output() onChange = new EventEmitter();
    @Input() maxModels: number = 6;
    @Input() tagSizeBytes: number = 2;

    models: ModelItem[] = [];

    counter: number = 0;

    form: FormGroup;

    constructor(fb: FormBuilder, public validationService: ValidationService) {
        this.form = fb.group({});
    }

    static hexStringToByte(str: string): number[] {
        if (!str) {
            return [];
        }
        let a = [];
        for (let i = 0, len = str.length; i < len; i += 2) {
            a.push(parseInt(str.substr(i, 2), 16));
        }
        return a;
    }

    getFieldLength(value: string): number {
        return value.length / 2;
    }

    getUdhLength(value: string): number {
        const udhLength = 1 + 1 + this.getFieldLength(value);
        return udhLength;
    }

    getPaddedLength(value: number, padLength: number): string {
        return value.toString().padStart(padLength, '0');
    };

    getTagValue(valueHex: string): string {
        if (this.tagSizeBytes === 1 || valueHex.length < 196) {
            return this.getPaddedLength(this.getFieldLength(valueHex), 2);
        }
        return this.getPaddedLength(this.getFieldLength(valueHex), 4);
    }

    getValue(valueHex: string): string {
        if (this.tagSizeBytes === 1 || valueHex.length < 196) {
            return this.getPaddedLength(this.getUdhLength(valueHex), 2);
        }
        return this.getPaddedLength(this.getUdhLength(valueHex), 4);
    }

    getSimulatedUdh(): string {
        if (!this.models || this.models.length === 0) {
            return '';
        }
        let udhRender = '';
        let udh = '';
        for (let model of this.models) {
            const currentUdh = model.tagHex + ('0' + this.getFieldLength(model.valueHex)).slice(-2) + model.valueHex;
            udh += currentUdh;
            const currentUdhRender = `<span class="text-sucess-s2">${model.tagHex}</span>` + ('0' + this.getFieldLength(model.valueHex)).slice(-2) + `<span class="text-primary">${model.valueHex}</span>`;
            udhRender += currentUdhRender;
            if (model.valueHex?.length === 0) {
                return '';
            }
        }
        return this.getPaddedLength(this.getFieldLength(udh), 2) + udhRender;
    }

    getSimulatedTlv(): string {
        if (!this.models || this.models.length === 0) {
            return '';
        }
        let tlvRender = '';
        let tlv = '';
        for (let model of this.models) {
            const lengthStr = model.valueHex.length < 196 ? this.getPaddedLength(this.getFieldLength(model.valueHex), 2) : this.getPaddedLength(this.getFieldLength(model.valueHex), 4);
            const currentTlv = `${model.tagHex}${lengthStr}${model.valueHex}`;
            tlv += currentTlv;
            const currentTlvRender = `<span class="text-sucess-s2">${model.tagHex}</span>${lengthStr}<span class="text-primary">${model.valueHex}</span>`;
            tlvRender += currentTlvRender;
        }
        const calculatedLength = tlv.length < 196 ? this.getPaddedLength(this.getFieldLength(tlv), 2) : this.getPaddedLength(this.getFieldLength(tlv), 4);
        return `${calculatedLength}${tlvRender}`;
    }

    addControls(data, count = 1) {
        while (count) {
            count--;
            this.createControl(data);
        }
    }

    createControl(data: UdhTlv) {
        let model: ModelItem = {
            tagHex: data.tagHex + '',
            valueHex: data.valueHex,
            controlTagName: 'tag' + this.counter,
            controlValueName: 'value' + this.counter,
        };
        let tagControl = new FormControl(model.tagHex, Vld.compose([
            Vld.required,
            Vld.hex(true),
            UdhTlvInputComponent.createTagValidator(this.tagSizeBytes, false)
        ]));
        this.form.addControl(model.controlTagName, tagControl);

        let valueControl = new FormControl(model.valueHex, Vld.compose([
            Vld.required,
            Vld.hex(true),
            UdhTlvInputComponent.createValueValidator(false)
        ]));
        this.form.addControl(model.controlValueName, valueControl);

        this.models.push(model);
        this.counter++;
    }

    static createTagValidator(sizeBytes = 2, skipEmpty = true): ValidatorFn {
        return (control) => {
            let val: string = control.value,
                err = {
                    custom: { message: `Must be ${sizeBytes} ` + (sizeBytes === 1 ? 'byte' : 'bytes') }
                },
                min = 0,
                max = 255;
            if (skipEmpty && !val) {
                return null;
            }
            if (val) {
                let byteArr = UdhTlvInputComponent.hexStringToByte(val).filter(_ => _ >= min && _ <= max);
                if (byteArr.length !== sizeBytes) { return err; }
                if ((val.length % 2) !== 0) {
                    return { custom: { message: 'Length must be even' } };
                }
                return;
            }
            return err;
        };
    }

    static createValueValidator(skipEmpty = true): ValidatorFn {
        return (control) => {
            let val: string = control.value,
                err = {
                    custom: { message: 'Must be a hex string and must not exceed 2550 bytes.' }
                };
            if (skipEmpty && !val) {
                return null;
            }
            if (val && (val.length % 2) !== 0) {
                return { custom: { message: 'Length must be even' } };
            }
            if (val) {
                let byteArr = UdhTlvInputComponent.hexStringToByte(val);
                if (!byteArr.length || byteArr.length > 2550) {
                    return err;
                }
                return;
            }
            return err;
        };
    }

    getModels(): UdhTlv[] {
        return this.models.map(_ => {
            return {
                id: _.id ? _.id : null,
                tagHex: _.tagHex,
                valueHex: _.valueHex
            };
        });
    }

    removeControls(model) {
        this.models = this.models.filter(_ => _.controlTagName !== model.controlTagName);
        this.form.removeControl(model.controlTagName);
        this.form.removeControl(model.controlValueName);
    }

    reset() {
        this.models.forEach(_ => this.removeControls(_));
    }
}

type ModelItem = {
    id?: any,
    tagHex: string,
    valueHex: string,
    controlTagName: string,
    controlValueName: string
}