import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SettingService } from "../../shared/services/setting.service";
import { UsersService } from "../../shared/services/users.service";
import { forkJoin } from "rxjs";


@Component({
    selector: 'app-disabled-section-widget',
    templateUrl: './disabled-section-widget.component.html',
    styleUrls: ['./disabled-section-widget.component.scss']
})

export class DisabledSectionWidgetComponent implements OnInit, OnDestroy {

    state: ServiceState = {
        service: true,
        user: true
    };
    private interval = null;
    init = false;

    @Input() updatePeriod = 60 * 1000;
    @Input() service: string = null;
    @Input() serviceMessage: string = 'Disabled section';
    @Input() userMessage: string = 'This section for your account is disabled';

    @Output() changeState = new EventEmitter<ServiceState>()

    constructor(
        private settingsService: SettingService,
        private userService: UsersService,
    ) {
    }

    ngOnInit() {
        this.update();
        this.interval = setInterval(() => this.update(), this.updatePeriod);
    }

    ngOnDestroy() {
        if (this.interval !== null) {
            clearInterval(this.interval)
        }
    }

    private update() {
        forkJoin([
            this.settingsService.allKv(),
            this.userService.i()
        ]).subscribe({
            next: values => {
                const settings = values[0];
                const user = values[1];
                if (this.service) {
                    const serviceState = SettingService.getServiceState(this.service, settings, user.role, this.serviceMessage);
                    this.state.service = !serviceState.disabled;
                    this.serviceMessage = serviceState.message;
                }
                this.state.user = user.enabled;
                this.init = true;
                this.changeState.emit(this.state)
            },
            error: err => {},
        });
    }
}

export interface ServiceState {
    service: boolean,
    user: boolean
}