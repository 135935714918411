
import {
    Component,
    EventEmitter,
    ElementRef,
    ViewContainerRef,
    TemplateRef,
    ViewChild,
    Input,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { NotificationService } from '../../shared/services/notification.service';
import { CountryService } from '../../shared/services/country.service';
import { CustomUtils } from '../../shared/services/custom-utils';
import { UsersService } from '../../shared/services/users.service';
import { Role } from '../../shared/models/user.model';
import { UserFormData } from '../../shared/models/user.model';
import {DialogRef, ModalService} from "../../shared/services/modal.service";
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-users-form',
    styleUrls: ['./users-form.component.scss'],
    templateUrl: 'users-form.component.html',
})

export class UsersFormComponent {

    @ViewChild('userListModalTpl', { read: TemplateRef, static: false }) userListModalTpl: any;

    selectUserMode;
    parentUser;
    ownerUser;
    salesRepresentativeUser;
    convertedBy;
    convertedByUser;

    loading = false;
    @Input() model;
    @Output() onAfterSave: EventEmitter<void> = new EventEmitter();

    countries: string[] = [''];

    countSubaccounts: number;
    convertedToSubaccount = false;

    types;
    roles;

    backButtonShow = true;

    form: FormGroup;
    userListModal: DialogRef;

    constructor(
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public service: UsersService,
        public countryService: CountryService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        this.model = service.create();
        countryService.all().then(countries => {
            this.countries.push(...countries);
            this.countries.sort();
        }).catch(e => { });

        const parentIdValidator = (v) => {
            if (this.model.id) {
                return null;
            }
            if (this.model.userRole === 'subaccount' && !v) {
                return { parentIdValidator: true }
            }
            return null;
        }
        const passwordValidator = (v) => {
            if (!this.model.id && !v.value) {
                return { required: true }
            }
            return null;
        }
        const rankingIndexValidator = (c) => {
            if (!this.model.userRole || this.model.userRole === Role.SUB) {
                return null;
            }
            const val = parseInt(c.value);
            if (isNaN(val)) {
                return { integer: true };
            }
            if (val < 0) {
                return { min: { min: 0 } };
            }
            if (val > 10) {
                return { max: { max: 10 } };
            }
            return null;
        }
        validationService.addFormatter('parentIdValidator', validationService.createTextFormatter('Select parent'))
        this.form = formBuilder.group({
            fullName: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            email: ['', Vld.compose([Vld.required, Vld.email(true)])],
            password: ['', Vld.compose([passwordValidator, Vld.maxLength(50), Vld.password(true)]), [Vld.passwordBackend(service)]],
            paymentType: [''],
            enabled: [''],
            emailVerified: [''],
            sendVerificationEmail: [''],
            username: ['', Vld.compose([Vld.required, Vld.username(true)])],
            companyName: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            websiteUrl: ['', Vld.compose([Vld.noSpace()])],
            countryName: ['', Vld.compose([Vld.required])],
            userRole: ['', [Vld.required]],
            phone: ['', Vld.compose([Vld.required, Vld.globalPhoneNumber(), Vld.noSpace()])],
            emailForAlert: ['', Vld.compose([Vld.email(true)])],
            emailForInvoice: ['', Vld.compose([Vld.email(true)])],
            addressCity: ['', Vld.compose([Vld.minLength(3), Vld.maxLength(100)])],
            addressStreet: ['', Vld.compose([Vld.minLength(1), Vld.maxLength(100)])],
            addressZip: ['', Vld.compose([Vld.minLength(3), Vld.maxLength(20)])],
            vatNumber: ['', Vld.compose([Vld.minLength(3), Vld.maxLength(20)])],
            vatPercentage: ['', Vld.compose([Vld.min(0, true), Vld.max(100, true)])],
            applyVat: [''],
            testRetriesEnabled: [''],
            rankingIndex: ['', rankingIndexValidator],
            parentId: ['', Vld.compose([parentIdValidator])],
            ownerId: [''],
            testTtl: ['', UsersService.getValidatorForTestTtlMin()],
            source: [''],
            convertedBy: [''],
            salesRepresentative: [''],
            ipWhitelistEnabled: [''],
            ipWhitelistAddressList: ['']
        });

        this.form.statusChanges.subscribe(_ => {
            if (this.model.emailVerified) {
                this.model.sendVerificationEmail = false;
            }
        });
        this.types = this.service.getTypes();
        this.roles = this.service.getCreateRoles();
    }

    onChangeModelOnRole(model) {
        if (model.userRole === Role.SUB) {
            const keysTobeNull = ['rankingIndex', 'vatNumber', 'vatPercentage', 'testRetriesEnabled', 'testTtl', 'websiteUrl'];
            keysTobeNull.map(key => {
                model[key] = null;
            });
        }
        return model;
    }

    onSubmit() {
        this.loading = true;
        this.model = CustomUtils.trimModelFields(this.model);
        this.model = this.onChangeModelOnRole(this.model);
        const success = () => {
            this.loading = false;
            this.notificationService.success(
                'User ' + (this.model.id ? 'updated' : 'created'),
                'Users'
            );
            this.onAfterSave.emit();
        }
        const error = e => {
            this.loading = false;
            this.notificationService.error({
                title: 'Users',
                message: 'An error occurred while ' + (this.model.id ? 'updating' : 'creating') + ' the user',
                serviceName: 'API GATEWAY',
                requestMessage: e.statusText,
                requestCode: e.status,
                ts: e.timestamp ? e.timestamp : null
            });
        }
        if (this.model.id) {
            if (this.convertedToSubaccount) {
                this.service.convertToSubaccount(this.model.id, this.model.parent.id).subscribe(() => {
                    if (!this.model.source) {
                        this.model.source = null;
                    }
                    this.service.updateByAdmin(this.model).subscribe(success, error)
                }, error)
            } else {
                this.service.updateByAdmin(this.model).subscribe(success, error)
            }
        } else {
            this.service.createByAdmin(this.model).subscribe(success, error)
        }
    }

    showUserListModal(mode) {
        this.selectUserMode = mode;
        this.userListModal = this.modal.alert().component(this.userListModalTpl).open();
    }

    onSelectParentUser(user) {
        if (this.selectUserMode === 'parent') {
            if (this.model.id === user.id) {
                this.notificationService.error('User and parent must have different ID', 'Users');
                return;
            }
            this.parentUser = user;
            this.model.parent = user;
            this.form.controls.parentId.patchValue(user.id);
        }

        if (this.selectUserMode === 'owner') {
            this.ownerUser = user;
            this.model.owner = user;
            this.form.controls.ownerId.patchValue(user.id);
        }

        if (this.selectUserMode === 'salesRepresentative') {
            this.salesRepresentativeUser = user;
            this.model.salesRepresentative = user;
            this.form.controls.salesRepresentative.patchValue(user.id);
        }

        if (this.selectUserMode === 'convertedBy') {
            this.convertedByUser = user;
            this.model.convertedBy = user;
            this.form.controls.convertedBy.patchValue(user.id);
        }

        if (this.userListModal) {
            this.userListModal.close(true);
        }
    }

    onChangeUserRole() {
        const role = this.model.userRole;
        if ((role === Role.ADMIN || role === Role.MAIN) && this.model.parent) {
            this.model.parent = null;
            this.parentUser = null;
            this.form.controls.parentId.patchValue('');
        }

        if (role === Role.ADMIN || role === Role.SUB || role === Role.DEPUTY) {
            this.model.owner = null;
            this.form.controls.ownerId.patchValue('');
        }

        if (role !== Role.MAIN || role === Role.SUB || role === Role.DEPUTY) {
            this.model.source = null;
            this.form.controls.source.patchValue('');
            this.model.convertedBy = null;
            this.form.controls.convertedBy.patchValue('');
            this.model.salesRepresentative = null;
            this.form.controls.salesRepresentative.patchValue('');
        }
    }

    onClickConvertToSubaccount() {
        this.model.userRole = Role.SUB;
        this.convertedToSubaccount = true;
        this.onChangeUserRole();
    }

    showParent(): boolean {
        return this.model.userRole === Role.SUB || this.model.userRole === Role.DEPUTY;
    }

    showOwner(): boolean {
        return this.model.userRole === Role.MAIN;
    }

    showWebsite(): boolean {
        return this.model.userRole !== Role.SUB && this.model.userRole !== Role.DEPUTY;
    }

    showVatNumber(): boolean {
        return this.model.userRole !== Role.SUB && this.model.userRole !== Role.DEPUTY;
    }

    showRankingIndex(): boolean {
        return this.model.userRole !== Role.SUB && this.model.userRole !== Role.DEPUTY;
    }

    showBackupTests(): boolean {
        return this.model.userRole !== Role.SUB && this.model.userRole !== Role.DEPUTY;
    }

    showTestTtl(): boolean {
        return this.model.userRole !== Role.SUB && this.model.userRole !== Role.DEPUTY;
    }

    isFormValid(): boolean {
        if (this.form.valid && this.model.userRole === Role.SUB || this.model.userRole === Role.DEPUTY) {
            if (this.model.parent) {
                return true;
            }
            return false;
        }
        return this.form.valid;
    }

    isConvertToSubaccount(): boolean {
        return this.model && this.model.id && this.model.userRole === Role.MAIN && this.countSubaccounts === 0;
    }

    setModel(model: UserFormData): void {
        this.model = model;
        if (this.model.userRole === Role.MAIN) {
            this.service.countSubaccounts(this.model.email).subscribe(count => this.countSubaccounts = count);
        }
        if (model.parent && !this.parentUser) {
            this.parentUser = model.parent;
        }
        if (model.owner && !this.ownerUser) {
            this.ownerUser = model.owner;
        }
        if (model.salesRepresentative && !this.salesRepresentativeUser) {
            this.salesRepresentativeUser = model.salesRepresentative;
        }
        if (model.convertedBy && !this.convertedByUser) {
            this.convertedByUser = model.convertedBy;
        }
    }
}
