
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RowDef } from '../../shared/components/table/table.component';
import { StopWord } from "../../shared/models/stop-word.model";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { StopWordsService } from '../../shared/services/stop-words.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { StopWordsTableComponent } from '../stop-words-table/stop-words-table.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-stop-words-index',
    templateUrl: './stop-words-index.component.html',
    styleUrls: [],
})

export class StopWordsIndexComponent {

    @ViewChild(StopWordsTableComponent, { static: false }) table: StopWordsTableComponent;
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    form: FormGroup;
    formModal: DialogRef;
    model: StopWord;
    formLoading = false;

    constructor(
        public words: StopWordsService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
        public formBuilder: FormBuilder,
        public validationService: ValidationService,
    ) {
        titleService.setTitle('Stop words');
        this.form = formBuilder.group({
            word: ['', Vld.compose([Vld.required, Vld.maxLength(255), Vld.noSpace()])],
            mo: [''],
            mt: ['']
        });
    }

    wordAction(event) {
    }

    create() {
        this.model = this.words.create();
        this.formModal = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.formModalTpl).open();
    }

    onSubmit() {
        this.formLoading = true;
        this.words.createMultiple(this.model).subscribe({
            next: () => {
                this.formLoading = false;
                this.formModal.close();
                this.table.update();
                this.notificationService.success('Word created', 'Stop words');
            },
            error: e => {
                this.formLoading = false;
                this.notificationService.error({
                    title: 'Stop words',
                    message: 'An error occurred while creating the stop word',
                    serviceName: 'NTC',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
            }
        });
    }
}
