import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-account-index',
    templateUrl: 'account-index.component.html',
})

export class AccountIndexComponent {

    constructor(titleService: Title) {
        titleService.setTitle('Account');
    }
}