import {
    Component,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-dashboard-counter-widget',
    templateUrl: './dashboard-counter-widget.component.html',
    styleUrls: ['dashboard-counter-widget.component.scss'],
    animations: [
        trigger('diffToggle', [
            state('show', style({opacity: 1})),
            state('hide', style({opacity: 0})),
            transition('show => hide', [animate('0.3s')]),
            transition('hide => show', [
                animate('0.3s')

            ])
        ]),
    ]
})

export class DashboardCounterWidgetComponent implements OnChanges {

    @Input() title: string;
    @Input() val: number|undefined;

    diff = 0;
    diffShow = false;
    private diffTimeout = 1000 * 5;

    private icons = {
        Tests: 'icon-tests',
        Invoices: 'icon-payments',
        Suppliers: 'fa fa-users',
        Connections: 'icon-connections',
        Senders: 'icon-senders',
        Templates: 'icon-templates'
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.val && changes.val.previousValue !== undefined) {
            this.checkDiff(<number>changes.val.previousValue, <number>changes.val.currentValue);
        }
    }

    getIcon(): string {
        if (!this.title) {return '';}
        return this.icons[this.title] ? this.icons[this.title] : '';
    }

    private checkDiff(prev: number, current: number) {
        if (current !== prev) {
            this.diff = current - prev;
            this.diffShow = true;
            setTimeout(() => {
                this.diffShow = false;
                setTimeout(() => this.diff = 0, 300);
            }, this.diffTimeout);
        }
    }
}
