import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { SessionsService } from '../../../shared/services/sessions.service';

@Component({
    selector: 'app-no-supplier-overlay',
    templateUrl: './no-supplier-overlay.component.html',
    styleUrls: ['./no-supplier-overlay.component.scss']
})
export class NoSupplierOverlayComponent implements OnInit {

    public params!: INoRowsOverlayParams & { noRowsMessageFunc: () => string };

    constructor(private sessionService: SessionsService) { }

    ngOnInit(): void {
    }

    agInit(
        params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }
    ): void {
        this.params = params;
    }

    addSupplier(): void {
        const activeSession = this.sessionService.getActiveSessionForSupplier();
        this.sessionService.announceAction({ name: 'addSupplier', row: activeSession, column: 'action' });
    }

}
