import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Destination } from '../models/destination.model';
import { RestUtils } from './rest-utils';
import {
    VoiceTestGroup,
    VoiceTestResult,
    VoiceTestResultCollection,
    VoiceTestStatus
} from "../models/voice-testing.model";
import { Observable, of, Subject } from "rxjs";
import { TestDetails, TestResult } from "../models/test-result.model";
declare var moment: any;

@Injectable()
export class VoiceTestingService {

    private utils = new RestUtils();
    private headers = new HttpHeaders();

    private actionSubject = new Subject<VtActionData>();
    action$ = this.actionSubject.asObservable();

    constructor(private http: HttpClient) {
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: VtActionData) {
        this.actionSubject.next(action);
    }

    results(params: AllRequestParams, search?: SearchParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
        };

        let url = this.utils.buildUrl('ROLE/voice/test-results/search', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<VoiceTestResultCollection>(url, search, options);
    }

    run(group: VoiceTestGroup) {
        let url = this.utils.buildUrl(`ROLE/voice/test-groups`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, group, options);
    }

    getResultDetailsText(result: VoiceTestResult): Observable<TestDetails[]> {
        let text = [];
        text.push('ID: ' + result.id);
        text.push('Test group ID: ' + result.testGroupId);
        text.push('Date: ' + moment(result.createdAt).format('DD MMM YYYY HH:mm:ss'));
        text.push('Country: ' + this.formatDestination(result.destination));
        text.push('Phone: ' + (result.destination.phone ? result.destination.phone : 'N/A'));
        text.push('Status: ' + result.testStatus);
        text.push('Delay: ' + (result.delay ? result.delay + 's' : 'N/A'));
        text.push('Caller: ' + (result.callerId ? result.callerId : 'N/A'));
        text.push('Call created: ' + (result.callCreatedAt ?moment(result.callCreatedAt).format('DD MMM YYYY HH:mm:ss') : 'N/A'));
        text.push('Call connected: ' + (result.callConnectedAt ?moment(result.callConnectedAt).format('DD MMM YYYY HH:mm:ss') : 'N/A'));
        return of([
            {
                id: 'general',
                title: 'General',
                data: text
            }
        ])
    }

    formatDestination(dest: Destination, icon = false): string {
        if (dest.mnc === null && dest.mcc === null) {
            return (dest.countryName ? dest.countryName : 'Unknown') + '/' + dest.phone;
        }

        let str = (dest.countryName ? dest.countryName : 'Unknown') + '(' + dest.mcc + ')/' + (dest.providerName ? dest.providerName : 'Unknown') + '(' + dest.mnc + ')';

        if (dest.originalMnc) {
            let iconStr = icon ? '<i class="icon-ported"></i>' : '<=';
            let provider = dest.originalProviderName ? dest.originalProviderName : 'Unknown';
            let originalStr = ' ' + iconStr + ' ' + provider + '(' + dest.originalMnc + ')';
            if (icon) {
                originalStr = `<span title="Ported from ${provider}">${originalStr}</span>`;
            }
            str += originalStr;
        }

        return str;
    }

    recentDestinations() {
        let url = this.utils.buildUrl('ROLE/voice/recent-destinations', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Destination[]>(url, options);
    }

}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface SearchParams {
    testGroupIds?: number[];
    testIds?: number[];
    userIds?: number[];
    networkDetails?: Destination[];
    callerIds?: string[];
    createdAtFrom?: string;
    createdAtTo?: string;
    statuses?: VoiceTestStatus[];
}

interface VtActionData {
    name: string;
    row: VoiceTestResult;
    column: string;
}
