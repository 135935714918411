
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { TestCaseTemplatesService } from "../../shared/services/test-case-template.service";
import { TestCaseTemplateTableComponent } from '../test-case-template-table/test-case-template-table.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-test-case-template-index',
    templateUrl: './test-case-template-index.component.html',
    styleUrls: ['./test-case-template-index.component.scss'],
})

export class TestCaseTemplateIndexComponent {

    @ViewChild(TestCaseTemplateTableComponent, { static: false }) table: TestCaseTemplateTableComponent;
    @ViewChild('testCaseTemplateUpdateModalTpl', { read: TemplateRef, static: false }) testCaseTemplateUpdateModalTpl: any;
    testCaseTemplateUpdateModal: DialogRef;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public templates: TestCaseTemplatesService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
        private location: Location
    ) {
        titleService.setTitle('Test templates');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    clearUrlQuery(): void {
        // Clear the URL with any ids present ...
        const currentPath = this.location.path();
        const currentHistoryState = this.location.getState();
        const newPath = currentPath.split('?')[0];
        this.location.replaceState(newPath, '', currentHistoryState);
    }

    templateAction(event) {
        if (event.name === 'edit') {
            const currentUrl = this.router.url.split('?')[0];
            const navigationExtras: NavigationExtras = {
                queryParams: { 'id': event.row.data.id },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            };
            this.router.navigate([currentUrl], navigationExtras);
            this.testCaseTemplateUpdateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.testCaseTemplateUpdateModalTpl).open();
            this.testCaseTemplateUpdateModal.result.then((res) => {
                this.router.navigate([currentUrl]);
            });
        }
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.templates.delete(row.data.id).pipe(
                map(() => {
                    this.notificationService.success('Templates deleted', 'Templates');
                    row.batch = false;
                    this.completeTask();
                }), catchError((err, caught) => {
                    this.notificationService.error('Template delete  an error', 'Templates');
                    this.completeTask();
                    return caught;
                }));
        });
        this.startTasks(tasks);
    }

    batchClear(): void {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    onChangeInputSearch(phrase: string) {
        this.table.requestParams.search = phrase.trim();
        this.table.requestParams.page = 0;
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    onTestCaseTemplateSave(saved: boolean) {
        this.clearUrlQuery();
        this.testCaseTemplateUpdateModal.close();
        if (saved) {
            this.table.requestParams.page = 0;
            this.table.update();
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
