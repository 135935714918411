
import { DatePipe } from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import {ActionEvent, ColumnTypes} from '../../../shared/components/table/table.component';
import { AppscriptsSmsFilter } from '../../../shared/models/appscripts-sms.model';
import { AppscriptsSmsRequestParams, AppscriptsSmsService } from '../../../shared/services/appscripts-sms.service';
import { AppscriptsUserRequestParams, AppscriptsUserService } from '../../../shared/services/appscripts-user.service';
import {CellClickEvent, SortEvent} from "../../../shared/components/table/table.component";
import {ModalService} from "../../../shared/services/modal.service";
import { BrowserUtils } from "../../../shared/services/browser-utils";

@Component({
    selector: 'app-appscripts-sms-table',
    templateUrl: 'app-appscripts-sms-table.html',
})

export class AppscriptsSmsTableComponent extends TableBase implements OnInit {

    @Output() actions = new EventEmitter();

    filter: AppscriptsSmsFilter;
    requestParams = new AppscriptsSmsRequestParams();
    storageContextName = 'appscripts-sms-table';

    loading = true;

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsSmsService,
        public serviceUser: AppscriptsUserService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id', sort: true },
            { title: 'Phone', prop: 'phoneNumber', cellClassName: 'editable' },
            { title: 'Text', prop: 'text', type: ColumnTypes.HTML, format: this.formatText },
            { title: 'Sender', prop: 'sender' },
            { title: 'SMSC', prop: 'smsc' },
            { title: 'Received', prop: 'receivedAt', format: row => this.formatDate(row.receivedAt), sort: true },
            { title: 'Created', prop: 'createdAt', format: row => this.formatDate(row.createdAt), sort: true },
        ]));
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onChangeSize(size: number) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }
        if (this.filter) {
            this.requestParams.filter = this.filter;
        }
        this.loading = true;
        this.service.allMt(this.requestParams).subscribe((data) => {
            this.setData(this.createRows(data.content), data.totalElements > 5000 ? 5000 : data.totalElements);
            this.loading = false;
        }, () => this.loading = false);
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    formatDate(date) {
        return this.datePipe.transform(new Date(date), 'medium');
    }

    formatText(row) {
        if (!row.text) {
            return '';
        }
        return BrowserUtils.escapeHtml(row.text).replace(row.telqId, `<span class="text-success">${row.telqId}</span>`);
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'phoneNumber') {
            this.router.navigate(['appscripts/numbers'], { queryParams: { phonenumber: event.row.data.phoneNumber } }).then();
        }
    }
}
