import { Component } from '@angular/core';
import { UserAdmin } from '../../../shared/models/user.model';
import { ICellRendererParams } from 'ag-grid-community';
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'app-users-actions',
    templateUrl: './users-actions.component.html',
    styleUrls: ['./users-actions.component.scss']
})
export class UsersActionsComponent {

    currentRow: UserAdmin;

    constructor(private userService: UsersService) { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.userService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

    formatNumericBoolean(n): string {
        if (typeof n === 'boolean') {
            return n ? 'yes' : 'no'
        }
        return n === '1' ? 'yes' : 'no'
    }

    isResendVerificationPossible(): boolean {
        return this.formatNumericBoolean(this.currentRow.emailVerified) === 'no' && this.currentRow.role === 'mainaccount';
    }
}
