import { Component} from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";
import { CoverageNetwork } from "../../shared/models/coverage.model";

@Component({
    selector: 'app-coverage-table-actions-renderer',
    template: `
        <div class="actions">
            <button *ngIf="hasMyRequest" class="btn btn-sm btn-outline-danger action" tooltip="Remove request" (click)="onAction('request-delete')">Remove request</button>
            <button *ngIf="!hasMyRequest && !online" class="btn btn-sm btn-outline-primary action" tooltip="Add request" (click)="onAction('request-create')">Request network</button>
        </div>
    `,
    styles: [`
        .action {
            cursor: pointer; 
            padding: 3px 7px;
            width: 100%;
        }
    `]
})
export class CoverageTableActionsRendererComponent implements ICellRendererAngularComp {
    hasMyRequest = false;
    online = false;
    requests = 0;
    private params: ICellRendererParams<CoverageNetwork> & {action: Function};

    agInit(params: ICellRendererParams<CoverageNetwork> & {action: Function}): void {
        this.params = params;
        this.setData(params.data);
    }

    refresh(params: ICellRendererParams<CoverageNetwork>): boolean {
        this.setData(params.data);
        return true;
    }

    private setData(network: CoverageNetwork) {
        this.hasMyRequest = network.hasMyRequest;
        this.online = network.online;
        this.requests = network.requests;
    }

    onAction(action: string) {
        this.params.action(action, this.params);
    }

}