import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
import { Role } from '../../models/user.model';

@Component({
    selector: 'app-ag-tooltip',
    templateUrl: './ag-tooltip.component.html',
    styleUrls: ['./ag-tooltip.component.scss']
})
export class AgTooltipComponent {

    constructor() { }

    private params!: ITooltipParams;
    private currentHoverFieldId: string;
    tooltipData: string = '';

    agInit(params: ITooltipParams): void {
        this.params = params;
        if (params.location === 'header') {
            this.tooltipData = params.value;
            return;
        }
        const currentRow = params.data;
        this.currentHoverFieldId = params.column?.getUniqueId();
        switch (this.currentHoverFieldId) {
            case 'senderDelivered':
                if (currentRow.senderDelivered !== currentRow.senderSent) {
                    this.tooltipData = currentRow.senderSent;
                }
                break;
            case 'user':
                this.tooltipData = currentRow.user.username;
                break;
            case 'supplierCount':
                this.tooltipData = currentRow.supplierCount ? 'Show all' : null;
                break;
            case 'username': // for user table
                if (currentRow.role === Role.ADMIN) { this.tooltipData = 'Admin user'; return; }
                if (currentRow.role === Role.MAIN) { this.tooltipData = 'Main account'; return; }
                if (currentRow.role === Role.SUB) { this.tooltipData = 'Subaccount'; return; }
                if (currentRow.role === Role.DEPUTY) { this.tooltipData = 'Deputy account'; return; }
                break;
            case 'ownerUserEmail':
                this.tooltipData = 'Account manager';
                break;
            case 'emailVerified':
                this.tooltipData = 'Email verification and HMAC-based OTP status';
                break;
            default:
                this.tooltipData = null;
        }
    }
}
