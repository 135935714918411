import { Component, OnInit } from '@angular/core';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

@Component({
    selector: 'app-expand',
    templateUrl: './expand.component.html',
    styleUrls: ['./expand.component.scss']
})
export class ExpandComponent implements OnInit {

    node: IRowNode;

    constructor() { }

    ngOnInit(): void {
    }

    agInit(params: ICellRendererParams): void {
        this.node = params.node;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    getIcon() {
        if (this.node?.expanded)
            return "ag-icon-tree-open fs-2 text-primary cp";
        return "ag-icon-tree-closed fs-2 text-neutral-n6 cp";
    }
}
