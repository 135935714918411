
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import { AuthUser, Role } from '../../shared/models/user.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UsersRequestParams, UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-subaccounts-table',
    templateUrl: 'subaccounts-table.component.html',
})

export class SubaccountsTableComponent extends TableBase implements OnInit {

    @Output() actions = new EventEmitter();

    @ViewChild('subaccountModalTpl', { read: TemplateRef, static: false }) subaccountModalTpl: any;
    subaccountModel: any;
    subaccountModal: DialogRef;

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    requestParams = new UsersRequestParams();

    MAX_TOTAL_ITEMS_COUNT = 5000;

    loading: boolean = false;
    deleteUser: AuthUser;

    constructor(
        public notificationService: NotificationService,
        public service: UsersService,
        public router: Router,
        public storage: LocalStorageService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        let columns = [
            { title: 'Email', prop: 'username', format: (row) => `${row.email} <${row.username}>`, sort: true, cellClassName: 'w-lg' },
            { title: 'Last login activity', prop: 'last_active', format: _ => this.formatLastLogin(_.lastActive), sort: true, type: ColumnTypes.HTML, cellClassName: 'w-lg' },
            { title: 'Enabled', prop: 'enabled', type: ColumnTypes.INPUT_SWITCH, sort: true, cellClassName: 'text-center w-sm' },
            { title: 'Email verified', prop: 'emailVerified', type: ColumnTypes.HTML, format: this.formatNumericBoolean, cellClassName: 'w-sm' },
            { title: 'Show all test results', prop: 'showAllResults', sort: true, type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center w-sm', popover: 'If enabled, the user will be able to search and see the test results of all other users within your organisation.' },
            { title: 'Allow MO', prop: 'moEnabled', type: ColumnTypes.INPUT_SWITCH, sort: true, cellClassName: 'text-center w-sm' },
            { title: 'Whitelist MO SCs', prop: 'moAllowedNumbersEnabled', type: ColumnTypes.INPUT_SWITCH, sort: true, cellClassName: 'text-center w-sm' },
            { title: 'Deputy account', prop: 'isDeputy', sort: false, type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center w-sm', popover: 'Deputy accounts have the same rights and privileges as admin accounts with the exception of removing or deactivating admins.' },
        ];
        this.tableActions = [
            { icon: 'icon-edit', title: 'Edit', name: 'edit' },
            { icon: 'far fa-trash-alt', title: 'Delete', name: 'delete' },
        ];
        this.service.isMoAvailableForUser().subscribe(moEnabled => {
            if (!moEnabled) {
                columns = columns.filter(column => column.prop !== 'moEnabled' && column.prop !== 'moAllowedNumbersEnabled');
            }
            this.setColumns(this.createColumns(columns));
            this.update();
        });
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.order = order;
        this.order.forEach(ord => {
            ord.prop === 'showAllResults' ? ord.prop = 'show_all_results' : ord.prop;
            ord.prop === 'moEnabled' ? ord.prop = 'mo_enabled' : ord.prop;
            ord.prop === 'moAllowedNumbersEnabled' ? ord.prop = 'mo_allowed_numbers_enabled' : ord.prop;
        });
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            this.deleteUser = event.row.data;
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'switch' && event.column.prop === 'enabled') {
            const value = event.row.data.enabled;
            this.service.updateEnable(event.row.data.id, value).subscribe(() => {
                this.notificationService.success('User updated', 'Users')
            }, error => {
                this.notificationService.error('User updating an error', 'Users')
                event.row.data.enabled = !value
            });
        }
        if (event.name === 'switch' && event.column.prop === 'showAllResults') {
            const value = event.row.data.showAllResults as boolean;
            if (event.row.data.role === Role.DEPUTY) {
                this.notificationService.error('You cannot update this option for deputy account', 'Users');
                this.update();
            } else {
                this.service.updateShowAllResults(event.row.data.id, value).subscribe(() => {
                    this.notificationService.success('User updated', 'Users');
                    this.update();
                }, error => {
                    this.notificationService.error('User updating an error', 'Users')
                    event.row.data.showAllResults = !value;
                });
            }
        }
        if (event.name === 'switch' && event.column.prop === 'moEnabled') {
            const value = event.row.data.moEnabled as boolean;
            this.service.updateMoEnabled(event.row.data.id, value).subscribe(() => {
                this.notificationService.success('User updated', 'Users');
                this.update();
            }, error => {
                this.notificationService.error('User updating an error', 'Users')
                event.row.data.moEnabled = !value;
            });
        }
        if (event.name === 'switch' && event.column.prop === 'moAllowedNumbersEnabled') {
            const value = event.row.data.moAllowedNumbersEnabled as boolean;
            this.service.updateMoAllowedNumbersEnabled(event.row.data.id, value).subscribe(() => {
                this.notificationService.success('User updated', 'Users');
                this.update();
            }, error => {
                this.notificationService.error('User updating an error', 'Users')
                event.row.data.moAllowedNumbersEnabled = !value;
            });
        }
        if (event.name === 'switch' && event.column.prop === 'isDeputy') {
            const value = event.row.data.role;
            this.service.toggleSubaccountOrDeputy(event.row.data.id, value).subscribe(() => {
                this.notificationService.success('User updated', 'Users')
                this.update();
            }, error => {
                this.notificationService.error('User updating an error', 'Users')
                event.row.data.enabled = !value
            });
        }
        if (event.name === 'edit') {
            let subaccountModel = {};
            for (let i in event.row.data) {
                subaccountModel[i] = event.row.data[i]
            }
            this.subaccountModel = subaccountModel;
            this.subaccountModal = this.modal.alert().component(this.subaccountModalTpl).open();
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }
        this.service.all(this.requestParams).subscribe(res => {
            if (res == null) {
                res = { 'content': [] };
            }
            res.content.forEach(content => content.isDeputy = content.role === Role.DEPUTY);
            this.setData(this.createRows(res.content), res.totalElements);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.notificationService.error({
                title: 'Users',
                message: 'Loading users an error',
                serviceName: 'GATEWAY',
                requestMessage: e.statusText,
                requestCode: e.status
            });
        })
    }

    onCellClick(event: CellClickEvent) {}

    delete(connection) {
        this.loading = true;
        this.service.delete(connection.id).subscribe(() => {
            this.update();
            this.notificationService.success('User removed', 'Users')
        }, error => {
            this.notificationService.success('User removed', 'Users')
            this.loading = false
        })
    }

    formatLastLogin(date) {
        if (!date) {
            return 'N';
        }
        return this.datePipe.transform(new Date(Date.parse(date)), 'medium');
    }

    formatNumericBoolean(row) {
        const isEmailVerified = row.emailVerified;
        if (typeof isEmailVerified === 'boolean') {
            return isEmailVerified ? 'Yes' : 'No'
        }
        return isEmailVerified === '1' ? 'Yes' : 'No';
    }

    onAfterSaveSubaccount(): void {
        if (this.subaccountModal) {
            this.subaccountModal.close();
        }
    }
}
