import { Destination } from './destination.model';
import { UdhTlv } from './udh-tlv.model';

export interface TestGroup {
    id?: number;
    encodingId?: number;
    protocolId?: number;
    srcNpi?: number;
    srcTon?: number;
    ttl?: number;
    validityPeriod?: number;
    scheduleDeliveryTime?: string;
    replaceIfPresent?: boolean;
    priority?: number;
    testRepeatsPerIteration: number;
    amountX: number;
    testDtos: TestGroupTest[];
    tlvDtos: UdhTlv[];
    udhDtos: UdhTlv[];
    commentText?: string;
    callerIp?: string;
    textAsTlv: boolean;
}

export interface TestGroupTest {
    destinationDto: Destination;
    price: number;
    sender: string;
    text: string;
    sessionId: number;
    supplierId: number;
    taskId: number;
    telqId: string;
    userId: number;
    commentId?: number;
}

export const TON_ITEMS = [
    {
        id: 0,
        label: '[0x00] Unknown'
    }, {
        id: 1,
        label: '[0x01] International'
    }, {
        id: 2,
        label: '[0x02] National'
    }, {
        id: 3,
        label: '[0x03] Network'
    }, {
        id: 4,
        label: '[0x04] Subscriber'
    }, {
        id: 5,
        label: '[0x05] Alphanumeric'
    }, {
        id: 6,
        label: '[0x06] Abbreviated'
    }, {
        id: 7,
        label: '[0x07] Reserved EXTN'
    },
];

export const NPI_ITEMS = [
    {
        id: 0,
        label: '[0x00] Unknown'
    }, {
        id: 1,
        label: '[0x01] E164'
    }, {
        id: 1,
        label: '[0x01] ISDN'
    }, {
        id: 3,
        label: '[0x03] X121'
    }, {
        id: 4,
        label: '[0x04] TELEX'
    }, {
        id: 6,
        label: '[0x06] Land mobile'
    }, {
        id: 8,
        label: '[0x08] National'
    }, {
        id: 9,
        label: '[0x09] Private'
    }, {
        id: 10,
        label: '[0x0A] ERMES'
    }, {
        id: 14,
        label: '[0x0E] Internet'
    }, {
        id: 18,
        label: '[0x12] WAP CLIENT ID'
    }, {
        id: 15,
        label: '[0x0F] Reserved EXTN'
    }
];

export const LIVE_NUMBER_TEST_STATUSES = [
    {
        id: 'POSITIVE',
        label: 'Positive'
    }, {
        id: 'TEXT_REPLACED',
        label: 'Text replaced'
    }, {
        id: 'SENDER_REPLACED',
        label: 'Sender ID replaced'
    }, {
        id: 'NOT_DELIVERED',
        label: 'Negative'
    }, {
        id: 'WAIT',
        label: 'Wait'
    }, {
        id: 'TEST_NUMBER_NOT_AVAILABLE',
        label: 'Test number not available'
    }, {
        id: 'NETWORK_OFFLINE',
        label: 'Network offline'
    }, {
        id: 'TEST_NUMBER_OFFLINE',
        label: 'Test number offline'
    }, {
        id: 'INTERNAL_ERROR',
        label: 'Internal error'
    }, {
        id: 'SMPP_SESSION_OFFLINE',
        label: 'SMPP Connection offline'
    }
];


export const MANUAL_TEST_STATUSES = [
    {
        id: 'WAIT',
        label: 'Wait'
    }, {
        id: 'POSITIVE',
        label: 'Positive'
    }, {
        id: 'NOT_DELIVERED',
        label: 'Negative'
    }, {
        id: 'TEST_NUMBER_NOT_AVAILABLE',
        label: 'Test number not available'
    }, {
        id: 'INTERNAL_ERROR',
        label: 'Internal error'
    }, {
        id: 'TEST_NUMBER_OFFLINE',
        label: 'Test number offline'
    }, {
        id: 'NETWORK_OFFLINE',
        label: 'Network offline'
    }
];

export const MESSAGE_STATES = [
    {
        id: 'DELIVERED',
        label: 'Delivered'
    }, {
        id: 'PARTIALLY_DELIVERED',
        label: 'Partially delivered'
    }, {
        id: 'ACCEPTED',
        label: 'Accepted'
    }, {
        id: 'PENDING',
        label: 'Pending'
    }, {
        id: 'SCHEDULED',
        label: 'Scheduled'
    }, {
        id: 'ENROUTE',
        label: 'Enroute'
    }, {
        id: 'UNKNOWN',
        label: 'Unknown'
    }, {
        id: 'NO_DLR_RECEIVED',
        label: 'No DLR received'
    }, {
        id: 'EXPIRED',
        label: 'Expired'
    }, {
        id: 'DELETED',
        label: 'Deleted'
    }, {
        id: 'UNDELIVERABLE',
        label: 'Undeliverable'
    }, {
        id: 'REJECTED',
        label: 'Rejected'
    }, {
        id: 'SKIPPED',
        label: 'Skipped'
    }, {
        id: 'SUBMIT_SM_FAILED',
        label: 'SUBMIT_SM failed'
    }
];

export const VOICE_TEST_STATUSES = [
    {
        id: 'WAIT',
        label: 'Wait'
    }, {
        id: 'RECEIVED',
        label: 'Received'
    }, {
        id: 'EXPIRED',
        label: 'Expired'
    }, {
        id: 'INTERNAL_ERROR',
        label: 'Internal error'
    }, {
        id: 'NUMBER_OFFLINE',
        label: 'Test number offline'
    }, {
        id: 'NETWORK_OFFLINE',
        label: 'Network offline'
    }
];

export const getItemLabel = (items: any[], id: any, def = 'Automatic'): string => {
    let item = items.filter(_ => _.id === id);
    if (!item.length) {
        return def;
    }
    return item[0].label;
};
