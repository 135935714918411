import {Injectable} from "@angular/core";

@Injectable()
export class FreshChatService {

    static setUser(user) {
        // @ts-ignore
        if (window.fcWidget) {
            // @ts-ignore
            window.fcWidget.user.setProperties({
                email: user.email,
                firstName: user.fullName,
                company: user.companyName,
                userId: user.id,
                platform: location.host.split('.')[0]
            });
        }
    }
}