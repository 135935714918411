import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { NotificationService } from '../../../shared/services/notification.service';
import { RowDef } from '../../../shared/components/table/table.component';
import { AppscriptsPaymentMethodService } from '../../../shared/services/appscripts-payment-method.service';
import {
    AppscriptsPaymentMethodTableComponent
} from '../appscripts-payment-method-table/appscripts-payment-method-table.component';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { FormControl } from "@angular/forms";
import { AppscriptsPaymentMethod } from "../../../shared/models/appscripts-payment-method.model";
import { AppscriptsPaymentMethodFormComponent } from "../appscripts-payment-method-form/appscripts-payment-method-form.component";

@Component({
    selector: 'app-appscripts-payment-method-index',
    templateUrl: 'appscripts-payment-method-index.component.html',
    styleUrls: ['appscripts-payment-method-index.component.scss']
})

export class AppscriptsPaymentMethodIndexComponent implements OnInit {

    @ViewChild(AppscriptsPaymentMethodTableComponent, {static: false}) table: AppscriptsPaymentMethodTableComponent;
    @ViewChild(AppscriptsPaymentMethodFormComponent, {static: false}) form: AppscriptsPaymentMethodFormComponent;

    formModel: AppscriptsPaymentMethod;
    formLoading = false;
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;
    formModal: DialogRef;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public service: AppscriptsPaymentMethodService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Payment methods');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.table.requestParams.search = searchValue;
            this.table.requestParams.page = 1;
            this.table.update();
        });
    }

    paymentMethodAction(event) {
        if (event.name === 'edit') {
            this.formLoading = true;
            this.formModel = null;
            this.formModal = this.modal.alert().component(this.formModalTpl).open();
            const id = event.row.data.id;
            this.service.one(id).subscribe({
                next: m => this.formModel = m,
                complete: () => this.formLoading = false,
                error: err => {}
            });
        }
    }

    paymentMethodCreate() {
        this.formModel = this.service.create();
        this.formModal = this.modal.alert().component(this.formModalTpl).open();
    }

    onAfterSave() {
        if (this.formModal) {
            this.formModal.close();
            this.table.update();
        }
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.table.requestParams.search = phrase;
        this.table.page = 1;
        this.table.update();
    }

}