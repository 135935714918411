import {Component, OnInit, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../../shared/components/breadcrumbs/breadcrumbs';
import { NotificationService } from '../../../shared/services/notification.service';
import { SearchParams } from '../../../shared/services/voice-testing.service';
import { UsersService } from '../../../shared/services/users.service';
import { VtFormComponent } from '../vt-form/vt-form.component';
import { VtResultsTableComponent } from '../vt-results-table/vt-results-table.component';
import { VtSearchFormComponent } from '../vt-search-form/vt-search-form.component';


@Component({
    selector: 'app-vt-index',
    templateUrl: 'vt-index.component.html',
    styleUrls: ['vt-index.component.scss'],
})

export class VtIndexComponent implements OnInit {

    @ViewChild(VtFormComponent, {static: false}) form: VtFormComponent;
    @ViewChild(VtResultsTableComponent, {static: false}) results: VtResultsTableComponent;
    @ViewChild(VtSearchFormComponent, {static: false}) filter: VtSearchFormComponent;

    pauseUpdateForm = false;
    pauseUpdateResults = false;

    testingDisabled = null;

    constructor(
        public breadcrumbs: BreadcrumbService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        public userService: UsersService,
        titleService: Title
    ) {
        titleService.setTitle('Voice testing');
    }

    afterSaveTestCase() {
        this.pauseUpdateResults = false;
        this.results.currentPageNumber = 1;
        this.results.update();
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('Test results', ['voice-testing'], true);
    }

    onSubmitFilter(event: SearchParams) {
        if (this.results.loading) {
            return;
        }
        this.results.searchParams = event;
        this.results.currentPageNumber = 1;
        this.results.update();
    }
}
