import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    ViewContainerRef
} from '@angular/core';
import { Instance, createPopper } from '@popperjs/core';
import { Placement } from "./dropdown.directive";

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy {

    @Input() tooltip: string | HTMLElement = '';
    @Input() tooltipDelay?= 1000;
    @Input() tooltipPlacement: Placement = 'right';
    @Input() tooltipClass = '';

    private mouseOver = false;
    private tooltipEl: HTMLElement;

    private popper: Instance;
    private timeoutId: any;

    constructor(private el: ElementRef, private viewContainer: ViewContainerRef) { }


    @HostListener('mouseenter') enter() {
        if (!this.tooltip) { return; }
        this.mouseOver = true;
        this.timeoutId = setTimeout(() => {
            if (!this.mouseOver || !this.tooltip) { return; }
            this.tooltipEl = this.createTooltip();
            this.popper = createPopper(this.el.nativeElement, this.tooltipEl, {
                placement: this.tooltipPlacement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
            this.tooltipEl.classList.add('show');
        }, this.tooltipDelay);
    }

    @HostListener('mouseleave') leave() {
        if (!this.tooltip) { return; }
        this.mouseOver = false;

        clearTimeout(this.timeoutId);
        if (this.tooltipEl) {
            this.tooltipEl.classList.remove('show');
            if (typeof this.tooltip === 'string') {
                this.tooltipEl.remove();
            }
            this.tooltipEl = null;
            this.popper.destroy();
        }
    }

    @HostListener('click') click() {
        // This is needed when two element with tooltips switch quickly before the interval. Like backup test ...
        this.tooltip = '';
        this.tooltipEl?.remove();
        this.tooltipEl = null;
        clearTimeout(this.timeoutId);
    }

    private createTooltip(): HTMLElement {
        if (typeof this.tooltip === 'string') {
            let popup = document.createElement('div');
            let className = 'tooltip';
            if (this.tooltipClass) {
                className += ` ${this.tooltipClass}`;
            }
            popup.setAttribute('class', className);
            let content = document.createElement('div');
            content.setAttribute('class', 'tooltip-inner');
            content.innerHTML = this.tooltip;
            let arrow = document.createElement('div');
            arrow.setAttribute('class', 'arrow');
            popup.appendChild(arrow);
            popup.appendChild(content);
            document.body.appendChild(popup);
            return popup;
        }

        if (this.tooltipClass) {
            this.tooltip.classList.add(this.tooltipClass)
        }

        return this.tooltip;
    }

    ngOnDestroy(): void {
        if (this.tooltipEl) {
            this.tooltipEl.remove();
            this.tooltipEl = null;
        }
        clearTimeout(this.timeoutId);
    }
}