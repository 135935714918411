import {Component, OnInit, ViewChild} from '@angular/core';
import { UsersService } from '../../shared/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { Title } from '@angular/platform-browser';
import { UsersFormComponent } from '../users-form/users-form.component';

@Component({
    selector: 'app-users-update',
    templateUrl: 'users-update.component.html',
})

export class UsersUpdateComponent implements OnInit {

    @ViewChild(UsersFormComponent, {static: false}) form: UsersFormComponent;

    loading = false;

    constructor(
        public userService: UsersService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title,
    ) {
        titleService.setTitle('Update user');
    }

    afterSave() {
        this.router.navigate(['/users']).then();
    }

    ngOnInit() {
        const userId = parseInt(this.route.snapshot.params['id']);
        this.loading = true;
        this.userService.one(userId).subscribe(model => {
            model.id = userId;
            this.form.setModel(model);
            this.loading = false;
        });
    }
}