import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticsService } from '../../shared/services/statistics.service';
import { Subscription } from "rxjs";
import * as Highcharts from 'highcharts/highmaps';
import HC_map from 'highcharts/modules/map';
import worldMap from '../../shared/services/geo/world.json';
import { SeriesClickEventObject } from "highcharts/highmaps";
HC_map(Highcharts);

@Component({
    selector: 'app-dashboard-test-count-world-widget',
    templateUrl: './dashboard-test-count-world-widget.component.html',
    styleUrls: ['dashboard-test-count-world-widget.component.scss', '../shared.scss']
})

export class DashboardTestCountWorldWidgetComponent implements AfterViewInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = "mapChart";

    @ViewChild('chartContainer', {static: false}) chartContainer;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options = {
        chart: {height: 600, map: worldMap, style: {fontFamily: StatisticsService.chartFontFamily}},
        title: {style: {display: 'none'}},
        colors: StatisticsService.colors,
        mapNavigation: {
            enabled: true,
            enableMouseWheelZoom: false,
            buttonOptions: {
                alignTo: "spacingBox",
                align: 'right'
            }
        },
        legend: {enabled: true},
        colorAxis: {min: 0, maxColor: '#964fff'},
        tooltip: StatisticsService.chartTooltip,
        series: [{
            name: "Submitted tests",
            type: 'map',
            dataLabels: {enabled: false},
            states: {hover: {color: '#964fff'}},
            borderColor: '#EEEEEE',
            events: {
                click: (e) => this.onCountryClick(e),
            },
            tooltip: {
                pointFormatter: (): string => {
                    const point = this.chart.hoverPoint;
                    let text = point.name + ': ' + point.value;
                    if (!this.totalCount) {return text;}
                    const percent = ((point.value / this.totalCount) * 100).toFixed(2);
                    return text + ' (' + percent + '%)';
                }
            },
            data: [],
        } as Highcharts.SeriesMapOptions],
        credits: { enabled: false },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.3,
            }
        }
    };

    @Input() from: string;
    @Input() to: string;

    loading = true;

    private request: Subscription;
    private totalCount: number;

    @HostListener('window:resize') onResize() {this.resize();}
    resize() {
        if (!this.chartContainer || !this.chart) {return;}
        this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, <number>this.chartOptions.chart.height);
    }

    constructor(
        public statistics: StatisticsService,
        public notificationService: NotificationService
    ) {
    }

    onCountryClick(e: SeriesClickEventObject) {
        const countryId = e.point.options['hc-key'];
        //@todo implement click
    }

    ngAfterViewInit() {
        this.resize();
        this.update();
    }

    update() {
        this.loading = true;
        if (this.request && !this.request.closed) {this.request.unsubscribe();}
        this.request = this.statistics.world(this.from, this.to).subscribe({
            next: data => {
                this.loading = false;
                if (!this.chart) {return;}
                this.chart.hideLoading();
                this.totalCount = data.map(_ => _[1]).reduce((partialSum, i) => partialSum + i, 0);
                if (data.length) {
                    this.chart.series[0].setData(data);
                }
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Dashboard',
                    message: 'An error occurred while loading statistics',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                this.chart.showLoading('Error on loading');
            }
        });
    }

    setChart(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined)
        ) {
            this.update();
        }
    }
}
