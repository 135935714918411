import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { RowDef } from '../../../shared/components/table/table.component';
import {
    AppscriptsMtTierTableComponent
} from '../appscripts-mt-tier-table/appscripts-mt-tier-table.component';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { AppscriptsMtTierFormComponent } from "../appscripts-mt-tier-form/appscripts-mt-tier-form.component";
import { AppscriptsBillingService } from "../../../shared/services/appscripts-billing.service";
import { AppsctiptsMtTier } from "../../../shared/models/appscripts-billing.model";

@Component({
    selector: 'app-appscripts-mt-tier-index',
    templateUrl: 'appscripts-mt-tier-index.component.html',
    styleUrls: ['appscripts-mt-tier-index.component.scss']
})

export class AppscriptsMtTierIndexComponent implements OnInit {

    @ViewChild(AppscriptsMtTierTableComponent, {static: false}) table: AppscriptsMtTierTableComponent;
    @ViewChild(AppscriptsMtTierFormComponent, {static: false}) form: AppscriptsMtTierFormComponent;

    formModel: AppsctiptsMtTier;
    formLoading = false;
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;
    formModal: DialogRef;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public service: AppscriptsBillingService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('MT tiers');
    }

    ngOnInit() {

    }

    tierAction(event) {
        if (event.name === 'edit') {
            let model = Object.assign({}, event.row.data);
            model.rate = model.rate / 100;
            this.formModel = model;
            this.formModal = this.modal.alert().component(this.formModalTpl).open();
            this.formLoading = false;
        }
    }

    tierCreate() {
        this.formModel = this.service.createMtTier();
        if (!this.table.rows.length) {
            this.formModel.isFirst = true;
        }
        this.formModal = this.modal.alert().component(this.formModalTpl).open();
    }

    onAfterSave() {
        if (this.formModal) {
            this.formModal.close();
            this.table.update();
        }
    }
}