import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { TestCaseTemplate, TestCaseTemplatesCollection } from '../models/test-case-template.model';
import { TestGroupInfo } from '../models/test-group-info.model';
import { TestGroup } from '../models/test-group.model';
import { CreateTestGroup } from './live-number-testing.service';
import { RestUtils } from './rest-utils';
import {map} from "rxjs/operators";
import { TestCaseTemplateUpdateTitle } from '../models/test-case-template-update-title';

@Injectable()
export class TestCaseTemplatesService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : [],
            searchString: params.search ? params.search : ''
        };

        let url = this.utils.buildUrl('ROLE/ntc/test-case-templates', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestCaseTemplatesCollection>(url, options);
    }

    allLntTemplates(params: AllRequestParams) {
        // Related to TP-4387 ... Fetching templates way differs for templates in LNT ...
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : [],
            searchString: params.search ? params.search : ''
        };

        let url = this.utils.buildUrl('ROLE/ntc/test-case-templates-lnt', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestCaseTemplatesCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/test-case-templates/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<TestCaseTemplate>(url, options).pipe(map(response => {
            response.dynamicTexts = response.dynamicTextDtos;
            response.contentTexts.forEach(ct => ct.fromServer = true);
            return response;
        }));
    }

    testCaseTemplateConvertTestGroup(model: TestCaseTemplate): TestGroup {
        return {
            amountX: 1,
            testRepeatsPerIteration: 1,
            testDtos: [],
            tlvDtos: model.tlvDtos.map(_ => _),
            udhDtos: model.udhDtos.map(_ => _),
            encodingId: model.encodingId,
            srcNpi: model.srcNpi,
            srcTon: model.srcTon,
            protocolId: model.protocolId,
            ttl: model.ttl,
            commentText: model.commentText,
            validityPeriod: model.validityPeriod,
            scheduleDeliveryTime: model.scheduleDeliveryTime,
            replaceIfPresent: model.replaceIfPresent,
            priority: model.priority,
            textAsTlv: model.textAsTlv,
        }
    }

    testCaseTemplateConvertTestGroupInfo(model: TestCaseTemplate): TestGroupInfo {
        return  {
            senders: model.senders.map(_ => _),
            dynamicTexts: model.dynamicTexts.map(_ => _),
            contentTexts: model.contentTexts,
            destinations: model.destinationDtos.map(_ => _),
            supplierSessionsIds: model.routeDtos.map(_ => _),
        };
    }

    saveTestGroup(group: CreateTestGroup, model: TestCaseTemplate): Observable<TestCaseTemplate> {
        model.contentTexts = group.contentTexts;
        model.destinationDtos = group.destinations.map(_ => _);
        model.routeDtos = group.sessionsSuppliers.map(_ => {
            return {
                sessionId: _.sessionId,
                supplierId: _.supplierId
            }
        });
        model.tlvDtos = group.tlv?.map(_ => _);
        model.udhDtos = group.udh?.map(_ => _);
        model.encodingId = group.encodingId;
        model.srcNpi = group.srcNpi;
        model.srcTon = group.srcTon;
        model.protocolId = group.protocolId;
        model.userId = group.userId;
        model.ttl = group.ttl;
        model.commentText = group.commentText ? group.commentText : null;
        model.validityPeriod = group.validityPeriod;
        model.scheduleDeliveryTime = group.scheduleDeliveryTime;
        model.replaceIfPresent = group.replaceIfPresent;
        model.priority = group.priority;
        return this.save(model);
    }

    save(template: TestCaseTemplate) {
        let url = this.utils.buildUrl(`ROLE/ntc/test-case-templates`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (template.id) {
            return this.http.put<TestCaseTemplate>(url, template, options);
        }
        return this.http.post<TestCaseTemplate>(url, template, options);
    }

    updateTestCaseTemplateTitle(data: TestCaseTemplateUpdateTitle): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/ntc/test-case-templates`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.patch<TestCaseTemplate>(url, data, options);
    }

    delete(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/test-case-templates/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete<TestCaseTemplate>(url, options);
    }

    prepareTextError(e: {status: number, statusText: string}) {
        return e.status === 403 ? 'Access denied. You do not have the permission to modify or delete another account’s templates.' : e.statusText;
    }
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
