import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import { LocalStorageService } from '../../services/localStorage.service';
import { CustomUtils } from '../../services/custom-utils';
import { ActionDef, ColumnDef, RowDef, SortEvent } from './table.component';
import { ColumnTypes, TableComponent } from './table.component';

@Component({
    template: ''
})
export class TableBase {

    MAX_TOTAL_ITEMS_COUNT = 1000;

    columns: ColumnDef[] = [];
    rows: RowDef[] = [];
    tableActions: ActionDef[] = [];
    order: SortEvent[] = [];

    page = 1;
    currentSize = 20;
    sizes = [20, 50, 100];
    totalCount = 0;

    loading = false;
    flashTimeout = null;

    @Output() changeBatch: EventEmitter<RowDef[]> = new EventEmitter();
    @ViewChild(TableComponent, {static: false}) _table: TableComponent;

    setData(rows: RowDef[], count: number) {
        this.rows = rows;
        // TODO: Remove this later ...
        // I did a performance test when setting count to totalElements (all available elements)
        // and saw no difference. I also checked all occurence of totalCount used in UI and 
        // there was no place where it could impact performance. Hence this can be removed in later changes ...
        
        // if (count > this.MAX_TOTAL_ITEMS_COUNT) {
        //     count = this.MAX_TOTAL_ITEMS_COUNT;
        // }
        this.totalCount = count;
    }

    setColumns(columns: ColumnDef[]) {
        this.order = LocalStorageService.getItem(this.makeStorageKey('order'), []);

        const size = LocalStorageService.getItem(this.makeStorageKey('size'), this.currentSize);
        const minSize = Math.min.apply(null, this.sizes);
        const maxSize = Math.max.apply(null, this.sizes);
        this.currentSize = (size <= maxSize && size >= minSize) ? size : this.currentSize;

        this.columns = columns.map(column => {
            if (!column.sortDirection && column.sort) {
                this.order.filter(_ => _.prop === column.prop).forEach(sort => {
                    column.sortDirection = sort.direction;
                });
            }
            return column;
        });
    }

    onChangeSize(size) {
        this.currentSize = size;
        LocalStorageService.setItem(this.makeStorageKey('size'), size);
    }

    onChangeBatch(rows: RowDef[]) {
        this.changeBatch.emit(rows);
    }

    onChangeOrder(columns: SortEvent[]) {
        LocalStorageService.setItem(this.makeStorageKey('order'), columns);
    }

    makeStorageKey(key: string): string {
        return 'telq-' + CustomUtils.camelCaseToDash(this.constructor.name) + '-' + key;
    }

    createColumn(column: ColumnDef): ColumnDef {
        return {
            title: column.title,
            prop: column.prop,
            format: column.format ? column.format : null,
            type: column.type ? column.type : ColumnTypes.TEXT,
            sort: column.sort ? column.sort : false,
            sortDirection: column.sortDirection ? column.sortDirection : '',
            popover: column.popover ? column.popover : '',
            className: column.className ? column.className : '',
            cellClassName: column.cellClassName ? column.cellClassName : '',
            width: column.width || ''
        }
    }

    createColumns(columns: ColumnDef[]): ColumnDef[] {
        return columns.map(this.createColumn);
    }

    createRow(data: Object): RowDef {
        return {
            batch: false,
            batchDisabled: false,
            className: '',
            data: data,
            tables: null
        }
    }

    createRows(columns: Object[]): RowDef[] {
        return columns.map(this.createRow);
    }

    resetBatch() {
        this.rows.forEach(_ => {
            _.batch = false;
        });
        this.updateBatch();
    }

    updateBatch() {
        if (this._table) {
            this._table.onBatch();
        }
    }

    flashRow(idValue, idFieldName = 'id') {
        if (this.flashTimeout) {
            clearTimeout(this.flashTimeout);
        }
        let rows = this.rows.filter(_ => {
            _.className = TableBase.removeClass(_.className, 'flash');
            return idValue === _.data[idFieldName]
        });
        if (rows.length) {
            rows[0].className = TableBase.addClass(rows[0].className, 'flash');
            this.flashTimeout = setTimeout(() => {
                rows[0].className = TableBase.removeClass(rows[0].className, 'flash');
            }, 10 * 1000);
        }
    }

    static addClass(list, className: string): string {
        if (typeof list !== "string") {return list;}
        if (list.indexOf(className) === -1) {
            list += (' ' + className);
        }
        return list;
    }

    static removeClass(list, className: string): string {
        if (typeof list !== "string") {return list;}
        return list.replace(className, '').trim();
    }
}
