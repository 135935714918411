import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../../../shared/models/session.model';
import { Supplier } from "../../../shared/models/supplier.model";
import { NotificationService } from '../../../shared/services/notification.service';
import { SessionsService } from '../../../shared/services/sessions.service';
import { SessionFormComponent } from '../session-form/session-form.component';

@Component({
    selector: 'app-session-update',
    templateUrl: './session-update.component.html',
})

export class SessionUpdateComponent {

    @Output() afterSupplierSave: EventEmitter<Session> = new EventEmitter();
    @ViewChild(SessionFormComponent, { static: false }) form: SessionFormComponent;

    suppliers: Supplier[] = [];
    loading = false;

    constructor(
        public sessionService: SessionsService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Session update');
    }

    afterSave(session: Session) {
        this.afterSupplierSave.emit(session);
    }

}
