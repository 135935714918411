import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { PricingGroupTableComponent } from "../pricing-group-table/pricing-group-table.component";
import { ActionEvent } from '../../../shared/components/table/table.component';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";

@Component({
    selector: 'app-pricing-group-index',
    templateUrl: './pricing-group-index.component.html',
    styleUrls: ['pricing-group-index.component.scss'],
})

export class PricingGroupIndexComponent implements OnInit {

    @ViewChild(PricingGroupTableComponent, {static: false}) table: PricingGroupTableComponent;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('General pricing');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.table.requestParams.search = searchValue;
            this.table.requestParams.page = 1;
            this.table.update();
        });
    }

    groupAction(event: ActionEvent) {
        if (event.name === 'edit') {
            this.router.navigate(['pricing-general', event.row.data.id]).then();
        }
    }
}
