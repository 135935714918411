import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SessionSupplierFilter } from '../../shared/models/session-supplier.model';
import { BasicUser } from '../../shared/models/user.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SearchSessionSupplierParams } from '../../shared/services/sessions.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
    selector: 'app-session-supplier-search',
    templateUrl: './session-supplier-search.component.html',
    styleUrls: ['./session-supplier-search.component.scss']
})
export class SessionSupplierSearchComponent implements OnInit, OnDestroy {

    @Output() onSubmit = new EventEmitter;

    isBeingDragged: boolean = false;
    form: FormGroup;
    searchControl: FormControl = new FormControl();
    searchSubscription: Subscription;
    usersCollection = [];
    filters: SessionSupplierFilter[] = [
        { filterKey: 'sessionIds', title: 'Session ID', show: false },
        { filterKey: 'host', title: 'Host', show: true },
        { filterKey: 'logins', title: 'Login', show: true },
        { filterKey: 'users', title: 'Users', show: false },
        { filterKey: 'supplierIds', title: 'Supplier ID', show: false },
        { filterKey: 'supplierNames', title: 'Supplier Name', show: true },
        { filterKey: 'routeType', title: 'Route type', show: false },
        { filterKey: 'serviceType', title: 'Service Type', show: false },
    ];

    constructor(
        private localStorage: LocalStorageService,
        private userService: UsersService,
        private notificationService: NotificationService,
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            supplierIds: [null, Vld.compose([Vld.maxLength(255)])],
            sessionIds: [null, Vld.compose([Vld.maxLength(255)])],
            host: [null, Vld.compose([Vld.maxLength(255)])],
            logins: [null, Vld.compose([Vld.maxLength(255)])],
            supplierNames: [null, Vld.compose([Vld.maxLength(255)])],
            routeType: [null, Vld.compose([Vld.maxLength(255)])],
            serviceType: [null, Vld.compose([Vld.maxLength(255)])],
        });
        const existingFilters = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
        const searchData = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`) || {};
        this.setSearchData(searchData);
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onSearchInputChange(searchValue);
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`session_supplier_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    isFilterActive(filter: SessionSupplierFilter): boolean {
        switch (filter.filterKey) {
            case 'sessionIds':
                return this.form.get('sessionIds').value?.length > 0;
            case 'host':
                return this.form.get('host').value?.length > 0;
            case 'logins':
                return this.form.get('logins').value?.length > 0;
            case 'supplierIds':
                return this.form.get('supplierIds').value?.length > 0;
            case 'supplierNames':
                return this.form.get('supplierNames').value?.length > 0;
            case 'routeType':
                return this.form.get('routeType').value?.length > 0;
            case 'serviceType':
                return this.form.get('serviceType').value?.length > 0;
            case 'users':
                return this.usersCollection.length > 0;
            default:
                return false;
        }
    }

    getSearchModel(): SearchSessionSupplierParams {
        const rawValue = this.form.getRawValue();
        if (typeof rawValue.sessionIds === 'string') {
            rawValue.sessionIds = this.processIds(rawValue.sessionIds);
        }
        if (typeof rawValue.supplierIds === 'string') {
            rawValue.supplierIds = this.processIds(rawValue.supplierIds);
        }
        if (typeof rawValue.supplierNames === 'string') {
            rawValue.supplierNames = rawValue.supplierNames.split(" ").map(s => s.trim() === "" ? null : s.trim());
        }
        if (typeof rawValue.logins === 'string') {
            rawValue.logins = rawValue.logins.split(" ").map(s => s.trim() === "" ? null : s.trim());
        }
        rawValue.userIds = this.usersCollection.map(u => u.id);
        rawValue.usersCollection = this.usersCollection;
        rawValue.searchString = this.searchControl.value;
        return rawValue;
    }

    setSearchData(searchData: SearchSessionSupplierParams): void {
        const populateSearchData = {
            ...searchData,
            sessionIds: searchData.sessionIds?.join(" "), supplierIds: searchData.supplierIds?.join(" "),
            supplierNames: searchData.supplierNames?.join(" "), logins: searchData.logins?.join(" "),
        }
        this.form.patchValue(populateSearchData);
        this.searchControl.setValue(searchData.searchString);
        this.usersCollection = searchData.usersCollection || [];
    }

    getPeekForSelectedValues(filter: SessionSupplierFilter): number | string | null {
        switch (filter.filterKey) {
            case 'users':
                return this.usersCollection.length;
            default:
                return null;
        }
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    onSelectUser(user: BasicUser) {
        this.usersCollection.push(user);
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onUnSelectUser(user: BasicUser, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.usersCollection = this.usersCollection.filter(u => user.id !== u.id);
        this.saveUserSearch();
        this.onSubmitClick();
    }

    saveUserSearch(data: SearchSessionSupplierParams = null) {
        this.localStorage.set(`session_supplier_${this.userService.authUser.id}_search_data`, this.getSearchModel());
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'sessionIds':
                this.form.get('sessionIds').setValue(null);
                break;
            case 'host':
                this.form.get('host').setValue(null);
                break;
            case 'logins':
                this.form.get('logins').setValue(null);
                break;
            case 'supplierIds':
                this.form.get('supplierIds').setValue(null);
                break;
            case 'supplierNames':
                this.form.get('supplierNames').setValue(null);
                break;
            case 'routeType':
                this.form.get('routeType').setValue(null);
                break;
            case 'serviceType':
                this.form.get('serviceType').setValue(null);
                break;
            case 'users':
                this.usersCollection = [];
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`session_supplier_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    processIds(idsString: string) {
        const processedIds = idsString?.split(" ").map(s => s.trim() === "" ? null : Number(s.trim()));
        return processedIds?.length === 1 && processedIds[0] === null ? null : processedIds;
    }

    onSubmitClick(filterKey: string = null) {
        if (filterKey) {
            const isSearchStringPresent = this.searchControl.value;
            if (isSearchStringPresent?.length > 0) {
                this.notificationService.info("This filter combination cannot be used.", "Some filters have been updated");
                this.searchControl.setValue('', { emitEvent: false });
            }
        }
        const data = this.getSearchModel();
        this.onSubmit.emit(data);
        this.saveUserSearch(data);
    }

    checkFormEmpty(): boolean {
        const rawValue = this.form.getRawValue();
        return Object.keys(rawValue).filter(_ => rawValue[_]?.length > 0).length === 0;
    }

    onAdditionalFilterClick(filter: SessionSupplierFilter): void {
        filter.show = !filter.show;
        this.localStorage.set(`session_supplier_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`session_supplier_${this.userService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    resetAll(): void {
        this.form.reset();
        this.usersCollection = [];
        this.searchControl.reset();
    }

    onSearchInputChange(searchValue: string): void {
        const isAnyFilterActive = this.filters.filter(f => this.isFilterActive(f)).length > 0;
        if (isAnyFilterActive) {
            this.form.reset();
            this.notificationService.info("This filter combination cannot be used.", "Some filters have been updated");
        }
        this.onSubmitClick();
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }

}
