import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Supplier } from '../../../shared/models/supplier.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { SupplierFormComponent } from '../supplier-form/supplier-form.component';

@Component({
    selector: 'app-supplier-create',
    templateUrl: './supplier-create.component.html',
})

export class SupplierCreateComponent {

    @Output() afterSupplierSave: EventEmitter<Supplier> = new EventEmitter();

    @ViewChild(SupplierFormComponent, { static: false }) form;

    loading = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Supplier create');
    }

    afterSave(supplier: Supplier) {
        this.afterSupplierSave.emit(supplier);
    }
}
