import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { Session, SessionsCollection } from '../../shared/models/session.model';
import { Supplier } from '../../shared/models/supplier.model';
import { Roles } from "../../shared/models/user.model";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, SessionSupplierActionData, SessionsService } from '../../shared/services/sessions.service';
import { SuppliersService } from '../../shared/services/suppliers.service';
import { UsersService } from '../../shared/services/users.service';
import { SessionSupplierTableComponent } from '../session-suppliers-table/session-supplier-table.component';
import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, map, of, tap, throwError } from 'rxjs';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { FormControl } from '@angular/forms';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({
    selector: 'app-session-supplier-index',
    templateUrl: './session-supplier-index.component.html',
    styleUrls: ['./session-supplier-index.component.scss'],
})

export class SessionSupplierIndexComponent {

    @ViewChild('usersModalTpl', { read: TemplateRef, static: false }) usersModalTpl: any;
    usersModal: DialogRef;

    @ViewChild('sessionCreateModalTpl', { read: TemplateRef, static: false }) sessionCreateModalTpl: any;
    sessionCreateModal: DialogRef;
    @ViewChild('sessionUpdateModalTpl', { read: TemplateRef, static: false }) sessionUpdateModalTpl: any;
    sessionUpdateModal: DialogRef;
    @ViewChild('supplierCreateModalTpl', { read: TemplateRef, static: false }) supplierCreateModalTpl: any;
    supplierCreateModal: DialogRef;
    @ViewChild('supplierUpdateModalTpl', { read: TemplateRef, static: false }) supplierUpdateModalTpl: any;
    supplierUpdateModal: DialogRef;
    @ViewChild('importModalTpl', { read: TemplateRef, static: false }) importModalTpl: any;
    importModal: DialogRef;
    @ViewChild('reassignSuppliersModalTpl', { read: TemplateRef, static: false }) reassignSuppliersModalTpl: any;
    reassignSuppliersModal: DialogRef;

    @ViewChild(SessionSupplierTableComponent, { static: false }) table: SessionSupplierTableComponent;

    usersCollection: any[] = [];
    selectedRows: Supplier[] | Session[] = [];
    batchCompleted = 0;
    serviceDisabled = null;
    isSessionBatchActive: boolean = false;
    loading: boolean = false;
    userRole: Roles;
    sessionsList: Session[] = [];
    selectedSessionToMoveTo: Session;
    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public sessionService: SessionsService,
        private supplierService: SuppliersService,
        public router: Router,
        public notificationService: NotificationService,
        public titleService: Title,
        public modal: ModalService,
        public userService: UsersService,
        private location: Location,
        private localStorage: LocalStorageService,
    ) {
        titleService.setTitle('Sessions');
        this.userService.getAuthUser().then(user => {
            this.userRole = user.role;
        });
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    clearUrlQuery(): void {
        // Clear the URL with any ids present ...
        const currentPath = this.location.path();
        const currentHistoryState = this.location.getState();
        const newPath = currentPath.split('?')[0];
        this.location.replaceState(newPath, '', currentHistoryState);
    }

    sessionAction(event: SessionSupplierActionData) {
        if (event.name === 'edit') {
            const currentUrl = this.router.url.split('?')[0];
            const navigationExtras: NavigationExtras = {
                queryParams: { 'id': event.row.id },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            };
            this.router.navigate([currentUrl], navigationExtras);
            this.sessionUpdateModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.sessionUpdateModalTpl).open();
            this.sessionUpdateModal.result.then((res) => {
                this.router.navigate([currentUrl]);
            });
        } else if (event.name === 'addSupplier') {
            this.sessionService.setActiveSessionForSupplier(event.row);
            this.supplierCreateModal = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.supplierCreateModalTpl).open();
        } else if (event.name === 'editSupplier') {
            const currentUrl = this.router.url.split('?')[0];
            const navigationExtras: NavigationExtras = {
                queryParams: { 'id': event.row.id },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            };
            this.router.navigate([currentUrl], navigationExtras);
            this.supplierUpdateModal = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.supplierUpdateModalTpl).open();
            this.supplierUpdateModal.result.then((res) => {
                this.router.navigate([currentUrl]);
            });
        }
    }

    onClickCreateSmppConnection() {
        this.sessionCreateModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.sessionCreateModalTpl).open();
        this.sessionCreateModal.result.then((res) => {
            this.clearUrlQuery();
        });
    }

    afterSessionSupplierSave(supplier: Session): void {
        const searchParams = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`);
        this.table.update(true, searchParams);
        if (this.sessionCreateModal) this.sessionCreateModal.close();
        if (this.sessionUpdateModal) this.sessionUpdateModal.close();
        if (this.supplierCreateModal) this.supplierCreateModal.close();
        if (this.supplierUpdateModal) this.supplierUpdateModal.close();
    }

    onSubmitFilter(data) {
        if (this.table.loading) {
            return;
        }
        this.table.currentPageNumber = 1;
        this.table.update(true, data);
    }

    onChangeBatchSelect(event: Supplier[] | Session[]): void {
        if (event.length > 0) {
            if ('systemId' in event[0]) { // is session batch ...
                this.isSessionBatchActive = true;
            } else {
                this.isSessionBatchActive = false;
            }
        }
        this.selectedRows = event;
    }

    getParams(searchString?: string): AllRequestParams {
        const params = new AllRequestParams();
        params.size = 10;
        params.page = 0;
        params.sort = ['id,desc'];
        if (searchString) {
            params.search = searchString;
        }
        return params;
    }

    getSessionsList(searchString?: string) {
        this.loading = true;
        const params = this.getParams(searchString);
        this.sessionService.all(params).subscribe({
            next: (sessions: SessionsCollection) => {
                this.sessionsList = sessions.content;
                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                this.notificationService.error('Error when fetching sessions', 'SMPP & Suppliers');
            }
        });
    }

    batchMoveSuppliers() {
        if (!this.selectedRows.length) {
            return;
        }
        this.reassignSuppliersModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.reassignSuppliersModalTpl).open();
        this.getSessionsList();
    }

    batchEnableDisableSessions() {
        if (!this.selectedRows.length) {
            return;
        }
        this.modal.confirm('Are you sure?', 'Enable sessions will be disabled and disabled ones will be enabled.').open().result.then(res => {
            if (!res) { return; }
            this.table.loading = true;
            const tasks = this.selectedRows.map((row: Session) => {
                row.enabled = !row.enabled;
                return this.sessionService.save(row).pipe(
                    map(() => {
                        this.notificationService.success(`Update session ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                    }),
                    catchError((err) => {
                        this.notificationService.error(`Error when updating session ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                        return throwError(() => err);
                    })
                );
            });
            this.startTasks(tasks);
        });
    }

    batchDeleteSessions() {
        if (!this.selectedRows.length) {
            return;
        }
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.table.loading = true;
            const tasks = this.selectedRows.map((row: Session) => {
                if (row.supplierCount > 0) {
                    const errorMessage = `Session ${row.id} has ${row.supplierCount} suppliers. Please delete them first.`;
                    this.notificationService.error(errorMessage, 'SMPP & Suppliers');
                    return of(null).pipe(
                        tap(() => this.completeTask())
                    );
                }
                return this.sessionService.delete(row.id).pipe(
                    map(() => {
                        this.notificationService.success(`Deleted session ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                    }),
                    catchError((err) => {
                        this.notificationService.error(`Error when deleting session ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                        return throwError(() => err);
                    })
                );
            });
            this.startTasks(tasks);
        });

    }

    batchDeleteSuppliers() {
        if (!this.selectedRows.length) {
            return;
        }
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.table.loading = true;
            const tasks = this.selectedRows.map((row: Supplier) => {
                return this.supplierService.delete(row.id).pipe(
                    map(() => {
                        this.notificationService.success(`Deleted supplier ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                    }),
                    catchError((err) => {
                        this.notificationService.error(`Error when deleting supplier ${row.id}`, 'SMPP & Suppliers');
                        this.completeTask();
                        return throwError(() => err);
                    })
                );
            });
            this.startTasks(tasks);
        });
    }

    batchClear() {
        this.selectedRows = [];
        this.table.resetBatch();
    }

    reassignSuppliers(): void {
        const supplierIdsToMove = this.selectedRows.map(_ => _.id);
        this.sessionService.reassignSuppliers(this.selectedSessionToMoveTo.id, supplierIdsToMove).subscribe({
            next: (_) => {
                this.notificationService.success('Suppliers reassigned', 'SMPP & Suppliers');
                this.reassignSuppliersModal.close(true);
                this.selectedSessionToMoveTo = null;
                this.searchControl.reset();
                this.table.update();
                this.batchClear();
            },
            error: (err) => {
                this.notificationService.error('Error when reassigning suppliers', 'SMPP & Suppliers');
            }
        });
    }

    startTasks(tasks: Observable<any>[]) {
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    onClickImport() {
        this.importModal = this.modal.alert().isBlocking(true).component(this.importModalTpl).open();
    }

    importFinished() {
        //this.importModal.close(true);
        this.table.update();
    }

    onChangeInputSearch(phrase) {
        this.getSessionsList(phrase);
    }

}
