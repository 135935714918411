import { Component, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BreadcrumbService } from "../../shared/components/breadcrumbs/breadcrumbs";
import { AuditLog, AuditLogSearchParams } from "../../shared/models/audit-log.model";
import { AuditLogDetails, AuditLogService } from "../../shared/services/audit-log.service";
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { UsersService } from "../../shared/services/users.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
    selector: 'audit-log-index-component-v2',
    templateUrl: './audit-log-index.component.html',
    styleUrls: ['./audit-log-index.component.scss']
})
export class AuditLogIndexComponentV2 implements OnInit {

    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;

    page = 0;
    params: AuditLogSearchParams = {};
    rows: AuditLog[] = [];

    loading = false;
    isAdmin = false;

    currentLog: {log: AuditLog, details: AuditLogDetails} = {
        log: null,
        details: null
    };

    constructor(
        @Inject('API_URL') baseUrl: string,
        private breadcrumbs: BreadcrumbService,
        private service: AuditLogService,
        private userService: UsersService,
        private notificationService: NotificationService,
        private modal: ModalService,
        titleService: Title
    ) {
        titleService.setTitle('Audit');
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('Audit', ['audit/logs'], true);
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === 'admin';
        });
        this.update();
    }

    update() {
        this.loading = true;
        this.params.page = this.page;
        this.params.search = (this.params.search && this.params.search.trim()) || "";
        this.service.logsV2(this.params).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Audit logs',
                    message: 'Connection failed',
                    serviceName: 'GATEWAY'
                });
                return throwError(() => e);
            })
        ).subscribe(auditLogs => {
            this.loading = false;
            auditLogs.forEach(log => {
                this.rows.push(log)
            });
        });
    }

    next() {
        this.page++;
        this.update();
    }

    onFilter(params: AuditLogSearchParams) {
        this.page = 0;
        this.params = params;
        this.rows = []
        this.update();
    }

    showDetails(log: AuditLog) {
        if (!this.isAdmin || !log.requestDetails){ return; }
        this.currentLog.log = log;
        this.currentLog.details = AuditLogService.extractDeatils(log);
        this.modal.alert().component(this.detailsModalTpl).open();
    }
}