import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Session } from '../../../shared/models/session.model';
import { SessionsService } from '../../../shared/services/sessions.service';
import { SuppliersService } from '../../../shared/services/suppliers.service';

@Component({
    selector: 'app-supplier-table-footer',
    templateUrl: './supplier-table-footer.component.html',
    styleUrls: ['./supplier-table-footer.component.scss']
})

export class SupplierTableFooterComponent implements IStatusPanelAngularComp {

    public params!: IStatusPanelParams;
    totalSuppliersRowsCount: number = 0;
    currentSupplierPageNumber: number = 1;
    activeSession: Session;

    constructor(private sessionService: SessionsService, private supplierService: SuppliersService) {
        this.activeSession = this.sessionService.getActiveSessionForSupplier();
    }

    agInit(params: IStatusPanelParams): void {
        this.params = params;
        this.totalSuppliersRowsCount = this.activeSession.supplierCount;
    }

    onPageChange(event) {
        if (event === this.currentSupplierPageNumber) {
            return;
        }
        this.currentSupplierPageNumber = event;
        this.supplierService.setSupplierPageNumber(event);
    }

    getSupplierCount(): number {
        return this.sessionService.getActiveSessionForSupplier().suppliers?.length;
    }

}
