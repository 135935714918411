import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { ValidationService, Validators, Validators as Vld } from '../../services/validation.service';
import { UsersService } from "../../services/users.service";
import { DialogRef, ModalService } from "../../services/modal.service";
import { catchError } from "rxjs/operators";
import { Timeout } from "../../services/timeout";


@Component({
    selector: 'app-confirmed-action',
    templateUrl: 'confirmed-action.component.html',
    styleUrls: ['confirmed-action.component.scss']
})

export class ConfirmedActionComponent implements AfterViewInit {

    loading: boolean = false;
    resendLoading: boolean = false;

    form: FormGroup;
    codeModel: string = '';
    resendTimeout: Timeout;

    @Output() result = new EventEmitter<boolean>();
    @Input() action = 'Requesting new OTP'

    @ViewChild('modalTpl', { read: TemplateRef, static: false }) modalTpl: any;
    modalRef: DialogRef;

    constructor(
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        private notificationService: NotificationService,
        private userService: UsersService,
        private modal: ModalService,
    ) {
        this.form = formBuilder.group({
            code: ['', Vld.compose([
                Vld.required,
                Validators.digits(true),
                Validators.minLength(UsersService.TOTP_LENGTH),
                Validators.maxLength(UsersService.TOTP_LENGTH)
            ])]
        });
    }

    ngAfterViewInit() {
        this.modalRef = this.modal.alert()
            .isBlocking(false)
            .dialogClass('modal-dialog medium-modal')
            .component(this.modalTpl).open();
        this.modalRef.result.then(r => {
            setTimeout(() => {
                this.result.next(!!r);
            }, 500);
        });
        this.setupTimeout();
    }

    onSubmit(): void {
        if (!this.form.valid) {return;}
        this.loading = true;
        this.userService.otpValidate(this.form.controls.code.value.trim()).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Confirmed actions',
                    message: 'Сode validation error',
                    serviceName: 'API-GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                throw e;
            })
        ).subscribe(() => {
            this.modalRef.close(true);
        });
    }

    onResend() {
        this.resendLoading = true;
        this.userService.otpResend(this.action).pipe(
            catchError(e => {
                this.resendLoading = false;
                this.notificationService.error({
                    title: 'Confirmed actions',
                    message: 'An error occurred while request',
                    serviceName: 'API-GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                throw e;
            })
        ).subscribe(() => {
            this.resendLoading = false;
            this.notificationService.success('Email sent successfully.', 'Confirmed actions');
            this.setupTimeout();
        });
    }

    private setupTimeout() {
        if (this.resendTimeout && !this.resendTimeout.isFinish()) {
            this.resendTimeout.clear();
        }
        this.resendTimeout = new Timeout(120);
    }
}
