
import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Network, NetworkCountry } from '../../shared/models/network.model';
import { ModalService } from "../../shared/services/modal.service";
import { NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';

@Component({
    selector: 'app-network-form',
    templateUrl: './network-form.component.html',
    styleUrls: ['./network-form.component.scss']
})

export class NetworkFormComponent implements OnInit {

    @Input() backButtonShow = true;
    @Input() model: Network;

    @Output() onAfterSave = new EventEmitter();

    countries: NetworkCountry[] = [];

    loading = false;

    form: FormGroup;

    constructor(
        public notificationService: NotificationService,
        public elementRef: ElementRef,
        formBuilder: FormBuilder,
        public service: NetInfoService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        this.form = formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(1), Vld.maxLength(255)])],
            mcc: ['', Validators.compose([Validators.required, Validators.minLength(3), Vld.maxLength(3), Vld.digits()])],
            mnc: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(3), Vld.digits()])],
            prefixes: ['', Validators.compose([Vld.digitsAndCommas(true, 1, 15)])],
            countryId: [null, Validators.compose([Validators.required])],
            aggregator: ['']
        });
    }

    ngOnInit() {
        this.loading = true;
        this.service.countries().subscribe({
            next: (countries: NetworkCountry[]) => {
                this.countries = countries;
                if (this.model.id) {
                    this.form.patchValue({ ...this.model, countryId: this.model.country.id, prefixes: this.model.prefixes.join(',') });
                }
            },
            error: error => {
                this.notificationService.error({
                    title: 'Networks',
                    message: 'An error occurred while loading the regions',
                    serviceName: 'NET-INFO',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    onSubmit() {
        const rawFormValue = this.form.getRawValue();
        this.model = {
            ...rawFormValue,
            id: this.model.id,
            prefixes: rawFormValue.prefixes?.length > 0 ? rawFormValue.prefixes.split(',') : [],
            country: this.countries.find(c => c.id === Number(rawFormValue.countryId))
        };
        this.save(this.model);
    }

    private save(network: Network) {
        this.service.saveNetwork(network).subscribe({
            next: () => {
                this.loading = false;
                this.onAfterSave.emit();
                this.notificationService.success(
                    'Network ' + (this.model.id ? 'updated' : 'created'),
                    'Networks'
                );
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Networks',
                    message: 'An error occurred while creating/updating the network',
                    serviceName: 'NET-INFO',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }
}
