import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-billing-progress',
    templateUrl: './billing-progress.component.html',
    styleUrls: ['billing-progress.component.scss']
})

export class BillingProgressComponent {

    @Input() total = 100;
    @Input() current = 0;
    @Input() parts: Part[] = [];
    @Input() counters = true;

    getPercent() {
        return this.current ? ((this.current / this.total) * 100) : 0;
    }

    protected readonly isFinite = isFinite;
}

export interface Part {
    label?: string,
    tooltip?: string,
    max: number
}
