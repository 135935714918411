import {
    AfterViewInit, Component,
    ElementRef,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, throwError } from "rxjs";
import { UpdateDetectComponent } from '../help/update-detect/update-detect.component';
import { StatusPageStatus } from '../shared/models/status-page-status.model';
import { AuthUser, Role } from "../shared/models/user.model";
import { LocalStorageService } from '../shared/services/localStorage.service';
import { ModalService } from "../shared/services/modal.service";
import { StatusPageService } from '../shared/services/status-page.service';
import { UsersService } from '../shared/services/users.service';
import { SettingService } from '../shared/services/setting.service';
import { catchError } from "rxjs/operators";
import { BrowserUtils } from "../shared/services/browser-utils";
declare const rnw: any;

@Component({
    selector: 'app-main-menu',
    templateUrl: 'main-menu.component.html',
    styleUrls: ['main-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class MainMenuComponent implements AfterViewInit {

    @ViewChild(UpdateDetectComponent, { static: false }) updateDetector: UpdateDetectComponent;
    @ViewChild('sidebarMenuMain', { static: false }) sidebarMenuMain: ElementRef;

    currentRoute: string = '/';
    currentBalance = 0;

    isAdmin: boolean = false;
    isClient: boolean = false;
    user: AuthUser;

    routeSelectCount: number;
    sidebarOpen: boolean = false;
    isMoAvailableForUser: boolean = false;
    isMoAllowedNumbersAvailableForUser: boolean = false;
    isVoiceAvailableForUser: boolean = false;

    currentStatus: StatusPageStatus;
    statusUpdateInterval: any;

    bodyRef: HTMLElement;
    isProd = false;

    private $authUpdates: Subscription;
    private $balanceUpdates: Subscription;
    private moAvailableObservable$: Subscription;
    private updating = false;

    constructor(
        public router: Router,
        public userService: UsersService,
        public modal: ModalService,
        public elementRef: ElementRef,
        public storage: LocalStorageService,
        private statusService: StatusPageService,
        private renderer: Renderer2,
        private settingService: SettingService,
    ) {
        this.statusUpdateInterval = setInterval(() => this.getStatusUpdate(), 60 * 1000);
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.urlAfterRedirects;
                this.routeSelectCount = 5;
                this.onNavigationEnd();
            }
        });
        this.sidebarOpen = this.storage.get('sidebar_open', true);
        this.$balanceUpdates = userService.balanceUpdates.subscribe(balance => this.currentBalance = balance);
        this.moAvailableObservable$ = userService.getMoAvailableForUser().subscribe((isMoAvailable: boolean) => {
            this.isMoAvailableForUser = isMoAvailable;
        });
        this.isProd = BrowserUtils.isProd();
    }

    ngOnInit() {
        if (!this.user) {
            this.user = this.userService.authUser;
        }
        if (this.user) {
            this.update(this.user);
        }
        this.$authUpdates = this.userService.authUpdates.subscribe(user => {
            this.update(user)
        });
    }

    private update(user: AuthUser) {
        if (this.updating) {return;}
        this.updating = true;
        this.isAdmin = user.role === Role.ADMIN;
        this.isClient = !this.isAdmin;
        this.user = user;
        this.userService.isMoAvailableForUser().subscribe({
            next: res => {
                this.userService.setMoAvailableForUser(res);
                if (user.role == Role.SUB || user.role === Role.DEPUTY) {
                    this.isMoAllowedNumbersAvailableForUser = user.moAllowedNumbersEnabled;
                } else {
                    this.isMoAllowedNumbersAvailableForUser = this.isMoAvailableForUser;
                }
            },
            error: err => {
                this.userService.setMoAvailableForUser(false);
                this.isMoAllowedNumbersAvailableForUser = false;
            },
            complete: () => {
                this.updating = false;
            }
        });
        this.userService.isVoiceAvailableForUser().pipe(
            catchError(e => {
                return throwError(() => e);
            })
        ).subscribe(res => {
            this.isVoiceAvailableForUser = res;
        });
    }

    ngAfterViewInit() {
        this.bodyRef = document.body;
        this.applySidebar();
    }

    onMouseEnter(event) {
        const dropdown = event.target.querySelector('.sidebar-dropdown');

        if (dropdown && !this.sidebarOpen) {
            dropdown.classList.add('show');
        }
    }

    onMouseLeave(event) {
        const dropdown = event.target.querySelector('.sidebar-dropdown');
        if (dropdown && !this.sidebarOpen) {
            dropdown.classList.remove('show');
        }
    }

    getStatusUpdate() {
        this.statusService.summary().subscribe({
            next: (summary) => {
                this.currentStatus = summary.status;
            }
        });
    }


    onNavigationEnd() {
        if (!this.sidebarMenuMain) {
            this.sidebarMenuMain = this.elementRef.nativeElement.querySelector('#sidebar-menu-main');
        }
        const menuElement = this.sidebarMenuMain.nativeElement;
        menuElement.querySelectorAll('.sidebar-menu-active').forEach(activeElement => {
            activeElement.classList.remove('sidebar-menu-active');
        });
        let activeItemMenu = this.getItemMenu(this.currentRoute);

        if (!activeItemMenu && this.routeSelectCount) {
            this.routeSelectCount--;
            setTimeout(() => {
                this.onNavigationEnd();
            }, 1000);
            return;
        }

        if (activeItemMenu) {
            activeItemMenu.classList.add('sidebar-menu-active');
        }

        if (['/dashboard', '/live-testing', '/manual-testing', '/analytics', '/users', '/invoices', '/users/account', '/users/subaccounts'].includes(this.currentRoute)) {
            this.collapseAllOpenedSubmenus();
        }

        if (!this.sidebarOpen) {
            this.collapseAllOpenedSubmenus();
        }
    }


    sidebarToggle(event) {
        event.preventDefault();
        event.stopPropagation();
        this.sidebarOpen = !this.sidebarOpen;
        this.storage.set('sidebar_open', this.sidebarOpen);
        this.collapseAllOpenedSubmenus();
        this.applySidebar();
        this.settingService.setSidebarState(this.sidebarOpen);
    }

    collapseAllOpenedSubmenus() {
        const childUlElements = this.elementRef.nativeElement.querySelectorAll('ul.collapse.sidebar-dropdown');
        const parentAElements = this.elementRef.nativeElement.querySelectorAll('a.parent-link');

        for (let i = 0; i < childUlElements.length; i++) {
            this.renderer.removeClass(childUlElements[i], 'show');
        }
        for (let i = 0; i < parentAElements.length; i++) {
            this.renderer.setAttribute(parentAElements[i], 'aria-expanded', 'false');
            this.renderer.addClass(parentAElements[i], 'collapse');
        }
    }

    applySidebar() {
        if (this.sidebarOpen) {
            this.renderer.removeClass(this.bodyRef, 'sidebar-toggle');
        } else {
            this.renderer.addClass(this.bodyRef, 'sidebar-toggle');
        }
    }

    getItemMenu(url: string) {
        const cleanUrl = url.split('?')[0];
        let item = this.sidebarMenuMain.nativeElement.querySelector(`a[href="#${cleanUrl}"]`);
        if (item) {
            return item;
        }
        let urlSplit = cleanUrl.split('/');
        let simplifiedUrl = urlSplit.length > 2 ? `/${urlSplit[1]}` : urlSplit.join('/');
        return this.sidebarMenuMain.nativeElement.querySelector(`a[href^="#${simplifiedUrl}"]`);
    }

    is(roles: string[], onlyEnabled = false) {
        if (!this.user) {
            return false;
        }
        if (onlyEnabled && !this.user.enabled) {
            return false;
        }
        let result = false;
        for (let role of roles) {
            if (role === this.user.role) {
                result = true;
                break;
            }
        }
        return result;
    }

    openReleaseNotes() {
        rnw('show');
    }

    ngOnDestroy() {
        if (this.statusUpdateInterval) {
            clearInterval(this.statusUpdateInterval);
        }
        this.$authUpdates.unsubscribe();
        this.$balanceUpdates.unsubscribe();
        this.moAvailableObservable$.unsubscribe();
    }
}