import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { TestResult } from '../../../../shared/models/test-result.model';
import { ManualNumberTestingService } from '../../../../shared/services/manual-number-testing.service';
import { UsersService } from '../../../../shared/services/users.service';

@Component({
    selector: 'app-mnt-actions',
    templateUrl: './mnt-actions.component.html',
    styleUrls: ['./mnt-actions.component.scss']
})
export class MntActionsComponent {

    constructor(private userService: UsersService, private manualNumberTestingService: ManualNumberTestingService) { }

    currentRow: TestResult;
    showModem: boolean = true;

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.showModem = this.userService.authUser.role === 'admin';
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.manualNumberTestingService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }



}

