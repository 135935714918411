import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { Supplier } from '../../../shared/models/supplier.model';
import { SuppliersService } from '../../../shared/services/suppliers.service';
import { SupplierFormComponent } from '../supplier-form/supplier-form.component';

@Component({
    selector: 'app-supplier-update',
    templateUrl: './supplier-update.component.html',
})

export class SupplierUpdateComponent {

    @Output() afterSupplierSave: EventEmitter<Supplier> = new EventEmitter();
    @ViewChild(SupplierFormComponent, { static: false }) form: SupplierFormComponent;

    loading: boolean = false;
    model: Supplier;

    constructor(
        public supplierService: SuppliersService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Supplier update');
    }

    afterSave(supplier: Supplier): void {
        this.afterSupplierSave.emit(supplier);
    }
}
