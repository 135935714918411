
import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Session } from '../../../shared/models/session.model';
import { SupplierSelect } from "../../../shared/models/supplier.model";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { SessionsService } from '../../../shared/services/sessions.service';
import { SuppliersService } from '../../../shared/services/suppliers.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';

type SelectPair = { id: string | number, text: string | number };

@Component({
    selector: 'app-session-form',
    templateUrl: './session-form.component.html',
    styleUrls: ['./session-form.component.scss']
})

export class SessionFormComponent implements OnInit, AfterViewInit {

    @Input() backButtonShow = true;
    @Input() compact = false;

    @Output() onAfterSave = new EventEmitter();

    @ViewChild('confirmWindowEditModalTpl', { read: TemplateRef, static: false }) confirmWindowEditModalTpl: any;
    confirmWindowEditModal: DialogRef;

    isWindowValueEditable: boolean = false;

    model: Session;
    suppliers: SupplierSelect[] = [];
    valid: boolean = false;

    loading = false;
    formErrors = [];

    isAdmin = false;

    form: FormGroup;

    tonItems: SelectPair[] = [
        { id: 0, text: 0 },
        { id: 1, text: 1 },
        { id: 5, text: 5 },
    ];
    npiItems: SelectPair[] = [
        { id: 0, text: 0 },
        { id: 1, text: 1 },
    ];

    size: number = 20;
    page: number = 1;
    totalCountRows: number = 0;

    allSuppliers: SupplierSelect[] = [];
    currentSessionId: number;

    constructor(
        public elementRef: ElementRef,
        public notificationService: NotificationService,
        public userService: UsersService,
        public formBuilder: FormBuilder,
        public service: SessionsService,
        private supplierService: SuppliersService,
        public validationService: ValidationService,
        public modal: ModalService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.model = service.create();
        this.form = formBuilder.group({
            systemType: [null],
            hostIp: ['', Vld.compose([Vld.required, Vld.domainOrIp(true), Vld.maxLength(255), Vld.noSpace()])],
            hostPort: ['', Vld.compose([Vld.required, Vld.digits(true), Vld.noSpace(), Vld.min(0), Vld.max(65535)])],
            systemId: ['', Vld.compose([Vld.required, Vld.maxLength(16), Vld.gsm7(true), Vld.noSpace()])],
            password: ['', Vld.compose([Vld.maxLength(9), Vld.gsm7(true), Vld.noSpace()])],
            enabled: [true, Vld.compose([Vld.required])],
            useSSL: [true],
            throughput: [5, Vld.compose([Vld.required, Vld.min(1), Vld.max(50)])],
            dstTon: [1, Vld.compose([Vld.required])],
            dstNpi: [1, Vld.compose([Vld.required])],
            windowSize: new FormControl(
                { value: 1, disabled: true },
                Vld.compose([Vld.min(1, false), Vld.max(20, true)])
            ),
            windowWaitTimeout: new FormControl(
                { value: 60000, disabled: true },
                Vld.compose([Vld.min(0, false), Vld.max(300000, true)])
            ),
            bindTimeout: [null, Vld.compose([Vld.integer(), Vld.min(0), Vld.max(600000)])],
            connectTimeout: [null, Vld.compose([Vld.integer(), Vld.min(0), Vld.max(600000)])],
            enableCounters: [{ value: false, disabled: true }],
            interfaceVersion: [null, Vld.compose([Vld.integer(), Vld.min(0)])],
            requestExpiryTimeout: [null, Vld.compose([Vld.integer(), Vld.min(-1), Vld.max(600000)])],
            useLogPduOption: [{ value: false, disabled: true }],
            windowMonitorInterval: [null, Vld.compose([Vld.integer(), Vld.min(-1), Vld.max(600000)])],
            writeTimeout: [{ value: null, disabled: true }, Vld.compose([Vld.integer(), Vld.min(0), Vld.max(600000)])],
            visibleAll: [false]
        });
    }

    ngOnInit() {
        this.userService.can('admin').then(isAdmin => {
            this.isAdmin = isAdmin;
            this.isWindowValueEditable = isAdmin;
            if (isAdmin) {
                this.form.get('windowSize').enable();
                this.form.get('windowWaitTimeout').enable();
            }
        }).catch(e => { });
    }

    ngAfterViewInit(): void {
        const currentPath = this.location.path();
        const searchParams = new URLSearchParams(currentPath.substring(currentPath.indexOf('?')));
        this.currentSessionId = Number(searchParams.get('id'));
        if (this.currentSessionId) {
            this.loading = true;
            this.service.one(this.currentSessionId).subscribe((model: Session) => {
                this.setDefaultValues(model);
                this.model = model;
                this.loading = false;
            });
        } 
    }

    setDefaultValues(session: Session): void {
        session.windowSize = session.windowSize ? session.windowSize : 1;
    }

    isFormValid() {
        return this.form.valid;
    }

    alterFormValuesForPosting(): void {
        this.model.hostIp = this.model.hostIp.trim();
        this.model.systemType = this.model.systemType?.length ? this.model.systemType.trim() : null;
    }

    onWindowEdit(): void {
        this.confirmWindowEditModal = this.modal.alert().component(this.confirmWindowEditModalTpl).dialogClass('modal-dialog small-modal').open();
        this.confirmWindowEditModal.result.then(res => {
            if (res) {
                this.isWindowValueEditable = true;
                this.form.get('windowSize').enable();
                this.form.get('windowWaitTimeout').enable();
            }
        });
    }

    onSubmit() {
        this.loading = true;
        this.alterFormValuesForPosting();
        this.service.save(this.model)
            .subscribe({
                next: (res: Session) => {
                    this.loading = false;
                    this.notificationService.success(
                        `Session ${this.model.id ? 'updated' : 'created'}`,
                        'SMPP & Suppliers'
                    );
                    this.onAfterSave.emit(res);
                },
                error: (error) => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'SMPP & Supplier',
                        message: 'An error occurred while creating/updating SMPP session',
                        serviceName: 'NTC',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                }
            });
    }
}
