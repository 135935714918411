
import {debounceTime} from 'rxjs/operators';
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {SettingType} from "../../shared/models/setting.model";
import {SettingService} from "../../shared/services/setting.service";

@Component({
    selector: 'setting-value-component',
    templateUrl: './setting-value.component.html',
})

export class SettingValueComponent implements OnInit {

    @Output() settingChange = new EventEmitter();
    @Input() type: string;
    @Input() val;
    @Input() delay = 1000;
    changes = new EventEmitter();
    rowsCount: number = 5;

    constructor(public service: SettingService) {}

    ngOnInit() {
        this.changes.pipe(debounceTime(this.delay)).subscribe(from => {
            this.settingChange.emit(this.val);
        });

        if (this.type === SettingType.STR) {
            this.rowsCount = this.val.split("\n").length + 1;
        }
    }

    onChange(val) {
        if (val !== this.val) {
            this.val = val;
            this.changes.emit();
        }
    }
}