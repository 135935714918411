import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { ContentTemplatesFormComponent } from '../content-templates-form/content-templates-form.component';
import { Session } from '../../shared/models/session.model';

@Component({
    selector: 'app-content-template-create',
    templateUrl: './content-templates-create.component.html',
})

export class ContentTemplatesCreateComponent {

    @ViewChild(ContentTemplatesFormComponent, { static: false }) form;
    @Output() onAfterSave: EventEmitter<boolean> = new EventEmitter();

    loading = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Template create');
    }

    afterSave(data: boolean) {
        this.onAfterSave.emit(data);
    }
}
