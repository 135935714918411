import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    OnInit,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { AllRequestParamsNetwork, NetInfoService } from '../../../shared/services/net-info.service';
import {
    AppscriptsNetworkInfo,
    AppscriptsNumbersFilter,
    AppscriptsRegistrationInfo
} from '../../../shared/models/appscripts-user.model';
import { DropdownCtx } from "../../../shared/directives/dropdown.directive";
import { ModalService } from "../../../shared/services/modal.service";
import { AppscriptsUserService } from "../../../shared/services/appscripts-user.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../../shared/filter/filter";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";
import { NotificationService } from "../../../shared/services/notification.service";

@Component({
    selector: 'app-appscripts-user-filter',
    templateUrl: 'appscripts-user-filter.component.html',
    styleUrls: ['../../../shared/filter/filter.scss', 'appscripts-user-filter.component.scss'],
})
export class AppscriptsUserFilterComponent implements OnInit {

    @Output() onSubmit: EventEmitter<AppscriptsNumbersFilter> = new EventEmitter();

    @ViewChild('phoneInfoModalTpl', { read: TemplateRef, static: false }) phoneInfoModalTpl: any;
    networkInfo: AppscriptsNetworkInfo;
    registrationInfo: AppscriptsRegistrationInfo;
    tsLoading = false;
    tsPhone = '';

    @ViewChild(InputSearchDatePeriod, {static: false}) dateInput: InputSearchDatePeriod;

    networks: Network[] = [];
    networkSearch = '';

    @Input() model: ExtFilter = {
        id: null,
        activeFrom: null,
        activeTo: null,
        balanceMin: null,
        balanceMax: null,
        mtSmsCountMin: null,
        mtSmsCountMax: null,
        moSmsCountMin: null,
        moSmsCountMax: null,
        phoneNumbers: null,
        mcc: null,
        mnc: null,
        originalMnc: null,
        referrerCode: null,
        referralCode: null,
        email: null,
        paymentAddress: null,
        country: null,
        provider: null,
        moUserEnables: false
    };
    loading = false;
    modelKeys: string[] = [];

    filters: IFilterDef[] = [
        { title: 'Last active', show: true, order: 1 },
        { title: 'Phone', show: true, order: 2 },
        { title: 'Network', show: true, order: 3 },
        { title: 'MT Sms', show: true, order: 4 },
        { title: 'Balance', show: true, order: 5 },
        { title: 'MCC', show: false, order: 6 },
        { title: 'MNC', show: false, order: 7 },
        { title: 'Original MNC', show: false, order: 8 },
        { title: 'Referral', show: false, order: 9 },
        { title: 'Referrer', show: false, order: 10 },
        { title: 'Email', show: false, order: 11 },
        { title: 'Payment address', show: false, order: 12 },
        { title: 'MO numbers only', show: false, order: 13 },
        { title: 'ID', show: false, order: 14 },
    ];
    private readonly filterStorageKey = 'appscripts-numbers-filter';

    constructor(
        public elementRef: ElementRef,
        public networkService: NetInfoService,
        public modemService: AppscriptsUserService,
        private storage: LocalStorageService,
        public modal: ModalService,
        private notificationService: NotificationService
    ) {
        this.modelKeys = Object.keys(this.model);
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
                if (f.title === 'Last active' && this.dateInput) {
                    this.dateInput.reset();
                }
            }
        });
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    ngOnInit() {
        this.loading = true;
        let requestParams = new AllRequestParamsNetwork();
        requestParams.setSort('name', 'asc');
        this.networkService.networks(requestParams).subscribe({
            next: networks => {
                this.networks = networks.map(n => {
                    return {
                        mcc: n.mcc,
                        country: n.country.name,
                        mnc: n.mnc,
                        provider: n.name,
                        selected: false,
                        search: [n.mcc, n.country.name, n.mnc, n.name].join('')
                    }
                }).sort((a, b) => {
                    return a.country.localeCompare(b.country) || a.provider.localeCompare(b.provider)
                });
            },
            complete: () => {this.loading = false;}
        });

        let search = false;
        const defaults = {
            moUserEnables: false
        }
        for (let i in this.model) {
            const exceptedValue = typeof defaults[i] !== "undefined" ? defaults[i] : null;
            if (this.modelKeys.indexOf(i) === -1) {
                delete this.model[i];
            } else if (search === false && this.model[i] !== exceptedValue) {
                search = true;
            }
        }
        this.modelKeys.map(key => {
            if (!this.model[key]) {
                this.model[key] = null;
            }
        });

        if (search) {
            this.onSubmit.emit(this.model);
        }
    }

    onSubmitClick() {
        this.onSubmit.emit({
            id: this.model.id,
            activeFrom: this.model.activeFrom,
            activeTo: this.model.activeTo,
            balanceMin: this.model.balanceMin,
            balanceMax: this.model.balanceMax,
            mtSmsCountMin: this.model.mtSmsCountMin,
            mtSmsCountMax: this.model.mtSmsCountMax,
            phoneNumbers: this.model.phoneNumbers,
            mcc: this.model.mcc,
            mnc: this.model.mnc,
            originalMnc: this.model.originalMnc,
            referrerCode: this.model.referrerCode,
            referralCode: this.model.referralCode,
            email: this.model.email,
            paymentAddress: this.model.paymentAddress,
            moUserEnables: this.model.moUserEnables
       });
    }

    changeFilterValueByName(value: any, filterName: string, submit = true) {
        const filters = this.filters.filter(_ => _.title === filterName);
        if (filters.length) {
            this.onChangeFilterValue(value, filters[0], submit);
        }
    }

    getFilterByName(name: string): IFilterDef {
        return this.filters.filter(_ => _.title === name)[0];
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Last active':
                if (value === '') {
                    this.model.activeFrom = null;
                    this.model.activeTo = null;
                    filter.value = '';
                } else if (value.startIso && value.endIso) {
                    this.model.activeFrom = value.startIso;
                    this.model.activeTo = value.endIso;
                    filter.value = value.startFormatStr + ' ' + value.endFormatStr;
                }
                break;
            case 'Phone':
                this.model.phoneNumbers = value
                filter.value = this.model.phoneNumbers;
                break;
            case 'Network':
                this.networks.forEach(n => n.selected = false);
                if (value === '') {
                    this.model.mcc = '';
                    this.model.mnc = '';
                    this.model.originalMnc = '';
                    filter.value = '';
                    this.getFilterByName('MCC').value = '';
                    this.getFilterByName('MNC').value = '';
                } else {
                    let network = value as Network;
                    this.model.mcc = network.mcc;
                    this.model.mnc = network.mnc;
                    this.model.originalMnc = '';
                    filter.value = `${network.country}(${network.mcc})/${network.provider}(${network.mnc})`;
                    this.getFilterByName('MCC').value = network.mcc;
                    this.getFilterByName('MNC').value = network.mnc;
                    network.selected = true;
                }
                break;
            case 'MT Sms':
                if (value === '') {
                    this.model.mtSmsCountMin = null;
                    this.model.mtSmsCountMax = null;
                    filter.value = '';
                } else {
                    this.model.mtSmsCountMin = value.min ? value.min : null;
                    this.model.mtSmsCountMax =  value.max ? value.max : null;
                    if (this.model.mtSmsCountMin !== null && this.model.mtSmsCountMax === null) {
                        filter.value = `From ${value.min}`;
                    } else if (this.model.mtSmsCountMin === null && this.model.mtSmsCountMax !== null) {
                        filter.value = `To ${value.max}`;
                    } else {
                        filter.value = `${value.min} - ${value.max}`;
                    }
                }
                break;
            case 'Balance':
                if (value === '') {
                    this.model.balanceMin = null;
                    this.model.balanceMax = null;
                    filter.value = '';
                } else {
                    this.model.balanceMin = value.min ? value.min : null;
                    this.model.balanceMax =  value.max ? value.max : null;
                    if (this.model.balanceMin !== null && this.model.balanceMax === null) {
                        filter.value = `From ${value.min}`;
                    } else if (this.model.balanceMin === null && this.model.balanceMax !== null) {
                        filter.value = `To ${value.max}`;
                    } else {
                        filter.value = `${value.min} - ${value.max}`;
                    }
                }
                break;
            case 'MCC':
                filter.value = value;
                this.model.mcc = value ? value : null;
                this.getFilterByName('Network').value = '';
                break;
            case 'MNC':
                filter.value = value;
                this.model.mnc = value ? value : null;
                this.getFilterByName('Network').value = '';
                break;
            case 'Original MNC':
                filter.value = value;
                this.model.originalMnc = value ? value : null;
                break;
            case 'Referral':
                filter.value = value;
                this.model.referralCode = value ? value : null;
                break;
            case 'Referrer':
                filter.value = value;
                this.model.referrerCode = value ? value : null;
                break;
            case 'Email':
                filter.value = value;
                this.model.email = value ? value : null;
                break;
            case 'Payment address':
                filter.value = value;
                this.model.paymentAddress = value ? value : null;
                break;
            case 'MO numbers only':
                filter.value = value ? 'yes' : '';
                this.model.moUserEnables = !!value;
                break;
            case 'ID':
                filter.value = value;
                this.model.id = value;
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onClickTs() {
        this.networkInfo = null;
        this.registrationInfo = null;
        this.tsPhone = '';
        this.modal.alert().component(this.phoneInfoModalTpl).open();
    }

    onSubmitTs(phone: string) {
        phone = phone.trim();
        if (!phone) {return;}
        this.tsLoading = true;
        this.modemService.networkInfo(phone).subscribe({
            next: net => {
                this.networkInfo = net;
                console.log(this.networkInfo);
                this.tsLoading = false
            },
            error: e => {
                this.tsLoading = false;
                this.notificationService.error({
                    title: 'Numbers',
                    message: 'An error occurred',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        });
        this.modemService.registrationInfo(phone).subscribe({
            next: reg => this.registrationInfo = reg,
            error: e => {}
        });
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex);
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}

interface Network {
    country: string,
    mcc: string,
    provider: string,
    mnc: string,
    selected: boolean,
    search: string
}

interface ExtFilter extends AppscriptsNumbersFilter {
    country?: string
    provider?: string
}
