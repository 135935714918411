import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { SessionsService } from '../../../shared/services/sessions.service';
import { Supplier } from '../../../shared/models/supplier.model';

@Component({
    selector: 'app-supplier-actions',
    templateUrl: './supplier-actions.component.html',
    styleUrls: ['./supplier-actions.component.scss']
})
export class SupplierActionsComponent implements OnInit {

    currentRow: Supplier;

    constructor(private sessionService: SessionsService) { }

    ngOnInit(): void {
    }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.sessionService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

}
