
import { Component, Input, EventEmitter, ViewChild, Output, AfterViewInit, TemplateRef } from '@angular/core';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { InputFile } from "../../shared/components/input/input.file";
import { ImportInfo, MoShortcodeService, TaskStatus } from "../../shared/services/mo-shortcode.service";
import { UsersService } from "../../shared/services/users.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { BasicUser } from "../../shared/models/user.model";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
    selector: 'app-mo-shortcode-import',
    templateUrl: './mo-shortcode-import.component.html',
    styleUrls: ['./mo-shortcode-import.component.scss'],
})

export class MoShortcodeImportComponent implements AfterViewInit {

    @Output() import = new EventEmitter<TaskStatus>();
    @Input() fileList: FileList = null;

    loading = false;
    MAX_ROWS = 10000;
    accept = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    acceptExtensions = ['xlsx'];
    status: TaskStatus;
    importInfo: ImportInfo;
    validationFile: {lines: number[], description: string}[] = [];
    validationMessages: ValidationMessage[] = [];
    isAdmin = false;

    @ViewChild(InputFile, { static: false }) input: InputFile;
    @ViewChild('userListModalTpl', { read: TemplateRef, static: false }) userListModalTpl: any;
    userListModal: DialogRef;
    selectedUser: BasicUser = null;

    private file: File;
    private userId: number;

    constructor(
        public service: MoShortcodeService,
        public validationService: ValidationService,
        private userService: UsersService,
        private modal: ModalService,
        private notificationService: NotificationService
    ) {
        userService.getAuthUser().then(user => {
            this.isAdmin = user.role === 'admin';
            if (!this.isAdmin) {
                this.userId = user.id;
            }
        });
    }

    ngAfterViewInit() {
        if (this.fileList) {
            this.input.onChangeInput(this.fileList);
        }
    }

    onChangeImportFile(files: File[]) {
        if (files instanceof Event) {return;}
        if (!files.length) {return;}
        this.file = files[0];
        this.validationFile = [];
        this.status = null;
    }

    onSubmitImport() {
        this.loading = true;
        this.validationFile = [];
        this.status = null;
        this.service.import(this.file, this.userId).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'An error occurred while import short codes',
                    serviceName: 'MOTC',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                return throwError(() => e);
            })
        ).subscribe(s => {
            this.status = s;
            if (!this.importInfo) {
                this.importInfo = s.information;
            }
            s.validationFile?.forEach(e => {
                e.lines.forEach(l => this.validationMessages.push({line: l, message: e.description}));
            });
            if (s.status === 'SUCCEEDED') {
                this.notificationService.success(`Import finished was success`, 'Mobile Originated');
                this.import.emit(s);
            }
            if (s.status === 'SUCCEEDED' || s.status === 'FAILED') {
                this.loading = false;
                this.input.onClickRemoveFile(this.file);
                this.file = null;
            }
        });
    }

    onCancelImport(): void {
        this.import.emit(null);
    }

    isValid() {
        return !!this.file && !!this.userId;
    }

    showUserListModal(): void {
        this.userListModal = this.modal.alert().component(this.userListModalTpl).open();
    }

    onSelectUser(user: BasicUser) {
        this.selectedUser = user;
        this.userId = user.id;
        if (this.userListModal) {
            this.userListModal.close(true);
        }
    }
}

interface ValidationMessage {line: number, message: string}