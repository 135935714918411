import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportStatus } from '../models/export.model';
import { MoTestingSearch } from '../models/mo-testing.model';
import { SearchParams as LntSearchParams } from './live-number-testing.service';
import { SearchParams as MntSearchParams } from './manual-number-testing.service';
import { RestUtils } from './rest-utils';
import { BackendChannelService } from "./backend-channel.service";
import { SearchParams } from "./voice-testing.service";

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient, private backendChannel: BackendChannelService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    exportStatusByFile(statusCallBackUrl: number): Observable<ExportStatus> {
        let options = this.utils.getHttpHeaderOptions(this.headers);
        let url = this.utils.buildUrl(`ROLE/exp${statusCallBackUrl}`);

        return this.http.get<ExportStatus>(url, options);
    }

    export(source: string, limit: number, searchParams: MoTestingSearch | LntSearchParams | MntSearchParams = null): Promise<ExportFile> {
        const allSearchParams = {
            createdAtFrom: null,
            createdAtTo: null,
            ids: [],
            userIds: [],
            telqIds: [],
            origins: [],
            destinations: [],
            sentStatuses: [],
            dlrStatuses: [],
            messageStates: [],
            senders: [],
            sessionSupplierIds: [],
            testStatuses: [],
            text: '',
        };

        for (let i in allSearchParams) {
            if (typeof searchParams[i] === undefined) {
                searchParams[i] = allSearchParams[i];
            }
        }

        const exportRequestPayload = {
            totalSize: limit,
            format: 'XLSX',
            filter: searchParams
        }

        let url = this.utils.buildUrl(`ROLE/exp/export-async/${source}-test-results`);
        return this.exportByParams(url, exportRequestPayload);
    }

    exportVoiceTestsResults(limit: number, searchParams: SearchParams): Promise<ExportFile> {
        let url = this.utils.buildUrl(`ROLE/exp/export-async/voice-test-results`);
        return this.exportByParams(url, {
            totalSize: limit,
            format: 'XLSX',
            filter: searchParams
        });
    }

    private exportByParams(url: string, data: any): Promise<ExportFile> {
        return new Promise((then, error) => {
            this.http.post(url, data, this.utils.getHttpHeaderOptions(this.headers)).subscribe(data => {
                const statusCallbackUrl = data['statusCallback'];
                const downloadCallbackUrl = data['downloadCallback'];
                let repeat = 0;
                let maxRepeat = 120;
                let interval = setInterval(() => {
                    this.exportStatusByFile(statusCallbackUrl).subscribe(response => {
                        if (response.status === 'EXPORTED') {
                            clearInterval(interval);
                            then({
                                url: this.backendChannel.prepareUrl(this.utils.buildUrl(`ROLE/exp${downloadCallbackUrl}`)),
                                size: null,
                                sizeKb: null
                            });
                            return;
                        }
                        if (response.status === 'FAILED') {
                            clearInterval(interval);
                            error(new Error('Failed to generate file.'));
                        }
                        if (maxRepeat === repeat) {
                            clearInterval(interval);
                            error(Error('Timeout.'));
                        }
                        repeat++;
                    }, errorStatus => {
                        clearInterval(interval);
                        error(errorStatus);
                    });
                }, 5000);
            }, e => {
                error(e);
            });
        });
    }
}

interface ExportFile {
    url: string;
    size: number;
    sizeKb: number;
}