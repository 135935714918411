import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BreadcrumbService } from "../../shared/components/breadcrumbs/breadcrumbs";
import { AuditLog, AuditLogSearchParams } from "../../shared/models/audit-log.model";
import { AuditLogService } from "../../shared/services/audit-log.service";
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
    selector: 'audit-log-index-component',
    templateUrl: './audit-log-index.component.html',
    styleUrls: ['./audit-log-index.component.scss']
})
export class AuditLogIndexComponent implements OnInit {

    page = 0;
    totalCount = 0;
    params: AuditLogSearchParams = {};
    rows: AuditLog[] = [];

    iconByService = {
        'User': 'fas fa-user-friends',
        'Live testing': 'icon-live-Number-Testing',
        'Manual testing': 'icon-manual-testing',
        'Scheduler': 'icon-scheduler',
        'Public API': 'icon-api'
    }

    loading = false;

    constructor(
        @Inject('API_URL') baseUrl: string,
        public breadcrumbs: BreadcrumbService,
        public service: AuditLogService,
        public router: Router,
        public notificationService: NotificationService,
        public modal: ModalService,
        titleService: Title
    ) {
        titleService.setTitle('Audit');
        this.modal = modal;
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('Audit', ['audit/logs'], true);
        this.update();
    }

    update() {
        this.loading = true;
        this.params.page = this.page;
        this.params.search = (this.params.search && this.params.search.trim()) || "";
        this.service.logs(this.params).subscribe(event => {
            this.loading = false;
            switch (event.type) {
                case 'log':
                    event.value.ui = this.prepareValuesForUi(event.value);
                    this.rows.push(event.value);
                    break;
                case 'count':
                    this.totalCount = event.value;
                    break;
            }
        }, e => {
            this.loading = false;
            this.notificationService.error({
                title: 'Audit logs',
                message: 'Connection failed',
                serviceName: 'GATEWAY'
            });
        });
    }

    next() {
        this.page++;
        this.update();
    }

    onFilter(params: AuditLogSearchParams) {
        this.page = 0;
        this.params = params;
        this.rows = []
        this.update();
    }

    prepareValuesForUi(row: AuditLog) {
        let values = {
            title: row.resource,
            details: row.requestDetails,
            url: row.resource
        };

        if (row.requestDetails === null && row.params && row.params.indexOf('"userAgent"') === -1) {
            values.details = row.params;
        }

        if (row.resource === 'user.online') {
            values.title = `<span class="highlighted success">${row.resource}</span>`;
            return values;
        }
        if (row.resource === 'user.offline') {
            values.title = `<span class="highlighted error">${row.resource}</span>`;
            return values;
        }

        if (row.resource === 'user.mfa-enabled' || row.resource === 'user.mfa-disabled') {
            values.title = row.resource;
            values.url = null;
            values.details = null;
            return values;
        }

        if (row.resource === 'user/online') {
            values.title = `<span class="highlighted success">user.online</span>`;
            return values;
        }

        if (row.resource === 'user/offline') {
            values.title = `<span class="highlighted error">user.online</span>`;
            return values;
        }

        if (row.resource && row.resource.indexOf('login') !== -1) {
            values.title = `<span class="highlighted success">user.login</span>`;
            return values;
        }

        if (row.resource && row.resource.indexOf('logout') !== -1) {
            values.title = `<span class="highlighted error">user.logout</span>`;
            return values;
        }

        if (row.resource && row.resource.indexOf('sessions') !== -1 && row.resource.indexOf('suppliers') !== -1) {
            values.title = 'sessions.<span class="text-sucess-s2">assign</span> to supplier';
            if (row.method === 'DELETE') {
                values.title = 'sessions.<span class="text-error-r2">revoke</span> from supplier';
            }
            let regExp = /(suppliers|sessions)\/(\d+)\/(suppliers|sessions)\/(\d+)/gm;
            let result = regExp.exec(row.resource);
            if (Array.isArray(result) && result.length === 5) {
                let supplierId = parseInt(result[2]);
                let sessionId = parseInt(result[4]);
                if (result[1] === 'sessions') {
                    sessionId = parseInt(result[2]);
                    supplierId = parseInt(result[4]);
                }
                values.details = `{"sessionId": ${sessionId}, "supplierId": ${supplierId}}`;
            }
            return values;
        }

        const tasksResult = /tasks\/(\d+)\/(hidden|stop|schedule)/gm.exec(row.resource);
        if (tasksResult && tasksResult.length) {
            const taskActions = {
                schedule: '<span class="text-sucess-s2">schedule</span>',
                visible: '<span class="text-sucess-s2">visible</span>',
                stop: '<span class="text-error-r2">stop</span>',
                hidden: '<span class="text-error-r2">hide</span>'
            }
            const taskId = tasksResult[1];
            let action = tasksResult[2];
            if (action === 'hidden') {
                action = row.params.indexOf('hidden=[false]}') !== -1 ? 'visible' : 'hidden';
            }
            values.title = `task.${taskActions[action]}`;
            values.details = `{"id": ${taskId}}`;
            return values;
        }

        if (row.service === 'Public API') {
            let apiResults = /(v[.0-9]*)\/client/gm.exec(row.resource)
            if (apiResults && apiResults.length) {
                values.details = `{"version": "${apiResults[1]}"}`
            }
            if (row.resource.indexOf('client/tests') !== -1 || row.resource.indexOf('client/send')) {
                values.title = `test-groups.<span class="text-sucess-s2">create</span>`;
                return values;
            }
        }

        if (row.method && row.resource) {
            let lastResourceSplit = row.resource.split('/')
            if (row.resource.indexOf('bulk-delete') !== -1) {
                values.title = lastResourceSplit[lastResourceSplit.length - 2] + '.<span class="text-error-r2">delete</span>';
                return values;
            }

            let details: any = {};
            if (values.details) {
                try {
                    details = JSON.parse(values.details)
                } catch (e) {}
            }

            let lastResource = lastResourceSplit.pop();
            if (/^\d+$/.test(lastResource)) {
                details.id = parseInt(lastResource);
                lastResource = lastResourceSplit[lastResourceSplit.length - 1];
            }

            if (Object.keys(details).length) {
                values.details = JSON.stringify(details);
            }

            const methods = {
                POST: '<span class="text-sucess-s2">create</span>',
                PUT: '<span class="text-sucess-s2">update</span>',
                DELETE: '<span class="text-error-r2">delete</span>'
            }
            if (methods[row.method]) {
                values.title = lastResource + '.' + methods[row.method];
                return values;
            }
        }

        return values;
    }
}