import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']

})
export class RegistrationComponent {

    constructor(
        title: Title,
        public router: Router,
        public userService: UsersService,
        public notificationService: NotificationService
    ) {
        title.setTitle('Registration');
    }
}