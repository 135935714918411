
import {Component, EventEmitter, ViewChild, TemplateRef, Output, OnInit} from '@angular/core';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { AllRequestParams, PricingService } from "../../../shared/services/pricing.service";
import { PricingGroupCollection } from "../../../shared/models/pricing.model";
import { DatePipe } from "@angular/common";
import { UsersService } from '../../../shared/services/users.service';
import { TableBase } from '../../../shared/components/table/table-base';
import { ColumnTypes } from '../../../shared/components/table/table.component';
import { ActionEvent, CellClickEvent, ColumnDef } from '../../../shared/components/table/table.component';
import {ModalService} from "../../../shared/services/modal.service";
import { forkJoin } from "rxjs";

@Component({
    selector: 'app-pricing-group-table',
    templateUrl: './pricing-group-table.component.html',
    styleUrls: ['./pricing-group-table.component.scss'],
})

export class PricingGroupTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    isAdmin: boolean = false;
    requestParams = new AllRequestParams();

    constructor(
        public notificationService: NotificationService,
        public service: PricingService,
        public storage: LocalStorageService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id' },
            { title: 'Title', prop: 'title', cellClassName: 'editable' },
            { title: 'Type', prop: 'paymentType' },
            { title: 'Enabled', prop: 'enabled', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center' },
            { title: 'Visible all', prop: 'visibleAll', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center' },
            { title: 'Commitment', prop: 'totalCommitment' },
        ]));
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit' },
            { icon: 'far fa-trash-alt', name: 'delete' }
        ];
        this.loading = false;
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service.allGroups(this.requestParams).subscribe((collection: PricingGroupCollection) => {
            this.setData(this.createRows(collection.content), collection.totalElements);
            this.loading = false;
        }, (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'General pricing',
                message: 'An error occurred while loading the pricing group',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        })
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'title') {
            const positionRow = this.rows.indexOf(event.row);
            const nextRow = positionRow + 1;
            const isOpen = typeof this.rows[nextRow] !== 'undefined' && this.rows[nextRow].tables;
            if (isOpen) {
                this.rows = this.rows.filter((el, index) => index !== nextRow)
                return;
            }
            const gId = event.row.data.id;

            const columnsBundle: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'serviceType' },
                { title: 'Rate per product/test (EUR)', prop: 'ratePerUnit' },
                { title: 'Top up value (EUR)', prop: 'commitment' },
                { title: 'Pricing visible from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom) },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil) },
                { title: 'Purchased credit/access available until',  prop: 'validForSeconds', format: row => PricingService.formatValidForSeconds(row.validForSeconds ? row.validForSeconds : null) },
            ]);
            const columnsTier: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'serviceType' },
                { title: 'Rate per product/test (EUR)', prop: 'ratePerUnit' },
                { title: 'Commitment  (EUR)', prop: 'commitment', sort: false },
                { title: 'Valid from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom) },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil) }
            ]);

            const columnsAnalytics: ColumnDef[] = this.createColumns([
                { title: 'Product type', prop: 'rate', format: () => 'Analytics' },
                { title: 'Rate per product (EUR)', prop: 'rate' },
                { title: 'Valid from', prop: 'visibleFrom', format: row => this.formatDate(row.visibleFrom) },
                { title: 'Visible until', prop: 'visibleUntil', format: row => this.formatDate(row.visibleUntil) }
            ]);

            if (event.row.data.paymentType === 'PREPAID') {
                this.loading = true;
                forkJoin([this.service.bundles(gId), this.service.analyticsTariffs(gId, 'general')]).subscribe({
                    next: results => {
                        this.loading = false;
                        let row = this.createRow({});
                        let tables = [{
                            columns: columnsBundle,
                            rows: this.createRows(results[0])
                        }];
                        if (results[1] && results[1].length) {
                            tables.push({
                                columns: columnsAnalytics,
                                rows: this.createRows(results[1])
                            });
                        }
                        row.tables = tables;
                        this.rows.splice(positionRow + 1, 0, row);
                    },
                    error: err => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'General pricing',
                            message: 'An error occurred while loading the bundles',
                            serviceName: 'GATEWAY',
                            requestMessage: err.statusText,
                            requestCode: err.status
                        });
                    }
                });
            }
            if (event.row.data.paymentType === 'POSTPAID') {
                this.loading = true;
                forkJoin([this.service.tiers(gId), this.service.analyticsTariffs(gId, 'general')]).subscribe({
                    next: results => {
                        this.loading = false;
                        let row = this.createRow({});
                        let tables = [{
                            columns: columnsTier,
                            rows: this.createRows(results[0])
                        }];
                        if (results[1] && results[1].length) {
                            tables.push({
                                columns: columnsAnalytics,
                                rows: this.createRows(results[1])
                            });
                        }
                        row.tables = tables;
                        this.rows.splice(positionRow + 1, 0, row);
                    },
                    error: err => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'General pricing',
                            message: 'An error occurred while loading the tiers',
                            serviceName: 'GATEWAY',
                            requestMessage: err.statusText,
                            requestCode: err.status
                        });
                    }
                });
            }
        }
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'switch' && event.column.prop === 'enabled') {
            const oldValue = !event.row.data.enabled;
            event.row.data.enabled = !oldValue;
            this.service
                .changeEnabledGroup(event.row.data.id, event.row.data.enabled)
                .subscribe(
                    () => this.notificationService.success('Pricing group updated', 'General pricing'),
                    error => {
                        this.notificationService.error({
                            title: 'General pricing',
                            message: 'Pricing group updating an error',
                            serviceName: 'GATEWAY',
                            requestMessage: error.statusText,
                            requestCode: error.status
                        });
                        event.row.data.enabled = oldValue;
                    }
                );
        }

        if (event.name === 'switch' && event.column.prop === 'visibleAll') {
            const oldValue = !event.row.data.visibleAll;
            event.row.data.visibleAll = !oldValue;
            this.service
                .changeVisibleAllGroup(event.row.data.id, event.row.data.visibleAll)
                .subscribe(
                    () => this.notificationService.success('Pricing group updated', 'General pricing'),
                    error => {
                        this.notificationService.error({
                            title: 'General pricing',
                            message: 'Pricing group updating an error',
                            serviceName: 'GATEWAY',
                            requestMessage: error.statusText,
                            requestCode: error.status
                        });
                        event.row.data.visibleAll = oldValue;
                    }
                );
        }

        this.actions.emit(event);
    }

    onChangeSize(size) {
        super.onChangeSize(size);
        this.update();
    }

    delete(group) {
        this.loading = true;
        this.service
            .deleteGroup(group.id)
            .subscribe(() => {
                this.notificationService.success('Pricing group removed', 'General pricing');
                this.onAfterDelete.emit();
                this.update();
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'General pricing',
                    message: 'Pricing group deleting an error',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
    }

    formatDate(timestamp) {
        if (timestamp === null) {
            return '';
        }

        return this.datePipe.transform(new Date(timestamp), 'MMMM d, y');
    }
}
