import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { Title } from '@angular/platform-browser';
import { AppscriptsSmsFilterComponent } from '../appscripts-sms-filter/appscripts-sms-filter.component';
import { AppscriptsSmsTableComponent } from '../appscripts-sms-table/appscripts-sms-table.component';
import { RowDef } from '../../../shared/components/table/table.component';
import {AppscriptsSmsFilter} from "../../../shared/models/appscripts-sms.model";
import {ModalService} from "../../../shared/services/modal.service";
import { BrowserUtils } from '../../../shared/services/browser-utils';

@Component({
    selector: 'app-appscripts-sms-index',
    templateUrl: 'appscripts-sms-index.component.html',
})

export class AppscriptsSmsIndexComponent implements OnInit, AfterViewInit {

    @ViewChild('copyPhonesModalTpl', {read: TemplateRef, static: false}) copyPhonesModalTpl: any;
    copyPhonesText: string;
    copyPhonesTextControlRows: number;

    @ViewChild(AppscriptsSmsFilterComponent, {static: false}) filter: AppscriptsSmsFilterComponent;
    @ViewChild(AppscriptsSmsTableComponent, {static: false}) table: AppscriptsSmsTableComponent;

    backButtonShow: boolean = false;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
    ) {
        titleService.setTitle('Sms');
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.phonenumber) {
                this.filter.model.phoneNumbers = params.phonenumber;
                this.backButtonShow = true;
            }
            this.filter.onSubmitClick();
        });
    }

    onFilter(data) {
        let params: AppscriptsSmsFilter = {};
        if (data.receivedAtFrom && data.receivedAtTo) {
            params.receivedAtFrom = data.receivedAtFrom;
            params.receivedAtTo = data.receivedAtTo;
        }
        if (data.createdAtFrom && data.createdAtTo) {
            params.createdAtFrom = data.createdAtFrom;
            params.createdAtTo = data.createdAtTo;
        }

        let rawSearchFields = ['text', 'phoneNumbers', 'sender', 'smsc', 'telqId'];
        rawSearchFields.map(field => {
            let v = data[field];
            if (v === '' || v === null || v === undefined) {
                return;
            }
            params[field] = v;
        });

        this.table.filter = params;
        this.table.page = 1;
        this.table.update();
    }

    onClickCopyPhones() {
        let phones = this.selectedRows.map(_ => _.data.phoneNumber);
        this.copyPhonesText = phones.join("\n");
        this.copyPhonesTextControlRows = phones.length;
        BrowserUtils.copyToClipboard(this.copyPhonesText);
        this.notificationService.success('Copied to clipboard', 'SMS');
        this.modal.alert().component(this.copyPhonesModalTpl).open();
    }

    onClickCopyPhonesControl(e) {
        let input = e.target;
        if (input.setSelectionRange) {
            input.setSelectionRange(0, input.value.length)
        } else {
            input.select();
        }
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }
}