
import { DatePipe } from "@angular/common";
import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes } from "../../shared/components/table/table.component";
import { Invoice, InvoicesCollection } from "../../shared/models/invoice.model";
import { InvoiceRequestParams, InvoiceService } from '../../shared/services/invoice.service';
import { UsersService } from '../../shared/services/users.service';
import {ColumnDef} from "../../shared/components/table/table.component";
import { ModalService } from "../../shared/services/modal.service";
import { AuthUser, PaymentType } from "../../shared/models/user.model";
import { catchError, map } from "rxjs/operators";

@Component({
    selector: 'app-invoices-table',
    templateUrl: './invoices-table.component.html',
    styleUrls: ['./invoices-table.component.scss'],
})

export class InvoicesTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    requestParams = new InvoiceRequestParams();
    user: AuthUser;

    constructor(
        public notificationService: NotificationService,
        public service: InvoiceService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
        this.notificationService = notificationService;
        this.modal = modal;
        this.service = service;
        this.userService = userService;
    }

    ngOnInit() {
        this.loading = true;
        let columns: ColumnDef[] = [
            { title: 'Payment status', prop: 'status', sort: false, format: this.paymentStatusFormat, type: ColumnTypes.HTML },
            { title: 'Created on', prop: 'createdAt', type: ColumnTypes.HTML, cellClassName: 'full-content', sort: false, format: row => this.formatCreatedOn(row) },
            { title: 'Paid on', prop: 'paidAt', type: ColumnTypes.HTML, cellClassName: 'full-content', sort: false, format: row => this.formatPaidOn(row) },
            { title: 'Top-up value (EUR)', prop: 'totalCommitment', sort: false },
            { title: 'Purchase Rate for MT', prop: 'rateApplied', sort: false },
            { title: 'Package', prop: 'pricingGroupTitle', sort: false },
            { title: 'Created by', prop: 'createdByEmail', sort: false },
            { title: 'User', prop: 'userEmail', sort: false },
            { title: 'Email sent', prop: 'emailSent', sort: false, format: this.emailSentStatusFormat, type: ColumnTypes.HTML },
            { title: 'Payment', prop: 'paymentValue', sort: false, format: this.paypalFormat, type: ColumnTypes.HTML }
        ];
        this.tableActions = [
            { icon: 'fas fa-file-alt text-navy', title: 'Download Tests XLSX', name: 'reportDownload', show: row => !!row.reportReference },
            { icon: 'fa fa-eye text-navy', title: 'View', name: 'view' },
            { icon: 'fas fa-file-invoice-dollar text-navy', title: 'Generate Invoice', name: 'generateZohoReport', show: (row) => this.user.role === 'admin' && !row.zohoId },
            { icon: 'fas fa-file-invoice-dollar text-navy', title: 'Regenerate Invoice', name: 'generateZohoReport', show: (row) => this.user.role === 'admin' && !!row.zohoId },
            { icon: 'fas fa-file-download text-navy', title: 'Download PDF Invoice', name: 'downloadZohoReport', show: (row) => !!row.zohoId },
            { icon: 'fas fa-paper-plane text-navy', title: 'Send email from Zoho', name: 'sendZohoReport', show: (row) => !!row.zohoId &&  this.user.role === 'admin' },
            { icon: 'far fa-trash-alt', title: 'Delete', name: 'delete', show: row => this.canShowActionDelete(row) },
        ];
        this.userService.getAuthUser().then(user => {
            this.user = user;
            const role = user.role;
            if (role !== 'admin') {
                const exclude = ['User', 'Email sent'];
                columns = columns.filter(_ => exclude.indexOf(_.title) === -1);
            }
            if (role !== 'mainaccount' || user.paymentType === PaymentType.POSTPAID) {
                columns = columns.filter(_ => _.prop !== 'paymentValue');
            }
            this.setColumns(this.createColumns(columns));
            this.update();
        }).catch(e => {
            console.log(e)
        });
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    update(loading = true) {
        this.loading = loading;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        const batchIds = this.rows.filter(_ => _.batch).map(_ => _.data.id);
        this.service.all(this.requestParams).pipe(
            catchError(error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while loading the invoices',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                throw error;
            })
        ).subscribe(collection => {
            collection.content.forEach(invoice => {
                if (invoice.void) {
                    invoice.status = 'VOID';
                }
            });
            const rows = collection.content.map(_ => {
                let row = this.createRow(_);
                row.batch = batchIds.includes(_.id);
                return row;
            })
            this.setData(rows, collection.totalElements);
            setTimeout(() => this.updateBatch(), 0);
            this.loading = false;
        });
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'reportDownload') {
            let url = this.service.getReportUrl(event.row.data.reportReference);
            window.open(url, '_blank');
        }

        if (event.name === 'generateZohoReport') {
            this.loading = true;
            const hasZohoId = !!event.row.data.zohoId;
            this.service.createZohoInvoice(event.row.data.id).subscribe(() => {
                this.update();
                this.notificationService.success(hasZohoId ? 'Zoho report has been updated' : 'Zoho report has been generated', 'Invoices');
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while creating Zoho invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
        }

        if (event.name === 'downloadZohoReport') {
            let url = this.service.getZohoReportUrl(event.row.data.id);
            window.open(url, '_blank');
        }

        if (event.name === 'sendZohoReport') {
            this.loading = true;
            this.service.sendZohoInvoice(event.row.data.id).subscribe(() => {
                this.loading = false;
                this.notificationService.success('Zoho report has been sent', 'Invoices');
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
        }

        this.actions.emit(event);
    }

    onChangeSize(size) {
        super.onChangeSize(size);
        this.update();
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'paymentValue') {
            this.actions.emit({
                column: event.column,
                name: 'paypalPayment',
                row: event.row
            });
        }
    }

    delete(invoice) {
        this.loading = true;
        this.service
            .delete(invoice.id)
            .subscribe(() => {
                this.notificationService.success('Invoice removed', 'Invoices');
                this.onAfterDelete.emit();
                this.update();
            }, () => this.loading = false);
    }

    canShowActionEdit(row: Invoice) {
        return row.status === 'WAITING';
    }

    canShowActionDelete(row) {
        if (row.autoGenerated) return false;
        return this.canShowActionEdit(row);
    }

    paymentStatusFormat(invoice: Invoice) {
        const statusMap = {
            WAITING: '<span class="icon icon-wait"></span> Waiting',
            PROCESSING: '<span class="icon icon-wait"></span> Processing',
            CONFIRMED: '<span class="icon icon-positive text-sucess-s2"></span> Confirmed',
            VOID: 'Void',
            PARTIALLY_PAID: 'Partial payment',
        };
        return statusMap[invoice.status];
    }

    emailSentStatusFormat(invoice: Invoice) {
        if (invoice.emailSent == true) {
            return 'Yes'
        } else {
            return 'No'
        }
    }

    paypalFormat(invoice: Invoice) {
        if (invoice.status === 'WAITING' || invoice.status === 'PARTIALLY_PAID') {
            return '<div class="btn btn-paypal btn-primary"><i class="fab fa-paypal"></i> Payment</div>';
        }
        return '';
    }

    formatCreatedOn(invoice: Invoice) {
        let content = this.datePipe.transform(invoice.createdAt, 'medium');
        if (invoice.autoGenerated) {
            content = `<i class="fas fa-robot text-primary-p8" title="Auto generate"></i> ` + content;
        }
        return content;
    }

    formatPaidOn( row: Invoice) {
        return this.datePipe.transform(row.paidAt, 'medium');
    }
}
