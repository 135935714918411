import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { TestCaseTemplate } from '../../shared/models/test-case-template.model';
import { TestCaseTemplatesService } from '../../shared/services/test-case-template.service';
import { LntFormComponent } from '../../test/lnt/lnt-form/lnt-form.component';
import { Location } from '@angular/common';
import { catchError } from "rxjs/operators";

@Component({
    selector: 'app-test-case-template-update',
    templateUrl: './test-case-template-update.component.html',
    styleUrls: ['./test-case-template-update.component.scss']
})

export class TestCaseTemplateUpdateComponent implements OnInit {

    @Output() onTestCaseTemplateSave: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(LntFormComponent, { static: false }) formTestCase: LntFormComponent;

    model;
    form: FormGroup;

    lntFormReady: boolean = false;
    loading: boolean = false;
    backButtonShow: boolean = true;

    constructor(
        public service: TestCaseTemplatesService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title,
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        private location: Location
    ) {
        titleService.setTitle('Template update');
        this.form = formBuilder.group({
            title: ['', Vld.compose([Vld.required])],
        });
    }

    ngOnInit() {
        const currentPath = this.location.path();
        const searchParams = new URLSearchParams(currentPath.substring(currentPath.indexOf('?')));
        let id: number = Number(searchParams.get('id'));
        this.loading = true;
        this.service.one(id).subscribe((model: TestCaseTemplate) => {
            this.model = model;
            this.onLoad();
        });
    }

    onLoadForm() {
        this.lntFormReady = true;
        this.onLoad();
    }

    onLoad() {
        if (this.model && this.lntFormReady) {
            this.formTestCase.setTestGroup(
                this.service.testCaseTemplateConvertTestGroup(this.model),
                this.service.testCaseTemplateConvertTestGroupInfo(this.model)
            );
            this.loading = false;
        }
    }

    onSubmit() {
        this.loading = true;
        this.model.title = this.model.title && this.model.title.trim();
        this.service.saveTestGroup(this.formTestCase.createModel(false), this.model).pipe(catchError(e => {
            this.notificationService.error({
                title: 'Test templates',
                message: 'An error occurred while test template updating',
                serviceName: 'LNT',
                requestMessage: this.service.prepareTextError(e),
                requestCode: e.status,
                ts: e.timestamp ? e.timestamp : null
            });
            this.loading = false;
            throw e;
        })).subscribe(_ => {
            this.loading = false;
            this.notificationService.success('Test template updated', 'Test templates');
            this.onTestCaseTemplateSave.emit(true);
        });
    }
}
