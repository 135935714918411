import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { InputSearch } from '../../shared/components/input/input.search';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { MoShortcodeService, TaskStatus } from '../../shared/services/mo-shortcode.service';
import { DialogRef, ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { MoShortcodeTableComponent } from '../mo-shortcode-table/mo-shortcode-table.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-mo-shortcode-index',
    templateUrl: './mo-shortcode-index.component.html',
    styleUrls: ['./mo-shortcode-index.component.scss']
})
export class MoShortcodeIndexComponent {

    @ViewChild(MoShortcodeTableComponent, { static: false }) table: MoShortcodeTableComponent;
    @ViewChild(InputSearch, { static: false }) search: InputSearch;

    spinner: boolean = false;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    @ViewChild('importModalTpl', { read: TemplateRef, static: false }) importModalTpl: any;
    importModal: DialogRef;

    constructor(
        public router: Router,
        public moShortCodeService: MoShortcodeService,
        public notificationService: NotificationService,
        public titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Short codes');
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    moShortCodeAction(event) {
        if (event.name === 'edit') {
            this.router.navigate(['/mo-testing/short-codes/', event.row.data.id]).then();
        }
    }

    moShortCodeCreate() {
        this.router.navigate(['/mo-testing/short-codes/create']).then();
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.moShortCodeService.deleteMoShortCode(row.data.id).pipe(map(() => {
                this.notificationService.success(`${row.data.id} deleted.`, 'Mobile Originated');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error(`${row.data.id} could not be deleted.`, 'Mobile Originated');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchToggle(enabled: boolean) {
        if (!this.selectedRows.length) {
            return;
        }
        this.modal.confirm('Are you sure?', `You are going to ${enabled ? 'enable' : 'disable'} your selection.`).open().result.then(res => {
            if (!res) { return; }
            this.batchToggle(enabled);
        });
    }

    private batchToggle(enabled: boolean) {
        const tasks = this.selectedRows.map(row => {
            row.data.enabled = enabled;
            return this.moShortCodeService.updateMoShortCode(row.data.id, row.data).pipe(map(() => {
                const text = (enabled ? 'Enabled short code ' : 'Disabled short code ') + row.data.phoneNumber;
                this.notificationService.success(text, 'Mobile Originated');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                const text = enabled ? `Error in enabling phone number ${row.data.phoneNumber}` : `Error in disabling phone number ${row.data.phoneNumber}`;
                this.notificationService.error(text, 'Mobile Originated');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.table.requestParams.search = phrase;
        this.table.page = 1;
        this.table.update();
    }

    handleSearch(phrase) {
        this.search.setVal(phrase);
    }

    onClickImport() {
        this.importModal = this.modal.alert().component(this.importModalTpl).open();
    }

    onImport(task: TaskStatus) {
        if (!task) {
            this.importModal.close();
            return;
        }
        if (task.status === 'SUCCEEDED') {
            this.table.update();
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

}
