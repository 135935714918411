import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, throwError } from 'rxjs';
import { MoShortCode, MoShortCodeCollection } from '../models/mo-shortcode.model';
import { RestUtils } from './rest-utils';
import { ImportResult } from "../models/import-result.model";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MoShortcodeService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: AllRequestParams, enabled: boolean = null): Observable<MoShortCodeCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort.length ? params.sort : [],
            search: params.search ? params.search : '',
            enabled: enabled
        };
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<MoShortCodeCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<MoShortCode>(url, options);
    }

    createMoShortCode(data: MoShortCode[]): Observable<MoShortCode> {
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers/');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post<MoShortCode>(url, data, options);
    }

    updateMoShortCode(id: number, data: MoShortCode): Observable<MoShortCode> {
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put<MoShortCode>(url, data, options);
    }

    deleteMoShortCode(id: number): Observable<void> {
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete<void>(url, options);
    }

    getDetailsForShortCode(shortCode: string): Observable<MoShortCodeCollection> {
        let queryParams = {
            shortcode: shortCode,
            size: 1000
        };
        let url = this.utils.buildUrl('ROLE/motc/allowed-short-numbers', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<MoShortCodeCollection>(url, options);
    }

    import(file: File, userId: number) {
        let currentStatus: TaskStatus;
        return new Observable<TaskStatus>(subscriber => {
            const statusHandler = (s: TaskStatus) => {
                if (!currentStatus || currentStatus.status !== s.status) {
                    currentStatus = s;
                    subscriber.next(s);
                }
                if (currentStatus.status === 'SUCCEEDED' || currentStatus.status === 'FAILED') {
                    subscriber.complete();
                }
                if (currentStatus.status === 'PROCESSING') {
                    setTimeout(() => {
                        this.getTaskStatus(currentStatus.taskId).pipe(
                            catchError(e => {
                                subscriber.error(e);
                                subscriber.complete();
                                return throwError(() => e);
                            })
                        ).subscribe(statusHandler);
                    }, 10 * 1000);
                }
            };
            this.upload(file, userId).pipe(
                catchError(e => {
                    subscriber.error(e);
                    subscriber.complete();
                    return throwError(() => e);
                })
            ).subscribe(statusHandler);
        });
    }

    private upload(file: File, userId: number) {
        let url = this.utils.buildUrl(`ROLE/motc/allowed-short-numbers/upload-file`, {clientId: userId});
        let data = new FormData();
        data.append('file', file);

        let options = this.utils.getHttpHeaderOptions(new HttpHeaders());
        return this.http.post<TaskStatus>(url, data, options);
    }

    private getTaskStatus(taskId: number) {
        let url = this.utils.buildUrl(`ROLE/motc/async-tasks/${taskId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<TaskStatus>(url, options);
    }

    getImportExampleFileUrl() {
        return window.location.origin + '/templates/short-codes.xlsx';
    }

}

export interface TaskStatus {
    taskId: number,
    status: 'PROCESSING'|'SUCCEEDED'|'FAILED',
    information: ImportInfo,
    validationFile: {lines: number[], description: string}[]
}

export interface ImportInfo {rows: number, skipped: number, imported: number}


export class AllRequestParams {
    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    userIds: number[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}