
import { DatePipe } from '@angular/common';
import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import {ActionEvent, ColumnTypes} from '../../../shared/components/table/table.component';
import {
    AppscriptsPaymentRequest,
    AppscriptsPaymentRequestFilter, AppscriptsPayoutCrypto, AppscriptsPayoutPaypal
} from '../../../shared/models/appscripts-payment-request.model';
import { AppscriptsPaymentRequestParams, AppscriptsPaymentRequestService } from '../../../shared/services/appscripts-payment-request.service';
import {CellClickEvent, SortEvent} from "../../../shared/components/table/table.component";
import {ModalService} from "../../../shared/services/modal.service";
import { BrowserUtils } from "../../../shared/services/browser-utils";


@Component({
    selector: 'app-appscripts-payment-request-table',
    templateUrl: 'appscripts-payment-request-table.component.html'
})

export class AppscriptsPaymentRequestTableComponent extends TableBase implements OnInit {

    @Output() actions = new EventEmitter();
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('infoTransactionModalTpl', { read: TemplateRef, static: false }) infoTransactionModalTpl: any;
    errorText: string;
    payoutPaypal: AppscriptsPayoutPaypal;
    payoutCrypto: AppscriptsPayoutCrypto;

    @ViewChild('balanceModalTpl', { read: TemplateRef, static: false }) balanceModalTpl: any;
    balanceUserId;

    requestParams = new AppscriptsPaymentRequestParams();
    filter: AppscriptsPaymentRequestFilter;
    storageContextName = 'appscripts-payment-request-table';

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsPaymentRequestService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        let statuses = {
            PENDING: {
                text: 'Pending',
                className: 'text-muted'
            },
            PROCESSING: {
                text: 'Processing',
                className: 'text-muted'
            },
            PAYMENT_SENT: {
                text: 'Payment sent',
                className: 'text-muted'
            },
            PAID: {
                text: 'Paid',
                className: 'text-navy'
            },
            FAILED: {
                text: 'Failed',
                className: 'text-danger'
            }
        };
        this.setColumns(this.createColumns([
            { title: 'ID', prop: 'id', sort: true },
            { title: 'Date', prop: 'createdAt', format: row => this.formatDate(row.createdAt), sort: true },
            { title: 'Phones', prop: 'phoneNumbers', cellClassName: 'editable', sort: false },
            { title: 'Email', prop: 'email', sort: false },
            { title: 'Payment method', prop: 'paymentMethodName', sort: true },
            { title: 'Payment address', prop: 'paymentAddress', sort: true, type: ColumnTypes.HTML, format: row => {
                return `<span class="text-cut">${BrowserUtils.escapeHtml(row.paymentAddress)}</span> <i class="pointer icon-copy text-primary"></i>`
            }},
            { title: 'Amount', prop: 'amount', format: row => (row.amount > 0) ? (row.amount / 100) : 0, sort: true },
            {
                title: 'Status', prop: 'status', type: ColumnTypes.HTML, format: row => {
                    const status = statuses[row.status];
                    return `<span class="${status.className}"><strong>${BrowserUtils.escapeHtml(status.text)}</strong></span>`
                }, sort: true
            },
            { title: 'Autopay', prop: 'withdrawalAvailable', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'MT SMS', prop: 'mtSmsCount', cellClassName: 'editable' },
            { title: 'MO SMS', prop: 'moSmsCount', cellClassName: 'editable' },
        ]));
        this.tableActions = [
            { icon: 'fa fa-info text-navy', name: 'info', title: 'Transaction details', show: row => row.status !== 'PENDING' && row.status !== 'PROCESSING'},
            { icon: 'icon-edit', name: 'edit', show: row => row.status === 'PENDING' },
            { icon: 'far fa-trash-alt', name: 'delete', show: row => row.status === 'PENDING' },
        ]
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onChangeSize(size: number) {
        super.onChangeSize(size);
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    update() {
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }
        if (this.filter) {
            this.requestParams.filter = this.filter;
        }

        this.loading = true;
        this.service.all(this.requestParams).subscribe((data) => {
            this.setData(this.createRows(data.content), data.totalElements);
                this.loading = false;
            }, () => {
                this.loading = false;
            });
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        if (event.name === 'switch') {
            this.service.updateWithdrawalAvailable(event.row.data.id, event.row.data.withdrawalAvailable).subscribe(() => {
                this.notificationService.success('Payment request updated', 'Appscripts');
            }, (error) => {
                event.row.data.withdrawalAvailable = !event.row.data.withdrawalAvailable
                this.notificationService.error({
                    title: 'Appscripts',
                    message: 'Payment request updating an error',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            });
        }
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }
        if (event.name === 'info') {
            this.errorText = null;
            this.payoutPaypal = null;
            this.payoutCrypto = null;
            const pr = event.row.data as AppscriptsPaymentRequest;
            switch (this.service.getPayoutType(pr)) {
                case "paypal":
                    this.service.payoutPaypal(pr.id).subscribe({
                        next: p => this.payoutPaypal = p,
                        error: e => console.error(e)
                    })
                    break;
                case "crypto":
                    this.service.payoutCrypto(pr).subscribe({
                        next: p => this.payoutCrypto = p,
                        error: e => console.error(e)
                    })
                    break;
            }
            this.errorText = pr.errorText;
            this.modal.alert().component(this.infoTransactionModalTpl).open();
        }
    }

    delete(pr: AppscriptsPaymentRequest) {
        this.loading = true;
        this.service
            .delete(pr.id)
            .subscribe(() => {
                this.update();
                this.notificationService.success('Payment request removed', 'Payment request')
            }, (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Appscripts',
                    message: 'Payment request removing an error',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            });
    }

    onCellClick(event: CellClickEvent) {
        let phones = event.row.data.phoneNumbers.join(',');
        if (event.column.prop === 'mtSmsCount') {
            this.router.navigate(['/appscripts/sms'], { queryParams: { phonenumber: phones } }).then();
        }
        if (event.column.prop === 'moSmsCount') {
            this.router.navigate(['/appscripts/outgoing-sms'], { queryParams: { phonenumber: phones } }).then();
        }
        if (event.column.prop === 'phoneNumbers') {
            this.router.navigate(['/appscripts/numbers'], {queryParams: {phonenumber: phones}}).then();
        }
        if (event.column.prop === 'paymentAddress') {
            BrowserUtils.copyToClipboard(event.row.data.paymentAddress);
            this.notificationService.success('Copy to clipboard', 'Appscripts');
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    formatDate(date) {
        return this.datePipe.transform(new Date(date), 'dd MMMM y');
    }
}
