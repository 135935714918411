
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import { TrustedDevice, TrustedDeviceCollection, TrustedDeviceRequestParams } from "../../shared/models/user.model";
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-devices-table',
    templateUrl: 'devices-table.component.html',
})

export class DevicesTableComponent extends TableBase implements OnInit {

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    loading: boolean = false;
    trustedDeviceRequestParams: TrustedDeviceRequestParams = new TrustedDeviceRequestParams();

    constructor(
        public notificationService: NotificationService,
        public service: UsersService,
        public router: Router,
        public storage: LocalStorageService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        let columns = [
            { title: 'Device', prop: 'lastClientAgent', type: ColumnTypes.TEXT, cellClassName: 'w-lg' },
            { title: 'Last login activity', prop: 'lastLoginAt', sort: true, sortDirection: 'desc', format: _ => this.formatLastLogin(_.lastLoginAt), type: ColumnTypes.HTML, cellClassName: 'w-lg' },
            { title: 'IP', prop: 'lastIpAddress', type: ColumnTypes.TEXT },
        ];
        this.tableActions = [
            { icon: 'far fa-trash-alt', title: 'Delete', name: 'delete' },
        ];
        this.setColumns(this.createColumns(columns));
        this.update();
    }

    update() {
        this.loading = true;
        this.trustedDeviceRequestParams.size = this.currentSize;
        this.trustedDeviceRequestParams.page = this.page - 1;
        this.trustedDeviceRequestParams.resetSort();

        for (let sort of this.order) {
            this.trustedDeviceRequestParams.setSort(sort.prop, sort.direction);
        }

        if (this.trustedDeviceRequestParams.sort.length === 0) {
            this.trustedDeviceRequestParams.setSort('lastLoginAt', 'desc');
        }

        this.service.allDevices(this.trustedDeviceRequestParams).subscribe({
            next: (trustedDeviceCollection: TrustedDeviceCollection) => {
                this.setData(this.createRows(trustedDeviceCollection.content), trustedDeviceCollection.totalElements);
                this.loading = false;
            },
            error: e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Users',
                    message: 'Loading devices an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        })
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            }).catch(_ => {
            });
        }
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    private delete(device: TrustedDevice) {
        this.loading = true;
        this.service
            .deleteDevice(device.id)
            .subscribe((res) => {
                this.notificationService.success('Device removed', 'Users');
                this.update();
            }, () => this.loading = false);
    }

    formatLastLogin(date) {
        if (!date) {
            return 'N';
        }
        return this.datePipe.transform(new Date(Date.parse(date)), 'medium');
    }
}
