
import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent } from "../../shared/components/table/table.component";
import { Network } from '../../shared/models/network.model';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { AllRequestParamsNetwork, NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
    selector: 'app-network-table',
    templateUrl: './network-table.component.html',
    styleUrls: ['./network-table.component.scss'],
})

export class NetworkTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;
    formModal: DialogRef;
    networkModel: Network;

    requestParams = new AllRequestParamsNetwork();
    allRows = [];
    loading: boolean = false;

    constructor(
        public notificationService: NotificationService,
        public service: NetInfoService,
        public modal: ModalService,
    ) {
        super();
    }

    ngOnInit() {
        this.setColumns(this.createColumns([
            { title: 'Name', prop: 'name' },
            { title: 'Country', prop: 'country', format: row => row.country.name },
            { title: 'MCC', prop: 'mcc' },
            { title: 'MNC', prop: 'mnc' },
            { title: 'Prefixes', prop: 'prefixes' },
            { title: 'Aggregator', prop: 'aggregator', format: row => row.aggregator ? 'Yes' : 'No' },
        ]));
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit' },
            { icon: 'far fa-trash-alt', name: 'delete' },
        ];
        this.requestParams.setSort('name', 'asc')
        this.load();
    }

    update() {
        const search = this.requestParams.search ? this.requestParams.search.toLowerCase().trim() : '';
        let rows = this.allRows.filter((network: Network) => {
            if (search) {
                return network.name.toLowerCase().indexOf(search) !== -1 ||
                    network.country.name.toLowerCase().indexOf(search) !== -1 ||
                    network.mcc.toLowerCase().indexOf(search) !== -1 ||
                    network.mnc.toLowerCase().indexOf(search) !== -1;
            }
            return true;
        });
        this.setData(this.createRows(rows), rows.length);
    }

    onCreate() {
        this.networkModel = this.service.createNetwork();
        this.formModal = this.modal.alert().component(this.formModalTpl).open();
    }

    onAfterSave() {
        this.load();
        if (this.formModal) {
            this.formModal.close();
        }
    }

    load() {
        this.loading = true;

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service.networks(this.requestParams).subscribe({
            next: (collection: Network[]) => {
                this.allRows = collection;
                this.update();
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Networks',
                    message: 'An error occurred while loading the networks',
                    serviceName: 'NET-INTEL',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            },
            complete: () => {
                if (this.formModal) {
                    this.formModal.close();
                }
            }
        });
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            });
        }

        if (event.name === 'edit') {
            this.networkModel = event.row.data;
            this.formModal = this.modal.alert().component(this.formModalTpl).open();
        }

        this.actions.emit(event);
    }

    delete(region) {
        this.loading = true;
        this.service
            .deleteNetwork(region.id)
            .subscribe({
                next: () => {
                    this.notificationService.success('Network removed', 'Networks');
                    this.onAfterDelete.emit();
                    this.load();
                },
                error: (error) => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'Networks',
                        message: 'An error occurred while deleting the networks',
                        serviceName: 'NET-INTEL',
                        requestMessage: error.statusText,
                        requestCode: error.status
                    });
                }
            });
    }

}
