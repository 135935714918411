
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BreadcrumbService } from '../../../shared/components/breadcrumbs/breadcrumbs';
import { RowDef } from '../../../shared/components/table/table.component';
import {
    AppscriptsNumber,
    AppscriptsNumbersFilter,
    AppscriptsUpdateNumber
} from "../../../shared/models/appscripts-user.model";
import { AppscriptsUserService } from '../../../shared/services/appscripts-user.service';
import { BatchTasks } from '../../../shared/services/batch-tasks';
import { BrowserUtils } from '../../../shared/services/browser-utils';
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { AppscriptsUserFilterComponent } from '../appscripts-user-filter/appscripts-user-filter.component';
import { AppscriptsUserTableComponent } from '../appscripts-user-table/appscripts-user-table.component';

@Component({
    selector: 'app-appscripts-user-index',
    templateUrl: 'appscripts-user-index.component.html',
    styleUrls: ['appscripts-user-index.component.scss'],
})

export class AppscriptsUserIndexComponent implements OnInit, AfterViewInit {

    @ViewChild(AppscriptsUserTableComponent, { static: false }) table: AppscriptsUserTableComponent;
    @ViewChild(AppscriptsUserFilterComponent, { static: false }) filter: AppscriptsUserFilterComponent;

    copyPhonesTextControlRows: number;
    model: AppscriptsNumbersFilter = { moUserEnables: false };

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public breadcrumbs: BreadcrumbService,
        public router: Router,
        public modemService: AppscriptsUserService,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
        public route: ActivatedRoute
    ) {
        titleService.setTitle('Appscripts numbers');
        this.route.queryParams.subscribe(p => {
            for (let i in p) {
                this.model[i] = p[i];
            }
        });
    }

    action(event) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.phonenumber) {
                this.filter.changeFilterValueByName(params.phonenumber, 'Phone', false);
            }
            this.filter.onSubmitClick();
        });
    }

    onFilter(data) {
        let params: AppscriptsNumbersFilter = { moUserEnables: data.moUserEnables ? true : false };
        let queryParams = {};

        for (let i in data) {
            if (data[i] !== null) {
                queryParams[i] = data[i];
            }
        }

        if (data.activeFrom && data.activeTo) {
            params.activeFrom = data.activeFrom;
            params.activeTo = data.activeTo;
        }
        let isInt = (v) => {
            return !(v === '' || v === null || v === undefined);
        };

        if (isInt(data.mtSmsCountMin) && isInt(data.mtSmsCountMax)) {
            params.mtSmsCountMin = data.mtSmsCountMin;
            params.mtSmsCountMax = data.mtSmsCountMax;
        } else if (isInt(data.mtSmsCountMin)) {
            params.mtSmsCountMin = data.mtSmsCountMin;
        } else if (isInt(data.mtSmsCountMax)) {
            params.mtSmsCountMax = data.mtSmsCountMax;
        }

        if (isInt(data.moSmsCountMin) && isInt(data.moSmsCountMax)) {
            params.moSmsCountMin = data.moSmsCountMin;
            params.moSmsCountMax = data.moSmsCountMax;
        } else if (isInt(data.moSmsCountMin)) {
            params.moSmsCountMin = data.moSmsCountMin;
        } else if (isInt(data.moSmsCountMax)) {
            params.moSmsCountMax = data.moSmsCountMax;
        }

        if (isInt(data.balanceMin) && isInt(data.balanceMax)) {
            params.balanceMin = Math.trunc(data.balanceMin * 100);
            params.balanceMax = Math.trunc(data.balanceMax * 100);
        } else if (isInt(data.balanceMin)) {
            params.balanceMin = Math.trunc(data.balanceMin * 100);
        } else if (isInt(data.balanceMax)) {
            params.balanceMax = Math.trunc(data.balanceMax * 100);
        }
        let rawSearchFields = ['id', 'mcc', 'mnc', 'originalMnc', 'phoneNumbers', 'referralCode', 'referrerCode', 'paymentAddress', 'email'];
        rawSearchFields.map(field => {
            let v = data[field];
            if (v === '' || v === null) {
                return;
            }
            params[field] = v;
        });

        let apply = () => {
            this.table.filter = params;
            this.table.page = 1;
            this.table.update();
        };

        if (!this.table) {
            let interval = setInterval(() => {
                if (this.table) {
                    apply();
                }
                clearInterval(interval);
            }, 100);
        } else {
            apply();
        }
    }

    private getUpdateNumber(row: AppscriptsNumber): AppscriptsUpdateNumber {
        return {
            phoneNumber: row.phoneNumber,
            mcc: row.mcc,
            mnc: row.mnc,
            country: row.country,
            provider: row.provider,
            originalProvider: row.originalProvider,
            originalMnc: row.originalMnc,
            showNumberMt: row.showNumberMt,
            showNumberMo: row.showNumberMo,
            blocked: row.blocked,
            updateHlr: row.updateHlr,
        }
    }

    onBatchMoShow() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchMoShow();
        });
    }

    private batchMoShow() {
        const tasks = this.selectedRows.map(row => {
            let data = this.getUpdateNumber(row.data);
            data.showNumberMo = true;
            return this.modemService.updateNumber(row.data.id, data).pipe(map(() => {
                this.notificationService.success('Show numbers', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Show number an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchMoHide() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchMoHide();
        });
    }

    private batchMoHide() {
        const tasks = this.selectedRows.map(row => {
            let data = this.getUpdateNumber(row.data);
            data.showNumberMo = false;
            return this.modemService.updateNumber(row.data.id, data).pipe(map(() => {
                this.notificationService.success('Hide numbers', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Hide number an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchShow() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchShow();
        });
    }

    private batchShow() {
        const tasks = this.selectedRows.map(row => {
            let data = this.getUpdateNumber(row.data);
            data.showNumberMt = true;
            return this.modemService.updateNumber(row.data.id, data).pipe(map(() => {
                this.notificationService.success('Show numbers', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Show number an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchHide() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchHide();
        });
    }

    private batchHide() {
        const tasks = this.selectedRows.map(row => {
            let data = this.getUpdateNumber(row.data);
            data.showNumberMt = false;
            return this.modemService.updateNumber(row.data.id, data).pipe(map(() => {
                this.notificationService.success('Hide numbers', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Hide number an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.modemService.deleteUser(row.data.userId).pipe(
                map(() => {
                    this.notificationService.success('User deleted', 'Appscripts');
                    row.batch = false;
                    this.completeTask();
                }), catchError((err, caught) => {
                    this.notificationService.error('User delete an error', 'Appscripts');
                    this.completeTask();
                    return caught;
                }));
        });
        this.startTasks(tasks);
    }

    onClickCopyPhones() {
        let text = this.selectedRows.map(_ => _.data.phoneNumber).join("\n");
        BrowserUtils.copyToClipboard(text);
        this.notificationService.success('Copy to clipboard', 'Appscripts');
    }

    onClickCopyPhonesControl(e) {
        let input = e.target;
        if (input.setSelectionRange) {
            input.setSelectionRange(0, input.value.length)
        } else {
            input.select();
        }
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }
}