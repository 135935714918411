import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-scheduler-reports-index',
    templateUrl: './scheduler-reports-index.component.html',
    styleUrls: [],
})

export class SchedulerReportsIndexComponent {

    serviceDisabled = null;

    constructor(titleService: Title) {
        titleService.setTitle('Reports');
    }
}
