import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LiveNumberTestingService } from '../../../../shared/services/live-number-testing.service';
import { TestResultAction } from '../../../../shared/models/test-result.model';
import { UsersService } from '../../../../shared/services/users.service';

@Component({
    selector: 'app-lnt-actions',
    templateUrl: './lnt-actions.component.html',
    styleUrls: ['./lnt-actions.component.scss']
})
export class LntActionsComponent implements ICellRendererAngularComp {

    constructor(private userService: UsersService, private liveNumberTestingService: LiveNumberTestingService) { }

    currentRow: TestResultAction;
    showAppscriptsUserIcon: boolean = true;

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.showAppscriptsUserIcon = this.userService.authUser.role === 'admin';
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.liveNumberTestingService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

}

