import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, switchMap, Observable, throwError } from 'rxjs';
import { ConfirmedActionsService } from "./confirmed-actions.service";
import { RestUtils } from "./rest-utils";
import { Role } from "../models/user.model";

@Injectable()
export class ConfirmedActionsInterceptor implements HttpInterceptor {

    constructor(private confirmationActionsService: ConfirmedActionsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone();
        return next.handle(newReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 424 && RestUtils._role !== Role.GUEST) {
                    return this.confirmationActionsService.confirm().pipe(
                        switchMap(() => {
                            return next.handle(newReq);
                        }),
                    )
                }
                return throwError(() => error)
            })
        );
    }
}