import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import {PricingService} from "../../../shared/services/pricing.service";
import {PricingGroupFormComponent} from "../pricing-group-form/pricing-group-form.component";

@Component({
    selector: 'app-pricing-group-create',
    templateUrl: './pricing-group-create.component.html',
})

export class PricingGroupCreateComponent {

    @ViewChild(PricingGroupFormComponent, {static: false}) form;

    loading = false;

    constructor(
        public pricingService: PricingService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        public titleService: Title
    ) {
        titleService.setTitle('General pricing create');
    }

    afterSave() {
        this.router.navigate(['/pricing-general']).then();
    }
}
