import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
    StopWord,
    StopWordMo,
    StopWordMt,
    StopWordsCollection,
    StopWordsMtCollection
} from '../models/stop-word.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";

@Injectable()
export class StopWordsService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    allMt(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : []
        };

        let url = this.utils.buildUrl('ROLE/ntc/stop-words', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<StopWordsMtCollection>(url, options);
    }

    allMo() {
        let url = this.utils.buildUrl('ROLE/motc/stop-words');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<StopWordMo[]>(url, options);
    }

    all(params: AllRequestParams): Observable<StopWord[]> {
        return forkJoin([
            this.allMt(params),
            this.allMo()
        ]).pipe(
            map(results => {
                let stopWords = new Map<string, StopWord>();
                results[0].content.forEach(sw => stopWords.set(sw.word, {word: sw.word, mtId: sw.id, mt: true, moId: null, mo: false}));
                results[1].forEach(sw => {
                    if (stopWords.has(sw.word)) {
                        let word = stopWords.get(sw.word);
                        word.moId = sw.id;
                        word.mo = true;
                        stopWords.set(word.word, word);
                    } else {
                        stopWords.set(sw.word, {word: sw.word, mtId: null, mt: false, moId: sw.id, mo: true})
                    }
                });

                let list = [];
                stopWords.forEach(sw => list.push(sw));

                return list;
            })
        )
    }

    createMultiple(stopWord: StopWord): Observable<any> {
        let requests = [];
        if (stopWord.mt) {
            requests.push(this.createMt(stopWord.word));
        }
        if (stopWord.mo) {
            requests.push(this.createMo(stopWord.word));
        }

        return forkJoin(requests);
    }

    createMo(word: string) {
        let url = this.utils.buildUrl(`ROLE/motc/stop-words/${word}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, {}, options)
    }

    createMt(word: string) {
        let url = this.utils.buildUrl(`ROLE/ntc/stop-words`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.post(url, {word: word}, options)
    }

    deleteMo(stopWord: StopWord) {
        let url = this.utils.buildUrl(`ROLE/motc/stop-words/${stopWord.word}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete(url, options);
    }

    deleteMt(stopWord: StopWord) {
        let url = this.utils.buildUrl(`ROLE/ntc/stop-words/${stopWord.mtId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.delete(url, options);
    }

    create(): StopWord {
        return {
            word: '',
            mt: true,
            mo: true
        };
    }


}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
