
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from "../../shared/components/table/table.component";
import { StopWord } from '../../shared/models/stop-word.model';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, StopWordsService } from '../../shared/services/stop-words.service';

@Component({
    selector: 'app-stop-words-table',
    templateUrl: './stop-words-table.component.html',
    styleUrls: ['./stop-words-table.component.scss'],
})

export class StopWordsTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('titleModalTpl', { read: TemplateRef, static: false }) titleModalTpl: any;

    isAdmin: boolean = false;
    requestParams = new AllRequestParams();
    currentTitle: string = '';
    storageContextName = 'stop-words-table';

    constructor(
        public notificationService: NotificationService,
        public service: StopWordsService,
        public modal: ModalService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.tableActions = [];
        this.setColumns(this.createColumns([
            { title: 'Word', prop: 'word', sort: false },
            { title: 'MT', prop: 'mt', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: false },
            { title: 'MO', prop: 'mo', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: false },
        ]));
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = 1000;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.service.all(this.requestParams).subscribe({
            next: collection => {
                this.setData(this.createRows(collection), collection.length);
                this.loading = false;
            },
            error: e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Stop words',
                    message: 'An error occurred while loading the stop words',
                    serviceName: 'NTC',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
            }
        });
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'word') {
           // this.editWord(event.row.data);
        }
    }

    /*editWord(row) {
        this.currentTitle = row.word;
        let dialogRef = this.modal.alert().component(this.titleModalTpl).open();
        dialogRef.result.then(result => {
            if (result) {
                row.word = result;
                this.loading = true;
                this.service.update(row).subscribe((res) => {
                    this.notificationService.success('Word updated', 'Stop words');
                }, error => {
                    this.loading = false;
                    row.word = this.currentTitle;
                }, () => {
                    this.flashRow(row.id);
                    this.loading = false;
                });
            }
        });
    }*/

    onAction(event: ActionEvent) {
        this.actions.emit(event);

        if (event.name === 'switch') {
            let stopWord: StopWord = event.row.data;
            if (event.column.prop === 'mo' && stopWord.mo) {
                this.loading = true;
                this.service.createMo(stopWord.word).subscribe({
                    next: () => {
                        this.notificationService.success('Word created', 'Stop words');
                        this.update();
                    },
                    error: e => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Stop words',
                            message: 'An error occurred while creating the stop word',
                            serviceName: 'MO',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            }
            if (event.column.prop === 'mo' && !stopWord.mo) {
                this.loading = true;
                this.service.deleteMo(stopWord).subscribe({
                    next: () => {
                        this.notificationService.success('Word removed', 'Stop words');
                        this.update();
                    },
                    error: e => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Stop words',
                            message: 'An error occurred while deleting the stop word',
                            serviceName: 'MO',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            }

            if (event.column.prop === 'mt' && stopWord.mt) {
                this.loading = true;
                this.service.createMt(stopWord.word).subscribe({
                    next: () => {
                        this.notificationService.success('Word created', 'Stop words');
                        this.update();
                    },
                    error: e => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Stop words',
                            message: 'An error occurred while creating the stop word',
                            serviceName: 'NTC',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            }
            if (event.column.prop === 'mt' && !stopWord.mt) {
                this.loading = true;
                this.service.deleteMt(stopWord).subscribe({
                    next: () => {
                        this.notificationService.success('Word removed', 'Stop words');
                        this.update();
                    },
                    error: e => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'Stop words',
                            message: 'An error occurred while deleting the stop word',
                            serviceName: 'NTC',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                    }
                });
            }
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }
}
