
import {
    Component,
    Output,
    EventEmitter,
    ElementRef,
    Input
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import {LiveNumberTestingService} from "../../shared/services/live-number-testing.service";

@Component({
    selector: 'app-test-result-comment',
    templateUrl: './test-result-comment.component.html',
    styleUrls: ['./test-result-comment.component.scss']
})

export class TestResultCommentComponent {

    @Input() model;

    @Output() onAfterSave = new EventEmitter();

    loading = false;

    form: FormGroup;

    constructor(
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: LiveNumberTestingService,
        public validationService: ValidationService
    ) {
        this.form = formBuilder.group({
            text: ['', Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(500)])],
        });
    }

    onSubmit() {
        let model = {
            userId: null,
            id: this.model.commentId,
            text: this.model.text,
            changedAt: null
        };
        const errorHandler = (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'Test comments',
                message: 'An error occurred while creating/updating a text comment',
                serviceName: 'NTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        }
        const successHandler = (res) => {
            this.loading = false;
            this.notificationService.success(
                'Comment ' + (model.id ? 'updated' : 'created'),
                'Test comments'
            );
            this.onAfterSave.emit(model);
        }
        this.loading = true;
        if (this.model.id) {
            this.service.updateComment(this.model.commentId, model).subscribe(successHandler, errorHandler);
        } else {
            this.service.createComment(this.model.testId, model).subscribe(successHandler, errorHandler);
        }
    }
}
