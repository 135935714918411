import { Subject } from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable()
export class NotificationService {

    notifications: Subject<Notification> = new Subject();
    updates: Subject<any> = new Subject();

    setUpdates(data: any): void {
        this.updates.next(data);
    }

    success(message: string, title = ''): void {
        this.notifications.next({
            type: 'success',
            title: title,
            content: message
        });
    }

    info(message: string, title = ''): void {
        this.notifications.next({
            type: 'info',
            title: title,
            content: message
        });
    }

    warning(message: string, title = '') {
        this.notifications.next({
            type: 'warning',
            title: title,
            content: message
        });
    }

    error(message: any, title = '') {
        const data = this.formatMessage(message, title);
        if (data === false) {
            return;
        }
        const code = message.requestCode ? message.requestCode : 0;
        if (data.message.indexOf('Service is unavailable') !== -1 || code === 503) {
            data.message = 'Service is updating';
        }
        this.notifications.next({
            type: 'error',
            title: data.title,
            content: data.message
        });
    }

    formatMessage(message: any, title: string) {
        if (typeof message === 'string') {
            return {
                message: message,
                title: title
            };
        }

        if (message.requestCode && message.requestCode === 401) {
            return false;
        }

        let resultMessage = message.message ? (message.message + '<br>') : '';
        if (message.serviceName) {
            resultMessage += '<strong>Service</strong>: ' + message.serviceName + '<br>';
        }
        if (message.ts) {
            resultMessage += '<strong>Timestamp</strong>: ' + message.ts + '<br>';
        }
        if (message.staus) {
            resultMessage += '<strong>Response status</strong>: ' + message.staus + '<br>';
        }
        if (message.requestMessage) {
            resultMessage += '<strong>Response message</strong>: ' + message.requestMessage;
        }

        return {
            title: message.title ? message.title : title,
            message: resultMessage
        };
    }
}

export interface Notification {
    title: string,
    content: string,
    ttl?: number,
    type?: string
}