import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import { ContentTemplate, ContentTemplateText } from '../../shared/models/content-templates';
import { ContentTemplateService } from '../../shared/services/content-template.service';
import { ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams } from '../../shared/services/templates.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-sms-text-templates-table',
    templateUrl: './sms-text-templates-table.component.html',
    styleUrls: ['./sms-text-templates-table.component.scss']
})
export class SmsTextTemplatesTableComponent extends TableBase implements OnInit {

    @Input('currentContentTemplateId') currentContentTemplateId: number;

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterAction = new EventEmitter();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    loading: boolean = false;
    isAdmin: boolean = false;
    requestParams = new AllRequestParams();

    storageContextName = 'sms-text-template-table';

    textTemplatesForCurrentContentTemplate: ContentTemplateText[];

    constructor(
        private contentTemplateService: ContentTemplateService,
        private modal: ModalService,
        private notificationService: NotificationService,
        private router: Router,
        private userService: UsersService,
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        let columns = [
            { title: 'ID', prop: 'id', sort: false },
            { title: 'Text', prop: 'value', sort: false, cellClassName: 'w-lg' },
            { title: 'Description', prop: 'description', sort: false },
            { title: 'Visible all', prop: 'visibleAll', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: true },
            { title: 'Default', prop: 'default', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center', sort: false },
            { title: 'Telq ID Type', prop: 'telqIdType', sort: false },
            { title: 'Telq ID Case', prop: 'telqIdCase', sort: false },
            { title: 'Telq ID Length', prop: 'telqIdLength', sort: false },
            { title: 'User', prop: 'user', format: _ => this.formatUser(_) },
            { title: 'Message Type', prop: 'messageType', sort: false },
        ];
        this.tableActions = [
            { icon: 'icon-favourite marked', name: 'favorite', title: "Remove favorite", show: row => row.userFavourite },
            { icon: 'icon-favourite', name: 'favorite', title: 'Add favorite', show: row => !row.userFavourite },
            { icon: 'far fa-trash-alt', name: 'delete', title: 'Delete', show: row => this.canShowActionDelete(row) },
        ];

        this.userService.can('admin').then(isAdmin => {
            this.isAdmin = isAdmin;
            if (!isAdmin) {
                const hideColumns = [
                    'Visible all',
                    'ID'
                ];
                columns = columns.filter(c => hideColumns.indexOf(c.title) === -1)
            }
            this.setColumns(this.createColumns(columns));
            this.update();
        }).catch(e => {

        });
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    onCellClick(event: CellClickEvent) {

    }

    canShowActionDelete(row: any): boolean {
        return this.isAdmin || (row.userId === this.userService.authUser.id);
    } 

    formatUser(row: any): string {
        if (row.visibleAll) return 'TelQ';
        return row.user ? row.user.email : '';
    }

    update() {
        this.loading = true;
        this.contentTemplateService.one(this.currentContentTemplateId).subscribe({
            next: (collection: ContentTemplate) => {
                // The first one is always the default for the user ...
                collection.smsTemplateTextDtos[0].default = true;
                this.textTemplatesForCurrentContentTemplate = collection.smsTemplateTextDtos;
                this.setData(this.createRows(collection.smsTemplateTextDtos), collection.smsTemplateTextDtos.length);
                this.loading = false;
            },
            error: (error) => {
                if (error.status === 404) { // Close the popup if no text template present for the content template ...
                    this.onAfterAction.emit();
                    return;
                }
                this.loading = false;
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while loading content templates',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }

    handleVisibleAllToggle(event: ActionEvent): void {
        if (!event.row.data.user.email.includes('@telqtele')) {
            this.notificationService.error({
                title: 'Content Templates',
                message: 'Visible all only allowed for telqtele.com user templates.',
                serviceName: 'NTC',
            });
            this.update();
            return;
        }
        const data = {
            id: event.row.data.id,
            visibleAll: event.row.data.visibleAll
        }
        this.contentTemplateService.updateVisibleAllToggle(data).subscribe({
            next: (res) => {
                this.notificationService.success('Content template updated', 'Content Templates');
            },
            error: (err) => {
                event.row.data[event.column.prop] = !event.row.data[event.column.prop];
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while updating the content templates',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
            }
        });
    }

    handleDefaultToggle(event: ActionEvent): void {
        const smsTextTemplateId = event.row.data.id;
        this.loading = true;
        let apisToSubscribe = [this.contentTemplateService.updateDefaultToggle(smsTextTemplateId)];
        if (this.isAdmin) {
            apisToSubscribe.push(this.contentTemplateService.updateGlobalDefaultAsAdminToggle(smsTextTemplateId));
        } 
        forkJoin(apisToSubscribe).subscribe({
            next: (res) => {
                this.textTemplatesForCurrentContentTemplate.forEach(tt => {
                    if (tt.id !== smsTextTemplateId) {
                        tt.default = false;
                    }
                });
                this.setData(this.createRows(this.textTemplatesForCurrentContentTemplate), this.textTemplatesForCurrentContentTemplate.length);
                this.loading = false;
                this.notificationService.success('Content template default updated', 'Content Templates');
            },
            error: (err) => {
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while updating the default text template for this content template.',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.update();
            }
        });
    }

    onAction(event: ActionEvent) {
        if (event.name === 'delete') {
            // if (this.textTemplatesForCurrentContentTemplate.length === 1) {
            //     this.notificationService.error({
            //         title: 'Content Templates',
            //         message: 'Only one text template is linked with this sender. Please delete the content template to delete this text.',
            //         serviceName: 'NTC',
            //     });
            //     return;
            // }
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.loading = true;
                    this.deleteTextTemplate(event.row.data.id);
                }
            });
        } else if (event.name === 'edit') {
            this.router.navigate(['content-templates', this.currentContentTemplateId]);
        } else if (event.name === 'favorite') {
            this.onFavoriteIconClick(event);
        } else if (event.name === 'switch' && event.column.prop === 'visibleAll') {
            this.handleVisibleAllToggle(event);
        } else if (event.name === 'switch' && event.column.prop === 'default') {
            this.handleDefaultToggle(event);
        }
        this.actions.emit(event);
    }

    onFavoriteIconClick(event: any): void {
        this.loading = true;
        const smsTextTemplate = event.row.data;
        smsTextTemplate.userFavourite = !smsTextTemplate.userFavourite;
        this.contentTemplateService.updateFavorite(smsTextTemplate.id, smsTextTemplate.userFavourite).subscribe({
            next: (res) => {
                this.notificationService.success('Favourite mark updated.', 'Content Templates');
                this.loading = false;
            }, error: (error) => {
                smsTextTemplate.userFavourite = !smsTextTemplate.userFavourite;
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while marking/unmarking the SMS text template as favorite.',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    deleteTextTemplate(textTemplateId: number): void {
        this.contentTemplateService.deleteTextTemplate(textTemplateId).subscribe({
            next: (res) => {
                this.notificationService.success('Text deleted.', 'Content Templates');
                this.update();
                this.loading = false;
            },
            error: (error) => {
                this.notificationService.error({
                    title: 'Content Templates',
                    message: 'An error occurred while deleting text.',
                    serviceName: 'NTC',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            }
        });
    } 

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

}
