import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultTelqId } from '../models/telqId.model';
import { RestUtils } from './rest-utils';

@Injectable()
export class TemplatesService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    static TELQ_MESSAGE_DEFAULT = 'OTP';
    static TELQ_MESSAGE_TYPES = [
        { id: 'OTP', label: 'OTP' },
        { id: 'PROMOTIONAL', label: 'Promotional' },
        { id: 'INFORMATIONAL', label: 'Informational' },
        { id: 'P2P', label: 'P2P' },
        { id: 'OTHERS', label: 'Others' },
    ]

    static TELQ_ID_TYPE_DEFAULT = 'NUMERIC';
    static TELQ_ID_TYPES = [
        { id: 'ALPHA', label: 'Alpha' },
        { id: 'NUMERIC', label: 'Numeric' },
        { id: 'ALPHA_NUMERIC', label: 'Alphanumeric' },
        { id: 'WHATSAPP_CODE', label: 'Whatsapp code' }
    ]

    static TELQ_ID_TYPES_LABELS = {
        ALPHA: 'Alpha',
        NUMERIC: 'Numeric',
        ALPHA_NUMERIC: 'Alphanumeric',
        WHATSAPP_CODE: 'Whatsapp code'
    }

    static TELQ_ID_CASE_DEFAULT = 'MIXED';
    static TELQ_ID_CASES = [
        { id: 'MIXED', label: 'Mixed' },
        { id: 'UPPER', label: 'Upper' },
        { id: 'LOWER', label: 'Lower' }
    ]
    static TELQ_ID_CASES_LABELS = {
        MIXED: 'Mixed',
        UPPER: 'Upper',
        LOWER: 'Lower'
    }
    static TELQ_ID_LEN_DEFAULT = 6;
    static TELQ_ID_LEN_MIN = 4;
    static TELQ_ID_LEN_MAX = 20;

    static TELQ_ID_LENGTH_DEFAULT = {
        //ALPHA: 10,
        //NUMERIC: 10,
        //ALPHA_NUMERIC: 10,
        WHATSAPP_CODE: 7
    }

    static TELQ_ID_CASES_DEFAULT = {
        //ALPHA: 'MIXED',
        //NUMERIC: 'MIXED',
        //ALPHA_NUMERIC: 'MIXED',
        WHATSAPP_CODE: 'MIXED'
    }

    static TELQ_ID_DEFAULT = {
        telqIdType: TemplatesService.TELQ_ID_TYPE_DEFAULT,
        telqIdCase: TemplatesService.TELQ_ID_CASE_DEFAULT,
        telqIdLength: TemplatesService.TELQ_ID_LEN_DEFAULT
    }

    static showCaseForType(type) {
        switch (type) {
            case 'ALPHA':
                return true;
            case 'ALPHA_NUMERIC':
                return true;
            default:
                return false;
        }
    }

    static showLengthForType(type) {
        switch (type) {
            case 'ALPHA':
                return true;
            case 'ALPHA_NUMERIC':
                return true;
            case 'NUMERIC':
                return true;
            default:
                return false;
        }
    }

    static getLabelType(type) {
        return typeof TemplatesService.TELQ_ID_TYPES_LABELS[type] !== 'undefined' ? TemplatesService.TELQ_ID_TYPES_LABELS[type] : type;
    }

    static getLabelCase(caseId) {
        return typeof TemplatesService.TELQ_ID_CASES_LABELS[caseId] !== 'undefined' ? TemplatesService.TELQ_ID_CASES_LABELS[caseId] : caseId;
    }

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    getDefaultTelqIdDetails(): Observable<DefaultTelqId> {
        let url = this.utils.buildUrl(`ROLE/ntc/telq-id-template`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<DefaultTelqId>(url, options);
    }

    saveDefaultTelqIdDetails(telqIdSetting: { telqIdType: string, telqIdCase: string, telqIdLength: number }): Observable<unknown> {
        let url = this.utils.buildUrl(`ROLE/ntc/telq-id-template`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, telqIdSetting, options);
    }

}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
