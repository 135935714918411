import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import { ActionEvent, ColumnTypes, SortEvent } from '../../../shared/components/table/table.component';
import {
    AppscriptsPaymentMethodParams,
} from '../../../shared/services/appscripts-payment-method.service';
import { UsersService } from '../../../shared/services/users.service';
import {ModalService} from "../../../shared/services/modal.service";
import { AppscriptsBillingService } from "../../../shared/services/appscripts-billing.service";
import { AppsctiptsMtTier } from "../../../shared/models/appscripts-billing.model";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { BasicUser } from "../../../shared/models/user.model";

@Component({
    selector: 'app-appscripts-mt-tier-table',
    templateUrl: 'appscripts-mt-tier-table.component.html'
})

export class AppscriptsMtTierTableComponent extends TableBase implements OnInit {

    @Output() actions: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<any> = new EventEmitter();

    requestParams = new AppscriptsPaymentMethodParams();
    storageContextName = 'appscripts-mt-tier-table'

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsBillingService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.setColumns(this.createColumns([
           // { title: 'ID', prop: 'id', sort: false },
            { title: 'Name', prop: 'name', sort: false },
            { title: 'SMS count', prop: 'smsCount', format: (t: AppsctiptsMtTier) => t.smsCountText, sort: false },
            { title: 'Rate [€]', prop: 'rate', format: (t: AppsctiptsMtTier) => `€${t.rate / 100}`, sort: false },
            { title: 'Created/Updated by', prop: 'changedAt', format: (t: AppsctiptsMtTier) => {
                if (!t.adminUser) {
                    return t.adminUserId;
                }
                return t.adminUser.email;
            }, sort: false },
            { title: 'Created/Updated at', prop: 'changedAt', format: (t: AppsctiptsMtTier) => this.datePipe.transform(t.changedAt ? t.changedAt : t.createdAt, 'medium'), sort: false },
        ]));
        this.tableActions = [
            { icon: 'icon-edit', name: 'edit' },
            { icon: 'far fa-trash-alt', name: 'delete' }
        ]
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);

        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data);
                }
            }).catch(_ => { });
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.service.mtTiers().pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'MT Tiers',
                    message: 'An error occurred while load tiers',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                return throwError(() => e);
            })
        ).subscribe(rows => {
            if (!rows.length) {
                this.currentSize = rows.length;
                this.setData(this.createRows(rows), rows.length);
                this.loading = false;
                return;
            }
            this.getUsers(rows).subscribe(users => {
                rows.forEach(r => {
                    r.adminUser = users.has(r.adminUserId) ? users.get(r.adminUserId) : null;
                });
                this.currentSize = rows.length;
                this.setData(this.createRows(rows), rows.length);
                this.loading = false;
            });
        });
    }

    delete(tier: AppsctiptsMtTier) {
        this.loading = true;
        this.service.deleteMtTier(tier.id).pipe(
            catchError(e => {
                this.loading = false
                return throwError(() => e);
            })
        ).subscribe(res => {
            this.update();
            this.notificationService.success('Tier removed', 'Appscripts');
            this.onAfterDelete.emit(res)
        });
    }

    private getUsers(tiers: AppsctiptsMtTier[]): Observable<Map<number, BasicUser>> {
        const userIds = new Set<number>(tiers.map(_ => _.adminUserId));
        return this.userService.basicListByIds(Array.from(userIds)).pipe(
            catchError(e => {
                this.notificationService.error({
                    title: 'MT Tiers',
                    message: 'An error occurred while load users',
                    serviceName: 'API_GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                return throwError(() => e);
            })
        ).pipe(
            map(users => {
                let userMap = new Map<number, BasicUser>();
                users.forEach(u => userMap.set(u.id, u));
                return userMap;
            })
        )
    }
}
