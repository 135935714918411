import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { Session } from '../models/session.model';
import { Supplier, SuppliersCollection } from '../models/supplier.model';
import { RestUtils } from './rest-utils';

@Injectable()
export class SuppliersService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    private supplierPageNumber$: Subject<number> = new Subject<number>();

    static attrDisableRules = {
        DOMESTIC: 'INTERNATIONAL',
        INTERNATIONAL: 'DOMESTIC',

        TRANSACTIONAL: 'PROMOTIONAL',
        PROMOTIONAL: 'TRANSACTIONAL',

        SHORT_CODE: 'LONG_CODE',
        LONG_CODE: 'SHORT_CODE',
    }

    attributes = [
        'PRE_REGISTRATION',
        'LONG_CODE',
        'DOMESTIC',
        'TWO_WAY',
        'DLR',
        'TRANSACTIONAL',
        'SHORT_CODE',
        'SMSC',
        'PROMOTIONAL',
        'INTERNATIONAL',
        'P2P',
        'SPAM'
    ];

    static ATTRIBUTE_OPTIONS = [
        [
            { id: 'DOMESTIC', label: 'Domestic', icon: 'icon-domestic text-accent-a6' },
            { id: 'INTERNATIONAL', label: 'International', icon: 'fas fa-globe text-accent-a5' }
        ],
        [
            { id: 'TRANSACTIONAL', label: 'Transactional', icon: 'icon-security text-sucess-s2' },
            { id: 'PROMOTIONAL', label: 'Promotional / Marketing', icon: 'icon-promotional text-accent-a2' }
        ],
        [
            { id: 'SHORT_CODE', label: 'Short-code', icon: 'icon-api text-primary' },
            { id: 'LONG_CODE', label: 'Long-code', icon: 'icon-long-code text-primary' }
        ],
        [{ id: 'PRE_REGISTRATION', label: 'Pre-registration', icon: 'icon-pre-registration text-accent-a2' }],
        [{ id: 'TWO_WAY', label: 'Two-way', icon: 'icon-test-sender-id-replaced text-accent-a5' }],
        [{ id: 'P2P', label: 'P2P', icon: 'icon-senders text-accent-a3' }],
        [{ id: 'SMSC', label: 'SMSC DLR', icon: 'icon-network text-accent-a7' }],
        [{ id: 'SPAM', label: 'Spam', icon: 'fas fa-comment-slash text-accent-a2' }]
    ];

    static ATTRIBUTE_TO_LABEL = {
        DOMESTIC: 'Domestic',
        INTERNATIONAL: 'International',
        TRANSACTIONAL: 'Transactional',
        PROMOTIONAL: 'Promotional / Marketing',
        SHORT_CODE: 'Short-code',
        LONG_CODE: 'Long-code',
        PRE_REGISTRATION: 'Pre-registration',
        TWO_WAY: 'Two-way',
        P2P: 'P2P',
        SMSC: 'SMSC DLR',
        SPAM: 'Spam'
    };

    static ROUTE_TYPES = ['Direct', 'Wholesale', 'Premium', 'HQ', 'SIM', 'SS7', 'Other', 'Custom'];

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    getSupplierPageChange() {
        return this.supplierPageNumber$.asObservable();
    }

    setSupplierPageNumber(pageNumber: number) {
        this.supplierPageNumber$.next(pageNumber);
    }

    getImportExampleFileUrl() {
        return window.location.origin + '/templates/suppliers.xlsx';
    }

    all(params: AllRequestParams): Observable<SuppliersCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : '',
            searchUserIds: params.searchUserIds ? params.searchUserIds : '',
            sort: (params.sort && params.sort.length) ? params.sort : []
        };
        let url = this.utils.buildUrl('ROLE/ntc/suppliers', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SuppliersCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/suppliers/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Supplier>(url, options).pipe(map(supplier => {
            if (!supplier.attributeList) { supplier.attributeList = []; }
            supplier.attributeList = this.prepareAttrs(supplier.attributeList);
            if (supplier.attributeList.includes('DLR') && supplier.attributeList.includes('SMSC')) {
                supplier.attributeList = supplier.attributeList.filter(_ => _ !== 'DLR');
            }
            return supplier;
        }));
    }

    save(sessionToAssignTo?: Session, supplierToBeAssigned?: Supplier) {
        let url = '';
        let model = Object.assign({}, supplierToBeAssigned);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (model.serviceType === '') {
            model.serviceType = null;
        }
        if (model.internalId === '') {
            model.internalId = null;
        }
        model.attributeList = model.attributeList ? this.prepareAttrs(model.attributeList) : null;
        if (model.id) {
            url = this.utils.buildUrl(`ROLE/ntc/suppliers`, {});
            return this.http.put<Supplier>(url, model, options);
        }
        url = this.utils.buildUrl(`ROLE/ntc/suppliers`, { sessionId: sessionToAssignTo.id });
        return this.http.post<Supplier>(url, model, options);
    }

    delete(id: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/suppliers/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    sessions(supplierId: number) {
        let url = this.utils.buildUrl(`ROLE/ntc/suppliers/${supplierId}/sessions`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Session[]>(url, options);
    }

    create(): Supplier {
        return {
            id: null,
            tlvDtos: [],
            udhDtos: [],
            internalId: null,
            routeType: null,
            userId: null,
            title: null,
            attributeList: [],
            comment: null
        };
    }

    prepareAttrs(attrs: string[]): string[] {
        if (!attrs.length) { return []; }
        let attributes = attrs.map(_ => _);
        if (attributes.includes('DLR')) { attributes.push('SMSC'); }
        if (attributes.includes('SMSC')) { attributes.push('DLR'); }

        return attributes.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    searchUserIds;

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
