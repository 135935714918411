
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NetworkCountry } from '../../shared/models/network.model';
import { ModalService } from "../../shared/services/modal.service";
import { NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';

@Component({
    selector: 'app-country-form',
    templateUrl: './country-form.component.html',
    styleUrls: ['./country-form.component.scss']
})

export class CountryFormComponent implements OnInit {

    @Input() backButtonShow = true;
    @Input() model: NetworkCountry;

    @Output() onAfterSave = new EventEmitter();

    loading = false;

    form: FormGroup;

    constructor(
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: NetInfoService,
        public validationService: ValidationService,
        public modal: ModalService,
    ) {
        this.form = formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(2), Vld.maxLength(255)])],
            isoAlpha2: ['', Validators.compose([Validators.required, Validators.minLength(1), Vld.maxLength(2)])],
            prefixes: ['', Validators.compose([Validators.minLength(1), Validators.maxLength(1600), Vld.digitsAndCommas(false, 1, 4)])],
        });
    }

    ngOnInit(): void {
        this.form.patchValue({ ...this.model, prefixes: this.model.prefixes.join(',') });
    }

    onSubmit() {
        this.loading = true;
        const rawFormValue = this.form.getRawValue();
        this.model = {
            ...rawFormValue,
            prefixes: rawFormValue.prefixes ? rawFormValue.prefixes.split(',') : [],
            id: this.model.id
        };
        this.service.saveCountry(this.model).subscribe({
            next: () => {
                this.loading = false;
                this.onAfterSave.emit();
                this.notificationService.success(
                    'Country ' + (this.model.id ? 'updated' : 'created'),
                    'Countries'
                );
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Regions',
                    message: 'An error occurred while creating/updating the region',
                    serviceName: 'NET-INFO',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }
}
