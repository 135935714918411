
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableBase } from '../../../shared/components/table/table-base';
import { ActionEvent, ColumnTypes } from '../../../shared/components/table/table.component';
import { AuthUser, Role } from '../../../shared/models/user.model';
import { BrowserUtils } from "../../../shared/services/browser-utils";
import { LiveNumberTestingService } from '../../../shared/services/live-number-testing.service';
import { UsersService } from '../../../shared/services/users.service';
import { CellClickEvent } from "../../../shared/components/table/table.component";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { MoTestingService, AllRequestParams } from "../../../shared/services/mo-testing.service";
import {
    MoTestDetails,
    MoTestDetailsStatus,
    MoTestingResult,
    MoTestingResultsCollection,
    MoTestingSearch
} from "../../../shared/models/mo-testing.model";
import { CustomUtils } from '../../../shared/services/custom-utils';
import { MoSearchFormComponent } from '../mo-search-form/mo-search-form.component';
import { ExportService } from '../../../shared/services/export.service';
declare var moment: any;


@Component({
    selector: 'app-mo-results-table',
    templateUrl: 'mo-results-table.component.html',
    outputs: ['actions'],
    inputs: ['filter'],
    styleUrls: ['mo-results-table.component.scss'],
})

export class MoResultsTableComponent extends TableBase implements OnInit, OnDestroy {

    @ViewChild('textModalTpl', { read: TemplateRef, static: false }) textModalTpl: any;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;

    isAdmin = false;
    user: AuthUser;

    intervalTimer: any;
    filter: Object = {};
    actions = new EventEmitter();
    onAfterDelete = new EventEmitter();

    searchParams: MoTestingSearch;

    MAX_TOTAL_ITEMS_COUNT = 5000;

    storageContext = 'mo-results';
    textModalHtml: string;

    exportSize = 500;
    exportSizes = [500, 1000, 3000, 5000, 'All'];
    exportData: any = {};
    exportSpinner = false;

    details: MoTestDetails & { test?: MoTestingResult, statuses: MoTestDetailsStatus[] & { showPdu?: boolean, parsedPdu?: string }[] };
    detailsModal: DialogRef;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: MoTestingService,
        public liveService: LiveNumberTestingService,
        public userService: UsersService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        public exportService: ExportService
    ) {
        super();
        userService.getAuthUser().then(user => {
            this.isAdmin = user.role === Role.ADMIN;
            this.user = user;
            this.init();
        }).catch(e => { });
    }

    init() {
        let hideColumns = [];
        let columns = [
            { title: 'Date', prop: 'createdAtFormat', type: ColumnTypes.DATE, cellClassName: 'w-sm', popover: 'Date and time when the test was triggered, in the browser’s time zone' },
            { title: 'Country(MCC)/Network(MNC)', prop: 'originDto', format: _ => this.service.formatDestination(_.originDto), type: ColumnTypes.HTML, cellClassName: 'w-lg', popover: 'The origin network from which the MO test was sent' },
            {
                title: 'Sent/DLR status', prop: 'testStatus', format: (row: MoTestingResult) => {
                    const sentStatuses = {
                        wait: '<span class="icon-wait spin" title="Wait"></span>',
                        sent: '<span class="icon-done success" title="Sent"></span>',
                        sent_error: '<span class="icon-negative error" title="Negative"></span>',
                        expired: '<span class="far fa-frown error" title="Expired"></span>',
                        internal_error: '<span class="icon-bug-red p-0_2 error" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>',
                        unknown_error: '<span class="icon-enroute-unknown accent"  title="Unknown"></span>',
                        test_number_offline: '<span class="icon-negative accent" title="Test number offline"></span>',
                        test_number_not_available: '<span class="icon-test-number-not-available accent" title="Test number not available"></span>',
                        network_offline: '<span class="icon-network-offline error" title="Network offline"></span>',
                    }
                    const dlrStatuses = {
                        wait: '<span class="icon-wait spin" title="Wait"></span>',
                        delivered: '<span class="far fa-smile success" title="Delivered"></span>',
                        canceled: '<span class="icon-no-dlr-received error" title="Rejected"></span>',
                        undeliverable: '<span class="far fa-frown error" title="Undeliverable"></span>',
                        expired: '<span class="far fa-frown error" title="Expired"></span>',
                        internal_error: '<span class="icon-bug-red p-0_2 error" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>',
                        unknown_error: '<span class="icon-enroute-unknown accent"  title="Unknown"></span>',
                        test_number_offline: '<span class="icon-negative accent" title="Test number offline"></span>',
                        test_number_not_available: '<span class="icon-test-number-not-available accent" title="Test number not available"></span>',
                        network_offline: '<span class="icon-network-offline error" title="Network offline"></span>',
                    }
                    let sentStatus = row.sentStatus ? row.sentStatus.toLowerCase() : 'wait';
                    let dlrStatus = row.dlrStatus ? row.dlrStatus.toLowerCase() : 'wait';
                    if (sentStatus === 'internal_error') {
                        return `<div class="status-icons d-flex align-items-center justify-content-center">${sentStatuses[sentStatus]}</div>`;
                    }
                    if (sentStatus === dlrStatus && sentStatus in sentStatuses) {
                        return `<div class="status-icons d-flex align-items-center justify-content-center">${sentStatuses[sentStatus]}</div>`;
                    }
                    const value = [
                        '<div class="status-icons d-flex align-items-center justify-content-center">',
                        sentStatus in sentStatuses ? sentStatuses[sentStatus] : sentStatus,
                        '<span class="text-sm mx-0_2">|</span>',
                        dlrStatus in dlrStatuses ? dlrStatuses[dlrStatus] : dlrStatus,
                    ].join('');
                    return value;
                }, type: ColumnTypes.HTML, cellClassName: 'w-sm', popover: 'Sent - the SMS submission status we receive from our testing device, <br><br>DLR status - the delivery status that we receive from the MNO'
            },

            { title: 'Origin number', prop: 'originDto', format: (_: MoTestingResult) => _.originDto.phone, popover: 'Origin number - Test number from which the test SMS was sent' },
            { title: 'Destination number', prop: 'destination', popover: 'Number to which the test SMS was sent' },
            { title: 'Text', prop: 'textDelivered', format: this.formatText, cellClassName: 'editable', type: ColumnTypes.HTML, popover: 'Text of the sent/submitted SMS' },
            { title: 'Sent/DLR delay', prop: 'receiptDelay', format: this.formatDelay, type: ColumnTypes.HTML, popover: 'Delays in getting the testing device’s ‘Sent’ and MNO’s DLR status' },
            {
                title: 'User', prop: 'user', format: _ => {
                    return _.user ? (_.user.username ? _.user.username : _.user.email) : 'empty';
                }, popover: 'User that triggered the test'
            },
            { title: 'Price', prop: 'price', format: _ => _.price > 0 ? _.price / 1000 : 0, type: ColumnTypes.HTML }
        ];
        this.tableActions = [
            { icon: 'icon-tests-settings', name: 'details', title: 'Test case details' }
        ];

        if (!this.isAdmin && !this.user.impersonated) {
            hideColumns.push('Price');
        }

        if (this.user.role === 'subaccount' && !this.user.showAllResults) {
            hideColumns.push('User');
        }
        const finalColumns = columns.filter(c => hideColumns.indexOf(c.title) === -1);
        this.setColumns(this.createColumns(finalColumns));

        const savedUserSearchDetails = this.localStorage.get(`mo_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.searchParams = savedUserSearchDetails;
        }
        this.update();
        this.intervalTimer = setInterval(() => {
            if (this.loading) {
                return;
            }
            this.update(false);
        }, 1000 * 20);
    }

    ngOnInit() {
    }

    onPageChange(event) {
        if (event === this.page) {
            return;
        }
        this.page = event;
        this.update();
    }

    update(loading = true) {
        if (!this.user.enabled) {return;}
        this.loading = loading;
        let params = new AllRequestParams();
        params.size = this.currentSize;
        params.page = this.page - 1;
        const success = (data: MoTestingResultsCollection) => {
            data.content.forEach((_, index) => {
                _.createdAtFormat = moment(_.createdAt).format('DD/MM/YY HH:mm:ss').split(" ");
            });
            this.setData(this.createRows(data.content), data.totalElements);
            this.loading = false;
        };
        const error = (error) => {
            this.loading = false;
            this.notificationService.error({
                title: 'MO testing',
                message: 'An error occurred while loading the results',
                serviceName: 'MOTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        };

        if (this.searchParams) {
            this.service.searchResults(params, this.searchParams).subscribe({ next: success, error: error });
        } else {
            this.service.results(params).subscribe({ next: success, error: error });
        }
    }

    onAction(event: ActionEvent) {
        this.actions.emit(event);
        if (event.name === 'details') {
            this.detailsModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.detailsModalTpl).open();
            this.detailsModal.onDestroy.subscribe(() => {
                this.flashRow(event.row.data.id);
                this.details = null;
            });
            this.service.testDetails(event.row.data.id).subscribe({
                next: (details) => {
                    this.details = { ...details, test: event.row.data };
                    const testCreatedTimestamp = moment(this.details.test.createdAt);
                    const ttl = this.details.ttl;
                    this.details.statuses.forEach(st => {
                        const statusTimestamp = moment(st.serverReceivedAt);
                        const actualDelay = statusTimestamp.diff(testCreatedTimestamp, 'seconds');
                        if (ttl < actualDelay) {
                            st.isStatusAfterExpired = true;
                        }
                    });
                },
                error: e => {
                    this.detailsModal.close(true);
                    this.notificationService.error({
                        title: 'MO testing',
                        message: 'An error occurred while loading the details',
                        serviceName: 'MOTC',
                        requestMessage: e.statusText,
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                }
            });
        }
    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    formatDelay(row) {
        let text = [];
        if (typeof row.sentDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.sentDelay);
            text.push(row.sentDelay >= 300 ? `<span class="text-warning">${delay}</span>` : delay);
        }
        if (typeof row.receiptDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.receiptDelay);
            text.push(row.receiptDelay >= 300 ? `<span class="text-warning">${delay}</span>` : delay);
        }
        return text.join(' / ');
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'textDelivered') {
            this.textModalHtml = BrowserUtils.sanitizeHTML(event.row.data.textDelivered);
            if (event.row.data.telqId) {
                const telqIdContent = `<span title="Test ID Text" class="text-success">${event.row.data.telqId}</span>`;
                this.textModalHtml = this.textModalHtml.replace(event.row.data.telqId, telqIdContent);
            }
            this.modal.alert().dialogClass('modal-dialog small-modal').component(this.textModalTpl).open().onDestroy.subscribe(() => {
                this.flashRow(event.row.data.id);
            });
        }
    }

    ngOnDestroy() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
    }

    onClickPdu(status: MoTestDetailsStatus & { showPdu?: boolean, parsedPdu?: string }) {
        if (!status.pdu) {
            return;
        }
        status.parsedPdu = '';
        status.showPdu = !status.showPdu;
        if (status.showPdu) {
            status.parsedPdu = this.service.parsePdu(status.pdu);
        }
    }

    formatText(row): string {
        if (!row.textDelivered) {
            return BrowserUtils.escapeHtml(row.textDelivered);
        }
        let text = String(row.textDelivered);
        let maxLen = 30;
        let len = text.length;
        if (len >= maxLen) {
            text = text.substr(0, maxLen) + '...';
        }
        text = BrowserUtils.escapeHtml(text);
        return `<span>${text}</span>`;
    }

    onClickExport() {
        this.exportSize = 0;
        this.exportSpinner = false;
        this.exportData = {};
    }

    export(limit) {
        this.exportSpinner = true;
        let params = this.searchParams;
        const ids = MoSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!this.searchParams && ids.length) {
            params = { userIds: ids }
        }

        this.exportService.export('mo', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Live number testing',
                message: 'An error occurred while export tests',
                serviceName: 'NTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

}
