import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../../shared/components/breadcrumbs/breadcrumbs';
import { NotificationService } from '../../../shared/services/notification.service';
import { SearchParams } from '../../../shared/services/live-number-testing.service';
import { UsersService } from '../../../shared/services/users.service';
import { MntFormComponent } from '../mnt-form/mnt-form.component';
import { MntResultsTableComponent } from '../mnt-results-table/mnt-results-table.component';
import { MntSearchFormComponent } from '../mnt-search-form/mnt-search-form.component';
import { Observable, catchError, forkJoin, map } from 'rxjs';
import { TestResult } from '../../../shared/models/test-result.model';
import { BatchTasks } from '../../../shared/services/batch-tasks';
import { DialogRef, ModalService } from '../../../shared/services/modal.service';
import { CreateTestGroup, ManualNumberTestingService } from '../../../shared/services/manual-number-testing.service';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { PricingService } from '../../../shared/services/pricing.service';


@Component({
    selector: 'app-mnt-index',
    templateUrl: 'mnt-index.component.html',
    styleUrls: ['mnt-index.component.scss'],
})

export class MntIndexComponent implements OnInit {

    @ViewChild(MntFormComponent, { static: false }) form: MntFormComponent;
    @ViewChild(MntResultsTableComponent, { static: false }) resultsTable: MntResultsTableComponent;
    @ViewChild(MntSearchFormComponent, { static: false }) filter: MntSearchFormComponent;

    @ViewChild('batchRepeatModal', { read: TemplateRef, static: false }) batchRepeatModalTpl: any;
    batchRepeatModalDialogRef: DialogRef;

    pauseUpdateForm = false;
    pauseUpdateResults = false;
    selectedRows: TestResult[] = [];
    isBatchRepeatLoading: boolean = false;
    batchCompleted: number = 0;
    testsPrice: number = 0;
    testingDisabled = null;

    constructor(
        public breadcrumbs: BreadcrumbService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        public userService: UsersService,
        private modal: ModalService,
        private manualTestingService: ManualNumberTestingService,
        private pricingService: PricingService,
        titleService: Title
    ) {
        titleService.setTitle('Manual testing');
    }

    afterSaveTestCase() {
        this.pauseUpdateResults = false;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('Test results', ['result-test/manual'], true);
    }

    onSubmitFilter(event: SearchParams) {
        if (this.resultsTable.loading) {
            return;
        }
        this.resultsTable.searchParams = event;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    batchClear(): void {
        this.selectedRows = [];
        this.resultsTable.resetBatch();
    }

    onBatchRepeat(): void {
        this.isBatchRepeatLoading = true;
        this.batchRepeatModalDialogRef = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.batchRepeatModalTpl).open();
        this.pricingService.getTestPrices(this.selectedRows.length).subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.isBatchRepeatLoading = false;
            },
        });
    }

    onRepeatClick(): void {
        this.isBatchRepeatLoading = true;
        const testGroup: CreateTestGroup = {
            testDtos: this.selectedRows.map(_ => ({ destinationDto: _.destinationDto })),
        };
        this.manualTestingService.run(testGroup).subscribe({
            next: (_) => {
                this.notificationService.success('Tests repeated successfully', 'Manual testing');
                this.batchRepeatModalDialogRef.close();
                this.isBatchRepeatLoading = false;
                this.batchClear();
                this.resultsTable.update();
            },
            error: (error) => {
                console.error('Error in repeating: ', error);
                this.notificationService.success('Tests repeated successfully', 'Manual testing');
                this.isBatchRepeatLoading = false;
                this.batchClear();
            }
        });
    }
}
