
import { Component, Input, EventEmitter, ViewChild, Output, AfterViewInit } from '@angular/core';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import * as XLSX from 'xlsx';
import { InputFile } from "../../shared/components/input/input.file";
import { TemplatesService } from "../../shared/services/templates.service";
import { ContentTemplateService } from '../../shared/services/content-template.service';
import { ContentTemplateImportErrorCollection } from '../../shared/models/content-templates';
import { CustomUtils } from '../../shared/services/custom-utils';

@Component({
    selector: 'app-content-template-import',
    templateUrl: './content-templates-import.component.html',
    styleUrls: ['./content-templates-import.component.scss'],
})

export class ContentTemplatesImportComponent implements AfterViewInit {

    MAX_ROWS = 8000;
    MAX_ROWS_WITH_MAP_SENDER_TO_ALL = 500;

    @Output() import = new EventEmitter();

    @Input() parent: string;
    @Input() fileList: FileList = null;
    @Input() backendErrors: ContentTemplateImportErrorCollection;

    accept = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    acceptExtensions = ['xlsx']

    @ViewChild(InputFile, { static: false }) input: InputFile;

    items = [];
    validationErrors = [];
    duplicates = [];
    rowsCount = 0;

    isMapSenderToAllPresentInRows: boolean = false;

    senderValidator: any;

    constructor(
        public contentTemplateService: ContentTemplateService,
        public service: TemplatesService,
        public validationService: ValidationService,
    ) {
        this.senderValidator = Vld.compose([Vld.senderId(11, 20, true)]);
    }

    ngAfterViewInit() {
        if (this.fileList) {
            this.input.onChangeInput(this.fileList);
        }
    }

    onChangeImportFile(files: File[]) {
        if (files instanceof Event) {
            return;
        }
        this.items = [];
        this.validationErrors = [];
        this.backendErrors = null;
        this.duplicates = [];
        this.rowsCount = 0;

        if (!files.length) {
            return;
        }
        this.importFromExcelFile(files[0]);
    }

    isFavouriteTruthy(item: string[]): boolean {
        return (item[7]?.toLowerCase() === 'y' || item[7]?.toLowerCase() === 'true' || item[7]?.toLowerCase() === 'yes' || item[7]?.toLowerCase() === '1');
    }

    isMapSenderToAllTruthy(item: string[]): boolean {
        return (item[8]?.toLowerCase() === 'y' || item[8]?.toLowerCase() === 'true' || item[8]?.toLowerCase() === 'yes' || item[8]?.toLowerCase() === '1');
    }

    importFromExcelFile(file: File) {
        /* wire up file reader */
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            const rows: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
            this.rowsCount = rows.length;
            delete rows[0]; // Remove header ...
            this.isMapSenderToAllPresentInRows = false; // Reset for fresh start ...

            rows.forEach((row: any[], index: number) => {
                if (row?.length === 0) {
                    // Remove empty rows ...
                    return null;
                }
                rows[index] = [
                    typeof row[0] !== 'undefined' ? (String(row[0])).trim() : '', // senderId
                    typeof row[1] !== 'undefined' ? (String(row[1])).trim() : '', // content
                    typeof row[2] !== 'undefined' ? (String(row[2])).trim() : '', // Test ID Text Type
                    typeof row[3] !== 'undefined' ? (String(row[3])).trim() : '', // Test ID Text Case
                    typeof row[4] !== 'undefined' ? (String(row[4])).trim() : '', // Test ID Text Length
                    typeof row[5] !== 'undefined' ? (String(row[5])).trim() : '', // Template description
                    typeof row[6] !== 'undefined' ? (String(row[6])).trim() : '', // Message Type
                    typeof row[7] !== 'undefined' ? (String(row[7])).trim() : '', // Favourite
                    typeof row[8] !== 'undefined' ? (String(row[8])).trim() : '', // Map sender to all
                ];
                rows[index][7] = this.isFavouriteTruthy(row);
                if (this.isMapSenderToAllTruthy(rows[index])) {
                    rows[index][8] = true;
                    this.isMapSenderToAllPresentInRows = true;
                } else {
                    rows[index][8] = false;
                }
            });
            if (this.isMapSenderToAllPresentInRows && this.rowsCount > this.MAX_ROWS_WITH_MAP_SENDER_TO_ALL) {

            } else {
                this.categorizeRows(rows);
            };
        }
        reader.readAsBinaryString(file);
    }

    doesRowCountExceedLimit(): boolean {
        if (this.rowsCount > this.MAX_ROWS) {
            return true;
        }
        if (this.isMapSenderToAllPresentInRows && this.rowsCount > this.MAX_ROWS_WITH_MAP_SENDER_TO_ALL) {
            return true;
        }
        return false;
    }

    categorizeRows(rows: string[]): void {
        const validationErrors = []
        const items = [];
        rows.map((row: any, index: number) => {
            if (row.length === 0) return null;
            const errors = this.validate(row);
            if (errors.length) {
                validationErrors.push({ index: index, errors: errors });
            } else {
                const item = {
                    id: CustomUtils.generateRandomString(5),
                    senderId: row[0],
                    text: row[1],
                    telqIdType: row[2] ? row[2] : TemplatesService.TELQ_ID_TYPE_DEFAULT,
                    telqIdCase: row[3] ? row[3] : null,
                    telqIdLength: row[4] ? parseInt(row[4]) : null,
                    description: row[5] || '',
                    messageType: row[6] || TemplatesService.TELQ_MESSAGE_DEFAULT,
                    favourite: row[7],
                    mapSenderToAll: row[8]
                };
                if (!item.telqIdLength) {
                    delete item.telqIdLength;
                }
                if (!item.telqIdCase) {
                    delete item.telqIdCase;
                }
                items.push(item);
            }
        });
        this.items = items;
        this.validationErrors = validationErrors;
    }

    validateTelqId(str: string[], errorMessages: string[]): void {
        const types = [
            'WHATSAPP_CODE',
            'NUMERIC',
            'ALPHA',
            'ALPHA_NUMERIC'
        ];
        switch (str[2]) {
            case 'WHATSAPP_CODE':
                if (str[3]) {
                    errorMessages.push('TEST ID TEXT CASE: must be empty for WHATSAPP_CODE');
                }
                if (str[4]) {
                    errorMessages.push('TEST ID TEXT LENGTH: must be empty for WHATSAPP_CODE');
                }
                break;
            case 'NUMERIC':
                if (str[3]) {
                    errorMessages.push('TEST ID TEXT CASE: must be empty for NUMERIC');
                }
                if (!str[4]) {
                    errorMessages.push('TEST ID TEXT LENGTH: required for NUMERIC');
                }
                break;
            case 'ALPHA':
                if (!str[3]) {
                    errorMessages.push('TEST ID TEXT CASE: required for ALPHA');
                }
                if (!str[4]) {
                    errorMessages.push('TEST ID TEXT LENGTH: required for ALPHA');
                }
                break;
            case 'ALPHA_NUMERIC':
                if (!str[3]) {
                    errorMessages.push('TEST ID TEXT CASE: required for ALPHA_NUMERIC');
                }
                if (!str[4]) {
                    errorMessages.push('TEST ID TEXT LENGTH: required for ALPHA_NUMERIC');
                }
                break;
            default:
                errorMessages.push('TEST ID TEXT TYPE: allowed values: ' + types.join(', '));
                break;
        }

        const cases = ['UPPER', 'LOWER', 'MIXED'];
        if (str[3] && cases.indexOf(str[3]) === -1) {
            errorMessages.push('TEST ID TEXT CASE: allowed values: ' + cases.join(', '));
        }

        if (str[4]) {
            const len = parseInt(str[4]);
            const min = 4;
            const max = 20;
            if (len < min) {
                errorMessages.push('TEST ID TEXT LENGTH: Must be no less than ' + min);
            }
            if (len > max) {
                errorMessages.push('TEST ID TEXT LENGTH: Must be no greater than ' + max);
            }
        }
    }

    validate(str: string[]) {
        let errorMessages = [];
        const senderError = this.senderValidator.call(null, { value: str[0] });
        if (senderError) {
            switch (senderError?.max.max) {
                case 11:
                    errorMessages.push('SenderID: Must be no greater than 11 for numeric.');
                    break;
                case 16:
                    errorMessages.push('SenderID: Must be no greater than 16 for alpha and alphanumeric.');
                    break;
                default:
                    errorMessages.push('SenderID: SenderId is not valid');
                    break;
            }
        }
        // When importing from LNT we have different requirements ... TP-5550 ...
        if (this.parent === 'lnt-content-templates') {
            if (str[8] && str[0].length < 1) {
                errorMessages.push('SenderID: Cannot be empty when Map sender to all is Yes.');
            }
            if (!this.isMapSenderToAllPresentInRows && str[0].length < 1) {
                errorMessages.push('SenderID: Cannot be empty.');
            }
            if (!str[8]) {
                if (!str[1].includes('{TEST_ID_TEXT}')) {
                    errorMessages.push('Content: Invalid content. Placeholder {TEST_ID_TEXT} for test id text missing.');
                }
                this.validateTelqId(str, errorMessages);
            }  
        } else {
            if (str[0].length < 1) {
                errorMessages.push('SenderID: Cannot be empty.');
            }
            if (!str[1].includes('{TEST_ID_TEXT}')) {
                errorMessages.push('Content: Invalid content. Placeholder {TEST_ID_TEXT} for test id text missing.');
            }
            this.validateTelqId(str, errorMessages);
        }
        return errorMessages;
    }

    showItemsLength(items: any[]): number {
        if (this.isMapSenderToAllPresentInRows) return null;
        return items.length;
    }

    onSubmitImport() {
        this.import.emit(this.items);
    }

    onCancelImport(): void {
        this.import.emit([]);
    }
}
