
export interface Setting {
    name: string;
    valType: string;
    valRaw: string;
}

export class SettingType {
    static BOOL = 'BOOL'
    static INT = 'INT'
    static STR = 'STR'
}
