
import {Component, Output, EventEmitter, ElementRef, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { ValidationService, Validators as V, Validators as Vld } from '../../../shared/services/validation.service';
import { AppsctiptsMtTier } from "../../../shared/models/appscripts-billing.model";
import { AppscriptsBillingService } from "../../../shared/services/appscripts-billing.service";
import { catchError, throwError } from "rxjs";

@Component({
    selector: 'app-appscripts-mt-tier-form',
    templateUrl: 'appscripts-mt-tier-form.component.html',
})

export class AppscriptsMtTierFormComponent {

    @Input() model: AppsctiptsMtTier;

    @Input() showButtons = true;

    @Output() onAfterSave: EventEmitter<AppsctiptsMtTier> = new EventEmitter();

    backButtonShow = true;
    loading = false;

    form: FormGroup;

    constructor(
        public elementRef: ElementRef,
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public service: AppscriptsBillingService,
        public validationService: ValidationService
    ) {
        this.model = this.service.createMtTier();
        this.form = formBuilder.group({
            name: ['', Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(255)])],
            smsCount: ['', Vld.compose([Vld.required, Vld.min(1, true)])],
            rate: ['', Vld.compose([Vld.required, Vld.min(0.01, true), Vld.max(0.1, true), V.price(2, true)])],
        });
    }

    onSubmit() {
        this.loading = true;
        let model = Object.assign({}, this.model);
        model.rate = model.rate * 100;
        if (typeof model.createdAt !== 'undefined') {
            delete model.createdAt;
        }
        this.service.saveMtTier(model)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    this.validationService.handleRequestError(e, 'Appscripts');
                    return throwError(() => e);
                })
            )
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success(
                    'Tier ' + (this.model.id ? 'updated' : 'created'),
                    'Appscripts'
                );
                this.onAfterSave.emit(res);
            });
    }
}