
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InviteService } from '../../shared/services/invite.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { InviteTableComponent } from "../invite-table/invite-table.component";

@Component({
    selector: 'app-invite-index',
    templateUrl: './invite-index.component.html',
    styleUrls: ['invite-index.component.scss']
})

export class InviteIndexComponent implements OnInit {

    @ViewChild(InviteTableComponent, {static: false}) invites: InviteTableComponent;

    email: string = '';

    form: FormGroup;

    loading = false;

    userId: number;

    constructor(
        public notificationService: NotificationService,
        public service: InviteService,
        public userService: UsersService,
        formBuilder: FormBuilder,
        public validationService: ValidationService
    ) {
        this.form = formBuilder.group({
            email: ['', Vld.compose([Vld.required, Vld.email(true)])],
        });
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.userId = user.id;
            this.loading = false;
        }).catch(error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Invites',
                message: 'An error occurred while loading user',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
        });
    }

    onSubmit() {
        this.loading = true;
        this.service.create(this.userId, this.email).subscribe(invite => {
            this.notificationService.success('Invite created', 'Invites');
            this.invites.update();
            this.loading = false;
        }, (errors) => {
            this.loading = false;
            this.validationService.handleRequestError(errors, 'Invites');
        });
    }
}
