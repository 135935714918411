import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ScheduledTasksCollection,
    ScheduledTask,
    ScheduledTaskReportRule,
    ReportsCollection
} from '../models/scheduled-task.model';
import { getItemLabel, MESSAGE_STATES, LIVE_NUMBER_TEST_STATUSES } from '../models/test-group.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";
import { LocalStorageService } from "./localStorage.service";

@Injectable()
export class SchedulerService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    static DAYS = [
        {
            label: 'S',
            value: 'SUNDAY'
        },
        {
            label: 'M',
            value: 'MONDAY'
        },
        {
            label: 'T',
            value: 'TUESDAY'
        },
        {
            label: 'W',
            value: 'WEDNESDAY'
        },
        {
            label: 'T',
            value: 'THURSDAY'
        },
        {
            label: 'F',
            value: 'FRIDAY'
        },
        {
            label: 'S',
            value: 'SATURDAY'
        }
    ];

    static HIDDEN_STORAGE_KEY = 'sch-hidden-Mode'
    static SHOW_MY_TASKS_FLAG_KEY = 'sch-show-my-tasks-flag'

    constructor(http: HttpClient, public storage: LocalStorageService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    static formatReportRule(rule: ScheduledTaskReportRule): string {
        let result = '';
        if (rule.messageState) {
            result += ('Dlr status is ' + getItemLabel(MESSAGE_STATES, rule.messageState));
            if (rule.messageStateWaitingTime) {
                const messageStateTime = SchedulerService.secondsToHuman(rule.messageStateWaitingTime);
                result += ('>= ' + messageStateTime.value + messageStateTime.unit);
            }
        }

        if (rule.messageState && rule.testStatus) {
            result += ' AND ';
        }

        if (rule.testStatus) {
            result += ('Receipt status is ' + getItemLabel(LIVE_NUMBER_TEST_STATUSES, rule.testStatus));
            if (rule.testStatusWaitingTime) {
                const testStatusTime = SchedulerService.secondsToHuman(rule.testStatusWaitingTime);
                result += ('>= ' + testStatusTime.value + testStatusTime.unit);
            }
        }

        return result;
    }

    all(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            hidden: params.hidden ? 'true' : 'false',
            ownTasksOnly: params.ownTasksOnly ? 'true' : 'false',
            searchString: params.search ? params.search : '',
            sort: (params.sort && params.sort.length) ? params.sort : []
        };

        let url = this.utils.buildUrl('ROLE/sch/tasks', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ScheduledTasksCollection>(url, options).pipe(map(_ => {
            const collection = _;
            collection.content.map(task => {
                if (task.finishAt === '1970-01-01T00:00:00Z') {
                    task.finishAt = null;
                }
                if (task.daysOfWeek === null) {
                    task.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                }
                return task;
            });
            return collection;
        }));
    }

    reportsAll(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : '',
            sort: (params.sort && params.sort.length) ? params.sort : []
        };

        let url = this.utils.buildUrl('ROLE/sch/reports', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ReportsCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ScheduledTask>(url, options).pipe(map(_ => {
            const task = _;
            if (task.finishAt === '1970-01-01T00:00:00Z') {
                task.finishAt = null;
            }
            if (task.daysOfWeek === null) {
                task.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
            }
            return task;
        }));
    }

    save(task: ScheduledTask, startNow = false) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks`, { startNow: startNow ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<ScheduledTask>(url, task, options);
    }

    hidden(taskId: number, hidden: boolean) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/hidden`, { hidden: hidden ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, { hidden: hidden }, options);
    }

    stop(taskId: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/stop`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    schedule(taskId: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/schedule`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    create(): ScheduledTask {
        return {
            id: null,
            title: '',
            sendOnlyOnce: true,
            state: null,
            repeatEvery: 60 * 5,
            reportFrequency: 'ALL',
            reportEmails: [],
            daysOfWeek: [],
            reportRules: [],
            userId: null,
            testCaseTemplateId: null,
            fromHour: null,
            tillHour: null,
            reportPerAlertEnabled: true,
            allAlertXlsReportEnabled: false,
            allTestXlsReportEnabled: false,
            testRepeatsPerIteration: 1,
            callbackUrl: null
        };
    }

    getHiddenMode(): boolean {
        return this.storage.get(SchedulerService.HIDDEN_STORAGE_KEY, false);
    }

    saveHiddenMode(hidden: boolean) {
        this.storage.set(SchedulerService.HIDDEN_STORAGE_KEY, hidden);
    }

    getShowMyTasksFlag(): boolean {
        return this.storage.get(SchedulerService.SHOW_MY_TASKS_FLAG_KEY, false);
    }

    saveShowMyTasksFlag(showMyTasks: boolean) {
        this.storage.set(SchedulerService.SHOW_MY_TASKS_FLAG_KEY, showMyTasks);
    }

    static repeatEveryToSeconds(value: number, unit: string): number {
        if (unit === 'unlimited') {
            return null;
        }

        const map = {
            s: 1,
            m: 60,
            h: 3600,
            d: 3600 * 24
        };

        return value * map[unit];
    }

    static secondsToHuman(seconds: number) {
        if (seconds === null) {
            return { value: null, unit: 'unlimited' };
        }
        if (seconds < 60) {
            return { value: seconds, unit: 's' };
        }
        if (seconds < 3600) {
            return { value: seconds / 60, unit: 'm' };
        }
        if (seconds < (3600 * 24)) {
            return { value: seconds / 3600, unit: 'h' };
        }

        return { value: seconds / (3600 * 24), unit: 'd' };
    }

    static calculateSchedule(task: ScheduledTask, countTests: number = 0, limit: number = 10): TaskScheduleDef {
        let result = {
            countTasks: 0,
            countTests: 0,
            history: []
        };

        if (task.sendOnlyOnce && !task.startAt) {
            return result;
        }

        if (task.startAt && !task.finishAt) {
            const startAt = typeof task.startAt === 'number' ? task.startAt : Date.parse(task.startAt) / 1000;
            return {
                countTasks: 1,
                countTests: countTests,
                history: [
                    new Date(startAt * 1000)
                ]
            };
        }

        if (!parseInt(String(task.repeatEvery)) || !task.startAt || !task.finishAt || !task.daysOfWeek || !task.daysOfWeek.length) {
            return result;
        }

        const startAt = typeof task.startAt === 'number' ? task.startAt : Date.parse(task.startAt) / 1000;
        const finishAt = typeof task.finishAt === 'number' ? task.finishAt : Date.parse(task.finishAt) / 1000;
        const now = Date.now() / 1000;

        task.fromHour = task.fromHour || 0;
        task.tillHour = task.tillHour || 24;
        const taskFromHour = typeof task.fromHour === 'number' ? task.fromHour : (+ (task.fromHour.replace(':', '.')));
        const taskTillHour = typeof task.tillHour === 'number' ? task.tillHour : (+ (task.tillHour.replace(':', '.')));
        const hoursCheck = taskFromHour >= 0 && taskTillHour >= 0;

        if (finishAt <= now) {
            return result;
        }

        const dayMap = {
            SUNDAY: 0,
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6
        };

        let repeatEveryRecalculated = task.repeatEvery;
        const validDays = task.daysOfWeek.map(_ => dayMap[_]);

        // Calculating total task number for randomized schelued task
        if (task.randomized) {
            const fromHoursMinutes = task.fromHour.toString().split(':').map(_ => _.padStart(2, '0'));
            const tillHoursMinutes = task.tillHour.toString().split(':').map(_ => _.padStart(2, '0'));

            const currentTime = new Date();
            const configuredStart = new Date(startAt * 1000);

            // checks if the configured start time actually earlier than the current time
            // in this case the actual start should be chosen as current time  
            const start = configuredStart < currentTime ? currentTime : configuredStart;
            const finish = new Date(finishAt * 1000);

            let tillHourToday = new Date(start.getTime());
            tillHourToday.setHours(parseInt(tillHoursMinutes[0]));
            tillHourToday.setMinutes(parseInt(tillHoursMinutes.length === 1 ? '0' : tillHoursMinutes[1]));
            tillHourToday.setSeconds(0);
            tillHourToday.setMilliseconds(0);

            let fromHourToday = new Date(start.getTime());
            fromHourToday.setHours(parseInt(fromHoursMinutes[0]));
            fromHourToday.setMinutes(parseInt(fromHoursMinutes.length === 1 ? '0' : fromHoursMinutes[1]));
            fromHourToday.setSeconds(0);
            fromHourToday.setMilliseconds(0);

            // We choose from hour as start time if it is later than the current time. 
            // This means that the test is expected to start later than scheduled. 
            let lastScheduledTime = new Date(fromHourToday.getTime());

            // calculate periods between tests in seconds
            repeatEveryRecalculated = ((tillHourToday.getTime() - fromHourToday.getTime()) / 1000) / task.repeatsPerDayRandomized;

            const differenceMinutes = (tillHourToday.getTime() - fromHourToday.getTime()) / (60 * 1000);
            if (task.repeatsPerDayRandomized > differenceMinutes) {
                return result;
            }

            if (currentTime >= finish) {
                return result;
            }

            const ONE_DAY_MILLIS = 24 * 3600 * 1000;

            let lastScheduledTimeMillis = lastScheduledTime.getTime();
            let fromHourTodayMillis = fromHourToday.getTime();
            let tillHourTodayMillis = tillHourToday.getTime();

            while (lastScheduledTimeMillis < finish.getTime()) {

                // Skip calculating values in the past 
                if (lastScheduledTimeMillis < start.getTime()) {
                    lastScheduledTimeMillis += repeatEveryRecalculated * 1000;
                    continue;
                }

                // Check if the current day should not be considered and the time of the last scheduled task
                // is not later than the till hour, otherwise skips calculations for the current day and shifts to the next day 
                if (lastScheduledTimeMillis >= tillHourTodayMillis ||
                    validDays.indexOf(new Date(lastScheduledTimeMillis).getDay()) === -1
                ) {
                    fromHourTodayMillis += ONE_DAY_MILLIS;
                    tillHourTodayMillis += ONE_DAY_MILLIS;
                    lastScheduledTimeMillis = fromHourTodayMillis;
                    continue;
                }

                // if latest scheduled task is before the tillHour and finish time 
                // we increase counter of tests and tasks 
                if (lastScheduledTimeMillis < tillHourTodayMillis) {
                    result.countTasks++
                    result.countTests += countTests;
                    lastScheduledTimeMillis += repeatEveryRecalculated * 1000;
                }

                if (task.runUntilDisabled) {
                    return {
                        countTasks: result.countTasks > 0 ? 'Unlimited' : 0,
                        countTests: result.countTests > 0 ? 'Unlimited' : 0,
                        history: []
                    }
                }
            }
            return result;
        }

        let next = (task: ScheduledTask, start: number): Date => {
            while (true) {
                start += repeatEveryRecalculated;
                if (start >= finishAt) {
                    break;
                }
                let startDate = new Date(start * 1000);
                if (validDays.indexOf(startDate.getDay()) === -1) {
                    continue;
                }
                let h = startDate.getHours();
                let m = String(startDate.getMinutes()).padStart(2, '0');
                let hm = +(`${h}.${m}`);
                if (hoursCheck && !(hm >= taskFromHour && hm <= taskTillHour)) {
                    continue;
                }
                return startDate;
            }
            return null;
        };

        let startNow = now <= startAt ? startAt - repeatEveryRecalculated : startAt, nextAt: Date = null;
        while (true) {
            nextAt = next(task, startNow);
            if (nextAt === null) {
                break;
            }
            startNow = nextAt.getTime() / 1000;
            if (now >= startNow) {
                continue;
            }
            if (limit && result.history.length < limit) {
                result.history.push(nextAt);
            }
            result.countTasks++;
            if (countTests) {
                result.countTests += countTests;
            }
            if (task.runUntilDisabled && result.history.length >= limit) {
                break;
            }
        }
        if (task.runUntilDisabled) {
            return {
                countTasks: result.history.length > 0 ? 'Unlimited' : 0,
                countTests: result.history.length > 0 ? 'Unlimited' : 0,
                history: result.history
            }
        }
        return result;
    }
}



export interface TaskScheduleDef {
    countTasks: number | string,
    countTests: number | string,
    history: Date[]
}

export interface CombinationRuleItem {
    id: number;
    messageState: string;
    messageStateWait: number;
    messageStatusDropdownShow?: boolean;
    testStatus: string;
    testStatusDropdownShow?: boolean;
    testStatusWait: number;
}

export interface StatusItem {
    id: string;
    isSelectedInCombination?: boolean;
    label: string;
    selected?: boolean;
    wait?: number;
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    hidden: boolean = false;
    ownTasksOnly: boolean = false;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
