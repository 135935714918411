import {Inject, Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

    static data = {};

    constructor(@Inject('STORAGE_PREFIX') public prefix: string) {}

    get(key: string, def: any = null) {
        return LocalStorageService.getItem(this.prefix + key, def);
    }

    static getItem(key, def: any = null) {
        const storage = window.localStorage;
        let raw = storage.getItem(key);
        if (raw === null) {
            return def;
        }
        try {
            let data = JSON.parse(raw);
            return data.value;
        } catch (e) {
            return null;
        }
    }

    set(key: string, data: any) {
        LocalStorageService.setItem(this.prefix + key, data);
    }

    static setItem(key: string, data: any) {
        const storage = window.localStorage;
        let raw = JSON.stringify({value: data});
        storage.setItem(key, raw);
    }

    remove(key: string) {
        LocalStorageService.removeItem(this.prefix + key);
    }

    static removeItem(key: string) {
        const storage = window.localStorage;
        storage.removeItem(key);
    }

    getMem(key, def = null) {
        if (typeof LocalStorageService.data[key] === 'undefined') {
            return def;
        }
        return LocalStorageService.data[key];
    }

    setMem(key, data) {
        LocalStorageService.data[key] = data;
    }

    removeMem(key) {
        if (typeof LocalStorageService.data[key] !== 'undefined') {
            delete LocalStorageService.data[key];
        }
    }
}
