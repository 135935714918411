import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentTemplateService } from '../../shared/services/content-template.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ContentTemplatesFormComponent } from '../content-templates-form/content-templates-form.component';

@Component({
    selector: 'app-content-template-update',
    templateUrl: './content-templates-update.component.html',
})

export class ContentTemplatesUpdateComponent implements OnInit {

    @ViewChild(ContentTemplatesFormComponent, { static: false }) form: ContentTemplatesFormComponent;
    @Output() onAfterSave: EventEmitter<boolean> = new EventEmitter();

    loading = false;

    constructor(
        public activateRoute: ActivatedRoute,
        public contentTemplateService: ContentTemplateService,
        private formBuilder: FormBuilder,
        public notificationService: NotificationService,
        public router: Router,
        titleService: Title
    ) {
        titleService.setTitle('Template update');
    }

    afterSave(data): void {
        this.onAfterSave.emit(true);
    }

    ngOnInit() {
    }
}
