
import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewChildren
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentTemplate } from '../../shared/models/content-templates';
import { ContentTemplateService } from '../../shared/services/content-template.service';
import { ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { TemplatesService } from '../../shared/services/templates.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { TextTemplateComponent } from "../../test/text-template/text-template.component";
import { trigger, transition, style, animate } from '@angular/animations';
import * as he from "he";

@Component({
    selector: 'app-content-template-form',
    templateUrl: './content-templates-form.component.html',
    styleUrls: ['./content-templates-form.component.scss'],
    animations: [
        trigger('slideFadeInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate('0.5s ease-in', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                animate('0.5s ease-in', style({ opacity: 0, transform: 'translateX(100%)' })),
            ])
        ])
    ],
})

export class ContentTemplatesFormComponent implements AfterViewInit {

    @Input() backButtonShow = true;
    @ViewChildren(TextTemplateComponent) textTemplates: QueryList<TextTemplateComponent>;

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @Output() onAfterSave: EventEmitter<boolean> = new EventEmitter();

    currentId: any;
    currentContentTemplate!: ContentTemplate;

    isAdmin: boolean = false;
    loading: boolean = false;

    types = TemplatesService.TELQ_ID_TYPES;
    cases = TemplatesService.TELQ_ID_CASES;
    messageTypes = TemplatesService.TELQ_MESSAGE_TYPES;

    form: FormGroup = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        public notificationService: NotificationService,
        private formBuilder: FormBuilder,
        private router: Router,
        public templateService: TemplatesService,
        private contentTemplateService: ContentTemplateService,
        public userService: UsersService,
        private modal: ModalService,
        public validationService: ValidationService,
        private location: Location
    ) {
        this.loading = true;
        userService.can('admin').then(isAdmin => this.isAdmin = isAdmin).catch(e => { });
    }

    ngAfterViewInit(): void {
        const currentPath = this.location.path();
        const searchParams = new URLSearchParams(currentPath.substring(currentPath.indexOf('?')));
        this.currentId = searchParams.get('id');
        if (this.currentId) {
            this.contentTemplateService.one(this.currentId).subscribe({
                next: (res: ContentTemplate) => {
                    this.currentContentTemplate = res;
                    this.setForm(res);
                },
                error: (err) => {
                    console.error(err);
                    this.router.navigate(['content-template']);
                }
            });
        } else {
            this.setForm();
        }
    }

    get smsTextTemplatesForm(): FormArray {
        return this.form.get('smsTemplateTextDtos') as FormArray;
    }

    setForm(value: any = {}): void {
        this.form = this.formBuilder.group({
            name: [value.name || '', Vld.compose([Vld.senderId(11, 20, true)])],
            smsTemplateTextDtos: this.formBuilder.array([])
        });
        if (value?.smsTemplateTextDtos?.length > 0) {
            value.smsTemplateTextDtos.forEach((st: any, index: number) => {
                setTimeout(() => {
                    const textTemplateComp = this.textTemplates.find((_, i) => i === index);
                    textTemplateComp.setEditorContent(he.encode(st.value));
                }, 0);
                this.populateFormArray(st, index);
            });
        } else {
            this.smsTextTemplatesForm.push(this.newSmsTextTemplate());
        }
        this.loading = false;
    }

    getUserDetails(data: any): string {
        if (data.visibleAll) {
            return 'TelQ';
        }
        return data.user ? data.user.email : '';
    }

    populateFormArray(data: any, index: number): void {
        const fg = this.formBuilder.group({});
        fg.addControl('id', new FormControl(data.id));
        fg.addControl('value', new FormControl(data.value, Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(1600)])));
        fg.addControl('description', new FormControl(data.description));
        fg.addControl('messageType', new FormControl(data.messageType?.toUpperCase()));
        fg.addControl('userFavourite', new FormControl(data.userFavourite));
        fg.addControl('telqIdType', new FormControl(data.telqIdType));
        fg.addControl('user', new FormControl({ value: this.getUserDetails(data), disabled: true }));
        fg.addControl('visibleAll', new FormControl(data.visibleAll));
        if (data.telqIdCase) {
            fg.addControl('telqIdCase', new FormControl(data.telqIdCase));
        }
        if (data.telqIdLength) {
            fg.addControl('telqIdLength', new FormControl(
                data.telqIdLength,
                Vld.compose([
                    Vld.min(TemplatesService.TELQ_ID_LEN_MIN),
                    Vld.max(TemplatesService.TELQ_ID_LEN_MAX)
                ]))
            );
        }
        this.smsTextTemplatesForm.push(fg);
    }

    newSmsTextTemplate(): FormGroup {
        return this.formBuilder.group({
            value: new FormControl('', Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(1600)])),
            description: new FormControl(''),
            messageType: new FormControl(TemplatesService.TELQ_MESSAGE_DEFAULT),
            telqIdType: new FormControl(TemplatesService.TELQ_ID_TYPE_DEFAULT),
            userFavourite: new FormControl(false),
            telqIdLength: new FormControl(
                TemplatesService.TELQ_ID_LEN_DEFAULT,
                Vld.compose([
                    Vld.min(TemplatesService.TELQ_ID_LEN_MIN),
                    Vld.max(TemplatesService.TELQ_ID_LEN_MAX)
                ])
            ),
            user: new FormControl({ value: this.userService.authUser.email, disabled: true }),
            visibleAll: new FormControl(false)
        });
    }

    getFormControl(stt: FormGroup, controlName: string): FormControl {
        return stt.get(controlName) as FormControl;
    }

    onChangeText(event: any, stt: FormGroup): void {
        stt.get('value').patchValue(event);
    }

    addSmsTextTemplate(): void {
        this.smsTextTemplatesForm.push(this.newSmsTextTemplate());
    }

    deleteSmsTextTemplates(stt: FormGroup, index: number): void {
        let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
        dialogRef.result.then(result => {
            if (result) {
                const currentTextTemplateId = this.getFormControl(stt, 'id')?.value;
                if (currentTextTemplateId) {
                    this.loading = true;
                    this.contentTemplateService.deleteTextTemplate(currentTextTemplateId).subscribe({
                        next: (res) => {
                            this.notificationService.success('Text template deleted.', 'Content Templates');
                            this.smsTextTemplatesForm.removeAt(index);
                            this.loading = false;
                        },
                        error: (error) => {
                            this.notificationService.error({
                                title: 'Content Templates',
                                message: 'An error occurred while deleting text template.',
                                serviceName: 'NTC',
                                requestMessage: error.statusText,
                                requestCode: error.status,
                                ts: error.timestamp ? error.timestamp : null
                            });
                            this.loading = false;
                        }
                    })
                } else {
                    this.smsTextTemplatesForm.removeAt(index);
                }
            }
        });
    }

    checkIfControlPresent(stt: FormGroup, controlName: string): boolean {
        return stt.get(controlName) ? true : false;
    }

    onChangeType(event: Event, stt: FormGroup, index: number): void {
        const selectedValue = stt.get('telqIdType').value;
        if (selectedValue === 'ALPHA' || selectedValue === 'ALPHA_NUMERIC') {
            stt.addControl('telqIdCase', new FormControl(TemplatesService.TELQ_ID_CASE_DEFAULT));
            stt.addControl('telqIdLength', new FormControl(TemplatesService.TELQ_ID_LEN_DEFAULT));
        } else if (selectedValue === 'NUMERIC') {
            stt.removeControl('telqIdCase');
            if (!this.checkIfControlPresent(stt, 'telqIdLength')) {
                stt.addControl('telqIdLength', new FormControl(TemplatesService.TELQ_ID_LEN_DEFAULT));
            }
        } else if (selectedValue === 'WHATSAPP_CODE') {
            stt.removeControl('telqIdCase');
            stt.removeControl('telqIdLength');
        }
    }

    isFavourite(index: number): boolean {
        return this.smsTextTemplatesForm.at(index).get('userFavourite').value;
    }

    onFavouriteClick(index: number): void {
        const userFavControlAtIndex = this.smsTextTemplatesForm.at(index).get('userFavourite') as FormControl;
        userFavControlAtIndex.setValue(!userFavControlAtIndex.value);
    }

    createUpdateContentTemplate(rawFormValue): void {
        this.contentTemplateService.createUpdateSmsTemplates(rawFormValue).subscribe({
            next: (res) => {
                this.notificationService.success(
                    'Content Template ' + (this.currentId ? 'Updated' : 'Created'),
                    'Content Templates'
                );
                this.onAfterSave.emit(true);
            }, error: (err) => {
                this.notificationService.error({
                    title: 'Content templates',
                    message: 'An error occurred while creating/updating a content template',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.loading = false;
                this.onAfterSave.emit(true);
            }
        });
    }

    onVisibleAllToggleChange(event, index): void {
        const userEmailAtIndex: FormControl = this.smsTextTemplatesForm.at(index).get('user') as FormControl;
        const visibleAllAtIndex: FormControl = this.smsTextTemplatesForm.at(index).get('visibleAll') as FormControl;
        if (!(/TelQ|@teletele/.test(userEmailAtIndex.value))) {
            this.notificationService.error({
                title: 'Content Templates',
                message: 'Visible all only allowed for telqtele.com user templates',
                serviceName: 'NTC'
            });
            visibleAllAtIndex.setValue(false);
        }
    }

    onSubmit(): void {
        if (!this.form.valid) return;
        this.loading = true;
        const rawFormValue = this.form.getRawValue();
        if (this.isAdmin) {
            rawFormValue['id'] = this.currentId;
            this.createUpdateContentTemplate(rawFormValue);
            return;
        }
        const newSender = rawFormValue.name;
        const oldSender = this.currentContentTemplate?.name || '';
        if (oldSender === newSender) {
            rawFormValue['id'] = this.currentId;
            rawFormValue.smsTemplateTextDtos = rawFormValue.smsTemplateTextDtos.filter(sttd => {
              sttd.visibleAll = null;
              return !sttd.visibleAll;
            });
            rawFormValue.smsTemplateTextDtos.forEach(sttd => {
              sttd.value = he.decode(sttd.value);
            });
            this.createUpdateContentTemplate(rawFormValue);
        } else {
            rawFormValue.smsTemplateTextDtos.forEach(sttd => {
                sttd.id = null;
                sttd.value = he.decode(sttd.value);
                sttd.visibleAll = null;
            });
            this.createUpdateContentTemplate(rawFormValue);
        }
    }

    isDisabled(stt: FormGroup): boolean {
        if (this.isAdmin) return false;
        const visibleAll = this.getFormControl(stt, 'visibleAll').value;
        return visibleAll;
    }

    showCase(type) {
        return TemplatesService.showCaseForType(type);
    }

    showLength(type) {
        return TemplatesService.showLengthForType(type);
    }

    onCancelClick(): void {
        this.onAfterSave.emit(false);
    }
}
