import { Component} from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";
import { StatusStat } from "../../shared/models/analytic.model";

@Component({
    selector: 'status-renderer-component',
    template: `<div [tooltip]="tooltipValue" tooltipPlacement="bottom" tooltipClass="ag-grid">
        <div *ngIf="statuses.length" class="ag-statuses-bar">
            <div *ngFor="let s of statuses" [ngClass]="'color-' + s.color" [style.width.%]="s.valPercent"></div>
        </div>
    </div>`
})
export class StatusRendererComponent implements ICellRendererAngularComp {
    public statuses: StatusStat[] = [];
    public tooltipValue: string;

    agInit(params: ICellRendererParams): void {
        this.statuses = params.value as StatusStat[];
        this.updateTooltip();
    }

    refresh(params: ICellRendererParams): boolean {
        this.statuses = params.value as StatusStat[];
        this.updateTooltip();
        return true;
    }

    private updateTooltip() {
        this.tooltipValue = this.statuses.map(s => {
            return `<div><span class="text-${s.color}">•</span> ${s.name} ${s.valPercent}% (${s.val})</div>`
        }).join('');
    }
}