
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../shared/components/table/ag-table-base';
import { CellClickEvent } from "../../shared/components/table/table.component";
import { Destination } from '../../shared/models/destination.model';
import { Report, ReportsCollection } from '../../shared/models/scheduled-task.model';
import { Supplier } from '../../shared/models/supplier.model';
import { LiveNumberTestingService } from '../../shared/services/live-number-testing.service';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, SchedulerService } from '../../shared/services/scheduler.service';
import { UsersService } from '../../shared/services/users.service';
declare var moment: any;

@Component({
    selector: 'app-scheduler-reports-table',
    templateUrl: './scheduler-reports-table.component.html',
    styleUrls: ['./scheduler-reports-table.component.scss'],
})

export class SchedulerReportsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    requestParams = new AllRequestParams();
    storageContextName = 'scheduler-reports-table';

    @Output() actions = new EventEmitter();
    @Output() onAfterDelete = new EventEmitter();

    private scheduler: any;
    loading: boolean = false;
    isLoadedInitially: boolean = false;

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            minWidth: 80, maxWidth: 80, hide: true,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Date reported', field: 'createdAt',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            minWidth: 90, maxWidth: 90,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_);
            },
        },
        {
            headerName: 'Test ID', field: 'testId',
            minWidth: 90, maxWidth: 100,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.testDto.id}</div>`;
            }
        },
        {
            headerName: 'Schedule name', field: 'scheduledTaskTitle',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.scheduledTaskTitle}</div>`;
            }
        },
        {
            headerName: 'Test number', field: 'testNumber',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.testDto.destinationDto.phone}</div>`;
            }
        },
        {
            headerName: 'Country(MCC)/Network(MNC)', field: 'destination',
            minWidth: 350, maxWidth: 500,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDestination(_.testDto.destinationDto);
            }
        },
        {
            headerName: 'Supplier', field: 'supplierDto',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatSupplier(_.supplierDto);
            }
        },
        {
            headerName: 'Reason for report', field: 'reportRuleDto',
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatRule(_);
            }
        },
    ];

    rowData!: Report[];

    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: SchedulerService,
        public userService: UsersService,
        public storage: LocalStorageService,
        public lntService: LiveNumberTestingService,
        public router: Router,
        public modal: ModalService,
        private localStorage: LocalStorageService,
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.paginationPageSize = this.localStorage.get(`sch_report_table_component_size_${this.userService.authUser.id}`) || 20;
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.localStorage.set(`sch_report_table_state_${this.userService.authUser.id}`, this.gridApi.getColumnState());
        });
        this.scheduler = setInterval(() => {
            this.update(false);
        }, 1000 * 30);
    }

    update(spinner = true) {
        if (spinner) {
            this.loading = true;
        }
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState.length === 0 ? ['id,desc'] : this.sortState;

        this.service.reportsAll(this.requestParams).subscribe({
            next: (collection: ReportsCollection) => {
                collection.content.map(this.formatCreatedAt);
                this.rowData = collection.content;
                this.totalRowsCount = collection.totalElements;
                this.loading = false;
            },
            error: error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while loading reports',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    onSortChange(event: SortChangedEvent) {
        const columnState = this.gridApi.getColumnState();
        this.sortState = columnState.map(c => c.sort ? `${c.colId},${c.sort}` : null);
        this.update();
        this.isLoadedInitially = true;
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`sch_report_table_component_size_${this.userService.authUser.id}`, size);
        this.update();
    }

    formatRule(row) {
        return SchedulerService.formatReportRule(row.reportRuleDto);
    }

    formatDestination(dest: Destination) {
        return `<div class="two-lines">${this.lntService.formatDestination(dest, true)}</div>`;
    }

    formatSupplier(supplier: Supplier) {
        return supplier.title + ' ' + supplier.routeType;
    }

    formatDate(_: Report) {
        return `<div class="two-lines"><span>${_.createdAtFormat[1]} <br>${_.createdAtFormat[0]}</span></div>`
    }

    formatCreatedAt(item: Report) {
        const time = typeof item.createdAt === 'number' ? item.createdAt * 1000 : Date.parse(item.createdAt);
        item.createdAtFormat = moment(time).format('DD/MM/YY HH:mm:ss').split(" ");
        return item;
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'testDtoId') {
            this.router.navigate(['/live-testing'], {
                queryParams: {
                    ids: event.row.data.testDto.id
                }
            }).then();
        }
    }

    // ng grid
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const columnState = this.localStorage.get(`sch_report_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
        if (!this.isLoadedInitially) this.update();
    }

    // ng grid

    ngOnDestroy() {
        if (this.scheduler) {
            clearInterval(this.scheduler);
        }
        this.columnChange$.unsubscribe();
    }
}
