import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUser } from '../../shared/models/user.model';
import { CustomUtils } from '../../shared/services/custom-utils';
import { UsersService } from '../../shared/services/users.service';
import { BackendChannelService, BackendChannelType } from "../../shared/services/backend-channel.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    user: AuthUser;
    capitializedUserShortname: String = '';

    private $backendChanel: Subscription;
    currentBackendChanel: BackendChannelType;

    constructor(
        private userService: UsersService,
        private router: Router,
        backendChannel: BackendChannelService
    ) {
        this.$backendChanel = backendChannel.$current.subscribe(ch => {
            this.currentBackendChanel = ch;
        });
    }

    ngOnInit(): void {
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.capitializedUserShortname = CustomUtils.getInitials(user.fullName);
        });
    }

    logout(): void {
        this.userService.logout(['/login'], ['/users']).then(url => {
            this.router.navigate(url).finally(() => { });
        });
    }

    is(roles: string[], onlyEnabled = false) {
        if (!this.user) {
            return false;
        }
        if (onlyEnabled && !this.user.enabled) {
            return false;
        }
        let result = false;
        for (let role of roles) {
            if (role === this.user.role) {
                result = true;
                break;
            }
        }
        return result;
    }

    ngOnDestroy() {
        this.$backendChanel.unsubscribe();
    }
}
