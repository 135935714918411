
import { Component, ViewChild, TemplateRef } from '@angular/core';
import { PhoneReportService } from "../../shared/services/phone-report.service";
import { PhoneReportCreate } from "../../shared/models/phone-report.model";
import { DialogRef, ModalService } from "../../shared/services/modal.service";

@Component({
    selector: 'app-phone-report-widget',
    templateUrl: './phone-reports-widget.component.html'
})

export class PhoneReportsWidgetComponent {
    model: PhoneReportCreate = {};
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;

    dialog: DialogRef;

    constructor(
        public modal: ModalService,
        public service: PhoneReportService,
    ) { }

    open(phone): Promise<DialogRef> {
        let model = this.service.createModel();
        model.phone = phone;
        this.model = model;
        return new Promise((resolve) => {
            this.dialog = this.modal.alert().component(this.formModalTpl).open();
            resolve(this.dialog);
        })
    }

    onAfterSave() {
        this.dialog.close();
    }
}