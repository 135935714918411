import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { AnalyticResult, GroupByCol } from "../../shared/models/analytic.model";
import { AnalyticService } from "../../shared/services/analytic.service";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";

@Component({
    selector: 'app-group-cell-renderer',
    template: `
    <span class="ag-cell-wrapper" [style.paddingLeft]="'calc(' + level + ' * var(--ag-row-group-indent-size))'">
      <span *ngIf="group" class="ag-group-contracted" (click)="onClick($event)" [style.cursor]="'pointer'">
          <span class="icon-actions-hor"></span>
      </span>
        <span class="ag-group-value"><span (click)="onClick($event)" [style.cursor]="'pointer'" [innerHTML]="params.value"></span> <span *ngIf="hint" class="hint text-muted">{{ hint }}</span></span>
    </span>
    `,
    styles: ['.hint {font-size: calc(var(--ag-font-size) - 2px); margin-left: 5px;}']
})
export class GroupCellRenderer implements ICellRendererAngularComp {
    public params!: ICellRendererParams<AnalyticResult, string> & Params;
    public level = 0;
    public expanded = false;
    public group = false;
    public columns: GroupColumnDef[] = [];
    public hint = '';

    agInit(params: ICellRendererParams<AnalyticResult, string> & Params): void {
        this.params = params;
        this.level = params.node.level;
        this.expanded = params.node.expanded;
        this.group = !!params.node.groupData['ag-Grid-AutoColumn'];
        this.columns = params.columns;
        this.hint = (params.hints && params.hints[params.node.data.groupByColumn]) ? params.hints[params.node.data.groupByColumn] : '';
        this.params.node.addEventListener(
            RowNode.EVENT_EXPANDED_CHANGED,
            this.onExpand
        );
    }

    refresh(params: ICellRendererParams<AnalyticResult, string>) {
        return false;
    }

    destroy() {
        this.params.node.removeEventListener(RowNode.EVENT_EXPANDED_CHANGED, this.onExpand);
    }

    onClick(e) {
        const node = this.params.node;
        if (!node.expanded) {
            if (this.params.slicesLimit && this.params.onLimitReached && this.level >= (this.params.slicesLimit - 1)) {
                this.params.onLimitReached();
                return;
            }
            node['childStore'] = null;
            const colDef = this.getColDef(node.data.groupByColumn);
            if (colDef && colDef.groupByColumns.length > 1) {
                const index = colDef.groupByColumns.indexOf(node.data.groupByColumn);
                if (index !== -1 && colDef.groupByColumns.length > (index + 1)) {
                    this.selectGroup(colDef.groupByColumns[index + 1]);
                    return;
                }
            }

            const cols = this.getColumns();
            const wrapper = AgGridUtils.getWrapperMenu();
            const menu = this.buildMenu(cols);
            wrapper.append(menu);
            const x = e.clientX - menu.getBoundingClientRect().left;
            const y = e.clientY - menu.getBoundingClientRect().top;
            menu.style.top = `${y}px`;
            menu.style.left = `${x}px`;
            this.setupMenuClickHandler();
            return;
        }
        this.params.node.setExpanded(!node.expanded);
    }

    onClickMenu(clickEvent: Event, col: GroupColumnDef) {
        if (col.disabled) {
            clickEvent.stopPropagation();
            this.setupMenuClickHandler();
            return;
        }
        AgGridUtils.getWrapperMenu().remove();
        this.selectGroup(col.groupByColumns[0]);
    }

    onExpand = () => this.expanded = this.params.node.expanded;

    private setupMenuClickHandler() {
        setTimeout(() => {
            document.body.addEventListener('click', e => {
                const wrapper = AgGridUtils.getWrapperMenu();
                if (!wrapper.contains(e.target as HTMLElement)) {
                    e.stopPropagation();
                    const menu = wrapper.getElementsByClassName('group-cell-menu').item(0);
                    if (menu) {menu.remove();}
                }
            }, {once: true});
        });
    }

    private selectGroup(group: GroupByCol) {
        this.params.node.data.nextGroupByColumn = group;
        this.params.node.setExpanded(true);
    }

    private getColDef(group: GroupByCol): GroupColumnDef {
        const defs = this.columns.filter(_ => _.groupByColumns.includes(group));
        return defs.length ? defs[0] : undefined;
    }

    private buildMenu(columns: GroupColumnDef[]): HTMLElement {
        const menu = AgGridUtils.el('div', ['ag-tabs', 'ag-menu', 'ag-popup-child', 'group-cell-menu']);
        const body = AgGridUtils.el('div', ['ag-tabs-body', 'ag-menu-body']);
        const menuList = AgGridUtils.el('div', ['ag-menu-list', 'ag-focus-managed']);
        columns.forEach(c => {
            let option = AgGridUtils.el('div', ['ag-menu-option']);
            let text = AgGridUtils.el('span', ['ag-menu-option-part', 'ag-menu-option-text']);
            text.textContent = c.title;
            option.append(text);
            if (c.disabled) {
                option.classList.add('ag-menu-option-disabled');
            }
            option.onclick = (e) => this.onClickMenu(e, c);
            menuList.append(option);
        });

        body.append(menuList);
        menu.append(body);

        return menu;
    }

    private getColumns(): GroupColumnDef[] {
        let columns: GroupColumnDef[] = [];
        if (this.params.node.data.groupByColumn) {
            const current = this.params.node.data.groupByColumn;
            let depends = [];
            this.columns.filter(c => c.dependsOnGroupByColumns.includes(current)).forEach(c => depends.push(...c.groupByColumns));
            if (depends.length) {
                columns = this.columns.map(c => {
                    c.disabled = c.groupByColumns.filter(_ => depends.includes(_)).length === 0;
                    return  c;
                });
            }
        }

        if (!columns.length) {
            const p = this.params.node as RowNode<AnalyticResult>
            let exists: GroupByCol[] = AnalyticService
                .extractPath(p)
                .map(_ => _.groupByColumn)
                .filter(_ => _);
            columns = this.columns.map(c => {
                c.disabled = c.dependsOnGroupByColumns.length > 0 || c.groupByColumns.filter(_ => exists.includes(_)).length > 0;
                return  c;
            });
        }

        const level = this.params.api.getServerSideGroupLevelState().length;
        columns.forEach(c => {
            if (c.minLevel && !c.disabled) {
                c.disabled = !(level > c.minLevel);
            }
        });

        return columns;
    }
}

interface Params {
    columns: GroupColumnDef[],
    hints: {[key: string]: string},
    slicesLimit?: number,
    onLimitReached?: Function
}

export interface GroupColumnDef {
    title: string,
    groupByColumns: GroupByCol[],
    dependsOnGroupByColumns: GroupByCol[],
    minLevel?: number,
    disabled?: boolean
}