import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NetworkRegion } from '../../shared/models/network.model';
import { RegistrationByInviteModel, RegistrationModel, SubaccountInviteResponseModel, ZapierModel } from '../../shared/models/user.model';
import { CountryService } from '../../shared/services/country.service';
import { NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { catchError } from "rxjs/operators";
import { CustomUtils } from '../../shared/services/custom-utils';
import { BrowserUtils } from '../../shared/services/browser-utils';

@Component({
    selector: 'app-registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss']
})

export class RegistrationFormComponent {

    @Output() onAfterRegistration = new EventEmitter();

    emailValidateLoading: boolean = false;
    invite = null;
    registrationByInvite = false;
    loading = false;
    passwordType = 'password';
    phonePrefix = null;
    step: 'form' | 'success' | 'invalid-invite';

    model: RegistrationModel = {
        username: null,
        password: null,
        fullName: null,
        email: null,
        phone: null,
        addressCity: null,
        addressZip: null,
        addressStreet: null,
        countryName: '',
        emailForAlert: null,
        emailForInvoice: null,
        source: null,
        recaptchaToken: null,
        companyInformation: {
            companyName: null,
            websiteUrl: null,
            countryName: null,
            vatNumber: null
        }
    };

    form: FormGroup;
    regions = [];

    recaptchaErrorSubscription: Subscription;

    constructor(
        public countryService: CountryService,
        public userService: UsersService,
        private formBuilder: FormBuilder,
        public notificationService: NotificationService,
        public router: Router,
        public validationService: ValidationService,
        private netInfoService: NetInfoService,
        private route: ActivatedRoute,
        private recaptchaV3Service: ReCaptchaV3Service
    ) { }

    ngOnInit() {
        this.initializeFormAndControls();
        this.handleRecaptchaErrors();
        this.loadRegions();
        this.handleRouteParams();
    }

    initializeFormAndControls(): void {
        this.form = this.formBuilder.group({
            companyName: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
            websiteUrl: ['', Vld.compose([Vld.required, Vld.url(true), Vld.maxLength(100)])],
            source: [''],
            emailLocalName: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
            emailDomain: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
            email: ['', Vld.compose([Vld.required, Vld.email(true)])],
            fullName: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
            username: ['', Vld.compose([Vld.required, Vld.username(true)])],
            countryName: ['', Vld.compose([Vld.required])],
            phone: ['', Vld.compose([Vld.required, Vld.globalPhoneNumber()])],
            phonePrefix: ['', Vld.compose([Vld.required])],
            password: ['', Vld.compose([Vld.required, Vld.maxLength(50), Vld.password(true)]), [Vld.passwordBackend(this.userService)]]
        });

        const websiteUrlControl: FormControl = this.form.get('websiteUrl') as FormControl;
        const emailLocalNameControl: FormControl = this.form.get('emailLocalName') as FormControl;
        const emailDomainControl: FormControl = this.form.get('emailDomain') as FormControl;
        const emailControl: FormControl = this.form.get('email') as FormControl;
        const usernameControl: FormControl = this.form.get('username') as FormControl;
        websiteUrlControl.valueChanges.subscribe(value => {
            emailDomainControl.setValue(CustomUtils.extractDomain(value));
        });
        emailLocalNameControl.valueChanges.subscribe(value => {
            const email = `${value}${emailDomainControl.value}`;
            emailControl.setValue(email);
            usernameControl.setValue(value);
            this.model.email = email;
        });
        emailDomainControl.valueChanges.subscribe(value => {
            const email = `${emailLocalNameControl.value}${value}`;
            emailControl.setValue(email);
            this.model.email = email;
        });
    }

    handleRecaptchaErrors(): void {
        this.recaptchaErrorSubscription = this.recaptchaV3Service.onExecuteError.subscribe(error => {
            console.error('Error occurred during reCAPTCHA execution: ', error);
            this.notificationService.error('There is a problem with the registration. Please contact support.', 'Registration');
        });
    }

    loadRegions(): void {
        this.netInfoService.getAllRegions().subscribe({
            next: (regions: NetworkRegion[]) => {
                this.regions = regions.map(region => {
                    return {
                        country: region.name, prefix: region.prefixes[0]
                    };
                });
            },
            error: (err) => {
                console.log(err);
            }
        })
    }

    handleRouteParams(): void {
        this.route.queryParams.subscribe(params => {
            if (params.invite) {
                this.invite = params.invite;
                this.registrationByInvite = true;
                this.form.removeControl('email');
                this.form.removeControl('emailLocalName');
                this.form.removeControl('emailDomain');
                this.form.removeControl('companyName');
                this.form.removeControl('websiteUrl');
                this.form.removeControl('source');
                this.userService.validateInvite(params.invite).pipe(
                    catchError(e => {
                        this.step = 'form';
                        throw e;
                    })
                ).subscribe(valid => this.step = valid ? 'form' : 'invalid-invite');
            } else {
                this.step = 'form';
            }
        });
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    isPasswordType() {
        return this.passwordType === 'password';
    }

    onClearEmailDomain() {
        this.form.get('emailDomain').setValue('');
    }

    onClearUsername() {
        this.form.get('username').setValue('');
    }

    onSubmit() {
        this.recaptchaV3Service.execute('importantAction').subscribe({
            next: (token: string) => {
                this.loading = true;
                let model = this.model;
                if (!this.invite) {
                    let url = model.companyInformation.websiteUrl;
                    if (url.indexOf('http') === -1 && url.indexOf('https')) {
                        url = 'https://' + url;
                    }
                    model.companyInformation.websiteUrl = url;
                    model.companyInformation.countryName = this.model.countryName;
                    model.recaptchaToken = token;
                    if (model.source === "") {
                        model.source = null
                    }
                    this.userService.registration(model).subscribe(() => {
                        this.loading = false;
                        this.step = 'success';
                        this.forwardRegistrationToZapierHook(model);
                        this.onAfterRegistration.emit({ registrationByInvite: false });
                        // This is for GTag purposes ...
                        window.history.pushState({}, null, 'registration/thankyou');
                    }, e => {
                        if (e.statusText && e.statusText.indexOf('Duplicate entry') !== -1) {
                            this.notificationService.error('Registration failed. Please contact our support.', 'Registration');
                        } else if (e.statusText && e.statusText.indexOf('com.telqtele.api.gateway.InvalidEmail') !== -1) {
                            let message = e.statusText.replace('com.telqtele.api.gateway.InvalidEmail: ', '');
                            if (message.includes('Disposable address.')) {
                                message = this.getDomainError(this.model.email);
                            }
                            this.notificationService.error(message, 'Registration');
                        } else if (e.statusText && e.statusText.includes('com.telqtele.api.gateway.InvalidRequestException')) {
                            let message = this.getDomainError(this.model.email);
                            this.notificationService.error(message, 'Registration');
                        } else {
                            this.validationService.handleRequestError(e, 'Registration');
                        }
                        this.loading = false;
                    })
                } else {
                    const modelByInvite: RegistrationByInviteModel = {
                        username: model.username,
                        password: model.password,
                        fullName: model.fullName,
                        phone: model.phone,
                        countryName: model.countryName,
                    }
                    this.userService.registrationByInvite(modelByInvite, this.invite).subscribe({
                        next: (res: SubaccountInviteResponseModel) => {
                            console.debug('Registration by invite success : ', this.invite);
                            modelByInvite.email = res.email;
                            this.loading = false;
                            this.step = 'success';
                            this.forwardInviteToZapierHook(modelByInvite);
                            // This is for GTag purposes ...
                            window.history.pushState({}, null, 'registration/thankyou');
                            this.onAfterRegistration.emit({ registrationByInvite: true });
                        },
                        error: e => {
                            this.validationService.handleRequestError(e, 'Registration');
                            this.loading = false;
                        }
                    });
                }
            },
            error: (error: any) => {
                console.error('ReCaptcha failed: ', error);
                this.notificationService.error('There is a problem with the registration. Please contact support.', 'Registration');
            }
        });
    }

    onCountryChange(countryName: string): void {
        const prefix = this.regions.find(region => region.country === countryName)?.prefix;
        this.phonePrefix = prefix;
    }

    private getDomainError(email: string): string {
        const emailDomain = email.split('@')[1];
        return `The domain name ${emailDomain} cannot be used to register on our testing platform. Please use your corporate email instead.<br>Note:  We only allow accounts to be created with a corporate email (free domain emails such as gmail or disposable emails will not be accepted)`;
    }

    forwardRegistrationToZapierHook(model: RegistrationModel) {
        let dataToZapier: ZapierModel = {};
        for (let [key, value] of Object.entries(model)) {
            if (['username', 'fullName', 'email', 'phone', 'countryName', 'source', 'companyInformation'].includes(key)) {
                dataToZapier[key] = value;
            }
        }
        // This is for marketing purposes ... TP-7421 ...
        const telqTeleParams = BrowserUtils.getCookie('telqteleParams');
        dataToZapier['queryString'] = telqTeleParams?.length > 0 ? telqTeleParams : '';
        this.userService.sendRegistrationToZapier(dataToZapier).subscribe({
            next: (res) => {
                console.log(res);
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    forwardInviteToZapierHook(model: RegistrationByInviteModel) {
        let dataToZapier: ZapierModel = {};
        for (let [key, value] of Object.entries(model)) {
            if (['username', 'email', 'fullName', 'phone', 'countryName'].includes(key)) {
                dataToZapier[key] = value;
            }
        }
        const telqTeleParams = BrowserUtils.getCookie('telqteleParams');
        dataToZapier['queryString'] = telqTeleParams?.length > 0 ? telqTeleParams : '';
        this.userService.sendInviteToZapier(dataToZapier).subscribe({
            next: (res) => {
                console.log(res);
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    ngOnDestroy() {
        this.recaptchaErrorSubscription.unsubscribe();
    }
}
