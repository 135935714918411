
import { Component, OnInit } from '@angular/core';
import { AuthUser } from '../../shared/models/user.model';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-balance-widget',
    templateUrl: 'balance-widget.component.html',
    styleUrls: ['balance-widget.component.scss']
})

export class BalanceWidgetComponent implements OnInit {

    currentValue: number = 0;
    user: AuthUser;

    constructor(public service: UsersService) { }

    ngOnInit() {
        this.service.balanceUpdates.subscribe(balance => {
            this.currentValue = balance;
            this.service.getAuthUser().then(user => {
                this.user = user;
            });
        });
    }

}
