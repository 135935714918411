
import {catchError, map} from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from '../../shared/services/notification.service';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { PhoneReportService } from "../../shared/services/phone-report.service";
import { PhoneReportsTableComponent } from "../phone-reports-table/phone-reports-table.component";

@Component({
    selector: 'app-phone-reports-index',
    templateUrl: './phone-reports-index.component.html',
    styleUrls: [],
})

export class PhoneReportsIndexComponent {

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    @ViewChild(PhoneReportsTableComponent, {static: false}) table: PhoneReportsTableComponent;

    constructor(
        public reports: PhoneReportService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Phone reports');
    }

    batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.reports.delete(row.data.id).pipe(map(() => {
                this.notificationService.success('Reports deleted', 'Phone reports');
                row.batch = false;
                this.completeTask();
            }),catchError((error, caught) => {
                this.completeTask();
                this.notificationService.error({
                    title: 'Phone reports',
                    message: 'Report delete an error',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.table.requestParams.search = phrase;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }
}
