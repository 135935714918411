import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { CryptoWallet } from "../models/appscripts-crypto.model";
import { map } from "rxjs/operators";

@Injectable()
export class AppscriptsCryptoService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    btcWallet(): Observable<CryptoWallet> {
        let url = this.utils.buildUrl('ROLE/crypto/btc/wallet');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return forkJoin([
            this.http.get<CryptoWallet>(url, options),
            this.rate('EURBTC')
        ]).pipe(map(data => {
            const wallet = data[0];
            wallet.rateEur = data[1];
            return this.prepareWallet(wallet);
        }));
    }

    ltcWallet(): Observable<CryptoWallet> {
        let url = this.utils.buildUrl('ROLE/crypto/ltc/wallet');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return forkJoin([
            this.http.get<CryptoWallet>(url, options),
            this.rate('EURLTC')
        ]).pipe(map(data => {
            const wallet = data[0];
            wallet.rateEur = data[1];
            return this.prepareWallet(wallet);
        }));
    }

    rate(symbol: string): Observable<number> {
        let url = this.utils.buildUrl('ROLE/crypto/exchange-rate', {symbol: symbol});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<string>(url, options).pipe(map(s => parseFloat(s)));
    }

    private prepareWallet(wallet: CryptoWallet): CryptoWallet {
        wallet.balance = typeof wallet.balance === 'string' ? parseFloat(wallet.balance) : wallet.balance;
        wallet.balanceEur = wallet.balance * wallet.rateEur;
        return wallet;
    }
}