import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice, InvoiceByAdmin, InvoiceByClient } from "../models/invoice.model";
import { InvoicesCollection } from "../models/invoice.model";
import { RestUtils } from './rest-utils';
import { map } from "rxjs";
import { BackendChannelService } from "./backend-channel.service";

@Injectable()
export class InvoiceService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient, private backendChannel: BackendChannelService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(params: InvoiceRequestParams): Observable<InvoicesCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            startDate: params.startDate,
            endDate: params.endDate,
            autoGenerated: params.autoGenerated ? true : null,
            sort: ['createdAt,desc']
        };

        let url = this.utils.buildUrl(`ROLE/usr/invoices`, queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<InvoicesCollection>(url, options).pipe(
            map(data => {
                data.content = data.content.map(_ => this.prepare(_));
                return data;
            })
        );
    }

    one(invoiceId: string): Observable<Invoice> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices/${invoiceId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Invoice>(url, options).pipe(map(this.prepare));
    }

    getSecret(invoiceId: string): Observable<string> {
        let url = this.utils.buildUrl(`ROLE/usr/invoice/${invoiceId}/secret`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<{secret: string}>(url, options).pipe(map(_ => {
            return _.secret;
        }));
    }

    create(invoice: InvoiceByClient | InvoiceByAdmin): Observable<Invoice> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<Invoice>(url, invoice, options).pipe(map(this.prepare));
    }

    delete(invoiceId: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices/${invoiceId}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    markAsPaid(invoiceId: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices/${invoiceId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    markAsVoid(invoiceId: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices/${invoiceId}/void`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    prepare(invoice: Invoice): Invoice {
        invoice.createdAt = new Date(invoice.createdAt);
        if (invoice.paidAt) {
            invoice.paidAt = new Date(invoice.paidAt);
        }
        invoice.initialAmount = (invoice.commitment + (invoice.discountApplied ? invoice.discountApplied : 0))
        invoice.paymentValue = invoice.commitment;
        if (invoice.amountPaid) {
            invoice.paymentValue -= <number>invoice.amountPaid;
        }
        invoice.total = invoice.paymentValue;
        invoice.vatValue = 0;
        if (invoice.vatPercentage) {
            const vatMultiplier = invoice.vatPercentage / 100;
            invoice.vatValue = invoice.paymentValue * vatMultiplier;
            invoice.paymentValue += invoice.vatValue;
        }
        if (invoice.status === 'CONFIRMED') {
            invoice.total = invoice.amountPaid;
            invoice.amountPaid = 0;
            invoice.vatPercentage = 0;
        }
        return invoice;
    }

    getNotifyUrl(secret) {
        return this.backendChannel.prepareUrl(this.utils.buildUrl(`ipn/confirm`, { secret: secret }));
    }

    getReturnUrl(secret) {
        return this.backendChannel.prepareUrl(this.utils.buildUrl(`ROLE/usr/invoices/process`, { secret: secret }));
    }

    getReportUrl(reportReference: string) {
        return this.backendChannel.prepareUrl(this.utils.buildUrl('ROLE/exp' + reportReference));
    }

    createZohoInvoice(invoiceId: string): Observable<any> {
        let params = {invoiceId: invoiceId}
        let url = this.utils.buildUrl(`ROLE/usr/zoho-invoices`,params);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    getZohoReportUrl(invoiceId: string): string {
        return this.backendChannel.prepareUrl(this.utils.buildUrl(`ROLE/usr/zoho-invoices/pdf`, {invoiceId: invoiceId}));
    }

    sendZohoInvoice(invoiceId: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/usr/invoices/${invoiceId}/report/send`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }

    partialPayment(invoiceId: string, amountPaid: number): Observable<any> {
        let url = this.utils.buildUrl(`admin/usr/invoices/${invoiceId}/partial-payment?amountPaid=${amountPaid}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, null, options);
    }
}

export class InvoiceRequestParams {
    size: number = 20;
    page: number = 1;
    search: string = ''
    startDate: string = '';
    endDate: string = '';
    autoGenerated: boolean = false;
}
