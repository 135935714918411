import { Component, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UsersService } from '../../shared/services/users.service';
import { LoginCredentials } from '../../shared/models/user.model';
import { ValidationService, Validators } from "../../shared/services/validation.service";
import { Timeout } from "../../shared/services/timeout";

@Component({
    selector: 'app-login-form',
    templateUrl: 'login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent implements OnDestroy {

    @ViewChild('mfaInput', { static: false }) mfaInput!: ElementRef<HTMLInputElement>;

    @Output() login = new EventEmitter();

    model: LoginCredentials = {
        username: '',
        password: '',
        mfaCode: null,
        rememberDevice: true
    }
    spinner = false;
    spinnerResendEmail = false;
    form: FormGroup;

    shouldPasswordBeShown: boolean = false;

    step: LoginStep = 'login';
    mfaType: LoginMfaType = 'totp';
    @Output() changeStep = new EventEmitter<LoginStepEvent>();

    expiredPasswordTimeout: Timeout;
    emailCodeResendTimeout: Timeout;

    constructor(
        public notificationService: NotificationService,
        public userService: UsersService,
        public router: Router,
        public validationService: ValidationService,
        formBuilder: FormBuilder,
    ) {
        this.form = formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            mfaCode: [''],
            rememberDevice: [true]
        });
    }

    onSubmit() {
        this.spinner = true;
        this.userService.login(this.model).subscribe({
            next: () => {
                this.notificationService.success('Welcome!', 'Login');
                this.login.emit();
                setTimeout(() => {
                    this.spinner = false;
                }, 100);
            },
            error: err => {
                this.spinner = false;
                if (err.status === 424) {
                    this.mfaType = err.statusText === 'Requires 2FA code' ? 'totp' : 'email';
                    this.mfaEnable();
                    return;
                }
                if (err.statusText === 'Your password expired') {
                    this.passwordExpired();
                    return;
                }
                const mapText = {
                    'Invalid credentials': 'Wrong login or password!',
                    'Invalid 2FA code': 'Wrong 2FA code!'
                }
                const text = mapText[err.statusText] ? mapText[err.statusText] : err.statusText;
                this.notificationService.error({
                    title: 'Login',
                    message: text,
                    requestCode: err.status
                });
            }
        });
    }

    onSubmitExpiredPassword() {
        this.spinner = true;
        this.userService.recovery(this.model.username).subscribe({
           next: () => {
               this.notificationService.success('Email sent successfully.', 'Login');
               this.spinner = false;
               this.passwordExpired();
           },
           error: (e) => {
               this.spinner = false;
               this.notificationService.error({
                   title: 'Login',
                   message: 'An error occurred while request',
                   serviceName: 'GATEWAY',
                   requestMessage: e.statusText,
                   requestCode: e.status,
                   ts: e.timestamp ? e.timestamp : null
               });
           }
        });
    }

    onSubmitResendEmailCode() {
        this.spinnerResendEmail = true;
        this.userService.resendEmailCode(this.model.username).subscribe({
            next: () => {
                this.notificationService.success('Email sent successfully.', 'Login');
                this.spinnerResendEmail = false;
                this.mfaEnable();
            },
            error: (e) => {
                this.spinnerResendEmail = false;
                this.notificationService.error({
                    title: 'Login',
                    message: 'An error occurred while request',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
            }
        });
    }

    private mfaEnable() {
        const oldStep = this.step;
        this.step = 'mfa';
        if (oldStep !== this.step) {
            this.form.controls.mfaCode.setValidators(
                Validators.compose([
                    Validators.required,
                    Validators.digits(true),
                    Validators.minLength(UsersService.TOTP_LENGTH),
                    Validators.maxLength(UsersService.TOTP_LENGTH)
                ])
            );
            this.changeStep.emit({ step: 'mfa', mfaType: this.mfaType, username: this.model.username });
        }
        setTimeout(() => this.mfaInput.nativeElement.focus(), 0);
        if (this.mfaType === 'email') {
            if (this.emailCodeResendTimeout && !this.emailCodeResendTimeout.isFinish()) {
                this.emailCodeResendTimeout.clear();
            }
            this.emailCodeResendTimeout = new Timeout(120);
        }
    }

    private passwordExpired() {
        this.step = 'password-expired';
        if (this.expiredPasswordTimeout && !this.expiredPasswordTimeout.isFinish()) {
            this.expiredPasswordTimeout.clear();
        }
        this.expiredPasswordTimeout = new Timeout(120);
        this.changeStep.emit({ step: 'password-expired', mfaType: this.mfaType, username: this.model.username });
    }

    onMfaPaste(event: ClipboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        const pastedData = event.clipboardData.getData('text/plain').trim();
        this.form.controls.mfaCode.patchValue(pastedData);
        if (this.form.controls.mfaCode.valid) {
            this.model.mfaCode = pastedData;
        }
    }

    togglePasswordShow(): void {
        this.shouldPasswordBeShown = !this.shouldPasswordBeShown;
    }

    ngOnDestroy() {
        if (this.expiredPasswordTimeout && !this.expiredPasswordTimeout.isFinish()) {
            this.expiredPasswordTimeout.clear();
        }
    }
}

export type LoginStep = 'login'|'mfa'|'password-expired';
export type LoginMfaType = 'totp'|'email';
export interface LoginStepEvent {
    step: LoginStep;
    mfaType: LoginMfaType;
    username: string;
}