
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Session, SessionSelect } from '../../../shared/models/session.model';
import { Supplier } from '../../../shared/models/supplier.model';
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { SessionsService } from '../../../shared/services/sessions.service';
import { SuppliersService } from '../../../shared/services/suppliers.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { UdhTlvInputComponent } from '../../../test/udh-tlv-input/udh-tlv-input.component';

@Component({
    selector: 'app-supplier-form',
    templateUrl: './supplier-form.component.html',
    styleUrls: ['./supplier-form.component.scss']
})

export class SupplierFormComponent implements OnInit, AfterViewInit {

    @Input() backButtonShow = true;
    @Input() compact = false;

    @Output() onAfterSave = new EventEmitter();

    loading: boolean = false;

    @ViewChild('udhListComponent', { read: UdhTlvInputComponent, static: false }) udhListComponent: UdhTlvInputComponent;
    @ViewChild('tlvListComponent', { read: UdhTlvInputComponent, static: false }) tlvListComponent: UdhTlvInputComponent;
    @ViewChild('routeTypeInput', { static: false }) routeTypeInput: ElementRef;

    form: FormGroup;

    valid: boolean = false;
    model: Supplier;
    size: number = 20;
    page: number = 1;
    totalCountRows: number = 0;

    routeTypes: { id: string, label: string }[] = [];

    attributes = SuppliersService.ATTRIBUTE_OPTIONS;
    attrToLabel = SuppliersService.ATTRIBUTE_TO_LABEL;
    attributeSelections: Record<string, boolean> = {} as Record<string, boolean>;

    currentStep: number = 1;

    sessions: SessionSelect[] = [];
    selectedSessions: SessionSelect[] = [];
    activeSessionForSupplier: Session;

    constructor(
        public notificationService: NotificationService,
        public elementRef: ElementRef,
        formBuilder: FormBuilder,
        public service: SuppliersService,
        public validationService: ValidationService,
        public modal: ModalService,
        private sessionsService: SessionsService,
        private route: ActivatedRoute
    ) {
        this.model = service.create();
        this.form = formBuilder.group({
            title: ['', Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(30)])],
            routeType: ['', Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(255)])],
            customRouteType: [''],
            serviceType: ['', Vld.compose([Vld.maxLength(5)])],
            comment: ['', Vld.compose([Vld.maxLength(255)])]
        });
        this.validationService.checkRouteTypeValidation(this.form);
        this.validationService.addFormatter('serviceType', validationService.createTextFormatter('Service type is invalid'));
        this.activeSessionForSupplier = this.sessionsService.getActiveSessionForSupplier();
        this.attributeSelections = Object.keys(this.attrToLabel).reduce((obj, key) => {
            obj[key] = false;
            return obj;
        }, {} as Record<string, boolean>);
    }

    ngOnInit() {
        SuppliersService.ROUTE_TYPES.forEach(routeType => {
            this.routeTypes.push({ id: routeType, label: routeType });
        });
    }

    ngAfterViewInit() {
        this.init(this.route.snapshot.queryParams['id'] ? parseInt(this.route.snapshot.queryParams['id']) : null);
    }

    private init(id: number) {
        if (id) {
            this.loading = true;
            this.service.one(id).subscribe((model: Supplier) => {
                model.tlvDtos.map(_ => this.tlvListComponent.addControls(_));
                model.udhDtos.map(_ => this.udhListComponent.addControls(_));
                this.model = model;
                this.setRouteTypeData();
                this.model.attributeList.forEach(attr => {
                    this.attributeSelections[attr] = true;
                });
                this.loading = false;
            });
        }
        let forms = [
            this.tlvListComponent.form,
            this.udhListComponent.form,
            this.form
        ];
        forms.forEach(_ => _.statusChanges.subscribe(() => {
            this.valid = forms.filter(_ => _.valid).length === forms.length;
        }));
        this.updateProgressWidth();
    }

    private updateProgressWidth(): void {
        const widthPercentage = this.currentStep === 1 ? 49 : 100;
        document.documentElement.style.setProperty('--progress-width', `${widthPercentage}%`);
    }

    setRouteTypeData(): void {
        const standardRouteTypes = this.routeTypes.filter(rt => rt.id !== 'Custom').map(rt => rt.id);
        const isRouteCustom = !standardRouteTypes.includes(this.model.routeType);
        if (isRouteCustom) {
            this.form.get('customRouteType').setValue(this.model.routeType);
            this.model.routeType = 'Custom';
        }
    }

    onAttributeSelected(attribute: string): void {
        const oppositeAttributes: Record<string, string> = {
            'DOMESTIC': 'INTERNATIONAL',
            'INTERNATIONAL': 'DOMESTIC',
            'LONG_CODE': 'SHORT_CODE',
            'SHORT_CODE': 'LONG_CODE',
            'TRANSACTIONAL': 'PROMOTIONAL',
            'PROMOTIONAL': 'TRANSACTIONAL'
        };
        if (attribute in oppositeAttributes) {
            this.attributeSelections[oppositeAttributes[attribute]] = false;
        }

        const noneAttributeMappings: Record<string, string[]> = {
            'NONE_LOCAL': ['DOMESTIC', 'INTERNATIONAL'],
            'NONE_TYPE': ['TRANSACTIONAL', 'PROMOTIONAL'],
            'NONE_SENDER': ['LONG_CODE', 'SHORT_CODE']
        };

        if (attribute in noneAttributeMappings) {
            noneAttributeMappings[attribute].forEach(attr => {
                this.attributeSelections[attr] = false;
            });
        }

        this.attributeSelections[attribute] = !this.attributeSelections[attribute];
    }

    prevStep() {
        this.setStep(this.currentStep - 1);
    }

    nextStep() {
        this.setStep(this.currentStep + 1);
    }

    setStep(step: number) {
        this.currentStep = step;
        this.updateProgressWidth();
    }

    toggle(session: SessionSelect, list: 'selected' | 'all') {
        if (list === 'all') {
            session.isSelected = true;
            let prevSession = this.selectedSessions.length ? this.selectedSessions[0] : null;
            this.selectedSessions = [session];
            this.sessions = this.sessions.filter(_ => _.id !== session.id);
            if (prevSession) {
                prevSession.isSelected = false;
                this.sessions.push(prevSession);
            }
        }
        if (list === 'selected') {
            session.isSelected = false;
            this.selectedSessions = [];
            if (!this.sessions.filter(_ => _.id === session.id).length) {
                this.sessions.push(session);
            }
        }
    }

    getRouteTypeValue() {
        return this.model.routeType === 'Custom' ? this.form.get('customRouteType').value : this.model.routeType.trim();
    }

    onSubmit() {
        this.loading = true;
        this.model.udhDtos = [];
        this.model.tlvDtos = [];
        this.model.title = this.model.title.trim();
        this.model.routeType = this.getRouteTypeValue();
        this.model.serviceType = this.model.serviceType ? this.model.serviceType.trim() : null;
        this.model.attributeList = Object.keys(this.attributeSelections).filter(key =>
            this.attributeSelections[key] &&
            !['NONE_LOCAL', 'NONE_SENDER', 'NONE_TYPE'].includes(key));
        this.udhListComponent.getModels().map(_ => this.model.udhDtos.push(_));
        this.tlvListComponent.getModels().map(_ => this.model.tlvDtos.push(_));
        this.service.save(this.activeSessionForSupplier, this.model).pipe(catchError(e => {
            this.loading = false;
            this.notificationService.error({
                title: 'Suppliers',
                message: 'An error occurred while creating/updating a supplier',
                serviceName: 'NTC',
                requestMessage: e.statusText,
                requestCode: e.status,
                ts: e.timestamp ? e.timestamp : null
            });
            this.onAfterSave.emit(e);
            return throwError(() => e);
        })).subscribe((res: Supplier) => {
            this.loading = false;
            this.onAfterSave.emit(res);
            this.notificationService.success(
                `Supplier created`, 'SMPP & Suppliers');
        });
    }

    toggleAttr(attr: string) {
        if (this.model.attributeList.includes(attr)) {
            this.removeAttr(attr);
        } else if (!this.isDisabled(attr)) {
            this.model.attributeList.push(attr);
        }
    }

    removeAttr(attr: string) {
        this.model.attributeList = this.model.attributeList.filter(_ => _ !== attr);
    }

    isDisabled(attr: string) {
        if (SuppliersService.attrDisableRules[attr]) {
            return this.model.attributeList.includes(SuppliersService.attrDisableRules[attr]);
        }
        return false;
    }

    getIcon(attributeId: string): string {
        for (let attribute of this.attributes) {
            for (let item of attribute) {
                if (item.id === attributeId) {
                    return item.icon;
                }
            }
        }
        return '';
    }

    onAfterSessionSave(session: Session): void {
        this.page = 1;
        this.toggle(session, 'all');
    }

}
