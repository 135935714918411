import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { AuthUser } from "../shared/models/user.model";
import { BrowserUtils } from "../shared/services/browser-utils";
import { DatadogService } from '../shared/services/datadog.service';
import { FreshChatService } from "../shared/services/fresh-chat.service";
import { LocalStorageService } from '../shared/services/localStorage.service';
import { UsersService } from '../shared/services/users.service';
import { ConfirmedActionsService } from "../shared/services/confirmed-actions.service";

@Component({
    selector: 'app-component',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {

    @ViewChild(MainMenuComponent, { static: false }) mainMenu: MainMenuComponent;

    loading: boolean = false;
    user: AuthUser;
    lastActivePingInterval: any = null;

    today = new Date();

    constructor(
        public elementRef: ElementRef,
        public storage: LocalStorageService,
        public router: Router,
        public userService: UsersService,
        vc: ViewContainerRef,
        confirmedActionsService: ConfirmedActionsService,
        dg: DatadogService
    ) {
        confirmedActionsService.setRootViewContainerRef(vc);
        dg.init();
    }

    ngOnInit() {
        BrowserUtils.initPlugins();

        this.userService.authUpdates.subscribe((user: AuthUser) => {
            this.user = user;
            FreshChatService.setUser(user);
            // Call last-active endpoint every 5 minutes ...
            this.resetPings();
            this.lastActivePingInterval = setInterval(() => {
                // TODO: Remove this note later ...
                // authUser is the user that is being impersonated ...
                // this.user or user is the admin one ...
                if (this.userService.authUser && !this.userService.authUser.impersonated) {
                    this.userService.ping();
                }
            }, 5 * 60 * 1000);
            this.userService.ping();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        BrowserUtils.isUnload = true;
    }

    logout() {
        this.loading = true;
        this.userService.logout(['/login'], ['/users']).then(url => {
            this.resetPings();
            this.router.navigate(url).finally(() => this.loading = false);
        });
    }

    private resetPings() {
        if (this.lastActivePingInterval !== null) {
            clearInterval(this.lastActivePingInterval);
            this.lastActivePingInterval = null;
        }
    }

}
