import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular'
import { IHeaderParams } from 'ag-grid-community'

@Component({
    selector: 'app-analytics-table-header',
    template: `
        <div class="ag-cell-label-container" [ngClass]="{'ag-header-cell-sorted-asc': ascSort, 'ag-header-cell-sorted-desc': descSort}" role="presentation">
            <span *ngIf="params.enableMenu" #menuButton (click)="onMenuClicked()" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
            <div class="ag-header-cell-label" [tooltip]="tooltipContent" tooltipPlacement="bottom" tooltipClass="ag-grid" (click)="onClickLabel($event)" role="presentation">
                <span class="ag-header-cell-text">{{ params.displayName }}</span>
                <span *ngIf="params.enableSorting" class="ag-sort-indicator-container">
                    <span *ngIf="params.enableSorting" [ngClass]="{'ag-hidden': !ascSort}" class="ag-sort-indicator-icon ag-sort-ascending-icon" aria-hidden="true">
                        <span class="ag-icon ag-icon-asc"></span>
                    </span>
                    <span *ngIf="params.enableSorting" [ngClass]="{'ag-hidden': !descSort}" class="ag-sort-indicator-icon ag-sort-descending-icon" aria-hidden="true">
                        <span class="ag-icon ag-icon-desc"></span>
                    </span>
                </span>
            </div>
        </div>
   `,
    styles: []
})
export class AnalyticsTableHeaderComponent implements IHeaderAngularComp {
    public params: Params;

    public ascSort = false;
    public descSort = false;
    public noSort = false;
    public tooltipContent = '';

    @ViewChild('menuButton', {read: ElementRef}) public menuButton;

    agInit(params: Params): void {
        this.params = params;
        params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
        if (params.tooltipContent) {
            this.tooltipContent = params.tooltipContent;
        }
    }

    onMenuClicked() {
        this.params.showColumnMenu(this.menuButton.nativeElement);
    };

    onSortChanged() {
        this.ascSort = this.descSort = this.noSort = false;
        if (this.params.column.isSortAscending()) {
            this.ascSort = true;
        } else if (this.params.column.isSortDescending()) {
            this.descSort = true;
        } else {
            this.noSort = true;
        }
    }

    onClickLabel(event: any) {
        if (this.params.enableSorting) {
            if (this.noSort) {
                this.params.setSort('asc', true);
            } else if (this.ascSort) {
                this.params.setSort('desc', true);
            } else if (this.descSort) {
                this.params.setSort(null, true);
            }
        }
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }
}

interface Params extends IHeaderParams{
    tooltipContent: string
}