
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserAdmin, UserSearchEvent } from '../../shared/models/user.model';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UserActionData, UsersService } from '../../shared/services/users.service';
import { UsersTableComponent } from '../users-table/users-table.component';
import { UsersSearchComponent } from '../users-search/users-search.component';

@Component({
    selector: 'app-users-index',
    templateUrl: 'users-index.component.html'
})

export class UsersIndexComponent {

    @ViewChild(UsersSearchComponent, { static: false }) userSearchComponent: UsersSearchComponent;
    @ViewChild(UsersTableComponent, { static: false }) usersTableComponent: UsersTableComponent;

    spinner: boolean = false;

    selectedRows: UserAdmin[] = [];
    batchCompleted = 0;
    progressShow = false;

    constructor(
        public userService: UsersService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Users');
    }

    userAction(event: UserActionData) {
        if (event.name === 'edit') {
            this.router.navigate(['users', event.row.id]).then();
        }
        if (event.name === 'accountClick') {
            let phrase = event.row.parentEmail ? event.row.parentEmail : 'parent:' + event.row.email;
            this.userSearchComponent.setSearchFormControlValue(phrase);
        }
    }

    userCreate() {
        this.router.navigate(['users/create']).then();
    }

    batchClear(): void {
        this.selectedRows = [];
        this.usersTableComponent.resetBatch();
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.userService.delete(row.id).pipe(map(() => {
                this.notificationService.success(`Deleted user ${row.email}`, 'Users');
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error(`Delete user ${row.email} an error`, 'Users');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchEnabled(enabled: boolean) {
        const text = enabled ? 'Once enabled, the account will be able to view or issue tests.'
            : 'Once disabled, the account will not be able to view or issue tests.';
        this.modal.confirm('Are you sure?', text).open().result.then(res => {
            if (!res) { return; }
            this.batchEnabled(enabled);
        });
    }

    private batchEnabled(enabled: boolean) {
        const tasks = this.selectedRows.map(row => {
            return this.userService.updateEnable(row.id, enabled).pipe(map(() => {
                const text = (enabled ? 'Enabled user ' : 'Disabled user ') + row.email;
                this.notificationService.success(text, 'Users');
                this.completeTask();
            }), catchError((err, caught) => {
                const text = enabled ? `Enabled user ${row.email} an error` : `Disabled user ${row.email} an error`;
                this.notificationService.error(text, 'Users');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    startTasks(tasks: Observable<any>[]) {
        this.batchCompleted = 0;
        this.usersTableComponent.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.usersTableComponent.loading = false;
            this.selectedRows = [];
            this.usersTableComponent.resetBatch();
            this.usersTableComponent.update();
        }
    }

    onSubmitSearchForm(event: UserSearchEvent) {
        if (this.usersTableComponent.loading) {
            return;
        }
        this.usersTableComponent.requestParams.search = event.searchString;
        this.usersTableComponent.currentPageNumber = 1;
        this.usersTableComponent.update();
    }
}