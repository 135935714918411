
import {Component, Input, EventEmitter, ViewChild, Output, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import { InputFile } from "../../shared/components/input/input.file";
import { SuppliersService } from '../../shared/services/suppliers.service';

@Component({
    selector: 'app-routes-import',
    templateUrl: 'routes-import.component.html',
    styleUrls: ['routes-import.component.scss'],
})

export class RoutesImportComponent implements OnInit {

    MAX_ROWS = 8000;

    @Output() import = new EventEmitter();

    @Input() fileList: FileList = null;

    accept = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    acceptExtensions = ['xlsx']

    @ViewChild(InputFile, {static: false}) input: InputFile;

    items = [];
    duplicates = [];
    rowsCount = 0;

    constructor(public supplierService: SuppliersService) {
        this.supplierService = supplierService;
    }

    ngOnInit() {
        if (this.fileList) {
            this.input.onChangeInput(this.fileList);
        }
    }

    onChangeImportFile(files: File[]) {
        if (files instanceof Event) {
            return;
        }
        this.items = [];
        this.duplicates = [];
        this.rowsCount = 0;

        if (!files.length) {
            return;
        }

        this.importFromExcelFile(files[0]);
    }

    importFromExcelFile(file: File) {
        /* wire up file reader */
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            let items = []
            const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });
            this.rowsCount = rows.length;
            rows.map((row: string[], index) => {
                const strRaw = (row.join('_')).trim();
                if (!strRaw) {
                    return;
                }
                items.push(strRaw);
            });
            let duplicates = [];
            items.map(item => {
                if (!this.items.includes(item)) {
                    this.items.push(item)
                }
                if (duplicates.filter(_ => _.item === item).length) {
                    return;
                }
                const duplicatesItem = items.filter(_ => _ === item).length;
                if (duplicatesItem > 1) {
                    duplicates.push({ item: item, count: duplicatesItem });
                }
            });
            this.duplicates = duplicates;
        };
        reader.readAsBinaryString(file);
    }

    onSubmitImport() {
        this.import.emit(this.items)
    }
}
