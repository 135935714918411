import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../../../shared/models/session.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { SessionFormComponent } from '../session-form/session-form.component';

@Component({
    selector: 'app-session-create',
    templateUrl: './session-create.component.html',
})

export class SessionCreateComponent {

    @Output() afterSupplierSave: EventEmitter<Session> = new EventEmitter();

    @ViewChild(SessionFormComponent, { static: false }) form;

    loading = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Session create');
    }

    afterSave(session: Session) {
        this.afterSupplierSave.emit(session);
    }
}
