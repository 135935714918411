import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UsersFormComponent } from '../users-form/users-form.component';

@Component({
    selector: 'app-users-create',
    templateUrl: 'users-create.component.html',
    styleUrls: [],
})

export class UsersCreateComponent {

    @ViewChild(UsersFormComponent, {static: false}) form: UsersFormComponent;

    loading = false;

    constructor(public router: Router, titleService: Title) {
        titleService.setTitle('Create user');
    }

    afterSave() {
        this.router.navigate(['/users']).then();
    }
}