import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { AppscriptsCryptoService } from "../../../shared/services/appscripts-crypto.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as Vld } from "../../../shared/services/validation.service";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { CryptoWallet } from "../../../shared/models/appscripts-crypto.model";
import { Observable } from "rxjs";
import { BrowserUtils } from "../../../shared/services/browser-utils";

type UiWallet = {loading: boolean, wallet: CryptoWallet};
type Wallets = 'ltc'|'btc';

@Component({
    selector: 'app-appscripts-crypto-wallet-index',
    templateUrl: 'appscripts-crypto-wallet-index.component.html',
    styleUrls: ['appscripts-crypto-wallet-index.component.scss']
})

export class AppscriptsCryptoWalletIndexComponent {

    btc: UiWallet = {
        loading: false,
        wallet: null
    };

    ltc: UiWallet = {
        loading: false,
        wallet: null
    };

    constructor(
        public service: AppscriptsCryptoService,
        public router: Router,
        public notificationService: NotificationService,
        public validationService: ValidationService,
        public modal: ModalService,
        forBuilder: FormBuilder,
        titleService: Title
    ) {
        //titleService.setTitle('Crypto wallets');
    }

    ngOnInit() {
        this.update('btc');
        this.update('ltc');
    }

    update(name: Wallets) {
        const wallet = this.getWallet(name);
        wallet.loading = true;
        this.fetchWallet(name).subscribe({
            next: cryptoWallet => {
                wallet.loading = false;
                wallet.wallet = cryptoWallet;
            },
            error: err => {
                wallet.loading = false;
            }
        });
    }

    copyToBuffer(content: string) {
        BrowserUtils.copyToClipboard(content);
        this.notificationService.success('Data was copied to the clipboard', 'Copy')
    }

    private fetchWallet(name: Wallets): Observable<CryptoWallet> {
        switch (name) {
            case "btc":
                return this.service.btcWallet();
            case "ltc":
                return this.service.ltcWallet();
        }
    }

    private getWallet(name: Wallets): UiWallet {
        switch (name) {
            case "btc":
                return this.btc;
            case "ltc":
                return this.ltc;
        }
    }
}