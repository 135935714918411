
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginMfaType, LoginStep, LoginStepEvent } from "../login-form/login-form.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {

    username: string;
    step: LoginStep = 'login';
    mfaType: LoginMfaType;

    constructor(
        title: Title,
        public router: Router,
    ) {
        title.setTitle('Login');
        this.router = router;
    }

    onLogin() {
        this.router.navigate(['/dashboard']).then();
    }

    onChangeStep(event: LoginStepEvent) {
        this.step = event.step;
        this.username = event.username;
        this.mfaType =  event.mfaType;
    }
}
