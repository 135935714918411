import { Component, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { AppscriptsSmsFilter } from "../../../shared/models/appscripts-sms.model";
import { DropdownCtx } from "../../../shared/directives/dropdown.directive";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../../shared/filter/filter";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";

@Component({
    selector: 'app-appscripts-mo-sms-filter',
    templateUrl: 'appscripts-mo-sms-filter.component.html',
    styleUrls: ['../../../shared/filter/filter.scss'],
})

export class AppscriptsMoSmsFilterComponent {

    @Input() model: AppscriptsSmsFilter = {
        receivedAtFrom: null,
        receivedAtTo: null,
        createdAtFrom: null,
        createdAtTo: null,
        phoneNumbers: null,
        destination: null,
        moId: null,
        text: null,
        telqId: null
    };

    @Output() onSubmit: EventEmitter<AppscriptsSmsFilter> = new EventEmitter();

    loading = false;

    @ViewChild('dateReceivedInput', {read: InputSearchDatePeriod, static: false}) dateReceivedInput: InputSearchDatePeriod;
    @ViewChild('dateCreatedInput', {read: InputSearchDatePeriod, static: false}) dateCreatedInput: InputSearchDatePeriod;

    filters: IFilterDef[] = [
        { title: 'Date received', show: true, order: 1 },
        { title: 'Date created', show: true, order: 2 },
        { title: 'Phone', show: true, order: 3 },
        { title: 'TelQ ID', show: true, order: 4 },
        { title: 'MO ID', show: false , order: 5 },
        { title: 'Destination', show: false, order: 6 },
        { title: 'Text', show: false, order: 7 },
    ];
    private readonly filterStorageKey = 'appscripts-mo-sms-filter';

    constructor(private storage: LocalStorageService) {
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
                if (f.title === 'Date received' && this.dateReceivedInput) { this.dateReceivedInput.reset(); }
                if (f.title === 'Date created' && this.dateCreatedInput) { this.dateCreatedInput.reset(); }
            }
        });
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Date created':
                if (value === '') {
                    this.model.createdAtFrom = null;
                    this.model.createdAtTo = null;
                    filter.value = '';
                } else if (value.startIso && value.endIso) {
                    this.model.createdAtFrom = value.startIso;
                    this.model.createdAtTo = value.endIso;
                    filter.value = value.startFormatStr + ' ' + value.endFormatStr;
                }
                break;
            case 'Date received':
                if (value === '') {
                    this.model.receivedAtFrom = null;
                    this.model.receivedAtTo = null;
                    filter.value = '';
                } else if (value.startIso && value.endIso) {
                    this.model.receivedAtFrom = value.startIso;
                    this.model.receivedAtTo = value.endIso;
                    filter.value = value.startFormatStr + ' ' + value.endFormatStr;
                }
                break;
            case 'Phone':
                this.model.phoneNumbers = value
                filter.value = this.model.phoneNumbers;
                break;
            case 'TelQ ID':
                filter.value = value;
                this.model.telqId = value ? value : null;
                break;
            case 'MO ID':
                filter.value = value;
                this.model.moId = value ? value : null;
                break;
            case 'Destination':
                filter.value = value;
                this.model.destination = value ? value : null;
                break;
            case 'Text':
                filter.value = value;
                this.model.text = value ? value : null;
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onSubmitClick() {
        this.model = CustomUtils.trimModelFields(this.model);
        this.onSubmit.emit(this.model);
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex)
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}