import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllRequestParams, MoShortcodeService } from '../../shared/services/mo-shortcode.service';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-mo-short-code-list',
    templateUrl: './mo-short-code-list.component.html',
    styleUrls: ['./mo-short-code-list.component.scss']
})
export class MoShortCodeListComponent implements OnInit {

    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() unSelect: EventEmitter<any> = new EventEmitter();
    @Input() multiple = false;
    @Input() limit = 0;
    @Input() country = '';
    @Input() numbers: string[] = [];
    @Input() shortCodeCountries: string[] = [];

    requestParams = new AllRequestParams();

    sizes = [10, 20, 30];
    size = 10;
    page = 1;
    pageCountButtons = 5;
    rows = [];
    totalCountRows: number;
    selectCount = 0;
    loading = false;
    loadingSearch = false;
    batchSelected = false;
    selectedIds: Set<number> = new Set();

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(private moShortCodeService: MoShortcodeService) { }

    ngOnInit() {
        if (this.shortCodeCountries.length === 1) {
            this.requestParams.search = this.shortCodeCountries[0];
        }
        this.update();
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    getRows() {
        return this.rows;
    }

    update(search = false) {
        this.loading = !search;
        this.loadingSearch = search;

        this.requestParams.size = this.size;
        this.requestParams.page = this.page - 1;
        this.requestParams.search = this.requestParams.search ? this.requestParams.search : '';
        this.requestParams.setSort('id', 'desc');

        this.moShortCodeService.all(this.requestParams, true).subscribe({
            next: res => {
                this.totalCountRows = res.totalElements
                this.rows = res.content;
                this.rows.forEach(r => {
                    if (this.selectedIds.has(r.id) || this.numbers.includes(r.phoneNumber)) {
                        r.selected = true;
                    }
                });
                this.loading = false;
                this.loadingSearch = false;
            },
            error: err => {
                this.loading = false;
                this.loadingSearch = false;
            }
        });
    }

    onChangePage(page) {
        this.page = page;
        this.update();
    }

    onChangeSize(size) {
        this.size = size;
        this.update();
    }

    onClickRow(row) {
        let selected = !row.selected;
        if (this.multiple && this.limit > 0 && selected && this.selectCount >= this.limit) { return; }
        row.selected = selected;
        if (row.selected) {
            this.selectCount++;
            this.selectedIds.add(row.id);
            this.requestParams.search = row.country.replace(/\([^)]*\)/g, "").trim();
            this.select.emit(row);
        } else {
            this.selectCount--;
            this.selectedIds.delete(row.id);
            this.requestParams.search = this.selectedIds.size > 0 ? this.requestParams.search : '';
            this.unSelect.emit(row);
        }
        this.update();
    }

    onClickBatchSelect() {
        this.batchSelected = !this.batchSelected;
        this.rows.filter(_ => _.selected !== this.batchSelected).forEach(user => this.onClickRow(user));
    }

    onChangeInputSearch(text: string) {
        this.requestParams.search = text;
        this.page = 1;
        this.update();
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

}
