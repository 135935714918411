
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { AppscriptsPaymentRequestFilter } from '../../../shared/models/appscripts-payment-request.model';
import { DropdownCtx } from "../../../shared/directives/dropdown.directive";
import {
    AppscriptsPaymentMethodParams,
    AppscriptsPaymentMethodService
} from "../../../shared/services/appscripts-payment-method.service";
import { AppscriptsPaymentMethod } from "../../../shared/models/appscripts-payment-method.model";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../../shared/filter/filter";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";

@Component({
    selector: 'app-appscripts-payment-request-filter',
    templateUrl: 'appscripts-payment-request-filter.component.html',
    styleUrls: ['../../../shared/filter/filter.scss'],
})

export class AppscriptsPaymentRequestFilterComponent implements OnInit {

    @Output() onSubmit: EventEmitter<AppscriptsPaymentRequestFilter> = new EventEmitter();

    loading = false;

    @ViewChild(InputSearchDatePeriod, {static: false}) dateInput: InputSearchDatePeriod;

    model: AppscriptsPaymentRequestFilter = {
        createdAtFrom: null,
        createdAtTo: null,
        amountMin: null,
        amountMax: null,
        email: null,
        phoneNumbers: null,
        paymentMethodName: null,
        paymentAddress: null,
    };

    filters: IFilterDef[] = [
        { title: 'Date', show: true, order: 1 },
        { title: 'Phone', show: true, order: 2 },
        { title: 'Payment method', show: true, order: 3 },
        { title: 'Amount', show: true, order: 4 },
        { title: 'Email', show: false, order: 5 },
        { title: 'Payment address', show: false, order: 6 },
    ];
    private readonly filterStorageKey = 'appscripts-payment-requests-filter';

    paymentMethods: AppscriptsPaymentMethod[] = [];

    constructor(
        private paymentMethodService: AppscriptsPaymentMethodService,
        private storage: LocalStorageService,
    ) {
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    ngOnInit() {
        const params = new AppscriptsPaymentMethodParams();
        params.page = 0;
        params.size = 100;
        this.paymentMethodService.all(params).subscribe({
            next: methods => this.paymentMethods = methods,
        });
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
                if (f.title === 'Date' && this.dateInput) {
                    this.dateInput.reset();
                }
            }
        });
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onSubmitClick() {
        this.model = CustomUtils.trimModelFields(this.model);
        this.onSubmit.emit(this.model);
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Date':
                if (value === '') {
                    this.model.createdAtFrom = null;
                    this.model.createdAtTo = null;
                    filter.value = '';
                } else if (value.startIso && value.endIso) {
                    this.model.createdAtFrom = value.startIso;
                    this.model.createdAtTo = value.endIso;
                    filter.value = value.startFormatStr + ' ' + value.endFormatStr;
                }
                break;
            case 'Phone':
                this.model.phoneNumbers = value
                filter.value = this.model.phoneNumbers;
                break;
            case 'Amount':
                if (value === '') {
                    this.model.amountMin = null;
                    this.model.amountMax = null;
                    filter.value = '';
                } else {
                    this.model.amountMin = value.min ? value.min : null;
                    this.model.amountMax =  value.max ? value.max : null;
                    if (this.model.amountMin !== null && this.model.amountMax === null) {
                        filter.value = `From ${value.min}`;
                    } else if (this.model.amountMin === null && this.model.amountMax !== null) {
                        filter.value = `To ${value.max}`;
                    } else {
                        filter.value = `${value.min} - ${value.max}`;
                    }
                }
                break;
            case 'Payment method':
                filter.value = value;
                this.model.paymentMethodName = value ? value : null;
                break;
            case 'Email':
                filter.value = value;
                this.model.email = value ? value : null;
                break;
            case 'Payment address':
                filter.value = value;
                this.model.paymentAddress = value ? value : null;
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex)
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}