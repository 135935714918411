import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SchedulerService } from '../../shared/services/scheduler.service';
import { SchedulerFormComponent } from '../scheduler-form/scheduler-form.component';

@Component({
    selector: 'app-scheduler-create',
    templateUrl: './scheduler-create.component.html',
    styleUrls: [],
})

export class SchedulerCreateComponent implements OnInit {

    @ViewChild(SchedulerFormComponent, { static: false }) form: SchedulerFormComponent;
    @Output() onScheduleAfterSave: EventEmitter<boolean> = new EventEmitter<boolean>();

    loading: boolean = true;

    taskId: number;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public service: SchedulerService,
        public notificationService: NotificationService,
        public storage: LocalStorageService,
        titleService: Title,
        private location: Location
    ) {
        titleService.setTitle('Create task');
    }

    ngOnInit() {
        const currentPath = this.location.path();
        const searchParams = new URLSearchParams(currentPath.substring(currentPath.indexOf('?')));
        this.taskId = Number(searchParams.get('id'));
    }

    onAfterSave(data: boolean) {
        this.onScheduleAfterSave.emit(data);
    }

    onLoadForm() {
        let testGroup = this.storage.getMem('testGroup');
        if (testGroup) {
            testGroup.tlvDtos = testGroup.tlv;
            testGroup.udhDtos = testGroup.udh;
            testGroup.supplierSessionsIds = testGroup.sessionsSuppliers;
            this.storage.removeMem('testGroup');
            this.form.tests.setTestGroup(testGroup, testGroup);
            this.loading = false;
            return;
        }
        if (this.taskId) {
            this.service.one(this.taskId).subscribe(task => {
                this.form.setModel(task);
                this.loading = false;
            });
            return;
        }
        this.loading = false;
    }
}
